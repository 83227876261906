import { MyOrderCancel } from 'autogen/swagger/all';
import { MyOrder, Shared } from 'autogen/translation-keys/trans-root-website';
import i18next from 'i18next';
import mediator from 'modules/mediator';
import type { Dispatch, SetStateAction } from 'react';

export default async function cancelOrder(
    orderGuid: string,
    orderNumber: number,
    isMatasPlus: boolean,
    setLoading: Dispatch<SetStateAction<boolean>>
) {
    const { default: confirmationModal } = await import(
        /* webpackChunkName: "confirmationModal" */ 'modules/modal/confirmationModal'
    );
    const modal = confirmationModal();

    function errorHandling() {
        setLoading(false);

        mediator.publish('growl', {
            message: i18next.t(MyOrder.CancelFailed)
        });
    }

    function onConfirm() {
        setLoading(true);
        MyOrderCancel({ orderGuid })
            .then(() => {
                setLoading(false);
                window.location.reload();
            })
            .catch(errorHandling);
    }

    function ConfirmModalContent(props: { message: string }): JSX.Element {
        const { message } = props;

        return (
            <div className="mt-4 mb-5 text-center">
                {/* biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation> */}
                <span dangerouslySetInnerHTML={{ __html: message }} />
                <br />
                <span>{`${i18next.t(MyOrder.OrderNumber)} ${orderNumber}`}</span>
            </div>
        );
    }

    const content = isMatasPlus
        ? i18next.t(MyOrder.ConfirmCancellationDescriptionMatasPlus)
        : i18next.t(MyOrder.ConfirmCancellationDescription);

    modal({
        open: true,
        header: i18next.t(MyOrder.ConfirmCancellation),
        content: <ConfirmModalContent message={content} />,
        confirmText: i18next.t(MyOrder.Cancel),
        denyText: i18next.t(Shared.NoThanks),
        onConfirm
    });
}
