import React from 'react';
import styled, { css } from 'styled-components';
import { EmblaCarousel, Viewport, Slide, Prev, Next } from 'components/emblaCarousel';
import { EmblaOptionsType } from 'embla-carousel-react';
import { SampleSelectionViewModel } from 'autogen/swagger/all';
import {
    BorderRadiusMedium,
    BoxShadowSmall,
    ColorNeutralWhite,
    ColorNordicGrey1,
    Spacing8,
    Spacing12,
    Spacing16
} from 'autogen/design-tokens/tokens';
import { breakpoint, sizes } from 'variables';
import PickerCard from './PickerCard';

const SliderWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
`;

const Container = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: calc(25% - ${Spacing8});
    gap: ${Spacing8};
`;

const StyledPickerCard = styled(PickerCard)<{ isRecommended: boolean }>`
    ${({ isRecommended }) => !isRecommended && `margin-top: 36px;`};
    ${({ isRecommended }) => isRecommended && 'height: 100%;'};

    ${breakpoint.up(sizes.xxxl)} {
        ${({ isRecommended }) => !isRecommended && `margin-top: 41px;`};
    }
`;

const navigationButtonStyling = css`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    background-color: ${ColorNeutralWhite};
    border: 1px solid ${ColorNordicGrey1};
    border-radius: ${BorderRadiusMedium};
    padding: ${Spacing12};
    z-index: 1;
    box-shadow: ${BoxShadowSmall};
`;

const StyledPrev = styled(Prev)`
    ${navigationButtonStyling}
    left: -${Spacing16};
`;
const StyledNext = styled(Next)`
    ${navigationButtonStyling}
    right: -${Spacing16};
`;

const StyledViewport = styled(Viewport)`
    width: 100%;
`;

const options: EmblaOptionsType = {
    slidesToScroll: 4,
    draggable: true,
    dragFree: false,
    loop: false,
    speed: 10,
    inViewThreshold: 0.5,
    align: 'start',
    containScroll: 'trimSnaps'
};

interface ISampleSlider {
    samples: SampleSelectionViewModel[];
    locked: boolean;
}

export default function SamplesSlider({ samples, locked }: ISampleSlider): JSX.Element {
    return (
        <EmblaCarousel options={options}>
            <SliderWrapper>
                <StyledPrev />
                <StyledViewport>
                    <Container>
                        {samples.map((sample) => {
                            const { id } = sample;
                            return (
                                <Slide key={id}>
                                    <StyledPickerCard {...{ ...sample, locked, isDesktop: true }} />
                                </Slide>
                            );
                        })}
                    </Container>
                </StyledViewport>
                <StyledNext />
            </SliderWrapper>
        </EmblaCarousel>
    );
}
