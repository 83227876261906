import { OpenMenu, Menu } from 'autogen/translation-keys/trans-website-shared';
import { Button } from 'components/Button';
import useSmartviewManager from 'components/generic/smartview/shared/hooks/useSmartviewManager';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { breakpoint, sizes } from 'variables';
import BurgerLines from 'icons/BurgerLines.svg?react';
import mediator from 'modules/mediator';
import RootMenu from './components/Menu/RootMenu';
import { themed } from 'helpers/designThemes';

const StyledText = styled.span`
    display: none;
    margin-left: 8px;

    ${breakpoint.up(sizes.sm)} {
        display: flex;
    }
`;

const ThemedButton = themed(Button, { color: 'MenuButton' });

export default function SideMenuOpener() {
    const { open } = useSmartviewManager('side-menu');
    const { t } = useTranslation();

    useEffect(() => {
        mediator.subscribe('openSideMenu', open);
    }, []);

    return (
        <>
            <ThemedButton aria-label={t(OpenMenu)} onClick={open} variant="secondary">
                <BurgerLines aria-hidden />
                <StyledText>{t(Menu)}</StyledText>
            </ThemedButton>

            {/* SideMenu gets mounted using React portal internally */}
            <RootMenu />
        </>
    );
}
