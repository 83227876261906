import { MenuItemCustomActionWithUrlViewModel } from 'autogen/swagger/all';
import ClubMatasMenuItem from './Items/ClubMatasMenuItem';
import ClubMatasPlusMenuItem from './Items/ClubMatasPlusMenuItem';
import { DefaultMenuItemProps } from './types';

type CustomActionMenuItemRendererProps = {
    model: MenuItemCustomActionWithUrlViewModel;
    defaultProps: DefaultMenuItemProps;
};

/**
 * All Custom MenuItem actions with Url should be added here
 */
export default function CustomActionMenuItemRenderer({
    model,
    defaultProps,
    ...restProps
}: CustomActionMenuItemRendererProps) {
    switch (model.customAction) {
        case 'ClubMatas':
            return <ClubMatasMenuItem model={model} defaultProps={defaultProps} {...restProps} />;
        case 'ClubMatasPlus':
            return <ClubMatasPlusMenuItem model={model} defaultProps={defaultProps} {...restProps} />;
        default:
            return null;
    }
}
