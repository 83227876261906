import { ColorNordicGrey4 } from 'autogen/design-tokens/tokens';
import type { BasketLineViewModel } from 'autogen/swagger/all';
import { useUpdateQuantityMutation } from 'autogen/swagger/all/hooks';
import { Pieces } from 'autogen/translation-keys/trans-website-basket';
import buttonSizes from 'components/Button/shared/buttonSizes';
import { updateBasket } from 'components/basket/shared/basket';
import Select, { type IOption } from 'components/shared/inputs/select';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

function createNumberArray(start, end) {
    const result: number[] = [];

    for (let i = start; i <= end; i++) {
        result.push(i);
    }

    return result;
}

const StyledSelect = styled(Select)`
    && {
        border: none;
        background-color: var(--color-cta-secondary-base);
    }
`;

export default function QuantitySelect({
    productId,
    maximumSalesQuantity,
    minimumSalesQuantity,
    quantity,
    ...restProps
}: {
    productId: BasketLineViewModel['productId'];
    maximumSalesQuantity: BasketLineViewModel['maximumSalesQuantity'];
    minimumSalesQuantity: BasketLineViewModel['minimumSalesQuantity'];
    quantity: BasketLineViewModel['quantity'];
}) {
    const { t } = useTranslation();
    const { mutateAsync: updateQuantity } = useUpdateQuantityMutation({ onSuccess: updateBasket });
    const maxDisplayableOptions = 10;
    const quantityArray = createNumberArray(
        minimumSalesQuantity,
        Math.min(maximumSalesQuantity, maxDisplayableOptions)
    );

    const options = useMemo(
        () =>
            quantityArray.map((quantityAmount) => {
                if (quantityAmount === 10)
                    return {
                        value: quantityAmount.toString(),
                        label: '9 +'
                    };

                return {
                    value: quantityAmount.toString(),
                    label: `${quantityAmount} ${t(Pieces)}`
                };
            }),
        [quantityArray, t]
    );

    const selectedQuantity = options.find((option) => option.value.toString() === quantity.toString());

    const handleOnchange = async (option: IOption) => {
        const valueInt = Number(option.value);

        if (valueInt === quantity) return;

        const { willAffectSamplesConfirm } = await import('views/basket/samples');
        const { default: track } = await import('modules/tracking/dataLayer');

        /*
            if switching the quantity affects the sample you have in basket open a confirm modal
            if user denies the change return without updating quantity.
        */
        if (!(await willAffectSamplesConfirm({ productId, quantity: valueInt }))) return;
        const { dataLayerDto } = await updateQuantity({ params: { productId, quantity: valueInt } });

        track(dataLayerDto);
    };

    return (
        <StyledSelect
            {...restProps}
            name="quantity"
            options={options}
            value={selectedQuantity}
            onChange={handleOnchange}
        />
    );
}

const Container = styled.div`
    display: flex;
    align-items: center;
    ${buttonSizes.md}
    background-color: ${ColorNordicGrey4};
`;

export function QuantityText({ quantity, ...restProps }: { quantity: number }) {
    const { t } = useTranslation();
    return <Container {...restProps}>{`${quantity} ${t(Pieces)}`}</Container>;
}
