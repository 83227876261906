/* eslint-disable */
/// This file is auto generated - do not edit manually
export * as Sorting from './trans-website-myprofile-orderlist-sorting';

export const OrderStatus = 'Website.MyProfile.OrderList.OrderStatus';
export const SeeOrder = 'Website.MyProfile.OrderList.SeeOrder';
export const SeeOrderStatus = 'Website.MyProfile.OrderList.SeeOrderStatus';
export const SeeReceipt = 'Website.MyProfile.OrderList.SeeReceipt';
export const SeeInvoicePDF = 'Website.MyProfile.OrderList.SeeInvoicePDF';
export const SwapCertificateOrderPDF = 'Website.MyProfile.OrderList.SwapCertificateOrderPDF';
export const NoOrders = 'Website.MyProfile.OrderList.NoOrders';
export const OnlineOrder = 'Website.MyProfile.OrderList.OnlineOrder';
export const Subscription = 'Website.MyProfile.OrderList.Subscription';
export const OfflineOrder = 'Website.MyProfile.OrderList.OfflineOrder';
export const WebshopOrdersUnavailable = 'Website.MyProfile.OrderList.WebshopOrdersUnavailable';
export const OfflineOrdersUnavailable = 'Website.MyProfile.OrderList.OfflineOrdersUnavailable';
export const ExpandProductAccordion = 'Website.MyProfile.OrderList.ExpandProductAccordion';
export const CollapseProductAccordion = 'Website.MyProfile.OrderList.CollapseProductAccordion';
