import styled from 'styled-components';
import { zIndex } from 'variables';
import { customScrollbar } from 'modules/helpers/mixins';
import { useEffect, type UIEventHandler } from 'react';
import { useSetRecoilState } from 'recoil';
import type { QuickViewContentProps } from '../../shared/types';
import { quickViewHeaderBackgroundState } from '../../atoms/quickViewState';
import { QuickViewContent } from '../DefaultQuickViewComponents';

const StyledDefaultQuickContentContainer = styled(QuickViewContent)`
    z-index: calc(${zIndex.modal} + 1);
`;

const Scroller = styled.div`
    display: flex;
    overflow: auto;
    ${customScrollbar};
    width: 100%;
`;

export default function AbsoluteQuickViewContentContainer({ children, ...restProps }: QuickViewContentProps) {
    const setShowHeaderBackground = useSetRecoilState(quickViewHeaderBackgroundState);

    const handleScroll: UIEventHandler<HTMLDivElement> = (e) => {
        if (e.currentTarget.scrollTop > 0) {
            setShowHeaderBackground(true);
        } else {
            setShowHeaderBackground(false);
        }
    };

    useEffect(() => {
        return () => {
            setShowHeaderBackground(false);
        };
    }, []);

    return (
        <StyledDefaultQuickContentContainer {...restProps}>
            <Scroller onScroll={handleScroll}>{children}</Scroller>
        </StyledDefaultQuickContentContainer>
    );
}
