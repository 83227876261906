/* eslint-disable */
/// This file is auto generated - do not edit manually
export const Thanks = 'Website.KicksIpadPosReceipt.Thanks';
export const YourOrderNumber = 'Website.KicksIpadPosReceipt.YourOrderNumber';
export const CustomerEmail = 'Website.KicksIpadPosReceipt.CustomerEmail';
export const UserSearchPlaceholder = 'Website.KicksIpadPosReceipt.UserSearchPlaceholder';
export const UserSearch = 'Website.KicksIpadPosReceipt.UserSearch';
export const UserSearchHeadline = 'Website.KicksIpadPosReceipt.UserSearchHeadline';
export const ChooseStore = 'Website.KicksIpadPosReceipt.ChooseStore';
export const ChooseStoreError = 'Website.KicksIpadPosReceipt.ChooseStoreError';
