import { type CustomActionType, CustomActionDiscriminator } from 'autogen/swagger/all';
import { type ComponentPropsWithRef, forwardRef, memo } from 'react';
import CertificationAction from './components/CertificationAction';
import SurveyAction from './components/SurveyAction';
import LiveEventAction from './components/LiveEventAction';
import CustomerServiceAction from './components/CustomerServiceAction';
import VimeoAction from './components/VimeoAction';

export type CustomAction = CustomActionType['action'];

export type CustomActionProps = {
    action: CustomAction;
} & ComponentPropsWithRef<'button'>;

const CustomAction = forwardRef<HTMLButtonElement, CustomActionProps>(({ action, ...restProps }, ref) =>
    CustomActionDiscriminator(
        action,
        (certificationAction) => <CertificationAction action={certificationAction} ref={ref} {...restProps} />,
        (javascriptHookAction) => <CustomerServiceAction action={javascriptHookAction} ref={ref} {...restProps} />,
        (liveEventAction) => <LiveEventAction action={liveEventAction} ref={ref} {...restProps} />,
        (surveyAction) => <SurveyAction action={surveyAction} ref={ref} {...restProps} />,
        (vimeoAction) => <VimeoAction action={vimeoAction} ref={ref} {...restProps} />,
        () => {
            throw new Error('Invalid Custom Action');
        }
    )
);

export default memo(CustomAction);
