import React, { ReactNode, memo } from 'react';
import { MenuViewModel } from 'autogen/swagger/all';
import MenuHeader from './MenuHeader';
import { StyledSmartSideDrawerView } from '../../../shared/styles';
import SideMenu from '../SideMenu';
import MenuSkeleton from './MenuSkeleton';

type HeaderProps = {
    backButtonCallback: MenuWithHeaderProps['backButtonCallback'];
    headline: MenuViewModel['headline'];
    headerLinks?: MenuViewModel['headerLinks'];
    styling?: MenuViewModel['styling'];
};

type MenuWithHeaderProps = {
    smartSideDrawerId: string;
    menu?: MenuViewModel;
    backButtonCallback?: () => void;
    isLoading?: boolean;
    animate?: boolean;
    headerRenderer?: (headerProps: HeaderProps) => ReactNode;
};

const MenuWithHeader = ({
    smartSideDrawerId,
    menu,
    backButtonCallback,
    isLoading,
    animate,
    headerRenderer = (headerProps) => <MenuHeader {...headerProps} />
}: MenuWithHeaderProps) => {
    const { headline, headerLinks, styling } = menu || {};

    return (
        <StyledSmartSideDrawerView name={smartSideDrawerId} animate={animate}>
            {headerRenderer({ backButtonCallback, headline, headerLinks, styling })}
            {isLoading || !menu || !menu.sections.length ? <MenuSkeleton /> : <SideMenu menu={menu} />}
        </StyledSmartSideDrawerView>
    );
};

export default memo(MenuWithHeader);
