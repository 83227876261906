import { MenuViewModel } from 'autogen/swagger/all';
import React, { memo } from 'react';
import SideMenuSection from '../Section/SideMenuSection';
import MenuStyleRenderer from './components/MenuStyleRenderer';

const SideMenu = ({ menu: { sections, styling } }: { menu: MenuViewModel }) => (
    <MenuStyleRenderer styling={styling}>
        {sections.map((section) => (
            <SideMenuSection key={section.id} section={section} />
        ))}
    </MenuStyleRenderer>
);

export default memo(SideMenu);
