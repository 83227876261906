/* eslint-disable react/no-array-index-key */
import {
    type BasketAdditionalSummary,
    type AdditionalSummaryLine as AdditionalSummaryLineModel
} from 'autogen/swagger/all';
import React, { ReactNode } from 'react';
import AdditionalSummaryLine from './AdditionalSummaryLine';

type AdditionalSummaryRenderer = Record<string, any> & {
    model: BasketAdditionalSummary;
    renderer?: (model: AdditionalSummaryLineModel, props) => ReactNode;
};

export default function AdditionalSummaryRenderer({
    model: { additionalSummaryLines },
    renderer = (additionalSummaryLine, props) => <AdditionalSummaryLine {...props} model={additionalSummaryLine} />,
    ...restProps
}: AdditionalSummaryRenderer) {
    return (
        <>
            {additionalSummaryLines.map((additionalSummaryLine, index) => (
                <React.Fragment key={index}>{renderer(additionalSummaryLine, restProps)}</React.Fragment>
            ))}
        </>
    );
}
