import styled, { css } from 'styled-components';
import type { ColorTheme } from 'types/tokens';
import { kebabCase } from 'lodash-es';

export function getTheme(theme: ColorTheme): DesignTheme {
    if (!theme) return {};

    const themeName = kebabCase(theme);

    return {
        color1: `var(--color-${themeName}-1)`,
        color2: `var(--color-${themeName}-2)`,
        color3: `var(--color-${themeName}-3)`,
        color4: `var(--color-${themeName}-4)`,
        color5: `var(--color-${themeName}-5)`
    };
}

export interface DesignTheme {
    color1?: string;
    color2?: string;
    color3?: string;
    color4?: string;
    color5?: string;
}

type Theme = { color: ColorTheme };

export function themed<T>(
    Component: React.FC<T>,
    theme: Theme | undefined,
    getThemeNameFromProps?: (props: T) => ColorTheme | string
) {
    const ThemedComponent = styled(Component)<{ $theme: Theme | undefined }>`
        ${({ $theme }) => {
            if (!$theme) {
                return css``;
            }

            const themeName = kebabCase($theme.color);

            return css`
            --color-1: var(--color-${themeName}-1);
            --color-2: var(--color-${themeName}-2);
            --color-3: var(--color-${themeName}-3);
            --color-4: var(--color-${themeName}-4);
            --color-5: var(--color-${themeName}-5);
            --color-base: var(--color-${themeName}-base);
            --color-hover: var(--color-${themeName}-hover);
            --color-focus: var(--color-${themeName}-focus);
            --color-selected: var(--color-${themeName}-selected);
            --color-activated: var(--color-${themeName}-activated);
            --color-pressed: var(--color-${themeName}-pressed);
            --color-readonly: var(--color-${themeName}-readonly);
        `;
        }}
    `;

    return (props) => {
        const themeNameFromProps = getThemeNameFromProps?.(props);
        const $theme = (themeNameFromProps && { color: themeNameFromProps }) || theme;

        return <ThemedComponent $theme={$theme} {...props} />;
    };
}
