import { ColorNeutralWhite, Spacing12 } from 'autogen/design-tokens/tokens';
import { ButtonWithIcon } from 'components/Button';
import InlineButton from 'components/generic/InlineButton';
import { Typography } from 'components/generic/typography';
import format from 'helpers/translations';
import { isValidElement } from 'react';
import styled from 'styled-components';

/* #region  styles */
const TooltipHeader = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 8px;
`;

const Headline = styled(Typography.Body)`
    color: ${ColorNeutralWhite};
    margin: 0;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 280px;
`;

const StyledButtonWithIcon = styled(ButtonWithIcon)`
    align-self: self-end;
    box-sizing: content-box;
    width: 20px;
    height: 20px;
    padding: 0;
`;

const StyledBody = styled(Typography.Body)`
    color: ${ColorNeutralWhite};
    margin-bottom: ${Spacing12};
`;

/* #endregion */

export interface ILargeTooltipContent {
    title?: string;
    description: string;
    dismissButtonTitle?: string;
}

export default function LargeTooltipPopupContent({
    content,
    close
}: {
    content: ILargeTooltipContent;
    close: () => void;
}) {
    const { title, description, dismissButtonTitle } = content;

    return (
        <Content>
            <TooltipHeader>
                <Headline themeTypes="mediumSemiBold">{title}</Headline>
                <StyledButtonWithIcon
                    onClick={close}
                    icon="Close"
                    variant="primaryTextNegative"
                    size="sm"
                    iconProperties={{ size: '20px' }}
                />
            </TooltipHeader>

            <StyledBody themeTypes="medium">
                {isValidElement(description) ? description : format(description)}
            </StyledBody>

            {dismissButtonTitle && (
                <InlineButton onClick={close} variant="light" fontWeight="semibold" size="sm">
                    {dismissButtonTitle}
                </InlineButton>
            )}
        </Content>
    );
}
