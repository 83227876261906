import { ProductNameLine } from 'autogen/swagger/all';
import { Typography } from 'components/generic/typography';
import { ReactNode } from 'react';
import styled from 'styled-components';
import { verticalEllipsis } from 'modules/helpers/mixins';
import { FontLineHeightNormal } from 'autogen/design-tokens/tokens';
import { Variant } from '../shared/styles';

const StyledTypographyBody = styled(Typography.Body)`
    ${verticalEllipsis(1)}

    span {
        line-height: ${FontLineHeightNormal};
    }
`;

const StyledVariant = styled(Variant)`
    display: block;
    ${verticalEllipsis(1)}
`;

const compactNameRenderer = (line: ProductNameLine): JSX.Element | ReactNode => {
    const { type, value } = line;

    switch (type) {
        case 'Variant':
        case 'Color':
        case 'Size':
            return (
                <StyledVariant key={value} forwardedAs="span" themeTypes="small">
                    {value}
                </StyledVariant>
            );
        default:
            return (
                <StyledTypographyBody key={value} forwardedAs="span" themeTypes="small">
                    {value}
                </StyledTypographyBody>
            );
    }
};

export default compactNameRenderer;
