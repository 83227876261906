import fetch from 'cross-fetch';

const defaultHeaders = {
    'X-Requested-With': 'XMLHttpRequest'
};

type NestedRecord = {
    [k: string]: number | string | boolean | null | undefined | File | NestedRecord;
};

export const options = ({ method = 'GET', headers = {} }): RequestInit => ({
    method,
    headers: { ...defaultHeaders, ...headers },
    credentials: 'same-origin'
});

export const getJSON = options({
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
    }
});

export const getHTML = options({
    headers: {
        'Content-Type': 'text/html',
        Accept: 'text/html'
    }
});

export const fetchHTML = (url: string, customOptions: RequestInit = {}): Promise<string> =>
    fetch(url, { ...getHTML, ...customOptions })
        .then((res) => {
            if (!res.ok) {
                throw new Error(`Failed to fetch HTML content from: ${url}`);
            }
            return res.text();
        })
        .catch((error) => {
            // Throw error to enable custom error handling when using fetchHTML()
            throw error;
        });

export function fetchJSON<T>(url: string, customOptions: RequestInit = {}): Promise<T> {
    return fetch(url, { ...getJSON, ...customOptions })
        .then((res) => {
            if (!res.ok) {
                throw new Error(`Failed to fetch JSON content from: ${url}`);
            }
            if (res.status === 204) {
                return undefined;
            }
            return res.json();
        })
        .catch((error) => {
            // Throw error to enable custom error handling when using fetchHTML()
            throw error;
        });
}

export function fetchWithTimeout<T>(fetchFunc: (url: string, options: RequestInit) => Promise<T>, timeout: number) {
    return (url: string, optionsObject = {}): Promise<T> => {
        const controller = new window.AbortController();
        const { signal } = controller;
        const promise = fetchFunc(url, { ...optionsObject, signal });
        const timeoutId = setTimeout(() => {
            controller.abort();
        }, timeout);
        promise.then(() => clearTimeout(timeoutId));
        return promise;
    };
}

export function formatParams<
    T extends Record<
        string,
        Array<number | string | boolean | undefined | null> | number | string | boolean | undefined | null
    >
>(params: T): string {
    return Object.entries(params).reduce((acc, [key, value]) => {
        if (value === undefined || value === null) return acc;
        return `${acc}${!acc ? '?' : '&'}${
            Array.isArray(value)
                ? value.map((val) => `${key}=${encodeURIComponent(val)}`).join('&')
                : `${key}=${encodeURIComponent(value)}`
        }`;
    }, '');
}

export function extractParams(
    url: string
): Record<
    string,
    Array<number | string | boolean | undefined | null> | number | string | boolean | undefined | null
>[] {
    const { origin, pathname, searchParams } = new URL(url, window.location.origin);
    const base = `${origin}${pathname}`;
    const params = Array.from(searchParams.entries()).reduce(
        // biome-ignore lint/performance/noAccumulatingSpread: <explanation>
        (acc, [key, val]) => ({ ...acc, [key]: val }),
        {}
    );

    return [base, params];
}

const AddObjectToFormData = (formData: FormData, obj: NestedRecord, prependKey: string): void => {
    for (const [key, val] of Object.entries(obj)) {
        if (Array.isArray(val)) {
            for (const value of val) {
                formData.append(`${key}[]`, value.toString());
            }
        } else if (val instanceof File) {
            formData.append(`${prependKey}${key}`, val);
        } else if (typeof val === 'object' && val !== null) {
            AddObjectToFormData(formData, val, `${prependKey}${key}.`);
        } else {
            formData.append(`${prependKey}${key}`, val.toString());
        }
    }
};

export function postForm<T>(url: string, form, signal?: AbortSignal): Promise<T> {
    return fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json'
        },
        body: form,
        credentials: 'same-origin',
        signal
    })
        .then((res) => {
            if (!res.ok) {
                throw new Error(`Failed to fetch JSON content from: ${url}`);
            }
            return res.json() as Promise<T>;
        })
        .catch((error) => {
            // Throw error to enable custom error handling when using postFormData()
            throw error;
        });
}

export function postFormData<T>(
    url: string,
    // eslint-disable-next-line @typescript-eslint/ban-types
    data: NestedRecord,
    signal?: AbortSignal
): Promise<T> {
    if (typeof data !== 'object') {
        throw new Error('Data should be an object');
    }
    const formData = new window.FormData();
    AddObjectToFormData(formData, data, '');
    return postForm<T>(url, formData, signal);
}

export function postJSON<T>(
    url: string,
    // eslint-disable-next-line @typescript-eslint/ban-types
    data: Record<string, object | number | string | boolean | null | undefined | unknown>
): Promise<T> {
    if (typeof data !== 'object') {
        throw new Error('Data should be an object');
    }
    return fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
        credentials: 'same-origin'
    })
        .then((res) => {
            if (!res.ok) {
                throw new Error(`Failed to fetch JSON content from: ${url} status: ${res.status}`);
            }
            if (res.headers.get('content-length') === '0') return Promise.resolve({} as T);
            return res.json() as Promise<T>;
        })
        .catch((error) => {
            // Throw error to enable custom error handling when using postFormData()
            throw error;
        });
}

export function putJSON<T>(
    url: string,
    // eslint-disable-next-line @typescript-eslint/ban-types
    data: Record<string, object | number | string | boolean | null | undefined>
): Promise<T> {
    if (typeof data !== 'object') {
        throw new Error('Data should be an object');
    }
    return fetch(url, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
        credentials: 'same-origin'
    })
        .then((res) => {
            if (!res.ok) {
                throw new Error(`Failed to fetch JSON content from: ${url} status: ${res.status}`);
            }
            if (res.headers.get('content-length') === '0') return Promise.resolve({} as T);
            return res.json() as Promise<T>;
        })
        .catch((error) => {
            // Throw error to enable custom error handling when using postFormData()
            throw error;
        });
}

export function postQuery<T>(url: string): Promise<T> {
    if (typeof url !== 'string' || url.length < 1) return Promise.reject();

    return fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json'
        },
        credentials: 'same-origin'
    })
        .then((res) => {
            if (!res.ok) {
                throw new Error(`Failed to POST to: ${url}`);
            }
            return res.json() as Promise<T>;
        })
        .catch((error) => {
            throw error;
        });
}

export function putQuery<T>(url: string): Promise<T> {
    if (typeof url !== 'string' || url.length < 1) return Promise.reject();

    return fetch(url, {
        method: 'PUT',
        headers: {
            Accept: 'application/json'
        },
        credentials: 'same-origin'
    })
        .then((res) => {
            if (!res.ok) {
                throw new Error(`Failed to PUT to: ${url}`);
            }
            return res.json() as Promise<T>;
        })
        .catch((error) => {
            throw error;
        });
}

export function deleteQuery<T>(url: string): Promise<T> {
    if (typeof url !== 'string' || url.length < 1) return Promise.reject();

    return fetch(url, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json'
        },
        credentials: 'same-origin'
    })
        .then((res) => {
            if (!res.ok) {
                throw new Error(`Failed to DELETE to: ${url}`);
            }
            return res.json() as Promise<T>;
        })
        .catch((error) => {
            throw error;
        });
}

export const POST = (url: string): Promise<Response> => {
    if (typeof url !== 'string' || url.length < 1) return Promise.reject();

    return fetch(url, {
        method: 'POST'
    })
        .then((res) => {
            if (!res.ok) {
                throw new Error(`Failed to POST to: ${url}`);
            }
            return res;
        })
        .catch((error) => {
            throw error;
        });
};

export const GET = (url: string): Promise<Response> => {
    if (typeof url !== 'string' || url.length < 1) return Promise.reject();

    return fetch(url, {
        method: 'GET'
    })
        .then((res) => {
            if (!res.ok) {
                throw new Error(`Failed to GET to: ${url}`);
            }
            return res;
        })
        .catch((error) => {
            throw error;
        });
};
