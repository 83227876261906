import { GetLiveEventStatusModel } from 'autogen/swagger/all';

export default async function openBambuser(liveEventId: number) {
    const eventStatus = await GetLiveEventStatusModel({ id: liveEventId });

    const { importBambuserLiveShopping, initBambuserLiveShopping } = await import(
        /* webpackChunkName: "liveFunctions" */ 'components/live/liveFunctions'
    );

    importBambuserLiveShopping();
    initBambuserLiveShopping();
    window.initBambuserLiveShopping({
        showId: eventStatus.bambuserShowId,
        type: 'overlay'
    });
}
