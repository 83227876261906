import { ColorNeutralGrey5, ColorBrandBlue5, Spacing16, ColorNordicGrey3 } from 'autogen/design-tokens/tokens';
import { useMiniBasketIndexQuery as useMiniBasketQuery } from 'autogen/swagger/all/hooks';
import { GoToBasket } from 'autogen/translation-keys/trans-website-basket';
import { Headline } from 'autogen/translation-keys/trans-website-minibasket';
import Flex from 'components/Layout/components/Flex';
import BasketSummary from 'components/checkout/components/BasketOverview/components/BasketSummary';
import LoadingContainer from 'components/checkout/components/BasketOverview/components/LoadingContainer';
import Card from 'components/generic/card';
import { Typography } from 'components/generic/typography';
import ContentLoader from 'react-content-loader';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { customScrollbar } from 'modules/helpers/mixins';
import MiniBasketItemsRenderer from './components/MiniBasketItemsRenderer';
import MiniBasketMessageRenderer from './components/MiniBasketMessageRenderer';
import LinkActionButton from 'components/Button/LinkActionButton';

const StyledCard = styled(Card)`
    max-height: 80vh;
`;

const StyledCardContent = styled(Card.Content)`
    padding-top: 0;
    padding-bottom: 0;
    max-height: 496px;
    min-height: 50px;
    overflow: auto;
    ${customScrollbar}
    gap: ${Spacing16};
    border-bottom: 1px solid ${ColorNordicGrey3};
    border-top: 1px solid ${ColorNordicGrey3};
`;

const StyledLoadingContainer = styled(LoadingContainer)`
    display: flex;
    flex-direction: column;
    max-height: 80vh;
`;

export default function MiniBasket() {
    const { data: model, isLoading, isFetching } = useMiniBasketQuery();
    const { t } = useTranslation();

    if (!model || isLoading)
        return (
            <Card>
                <Card.Content>
                    <ContentLoader
                        speed={2}
                        width="100%"
                        height="100%"
                        viewBox="0 0 400 300"
                        backgroundColor={ColorNeutralGrey5}
                        foregroundColor={ColorBrandBlue5}
                    >
                        <rect x="0" y="0" rx="2" ry="2" width="100%" height="100%" />
                    </ContentLoader>
                </Card.Content>
            </Card>
        );

    const { basketMessage, basketSummary, basketLinkAction } = model;

    return (
        <StyledCard>
            <StyledLoadingContainer isLoading={isFetching}>
                <Card.Content>
                    <Typography.Title size={18}>{t(Headline)}</Typography.Title>
                </Card.Content>

                <StyledCardContent>
                    <MiniBasketItemsRenderer model={model} />
                </StyledCardContent>

                <Card.Content>
                    <Flex.Column>
                        {basketSummary && <BasketSummary model={basketSummary} />}
                        {basketMessage && <MiniBasketMessageRenderer model={basketMessage} />}
                        <LinkActionButton action={basketLinkAction}>{t(GoToBasket)}</LinkActionButton>
                    </Flex.Column>
                </Card.Content>
            </StyledLoadingContainer>
        </StyledCard>
    );
}
