/* eslint-disable react/no-array-index-key */
import {
    type VoucherSummaryLine as VoucherSummaryLineModel,
    type BasketVoucherSummary
} from 'autogen/swagger/all';
import React, { ReactNode } from 'react';
import VoucherSummaryLine from './VoucherSummaryLine';

type VoucherSummaryRenderer = Record<string, any> & {
    model: BasketVoucherSummary;
    renderer?: (model: VoucherSummaryLineModel, props) => ReactNode;
};

export default function VoucherSummaryRenderer({
    model: { voucherSummaryLines },
    renderer = (VoucherSummaryLineModel, props) => <VoucherSummaryLine {...props} model={VoucherSummaryLineModel} />,
    ...restProps
}: VoucherSummaryRenderer): JSX.Element | null {
    return (
        <>
            {voucherSummaryLines.map((voucherSummaryLineModel, index) => (
                <React.Fragment key={index}>{renderer(voucherSummaryLineModel, restProps)}</React.Fragment>
            ))}
        </>
    );
}
