/* eslint-disable */
/// This file is auto generated - do not edit manually
export * as Quick from './trans-website-checkout-quick';
export * as DeliveryPreview from './trans-website-checkout-deliverypreview';
export * as Delivery from './trans-website-checkout-delivery';
export * as DeliveryNudging from './trans-website-checkout-deliverynudging';
export * as DeliveryNudgingExpectedPickupDeliveryVariant from './trans-website-checkout-deliverynudgingexpectedpickupdeliveryvariant';
export * as SummaryBox from './trans-website-checkout-summarybox';
export * as Payment from './trans-website-checkout-payment';
export * as GiftCard from './trans-website-checkout-giftcard';
export * as ShippingInfo from './trans-website-checkout-shippinginfo';
export * as Field from './trans-website-checkout-field';
export * as Error from './trans-website-checkout-error';

export const YourOrdreOverview = 'Website.Checkout.YourOrdreOverview';
export const PayByCard = 'Website.Checkout.PayByCard';
export const PayByGiftCard = 'Website.Checkout.PayByGiftCard';
export const PaymentCoveredByGiftCard = 'Website.Checkout.PaymentCoveredByGiftCard';
export const ChooseShipping = 'Website.Checkout.ChooseShipping';
export const HeadingSelected = 'Website.Checkout.HeadingSelected';
export const HeadingNotSelected = 'Website.Checkout.HeadingNotSelected';
export const ChangeShipping = 'Website.Checkout.ChangeShipping';
export const HideChangeShipping = 'Website.Checkout.HideChangeShipping';
export const ChoosePaymentType = 'Website.Checkout.ChoosePaymentType';
export const InvoiceAddressTitle = 'Website.Checkout.InvoiceAddressTitle';
export const ReadMore = 'Website.Checkout.ReadMore';
export const ReadLess = 'Website.Checkout.ReadLess';
export const InvoiceAddressContinue = 'Website.Checkout.InvoiceAddressContinue';
export const InvoiceAddressSetToStoreDefault = 'Website.Checkout.InvoiceAddressSetToStoreDefault';
export const InvoiceNoEmailToggle = 'Website.Checkout.InvoiceNoEmailToggle';
export const GoToPayment = 'Website.Checkout.GoToPayment';
export const CompleteOrder = 'Website.Checkout.CompleteOrder';
export const PayWithSavedCard = 'Website.Checkout.PayWithSavedCard';
export const KicksIpadPos = 'Website.Checkout.KicksIpadPos';
export const CommentsToOrder = 'Website.Checkout.CommentsToOrder';
export const CommentsAdd = 'Website.Checkout.CommentsAdd';
export const CommentsNotice = 'Website.Checkout.CommentsNotice';
export const CommentsNoticeWhenDisabled = 'Website.Checkout.CommentsNoticeWhenDisabled';
export const CommentsSwapCertificate = 'Website.Checkout.CommentsSwapCertificate';
export const Comment = 'Website.Checkout.Comment';
