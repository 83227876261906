import { Spacing16, Spacing24, Spacing32 } from 'autogen/design-tokens/tokens';
import type { MenuSectionViewModel } from 'autogen/swagger/all';
import { BeforeSlot, StyledListItem } from 'components/List/components/ListItem';
import Menu from 'components/generic/menu';
import { itemDivider } from 'components/generic/menu/components/DefaultMenuSection';
import type { IMenuSection } from 'components/generic/menu/shared/types';
import React from 'react';
import styled from 'styled-components';
import { GreyCapitalHeadline } from './SectionHeadlineRenderer';
import { breakpoint, sizes } from 'variables';

/**
 * SectionStyleRenderer handles the styling variations of MenuSection that can come from MenuSectionViewModel
 */

type SectionStyleRenderer = IMenuSection & {
    styling: MenuSectionViewModel['styling'];
};

const BoxedSection = styled(Menu.BoxedSection)`
    margin: 0 ${Spacing16} ${Spacing16};

    & ${StyledListItem} {
        &:not(:last-child) {
            ${itemDivider}

            &:has(${BeforeSlot}):after {
                left: 56px;
            }
        }
    }

    ${breakpoint.up(sizes.md)} {
        margin: 0 ${Spacing16} ${Spacing16};
    }
`;

const SecondarySection = styled(Menu.SecondarySection)`
    margin-bottom: ${Spacing16};

    ${GreyCapitalHeadline} + & {
        padding-top: 0;
    }
`;

const SecondarySectionWithDividers = styled(Menu.SecondarySectionWithDividers)`
    & ${StyledListItem} {
        min-height: 74px;
    }

    margin-bottom: ${Spacing16};

    ${GreyCapitalHeadline} + & {
        padding-top: 0;
    }
`;

export default function SectionStyleRenderer(props: SectionStyleRenderer) {
    const { styling } = props;

    switch (styling) {
        case 'BoxedSection':
            return <BoxedSection {...props} />;
        case 'SecondarySection':
            return <SecondarySection {...props} />;
        case 'SecondarySectionWithDividers':
            return <SecondarySectionWithDividers {...props} />;
        default:
            return <Menu.Section {...props} />;
    }
}
