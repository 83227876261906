import type { ShippingOptionsBlock } from 'autogen/swagger/all';
import format from 'helpers/translations';
import React from 'react';

export type ShippingOptionsPlaceholderProps = {
    model: ShippingOptionsBlock;
};

export default function ShippingOptionsPlaceholder(props: ShippingOptionsPlaceholderProps) {
    const { model } = props;

    return (
        <>
            {model.shippingOptionGroups.map((group, i) => (
                <React.Fragment key={i}>
                    {group.supplierUsp && <p>{format(group.supplierUsp)}</p>}

                    {group.shippingOptions.map((option) => (
                        <div key={i + option.name}>
                            <p>
                                <b>{option.name}</b>
                            </p>
                            {option.usp && (
                                <p>
                                    <i>{format(option.usp)}</i>
                                </p>
                            )}
                            <ul>
                                {option.priceIntervals.map((interval, n) => (
                                    // eslint-disable-next-line react/no-array-index-key
                                    <li key={n}>{interval}</li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </React.Fragment>
            ))}
        </>
    );
}
