import React from 'react';
import { MenuItemCustomActionWithUrlViewModel } from 'autogen/swagger/all';
import UAEventTracking from 'modules/tracking/UAEventTracking';
import useIsClubMatasMember from 'hooks/user/useIsClubMatasMember';
import { GA4Tracking } from 'modules/tracking/GA4/GA4Tracking';
import DefaultMenuItem from './DefaultMenuItem';
import { DefaultMenuItemProps } from '../types';

type ClubMatasMenuItemProps = {
    model: MenuItemCustomActionWithUrlViewModel;
    defaultProps: DefaultMenuItemProps;
};

export default function ClubMatasMenuItem({
    model: { linkAction, text },
    defaultProps: { iconUrl, styling, id },
    ...restProps
}: ClubMatasMenuItemProps) {
    const { isClubMatasMember } = useIsClubMatasMember();

    if (isClubMatasMember) return null;

    return (
        <DefaultMenuItem.Anchor
            anchorProps={{ action: linkAction, title: text }}
            iconUrl={iconUrl}
            styling={styling}
            onClick={() => {
                GA4Tracking({
                    eventName: 'link_click',
                    context: 'menu',
                    category: 'navigation',
                    eventParams: [
                        { param_name: 'link_type', param_value: 'internal' },
                        { param_name: 'list_name', param_value: text },
                        { param_name: 'id', param_value: id }
                    ]
                });

                UAEventTracking({
                    eventCategory: 'sideMenu',
                    eventAction: 'customAction',
                    eventLabel: text
                });
            }}
            {...restProps}
        >
            {text}
        </DefaultMenuItem.Anchor>
    );
}
