import type { ConnectedContentMenuItemModel } from 'autogen/swagger/all';
import ArticleCard from 'components/ArticleCard';
import { useQuickView } from 'components/quickView';
import { PictureDeprecated } from 'components/generic/picture';
import QuickViewArticle from 'components/QuickViewArticle/QuickViewArticle';
import { rem } from 'modules/helpers/style';
import styled, { css } from 'styled-components';
import DefaultMenuItem from 'components/generic/menu/components/DefaultMenuItem';
import UAEventTracking from 'modules/tracking/UAEventTracking';
import { Spacing6 } from 'autogen/design-tokens/tokens';
import getUrlFromPageAction from 'components/shared/Routing/components/shared/getUrlFromPageAction';
import type { LinkAction } from 'components/shared/Routing/components/LinkAction/shared/types';

const ImageContainer = styled.div`
    min-width: ${rem(80)};
    min-height: ${rem(72)};
    max-width: ${rem(80)};
    max-height: ${rem(72)};

    img {
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 100%;
    }
`;

const paddingOverwrite = css`
    padding-top: ${Spacing6};
    padding-bottom: ${Spacing6};
`;

const StyledActionMenuItem = styled(DefaultMenuItem.Action)`
    a,
    button {
        ${paddingOverwrite}
    }
`;

const StyledButton = styled(DefaultMenuItem.Button)`
    button {
        ${paddingOverwrite}
    }
`;

export default function SideMenuArticleItem({ item }: { item: ConnectedContentMenuItemModel }) {
    const { link, heading, text = '', image, isQuickView } = item;
    const linkUrl = getUrlFromPageAction(link?.pageAction);
    const { toggle } = useQuickView('inlineConnectedContent');

    if (isQuickView && linkUrl) {
        const linkAction = link?.pageAction?.action as LinkAction;

        return (
            <>
                {linkUrl && <QuickViewArticle quickViewId="inlineConnectedContent" linkAction={linkAction} />}

                <StyledButton
                    buttonProps={{
                        onClick: () => {
                            toggle(true);
                            UAEventTracking({ eventCategory: 'sideMenu', eventAction: 'link', eventLabel: heading });
                        }
                    }}
                >
                    <ArticleCard
                        customTheme={{ layoutTheme: 'horizontalSmall' }}
                        textBox={{ title: text, overline: heading }}
                    >
                        <ImageContainer>
                            <PictureDeprecated {...image} />
                        </ImageContainer>
                    </ArticleCard>
                </StyledButton>
            </>
        );
    }

    if (link?.pageAction) {
        return (
            <StyledActionMenuItem
                action={link.pageAction}
                onClick={() => UAEventTracking({ eventCategory: 'sideMenu', eventAction: 'link', eventLabel: heading })}
            >
                <ArticleCard
                    customTheme={{ layoutTheme: 'horizontalSmall' }}
                    textBox={{ title: text, overline: heading }}
                >
                    <ImageContainer>
                        <PictureDeprecated {...image} />
                    </ImageContainer>
                </ArticleCard>
            </StyledActionMenuItem>
        );
    }

    return null;
}
