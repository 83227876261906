/* eslint-disable */
/// This file is auto generated - do not edit manually
export const ReceiptTextNotMember = 'Website.BrandClub.ReceiptTextNotMember';
export const ReceiptTextMemberV2 = 'Website.BrandClub.ReceiptTextMemberV2';
export const ReceiptTextMemberButtonText = 'Website.BrandClub.ReceiptTextMemberButtonText';
export const Details = 'Website.BrandClub.Details';
export const ReceiptPageTitle = 'Website.BrandClub.ReceiptPageTitle';
export const StartFollowing = 'Website.BrandClub.StartFollowing';
export const StopFollowing = 'Website.BrandClub.StopFollowing';
export const ProductListToolTip = 'Website.BrandClub.ProductListToolTip';
export const ProductRecommendationsHeadline = 'Website.BrandClub.ProductRecommendationsHeadline';
