import type { LoginInfoModel } from 'autogen/swagger/all';
import { LoginInfo } from 'components';
import { fetchJSON } from 'modules/helpers/fetch';
import { GetSampleSelection } from 'autogen/swagger/all';
import UAEventTracking from 'modules/tracking/UAEventTracking';
import SamplesPicker from '../SamplesPicker';

export default async function openSamplesModal(trackingLabel?: string, loginUrl?: string) {
    const { default: createModal } = await import(/* webpackChunkName: "modal" */ 'modules/modal');
    const modal = createModal();

    if (loginUrl) {
        fetchJSON<LoginInfoModel>(loginUrl).then((response) => {
            modal({
                open: true,
                content: <LoginInfo model={response} modal={modal} />,
                layout: { customTheme: { minWidth: 'unset', maxWidth: '375px' } }
            });
        });

        UAEventTracking({
            eventCategory: 'samples',
            eventAction: 'openLoginModal',
            eventLabel: trackingLabel
        });
    } else {
        GetSampleSelection().then((response) => {
            modal({
                open: true,
                content: <SamplesPicker {...response} modal={modal} />,
                layout: { customTheme: { maxWidth: '1024px' } }
            });
        });

        UAEventTracking({
            eventCategory: 'samples',
            eventAction: 'openSamplesModal',
            eventLabel: trackingLabel
        });
    }
}

export function setupSampleBindings() {
    if (!document) return;

    const buttons = Array.from(document.getElementsByClassName('js-samplesOpenModal'));

    buttons.forEach((button) => {
        button.addEventListener('click', () => {
            openSamplesModal(button.innerHTML);
        });
    });
}
