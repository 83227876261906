import { useEffect, useState } from 'react';
import { useDynamicStorage } from 'hooks/storage';
import { useRecoilValue } from 'recoil';
import TooltipPopup from './TooltipPopup';
import TooltipNotificationAtom from './shared/recoil/atom';
import type { TippyProps } from '@tippyjs/react';

export type PlainTooltipPopupType = {
    name: string;
    description: string;
    screenTimeLimitSeconds: number;
    customTheme?: string;
    hasBeenToggled?: boolean;
    children: JSX.Element;
};

export default function PlainTooltipPopup(props: PlainTooltipPopupType & Omit<TippyProps, 'visible' | 'content'>) {
    const {
        name,
        description,
        screenTimeLimitSeconds,
        customTheme = 'plain-tooltip',
        hasBeenToggled,
        children,
        ...restProps
    } = props;
    const isOpen = useRecoilValue(TooltipNotificationAtom(name));
    const [displayTimes, setDisplayTimes] = useDynamicStorage('localStorage', `${name}-display-times`, 0);
    const [shouldDisplay, setShouldDisplay] = useState<boolean>(false);

    // delay doesn't work on this kind of tooltip, so timeout is used to delay tooltip by 1.5 second
    useEffect(() => {
        const timer = setTimeout(() => {
            if (displayTimes <= 2 && !hasBeenToggled) {
                setShouldDisplay(true);
            } else if (displayTimes === 3) {
                setShouldDisplay(false);
            } else {
                setShouldDisplay(!hasBeenToggled);
            }
        }, 1500);
        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        if (isOpen && displayTimes <= 2) {
            setDisplayTimes(displayTimes + 1);
        }
    }, [isOpen]);

    if (shouldDisplay) {
        return (
            <>
                <TooltipPopup
                    name={name}
                    shouldDisplay={shouldDisplay}
                    content={{ description }}
                    config={{ screenTimeLimit: screenTimeLimitSeconds }}
                    size="xs"
                    customTheme={customTheme}
                    interactive={false}
                    placement="bottom"
                    animation="bounce"
                    {...restProps}
                >
                    {children}
                </TooltipPopup>
            </>
        );
    }

    return children;
}
