import styled from 'styled-components';
import format from 'helpers/translations';
import { type BasketMessageSameDayDeliveryViewModel } from 'autogen/swagger/all';
import { Typography } from 'components/generic/typography';
import WithIcon from 'components/hoc/withIcon/WithIcon';
import dynamic from 'helpers/dynamic';
import { ColorHealth5 } from 'autogen/design-tokens/tokens';
import BasketMessageContainer from '../shared/styles';

const StyledBasketMessageContainer = styled(BasketMessageContainer)`
    background-color: ${ColorHealth5};
`;

const Watch = dynamic(() => import('icons/Watch.svg?react'));

export default function SameDayDeliveryMessage({ model: { text } }: { model: BasketMessageSameDayDeliveryViewModel }) {
    return (
        <StyledBasketMessageContainer>
            <WithIcon icon={<Watch />}>
                <Typography.Body as="span" themeTypes="small">
                    {format(text)}
                </Typography.Body>
            </WithIcon>
        </StyledBasketMessageContainer>
    );
}
