import { TrackAdformImpressionsRequestBody, TrackAdformImpressionsUrl } from 'autogen/swagger/all';
import useIntersectionObserver from 'hooks/globals/useIntersectionObserver';
import { RefObject, useEffect, useState } from 'react';

type trackingUrlsArray = TrackAdformImpressionsRequestBody['trackingUrls'];
declare global {
    interface Window {
        Adform?: {
            _uid: string;
        };
    }
}

function postImpressionTracking(data: trackingUrlsArray) {
    if (data.length === 0) return;
    const uid = window.Adform?._uid ?? '';
    const body: TrackAdformImpressionsRequestBody = { trackingUrls: data, uid };

    fetch(TrackAdformImpressionsUrl, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body),
        credentials: 'same-origin',
        keepalive: true
    }).catch((error) => {
        throw error;
    });
}

let bulkArray = [] as trackingUrlsArray;
function postAndReset() {
    postImpressionTracking(bulkArray);
    bulkArray = [];
    window?.removeEventListener('beforeunload', postAndReset);
}

let timer;
const waitTime = 1000;
const maxBulkSize = 20;
function handlePostAndReset() {
    clearTimeout(timer);

    if (window) {
        window.addEventListener('beforeunload', postAndReset);
    }

    if (bulkArray.length >= maxBulkSize) {
        postAndReset();
        return;
    }

    timer = setTimeout(() => {
        postAndReset();
    }, waitTime);
}

export function bulkTogether(trackingUrls: trackingUrlsArray) {
    trackingUrls.forEach((trackingUrl) => {
        bulkArray.push(trackingUrl);
    });

    handlePostAndReset();
}

export default function useAdformImpressionTracking({
    ref,
    impTrackingUrls
}: {
    ref: RefObject<Element>;
    impTrackingUrls?: trackingUrlsArray;
}) {
    const [destroyObserver, setDestroyObserver] = useState(false);
    const threshold = 0.5;
    const entries = useIntersectionObserver(ref, { threshold, rootMargin: '0%', pauseOnceVisible: destroyObserver });

    useEffect(() => {
        if (!impTrackingUrls || impTrackingUrls.length < 1) return;

        let intersectionTimer;

        if (entries?.isIntersecting) {
            intersectionTimer = setTimeout(() => {
                bulkTogether(impTrackingUrls);
                setDestroyObserver(true);
            }, 1000);
        }

        if (!entries?.isIntersecting) {
            clearTimeout(intersectionTimer);
        }
    }, [entries, impTrackingUrls, ref]);
}
