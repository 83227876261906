/* eslint-disable */
/// This file is auto generated - do not edit manually
export const ByAuthor = 'Website.ArticleBlock.ByAuthor';
export const ButtonText = 'Website.ArticleBlock.ButtonText';
export const ReadAlso = 'Website.ArticleBlock.ReadAlso';
export const SeeAllHere = 'Website.ArticleBlock.SeeAllHere';
export const MoreContent = 'Website.ArticleBlock.MoreContent';
export const OthersRead = 'Website.ArticleBlock.OthersRead';
export const BrandHeadline = 'Website.ArticleBlock.BrandHeadline';
export const SocialHeadline = 'Website.ArticleBlock.SocialHeadline';
