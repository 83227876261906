import { SeeDeliveryOptionsV2 } from 'autogen/translation-keys/trans-website-basket-shipping';
import { useTranslation } from 'react-i18next';
import InlineButton from 'components/generic/InlineButton';
import useExpectedDeliveryQuickView, {
    type ExpectedDeliveryParams
} from 'components/expecteddelivery/shared/hooks/useExpectedDeliveryQuickView';
import { useGetExpectedDeliveryInformationForBasketQuery } from 'autogen/swagger/all/hooks';
import { useEffect } from 'react';

export default function DeliveryPreviewButton(): JSX.Element {
    const { t } = useTranslation();
    const [{ isOpen }, toggle] = useExpectedDeliveryQuickView();
    const { data } = useGetExpectedDeliveryInformationForBasketQuery({ enabled: isOpen });
    const { zipCodeWithSource } = data || {};
    const { zipCode } = zipCodeWithSource || {};

    useEffect(() => {
        if (zipCode) {
            toggle(({ isOpen, params }) => ({ isOpen, params: { ...params, zipCode } as ExpectedDeliveryParams }));
        }
    }, [zipCode]);

    return (
        <InlineButton
            size="sm"
            type="button"
            onClick={() => {
                toggle({ isOpen: true, params: { type: 'basket', zipCode } });
            }}
        >
            {t(SeeDeliveryOptionsV2)}
        </InlineButton>
    );
}
