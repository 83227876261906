import type { JavascriptWebsiteSettings } from 'autogen/swagger/all';

const websiteSettingsObj: JavascriptWebsiteSettings = {
    enhancedEcommerceTrackingEnabled: false,
    liveChat: {
        advisorChatDisabledText: '',
        customerServiceChatDisabledText: '',
        customerServiceChatOnlyEnabled: false,
        isAdvisorChatEnabled: false,
        isChatEnabled: false,
        isCustomerServiceChatEnabled: false,
        isZowieEnabled: false,
        openZowieOnLoad: false,
        zowieInstanceId: '',
        zowieZendeskAccountKey: ''
    },
    klarnaExpressClientId: ''
};

export const setWebsiteSettings = (obj: Record<string, unknown>): void => {
    Object.entries(obj).forEach(([key, item]): void => {
        websiteSettingsObj[key] = item;
    });
};

const WebsiteSettings = (): JavascriptWebsiteSettings => websiteSettingsObj;

export default WebsiteSettings();
