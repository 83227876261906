import type { RequestContextViewModel } from 'autogen/swagger/all';

const requestContextObj: RequestContextViewModel = {
    acceptedCookieCategories: [],
    isClubMatasApp: false,
    isClubMatasAppApplePaySupport: false,
    isClubMatasLightPdp: false,
    supportsNativeBackArrow: false,
    supportsNativeTitleBar: false,
    isKicksIpadPosRequest: false,
};

export const setRequestContext = (obj: Record<string, unknown>): void => {
    Object.entries(obj).forEach(([key, item]): void => {
        requestContextObj[key] = item;
    });
};

const RequestContext = (): RequestContextViewModel => requestContextObj;

export default RequestContext();
