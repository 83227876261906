import { ColorSpot5, Spacing12 } from 'autogen/design-tokens/tokens';
import Card from 'modules/shared/card';
import { HTMLAttributes, useEffect } from 'react';
import styled from 'styled-components';
import { toHtmlContent } from 'modules/helpers/strings';
import { CampaignInfo } from 'autogen/swagger/all';
import { useSetRecoilState } from 'recoil';
import HighlightedText from 'components/generic/HighlightedText';
import AdditionalInfoButton from './AdditionalInfoButton';
import campaignInfoAdditionalInfoIdAtom from '../recoil/additionalInfoIdAtom';

const StyledCard = styled(Card)`
    display: flex;
    align-items: flex-start;
    border: 1px solid ${ColorSpot5};
`;

const TextContainer = styled(HighlightedText)<{ hasAdditionalInfo: CampaignInfo['hasAdditionalInfo'] }>`
    display: flex;
    padding: ${Spacing12} ${({ hasAdditionalInfo }) => (hasAdditionalInfo ? 0 : Spacing12)} ${Spacing12} ${Spacing12};
    width: 100%;
`;

const StyledAdditionalInfoButton = styled(AdditionalInfoButton)`
    padding: ${Spacing12};
    margin-left: auto;
`;

export interface ICampaignInfoBox extends HTMLAttributes<HTMLDivElement> {
    campaignInfo: CampaignInfo;
    productId: number;
}

export default function CampaignInfoBox({ campaignInfo, productId, ...restProps }: ICampaignInfoBox) {
    const { campaignTeaser, hasAdditionalInfo } = campaignInfo;
    const setProductId = useSetRecoilState(campaignInfoAdditionalInfoIdAtom);

    useEffect(() => {
        setProductId(productId);
    }, []);

    if (!campaignTeaser) {
        return null;
    }

    return (
        <>
            <StyledCard>
                <TextContainer color="ColorSpot1" hasAdditionalInfo={hasAdditionalInfo} withBorder={false}>
                    {toHtmlContent(campaignTeaser)}
                </TextContainer>
                {hasAdditionalInfo && <StyledAdditionalInfoButton />}
            </StyledCard>
        </>
    );
}
