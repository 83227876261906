import Tippy, { type TippyProps } from '@tippyjs/react';
import React, { useRef } from 'react';
import { zIndex as zIndexLayer } from 'variables';
import SmallTooltipPopupContent, { type ISmallTooltipContent } from './components/SmallTooltipPopupContent';
import LargeTooltipPopupContent, { type ILargeTooltipContent } from './components/LargeTooltipPopupContent';
import useTooltipPopup, { type ITooltipPopupConfigMaybe } from './shared/hooks/useTooltipPopup';
import XSmallTooltipPopupContent, { type IXSmallTooltipContent } from './components/XSmallTooltipPopupContent';
import type { RenderProps } from 'tippy.js';

type XSmallProps = {
    size: 'xs';
    content: IXSmallTooltipContent;
};

type SmallProps = {
    size: 'sm';
    content: ISmallTooltipContent;
};

type LargeProps = {
    size: 'lg';
    content: ILargeTooltipContent;
};

export type TooltipPopupProps = {
    name: string;
    shouldDisplay: boolean;
    config?: ITooltipPopupConfigMaybe;
    children: JSX.Element;
    showCloseButton?: boolean;
    zIndex?: number;
    customTheme?: RenderProps['theme'];
} & (XSmallProps | SmallProps | LargeProps);

/**
 * Ready to use TooltipPopup that supports ScreenTimeLimit, visibility when intersection
 * Tooltip popup automatically opens based on user-defined conditions contrary to a traditional tooltip
 *
 * @param name - identifier for tooltip used for accessing the state of the tooltip globally anywhere.
 * @param shouldDisplay - a bool that determines whether the tooltip should possibly display. (Can be a value stored in backend)
 * @param content - Content for the tooltip
 * @param config - Tooltip configurationObj
 * @param children - Root reference element
 * @returns
 */

export default function TooltipPopup(props: TooltipPopupProps & Omit<TippyProps, 'visible' | 'content'>) {
    const {
        name,
        shouldDisplay,
        config,
        children,
        size,
        customTheme = 'rich-tooltip',
        delay = [500, 500],
        interactive = true,
        arrow = true,
        showCloseButton,
        zIndex = zIndexLayer.fixed - 1,
        ...restProps
    } = props;
    const rootElementRef = useRef<Element>(null);
    const { isOpen, close } = useTooltipPopup(name, shouldDisplay, rootElementRef, config);

    // Yes the content layout changes depending on the size prop. (No consistency whatsoever, see content interfaces for props)
    const getTooltipContent = () => {
        switch (size) {
            case 'xs':
                return <XSmallTooltipPopupContent content={props.content} />;
            case 'sm':
                return (
                    <SmallTooltipPopupContent content={props.content} close={close} showCloseButton={showCloseButton} />
                );
            case 'lg':
                return <LargeTooltipPopupContent content={props.content} close={close} />;
            default:
                return null;
        }
    };

    if (React.Children.count(children) === 0) return null;

    return (
        <Tippy
            {...restProps}
            ref={rootElementRef}
            visible={isOpen}
            content={getTooltipContent()}
            theme={customTheme}
            delay={delay}
            arrow={arrow}
            interactive={interactive}
            zIndex={zIndex}
        >
            {children}
        </Tippy>
    );
}
