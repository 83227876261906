import { useContext } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { SvgSprite } from 'modules/svgSprite';
import RectangularImage, { DefaultPosition } from 'modules/shared/rectangularImage';
import type { QuickSearchSectionViewModelOfQuickSearchProductViewModel } from 'autogen/swagger/all';
import UAEventTracking from 'modules/tracking/UAEventTracking';
import MainContext from 'components/QuickSearch/components/context';
import { GA4Tracking } from 'modules/tracking/GA4';
import Image from 'components/generic/Image';
import {
    BorderRadiusSmall,
    ColorNeutralDarkGrey,
    FontSize12,
    FontSize14,
    Spacing16,
    Spacing4,
    Spacing8
} from 'autogen/design-tokens/tokens';
import { layoutEnum, listTypeEnum } from '../../enums';
import HitsTotalLink from '../../components/shared/hitsTotalLink';
import LinkAction from 'components/shared/Routing/components/LinkAction/LinkAction';
import imageOverlayStyle from 'components/shared/style/imageOverlay';
import { useIsChanel } from 'hooks/user/useIsBrand';

const List = styled.ul<{ layout: layoutEnum }>`
    padding: 10px 0 0;
    margin: 0;
    display: flex;
    ${(props) => props.layout !== layoutEnum.tab && 'flex-direction: row;'}
    flex-wrap: wrap;
    overflow: hidden;
`;

const ListItem = styled.li<{ layout: layoutEnum; isHandheld: boolean }>`
    list-style: none;
    flex: 0 0 ${(props) => (props.layout === layoutEnum.tab ? '100%' : '50%')};
    max-width: ${(props) => (props.layout === layoutEnum.tab ? '100%' : '50%')};
    ${(props) => props.layout !== layoutEnum.tab && 'padding: 20px;'}
    ${(props) => props.isHandheld && 'border-bottom: 1px solid #ececec;'}
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const BottomPosition = styled(DefaultPosition)`
    justify-content: flex-end;
`;

const TextContainer = styled.div<{ layout: layoutEnum }>`
    ${(props) => props.layout !== layoutEnum.tab && 'text-align: center;'}
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
`;

const BrandName = styled.div<{ layout: layoutEnum }>`
    font-weight: 700;
    text-transform: uppercase;

    ${(props) => props.layout !== layoutEnum.tab && 'margin: 3px 0;'}
    font-size: ${(props) => (props.layout !== layoutEnum.tab ? '11px' : '13px')};
`;

const Title = styled.div<{ layout: layoutEnum }>`
    ${(props) =>
        props.layout !== layoutEnum.tab &&
        css`
            font-size: 11px;
            font-style: italic;
            line-height: 13px;
            margin-bottom: 10px;
            flex: 1 0 auto;
        `}
`;

const PriceContainer = styled.div<{ layout: layoutEnum }>`
    display: flex;
    ${(props) => props.layout !== layoutEnum.tab && 'justify-content: center;'}
    font-size: ${(props) => (props.layout !== layoutEnum.tab ? FontSize12 : FontSize14)};
    font-weight: 700;
`;

const Price = styled.div``;

const OriginalPrice = styled.div<{ layout: layoutEnum }>`
    margin-left: ${Spacing8};
    color: ${ColorNeutralDarkGrey};
    text-decoration: line-through;
    font-weight: ${(props) => (props.layout !== layoutEnum.tab ? '400' : '700')};
`;

const Arrow = styled.div`
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    margin: 0 20px;
`;

const SvgArrow = styled(SvgSprite)`
    width: 15px;
    height: 15px;
`;

const itemHover = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

const LinkHoverBeforeStyling = css`
    content: '';
    position: absolute;
    z-index: -1;
    top: -1px;
    left: -1px;
    width: calc(100% + 1px);
    height: calc(100% + 1px);
    background-color: #fff;
    border: 1px solid #b8b8b8;
    -webkit-animation-name: ${itemHover};
    animation-name: ${itemHover};
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
`;

const Link = styled(LinkAction)<{ layout: layoutEnum; isActive: boolean }>`
    display: flex;
    flex-direction: ${(props) => (props.layout === layoutEnum.tab ? 'row' : 'column')};
    ${(props) => props.layout === layoutEnum.tab && `padding: ${Spacing16} 0px;`}
    ${(props) => props.layout === layoutEnum.column && 'flex-grow: 1;'}

    &:hover {
        text-decoration: none;
    }

    ${(props) =>
        props.isActive &&
        css`
            &:before {
                ${LinkHoverBeforeStyling}
            }
        `};
    &:hover:before {
        ${(props) => props.layout !== layoutEnum.tab && LinkHoverBeforeStyling};
    }
`;

const ImageContainer = styled.div<{ layout: layoutEnum; isChanel: boolean }>`
    position: relative;
    width: ${(props) => (props.layout === layoutEnum.tab ? '60px' : '100%')};
    max-width: 150px;
    margin: ${(props) => (props.layout !== layoutEnum.tab ? '0 auto' : `0 ${Spacing16}`)};
    ${(props) => props.layout !== layoutEnum.tab && `margin-bottom: ${Spacing16};`}
    flex: 0 0 auto;
    padding: ${Spacing4};

    ${(props) =>
        !props.isChanel &&
        css`
            &:after {
                content: '';
                ${imageOverlayStyle}
                border-radius: ${BorderRadiusSmall}
            }
        `}
`;

const StyledImage = styled(Image.WithScaling)`
    max-height: 100%;
    max-width: 100%;
    width: auto;
    height: auto;
`;

const StyledHitsTotalLink = styled(HitsTotalLink)`
    font-weight: bold;
    width: 100%;
    padding: 10px;
`;

export default (props: QuickSearchSectionViewModelOfQuickSearchProductViewModel) => {
    const { items, hitsTotal, linkAction, actualSearchText } = props;
    const mainContext = useContext(MainContext) as any;
    const { layout, isHandheld, onItemHover } = mainContext;

    return (
        <List layout={layout}>
            {items.map((obj, index) => {
                const {
                    brandName,
                    id,
                    image,
                    name,
                    price,
                    isActive,
                    productAction,
                    linkAction,
                    minimumSalesQuantity,
                    trackingJson
                } = obj;
                const priceBefore = price.priceBefore ? price.priceBefore.match(/\d+/g).join(',') : price.priceBefore;
                const regex = new RegExp(/([\w-]*)$/); // from end capture any letter, digit, underscore or dash
                const pathname = linkAction.url.match(regex)[0];

                return (
                    <ListItem key={id} layout={layout} isHandheld={isHandheld}>
                        <Link
                            action={linkAction}
                            layout={layout}
                            isActive={isActive}
                            onMouseEnter={() => onItemHover(obj)}
                            onClick={() => {
                                UAEventTracking({
                                    eventCategory: 'quickSearch',
                                    eventAction: 'productClick',
                                    eventLabel: pathname
                                });

                                GA4Tracking({
                                    eventName: 'product_click',
                                    context: 'search',
                                    category: 'ecommerce',
                                    eventParams: [
                                        { param_name: 'search_type', param_value: 'quick_search' },
                                        { param_name: 'search_term', param_value: actualSearchText }
                                    ]
                                });
                            }}
                            data-js-tracking-action="ProductClickQuickSearch"
                            data-js-tracking-info={trackingJson}
                        >
                            <ImageContainer layout={layout} isChanel={brandName === 'CHANEL'}>
                                <RectangularImage
                                    layout={layout}
                                    styles={layout !== layoutEnum.tab && { Position: BottomPosition }}
                                >
                                    <StyledImage
                                        src={image.url}
                                        transformType="SquareScale"
                                        imageType="product"
                                        alt={name}
                                        sizes={layout === layoutEnum.tab ? 55 : 100}
                                    />
                                </RectangularImage>
                            </ImageContainer>
                            <TextContainer layout={layout}>
                                <BrandName layout={layout}>{brandName}</BrandName>
                                <Title layout={layout}>{name}</Title>
                                <PriceContainer layout={layout}>
                                    <Price>{price.priceText}</Price>
                                    {priceBefore && <OriginalPrice layout={layout}>{priceBefore}</OriginalPrice>}
                                </PriceContainer>
                            </TextContainer>
                            {layout === layoutEnum.tab && (
                                <Arrow>
                                    <SvgArrow sprite="ArrowRight" />
                                </Arrow>
                            )}
                        </Link>
                    </ListItem>
                );
            })}
            {layout === layoutEnum.tab && linkAction && linkAction.url && hitsTotal && hitsTotal > 10 && (
                <StyledHitsTotalLink
                    hitsTotal={hitsTotal}
                    url={linkAction.url}
                    listType={listTypeEnum.product}
                    searchTerm={actualSearchText}
                />
            )}
        </List>
    );
};
