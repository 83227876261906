import type { GetQuantitySuccess } from 'autogen/swagger/all';
import requestContext from 'modules/requestContext';

const context = requestContext;

export default function updateAppQuantities(quantities: GetQuantitySuccess) {
    try {
        if (context.clubMatasAppOS === 'Android') {
            (window as any).AndroidInterface.updateQuantities(JSON.stringify(quantities));
        }

        if (context.clubMatasAppOS === 'IOS') {
            (window as any).webkit.messageHandlers.updateQuantities.postMessage(JSON.stringify(quantities));
        }
    } catch (err) {
        console.error(err);
    }
}
