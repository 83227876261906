import { ColorClubMatasPlus3, ColorHealth3, ColorNordicGrey2 } from 'autogen/design-tokens/tokens';
import type { DeliveryMethodTagMaybe } from 'autogen/swagger/all';
import Tag from 'components/generic/tag/Tag';

export function getTagStyling(tagType: DeliveryMethodTagMaybe['type']) {
    switch (tagType) {
        case 'EcoChoice':
            return ColorHealth3;
        case 'ExtraPoints':
            return ColorClubMatasPlus3;
        case 'FastDelivery':
        case 'LowCost':
        default:
            return ColorNordicGrey2;
    }
}

export default function DeliveryTag(props: DeliveryMethodTagMaybe) {
    const { type, text, ...restProps } = props;

    return (
        <Tag backgroundColor={getTagStyling(type)} {...restProps}>
            {text}
        </Tag>
    );
}
