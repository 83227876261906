import dynamic from 'helpers/dynamic';

export { default as PrimaryHeader } from 'components/PrimaryHeader/components/PrimaryHeader';

export const QuestionnaireResult = dynamic(() => import('modules/questionnaireResult'));
export const MyProfileInformation = dynamic(() => import('views/myprofile/myProfileInformation'));
export const BrandClubTeaser = dynamic(() => import('views/myprofile/brandClub/modules/brandClubTeaser'));
export const BrandClub = dynamic(() => import('views/myprofile/brandClub'));
export const BrandClubFrontPage = dynamic(() => import('views/myprofile/brandClub/frontPage'));
export const BrandClubBonusGifts = dynamic(() => import('views/myprofile/brandClub/bonusGifts'));
export const BrandClubSignup = dynamic(() => import('views/myprofile/brandClub/signup'));
export const RelatedTags = dynamic(() => import('modules/relatedTags'));
export const ScrollableProductGroups = dynamic(() => import('modules/productGroups/scrollableProductGroups'));
export const FindStore = dynamic(() => import('modules/findStore'));
export const Store = dynamic(() => import('modules/findStore/store'));
export const BannerRenderer = dynamic(() => import('components/booking/banners/BannerRenderer'));
export const LiveEvents = dynamic(() => import('components/liveEvents'));
export const ArticleLiveEvent = dynamic(() => import('components/live/articleLiveEvent'));
export const InlineConnectedContent = dynamic(() => import('components/connectedContent/inlineConnectedContent'));
export const FullwidthConnectedContent = dynamic(() => import('components/connectedContent/fullwidthConnectedContent'));
export const ProductDisplayConnectedContent = dynamic(
    () => import('components/connectedContent/productDisplayConnectedContent')
);
export const CheckoutQuick = dynamic(() => import('components/checkoutQuick'));
export const GwpDiscountModels = dynamic(() => import('components/gwpDiscountModels'));
export const LiveEventPDPCards = dynamic(() => import('components/live/liveEventPDPCards'));
export const PlusPointSplash = dynamic(() => import('components/matasPlus/shared/plusPointSplash'));
export const MatasPlusPage = dynamic(() => import('components/matasPlus/matasPlusPage'));
export const BasketPointInfo = dynamic(() => import('components/basketPointInfo'));
export const PowerstepAddToBasketCounter = dynamic(() => import('modules/powerstep/addToBasketCounter'));
export const TopBanners = dynamic(() => import('components/topBanners'));
export const MatasPlusFAQPlaceholder = dynamic(
    () => import('components/matasPlus/matasPlusFAQ/matasPlusFAQPlaceholder')
);
export const MatasPlusUnboxingSlider = dynamic(() => import('components/matasPlus/unboxing/UnboxingOfferSlider'));
export const MatasPlusUnboxingSliderWithHeading = dynamic(
    () => import('components/matasPlus/unboxing/UnboxingOfferSliderWithHeading')
);
export const BrandSlider = dynamic(() => import('components/brandSlider'));
export const AdvisorContextPlaceholder = dynamic(() => import('components/advisorContextPlaceholder'));
export const Footer = dynamic(() => import('components/footer'));
export const CheckoutFooter = dynamic(() => import('components/footer/checkoutFooter'));
export const MatasPlusCalculatorBanner = dynamic(
    () => import('components/matasPlus/placeholders/matasPlusCalculatorBanner')
);
export const SubscriptionProductCard = dynamic(
    () => import('components/subscriptionProductCard/subscriptionProductCardButton')
);
export const MatasPlusSavingsBanner = dynamic(() => import('components/matasPlus/savingsBanner'));
export const MatasPlusPointSavingsBanner = dynamic(
    () => import('components/matasPlus/savingsBanner/MatasPlusPointSavingsBanner/MatasPlusPointSavingsBanner')
);
export const ReviewList = dynamic(() => import('components/reviews'));
export const SamplesProfile = dynamic(() => import('components/pages/MyProfile/MySampleProfile'));
export const ReviewsPreview = dynamic(() => import('components/reviews/reviews-preview'));
export const ExpectedDeliveryHandle = dynamic(() => import('components/expecteddelivery'));
export const PersonalOffer = dynamic(() => import('components/basket/personalOffer'));
export const QuickSearch = dynamic(() => import('modules/quicksearch'));
export const ProgressiveDiscountPDPHandle = dynamic(() => import('components/progressiveDiscount/pdpHandle'));
export const LoginInfo = dynamic(() => import('components/login/loginInfo'));
export const ConsultationProductList = dynamic(
    () => import('components/booking/consultations/ConsultationProductList')
);
export const ConsultationProduct = dynamic(() => import('components/booking/consultations/ConsultationProduct'));
export const BookingOverviewViewModel = dynamic(
    () => import('components/booking/bookingFlow/bookingOverviewViewModel')
);
export const BookingReceiptViewModel = dynamic(() => import('components/booking/bookingFlow/bookingReceiptViewModel'));

export const BookingPosJournalViewModel = dynamic(
    () => import('components/booking/bookingFlow/BookingPosJournalViewModel')
);
export const BookingDetailsView = dynamic(() => import('components/booking/myProfile/bookingDetailsView'));
export const BookingListView = dynamic(() => import('components/booking/myProfile/bookingListView'));
export const ProductNotificationForm = dynamic(() => import('components/product/notification'));
export const Accordion = dynamic(() => import('components/shared/cmsPlaceholders/accordion'));
export const DonationBanner = dynamic(() => import('components/donationBanner'));
export const SubscriptionNudge = dynamic(() => import('components/subscriptionNudge'));
export const AdvisorToolbox = dynamic(() => import('components/advisorToolbox'));
export const AdvisorQuickViewButton = dynamic(() => import('components/advisorToolbox/components/quickViewButton'));
export const StoreStock = dynamic(() => import('components/storeStock'));
export const RelevantTabs = dynamic(() => import('components/relevantTabs'));
export const StockIndicator = dynamic(() => import('components/shared/stockIndicator'));
export const PowerstepStepNudging = dynamic(() => import('components/powerstep/components/PowerstepStepNudging'));
export const SampleBasketEntrance = dynamic(() => import('components/samples/components/entrance'));
export const ProductRegime = dynamic(() => import('components/product/regime'));
export const Chat = dynamic(() => import('components/chat'), { ssr: false });
export const MedicalFeaturedFooter = dynamic(() => import('components/featuredFooter/components/Medical/MedicalTheme'));
export const Delivery = dynamic(() => import('components/checkout/Delivery'));
export const MyPointsPage = dynamic(() => import('components/clubMatas/points/MyPointsPage'));
export const MyProfileFrontPageHeader = dynamic(() => import('components/clubMatas/points/MyProfileFrontPageHeader'));
export const ProductList = dynamic(() => import('components/shared/productList'));
export const NoRebuysAvailable = dynamic(() => import('components/shared/productList/components/NoRebuysAvailable'));
export const PlPHeadline = dynamic(() => import('components/shared/productList/components/Headline'));
export const ProductRecommendation = dynamic(() => import('components/productRecommendation/ProductRecommendation'));
export const ProductAccordion = dynamic(() => import('components/product/accordion'));
export const CampaignInfoBox = dynamic(() => import('components/features/CampaignInfo/components/CampaignInfoBox'));
export const CampaignBasketNudge = dynamic(
    () => import('components/features/CampaignInfo/components/CampaignBasketNudge')
);
export const PaymentIcons = dynamic(() => import('components/checkout/PaymentIcons'));
export const AdvisorList = dynamic(() => import('components/advisorList'));
export const AdvisorInfo = dynamic(() => import('components/advisorProfile/components/AdvisorInfo'));
export const AdvisorProfileRightContainer = dynamic(
    () => import('components/advisorProfile/AdvisorProfileRightContainer')
);
export const HighlightedCampaign = dynamic(() => import('components/highlightedCampaign'));
export const ClubMatasInPageNotification = dynamic(
    () => import('components/clubMatas/inPageNotification/ClubMatasInPageNotification')
);
export const InventoryLaneConnectedContent = dynamic(
    () => import('components/connectedContent/inventoryLaneConnectedContent')
);
export const ImageWithDisclaimerPlaceholder = dynamic(
    () => import('components/pages/placeholders/ImageWithDisclaimerPlaceholder')
);
export const USPHeader = dynamic(() => import('components/features/USPHeader'));
export const SubscriptionUSP = dynamic(() => import('components/features/subscription/SubscriptionUSP'));
export const SignupNudgingCard = dynamic(() => import('components/clubMatas/onboarding/SignupNudgingCard'));
export const SignupNudgingCardWithImage = dynamic(
    () => import('components/clubMatas/onboarding/SignupNudgingCardWithImage')
);
export const ExtendedPlaceholder = dynamic(() => import('components/pages/placeholders/ExtendedPlaceholder'));
export const ImageBannerPlaceholder = dynamic(() => import('components/pages/placeholders/ImageBanner'));
export const PDPProductActionRenderer = dynamic(() => import('components/product/pdpProductAction'));
export const CampaignHeader = dynamic(() => import('components/plp/campaignHeader'));
export const Countdown = dynamic(() => import('components/countdown'));
export const MultiBuy = dynamic(() => import('components/multiBuy'));
export const SuccessMessage = dynamic(() => import('components/product/notification/components/SuccessMessage'));

export const OrderOverviewSimpleItem = dynamic(
    () => import('components/myProfile/OrderOverview/components/SimpleItem')
);
export const OrderOverviewOfflineItem = dynamic(
    () => import('components/myProfile/OrderOverview/components/OfflineItem')
);
export const OrderOverviewUpSaleItem = dynamic(
    () => import('components/myProfile/OrderOverview/components/UpSaleItem')
);
export const OrderOverview = dynamic(() => import('components/myProfile/OrderOverview'));
export const DigitalBonusGiftsHeader = dynamic(() => import('components/plp/BrandGifts/Header'));
export const DeliveryPreview = dynamic(() => import('components/basket/DeliveryPreview'));
export const BrandList = dynamic(() => import('components/BrandList'));
export const CrossSellProducts = dynamic(() => import('components/productRecommendation/components/CrossSellProducts'));
export const PowerstepNavigationButtons = dynamic(() => import('components/powerstep/components/PowerstepNavigation'));
export const BasketPowerstepOpener = dynamic(() => import('components/basket/BasketPowerstep/BasketPowerstepOpener'));
export const CheckoutDeliveryContinueButton = dynamic(
    () => import('components/checkout/components/DeliveryContinueButton')
);
export const ProductItemGallery = dynamic(() => import('components/product/ProductItemGallery/ProductItemGallery'));
export const SplashRenderer = dynamic(() => import('components/shared/Splash'));
export const GlobalComponents = dynamic(() => import('setup/GlobalComponents'));
export const PosFrontpage = dynamic(() => import('pos/frontpage/Frontpage'));
export const PosInit = dynamic(() => import('pos/init/InitPos'));
export const AdvisorFooter = dynamic(() => import('components/footer/components/AdvisorFooter'));
export const RecommendationLoader = dynamic(
    () => import('components/productRecommendation/components/RecommendationLoader')
);
export const StripedVerticalSpacer = dynamic(() => import('components/StripedVerticalSpacer'));
export const SubscriptionPendingPage = dynamic(() => import('components/myProfile/SubscriptionPendingPage'));
export const MyProfileSubscriptionPage = dynamic(() => import('components/pages/MyProfile/SubscriptionPage'));
export const MyProfileSubscriptionDetailsPage = dynamic(
    () => import('components/pages/MyProfile/SubscriptionDetailsPage')
);
export const MyProfileDeliveryPage = dynamic(() => import('components/pages/MyProfile/DeliveryPage'));
export const DeliveryIndicator = dynamic(() => import('components/Delivery/DeliveryIndicator'));
export const PermissionsForm = dynamic(() => import('components/myProfile/PermissionsForm'));
export const MobilePOSHeader = dynamic(() => import('components/MobilePOSHeader'));
export const ProductSlider = dynamic(() => import('components/product/ProductSlider'));
export const CMSProductSlider = dynamic(() => import('components/product/ProductSlider/components/CMSProductSlider'));
export const BrandFooter = dynamic(() => import('components/footer/components/BrandFooter'));
export const Breadcrumbs = dynamic(() => import('components/Breadcrumbs'));
export const ProductItem = dynamic(() => import('components/shared/productItem/ProductItem'));
export const YouTubePlaceholder = dynamic(() => import('components/CMS/components/YouTubePlaceholder'));
export const CMSButton = dynamic(() => import('components/CMS/components/Button/CMSButton'));
export const PrelaunchBanner = dynamic(() => import('components/PrelaunchBanner'));

export const BasketOverview = dynamic(() => import('components/checkout/components/BasketOverview/BasketOverview'));
export const BasketPrizeCertificate = dynamic(
    () => import('components/checkout/components/PrizeCertificate/BasketPrizeCertificate')
);
export const CheckoutPrizeCertificate = dynamic(
    () => import('components/checkout/components/PrizeCertificate/CheckoutPrizeCertificate')
);

export const ArticleHeaderFromContext = dynamic(
    () => import('components/shared/cmsPlaceholders/components/ArticleHeaderFromContext')
);
export const InspirationAlsoReadPlaceholder = dynamic(
    () => import('components/CMS/components/InspirationAlsoReadPlaceholder')
);
export const VimeoPlaceholder = dynamic(() => import('components/CMS/components/VimeoPlaceholder'));
export const InspirationArticleLeftHandPlaceholder = dynamic(
    () => import('components/CMS/components/InspirationArticleLeftHandPlaceholder')
);
export const InspirationArticleRightHandPlaceholder = dynamic(
    () => import('components/CMS/components/InspirationArticleRightHandPlaceholder')
);
export const MatasPlusChangeSubscriptionNudge = dynamic(() => import('components/matasPlus/ChangeSubscriptionNudge'));
export const UserMessageToast = dynamic(() => import('components/UserMessageToast'));
export const AIAssistant = dynamic(() => import('components/AIAssistant'));
export const TextContent = dynamic(() => import('components/CMS/components/TextContent'));
export const JsonLdContent = dynamic(() => import('components/CMS/components/JsonLdContent'));
export const VerticalSpacerPlaceholder = dynamic(() => import('components/CMS/components/VerticalSpacerPlaceholder'));
export const HorizontalLinePlaceholder = dynamic(() => import('components/CMS/components/HorizontalLinePlaceholder'));
export const AdvisorPlaceholder = dynamic(() => import('components/CMS/components/AdvisorPlaceholder'));
export const QuestionnaireContentBlock = dynamic(() => import('components/CMS/components/QuestionnaireContentBlock'));
export const QuestionnaireConsents = dynamic(() => import('components/CMS/components/QuestionnaireConsents'));
export const CallToAction = dynamic(() => import('components/CMS/components/CallToAction'));
export const SubMenu = dynamic(() => import('components/CMS/components/SubMenu'));
export const CustomContent = dynamic(() => import('components/CMS/components/CustomContent'));
export const SearchSuggestion = dynamic(() => import('components/SearchSuggestion'));
export const ProductListWithFilters = dynamic(() => import('components/shared/productList/ProductListWithFilters'));
export const ShippingOptionsPlaceholder = dynamic(() => import('components/CMS/components/ShippingOptionsPlaceholder'));
export const FollowButtonPlaceholder = dynamic(
    () => import('components/FollowButton/components/FollowButtonPlaceholder')
);

/* Pages */
export const PosSubscriptionPage = dynamic(() => import('pos/Subscription/SubscriptionPage'));
export const PosPaymentPage = dynamic(() => import('pos/payment/PosPaymentPage'));
export const ProcessingPage = dynamic(() => import('components/pages/Checkout/components/Payment/ProcessingPage'));
export const ProductListPage = dynamic(() => import('components/pages/PLP'));
export const ProductListPlaceholder = dynamic(() => import('components/CMS/components/ProductList'));
export const SearchPage = dynamic(() => import('components/pages/Search'));
export const MyProfileFrontPage = dynamic(() => import('components/pages/MyProfile/FrontPage'));
export const MyProfilePointPage = dynamic(() => import('components/pages/MyProfile/PointPage/PointPage'));
export const MyProfileSettingsPage = dynamic(() => import('components/pages/MyProfile/SettingsPage'));
export const MyProfileStorePage = dynamic(() => import('components/pages/MyProfile/StorePage'));
export const MyProfilePermissionPage = dynamic(() => import('components/pages/MyProfile/PermissionPage'));
export const MyProfileQuestionnaireTestResultPage = dynamic(
    () => import('components/pages/MyProfile/QuestionnaireTestResultPage')
);
export const MyProfileSignOffPage = dynamic(() => import('components/pages/MyProfile/SignOffPage'));
export const MyProfileOrdersPage = dynamic(() => import('components/pages/MyProfile/OrdersPage'));
export const MyProfileReviewsPage = dynamic(() => import('components/pages/MyProfile/ReviewsPage'));
export const MyProfileReviewRequestPage = dynamic(
    () => import('components/pages/MyProfile/ReviewsPage/ReviewRequestPage')
);
export const ReceiptPage = dynamic(() => import('components/pages/Receipt'));
export const FavoritesPage = dynamic(() => import('components/pages/MyProfile/FavoritesPage'));
export const MyFavoritesPage = dynamic(() => import('components/pages/MyProfile/MyFavoritesPage'));
export const MyFavoritesListPage = dynamic(() => import('components/pages/MyProfile/MyFavoritesListPage'));
export const DeliveryPage = dynamic(() => import('components/pages/Checkout/components/Delivery'));
export const SummaryPage = dynamic(() => import('components/pages/Checkout/components/SummaryPage'));
export const SummaryBasketGroups = dynamic(
    () => import('components/pages/Checkout/components/SummaryPage/components/SummaryBasketGroups')
);
export const ProductPage = dynamic(() => import('components/pages/PDP'));
export const BasketPage = dynamic(() => import('components/pages/Basket'));
export const ReviewPage = dynamic(() => import('components/pages/Review'));
export const GranularCampaignPermissionPage = dynamic(() => import('components/pages/GranularCampaignPermissionPage'));
export const KicksIpadPosRecieptPage = dynamic(() => import('components/KicksIpadPosReciept'));
export const LiveEventPlaceholder = dynamic(() => import('components/CMS/components/LiveEventPlaceholder'));
export const BeautyTrendBoxNotifyButtonPlaceholder = dynamic(
    () => import('components/CMS/components/BeautyTrendBoxNotifyButtonPlaceholder')
);
export const GiftCardBalancePlaceholder = dynamic(() => import('components/CMS/components/GiftCardBalancePlaceholder'));
export const MyProfileOrderDetailsPage = dynamic(
    () => import('components/pages/MyProfile/OrderDetailsPage/OrderDetailsPage')
);
