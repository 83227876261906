import { useOpenLoginQuickView } from 'components/login/shared/hooks/useLoginInfoQuickView';
import isClient from 'helpers/isClient';
import useIsAuthenticated from 'hooks/user/useIsAuthenticated';
import { useGetSampleSelectionQuery } from 'autogen/swagger/all/hooks';
import SamplesPicker from '../SamplesPicker';

export default function useChooseSample() {
    const { refetch: getSampleSelection } = useGetSampleSelectionQuery({ enabled: false });
    const isAuthenticated = useIsAuthenticated();
    const openLoginQuickView = useOpenLoginQuickView({
        infoType: 'BasketSample',
        level: 'Hard',
        mode: 'Standard',
        returnUrl: isClient ? `${window.location.pathname}?openSamples=True` : '/'
    });

    const openChooseSampleModal = async () => {
        const { default: createModal } = await import(/* webpackChunkName: "modal" */ 'modules/modal'); // lets deprecate this and use quickView instead
        const modal = createModal();
        const { data } = await getSampleSelection();
        if (data) {
            modal({
                open: true,
                content: <SamplesPicker {...data} modal={modal} />,
                layout: { theme: { maxWidth: '1024px' } }
            });
        }
    };

    const handleOpenChooseSample = async () => {
        try {
            if (!isAuthenticated) {
                openLoginQuickView();
            } else {
                openChooseSampleModal();
            }
        } catch (error) {
            console.error(error);
        }
    };

    return handleOpenChooseSample;
}
