/* eslint-disable */
/// This file is auto generated - do not edit manually
export * as Pending from './trans-website-myorder-pending';
export * as Unavailable from './trans-website-myorder-unavailable';
export * as Followup from './trans-website-myorder-followup';

export const MobileNotValid = 'Website.MyOrder.MobileNotValid';
export const MobileRequired = 'Website.MyOrder.MobileRequired';
export const OrderNotFound = 'Website.MyOrder.OrderNotFound';
export const Login = 'Website.MyOrder.Login';
export const Cancel = 'Website.MyOrder.Cancel';
export const Return = 'Website.MyOrder.Return';
export const Claim = 'Website.MyOrder.Claim';
export const ClaimsUrl = 'Website.MyOrder.ClaimsUrl';
export const ReturnsUrl = 'Website.MyOrder.ReturnsUrl';
export const CancelFailed = 'Website.MyOrder.CancelFailed';
export const Time = 'Website.MyOrder.Time';
export const SentBy = 'Website.MyOrder.SentBy';
export const ShowTrackAndTrace = 'Website.MyOrder.ShowTrackAndTrace';
export const OrderStatusHeadline = 'Website.MyOrder.OrderStatusHeadline';
export const DeliveryEstimatedArrival = 'Website.MyOrder.DeliveryEstimatedArrival';
export const Error = 'Website.MyOrder.Error';
export const CurrentStatus = 'Website.MyOrder.CurrentStatus';
export const Heading = 'Website.MyOrder.Heading';
export const OrderProcessHeadline = 'Website.MyOrder.OrderProcessHeadline';
export const DeliveryDetails = 'Website.MyOrder.DeliveryDetails';
export const InvoiceDetails = 'Website.MyOrder.InvoiceDetails';
export const DeliveredTo = 'Website.MyOrder.DeliveredTo';
export const Carrier = 'Website.MyOrder.Carrier';
export const DeliveredBy = 'Website.MyOrder.DeliveredBy';
export const DeliveredDigitally = 'Website.MyOrder.DeliveredDigitally';
export const ParcelNumberText = 'Website.MyOrder.ParcelNumberText';
export const CompanyName = 'Website.MyOrder.CompanyName';
export const Name = 'Website.MyOrder.Name';
export const Address = 'Website.MyOrder.Address';
export const ZipCode = 'Website.MyOrder.ZipCode';
export const City = 'Website.MyOrder.City';
export const Email = 'Website.MyOrder.Email';
export const Mobile = 'Website.MyOrder.Mobile';
export const ConfirmCancellation = 'Website.MyOrder.ConfirmCancellation';
export const ConfirmCancellationDescription = 'Website.MyOrder.ConfirmCancellationDescription';
export const ConfirmCancellationDescriptionMatasPlus = 'Website.MyOrder.ConfirmCancellationDescriptionMatasPlus';
export const CancelError = 'Website.MyOrder.CancelError';
export const CancelSuccess = 'Website.MyOrder.CancelSuccess';
export const Cancelling = 'Website.MyOrder.Cancelling';
export const OrderNumber = 'Website.MyOrder.OrderNumber';
export const OrderNumberShortHand = 'Website.MyOrder.OrderNumberShortHand';
export const PrintVersion = 'Website.MyOrder.PrintVersion';
export const DigitalVersion = 'Website.MyOrder.DigitalVersion';
export const DigitalOrderNotice = 'Website.MyOrder.DigitalOrderNotice';
export const DigitalOnlyOrderHeading = 'Website.MyOrder.DigitalOnlyOrderHeading';
export const BookingOrderHeading = 'Website.MyOrder.BookingOrderHeading';
export const GoToBooking = 'Website.MyOrder.GoToBooking';
export const OrderLinesFrom = 'Website.MyOrder.OrderLinesFrom';
export const MultipleSuppliersBoxDescription = 'Website.MyOrder.MultipleSuppliersBoxDescription';
export const YourInformation = 'Website.MyOrder.YourInformation';
export const OfflinePurchase = 'Website.MyOrder.OfflinePurchase';
