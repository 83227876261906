import { MenuItemCustomActionViewModel } from 'autogen/swagger/all';
import CustomerChatMenuItem from './Items/CustomerChatMenuItem';
import { DefaultMenuItemProps } from './types';

type CustomActionMenuItemRendererProps = {
    model: MenuItemCustomActionViewModel;
    defaultProps: DefaultMenuItemProps;
};

/**
 * All Custom MenuItem actions should be added here
 */
export default function CustomActionMenuItemRenderer({
    model,
    defaultProps,
    ...restProps
}: CustomActionMenuItemRendererProps) {
    switch (model.customAction) {
        case 'OpenCustomerServiceChat':
            return <CustomerChatMenuItem model={model} defaultProps={defaultProps} {...restProps} />;
        default:
            return null;
    }
}
