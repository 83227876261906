import { FontSize14 } from 'autogen/design-tokens/tokens';
import type { BasketLineViewModel } from 'autogen/swagger/all';
import { useUpdateQuantityMutation } from 'autogen/swagger/all/hooks';
import { Pieces } from 'autogen/translation-keys/trans-website-basket';
import { updateBasket } from 'components/basket/shared/basket';
import {
    ControlledReactCounterInput,
    type IReactCounterInputProps
} from 'components/react-counterInput/react-counterInput';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StyledCountInput = styled(ControlledReactCounterInput)`
    width: 80px;
    font-size: ${FontSize14} !important;
`;

type NumberInputProps = {
    productId: BasketLineViewModel['productId'];
    maximumSalesQuantity: BasketLineViewModel['maximumSalesQuantity'];
    minimumSalesQuantity: BasketLineViewModel['minimumSalesQuantity'];
    quantity: BasketLineViewModel['quantity'];
} & Pick<IReactCounterInputProps, 'size' | 'autoFocus'>;

export default function NumberInput({
    productId,
    maximumSalesQuantity,
    minimumSalesQuantity,
    quantity: initialQuantity, // state from server
    ...restProps
}: NumberInputProps) {
    const { t } = useTranslation();
    const { mutateAsync: updateQuantity } = useUpdateQuantityMutation({ onSuccess: updateBasket });
    const [quantity, setQuantity] = useState(initialQuantity);
    const previousUpdatedQuantity = useRef(initialQuantity);
    const [isFocus, setIsFocus] = useState(false);

    const handleOnChange = (value: number) => {
        setQuantity(value);
    };

    const handleOnBlur = async (value: number) => {
        setIsFocus(false);
        if (initialQuantity === value) return;

        const { willAffectSamplesConfirm } = await import('views/basket/samples');

        /*
            if switching the quantity affects the sample you have in basket open a confirm modal
            if user denies the change return without updating quantity.
        */
        if (!(await willAffectSamplesConfirm({ productId, quantity: value }))) {
            setQuantity(previousUpdatedQuantity.current);
            return;
        }

        await updateQuantity({ params: { productId, quantity: value } });
        setQuantity((previousValue) => {
            previousUpdatedQuantity.current = previousValue;
            return value;
        });
    };

    useEffect(() => {
        setQuantity(initialQuantity);
    }, [initialQuantity]);

    return (
        <StyledCountInput
            value={isFocus ? quantity : (`${quantity} ${t(Pieces)}` as any)}
            size="md"
            onValueChange={handleOnChange}
            onBlur={handleOnBlur}
            onFocus={() => setIsFocus(true)}
            maxValue={maximumSalesQuantity}
            minValue={minimumSalesQuantity}
            adjustCountButtons={false}
            {...restProps}
        />
    );
}
