/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable import/prefer-default-export */
import { SampleSelectionViewModel } from 'autogen/swagger/all';
import { createContext } from 'react';
import { itemState } from '../shared/types';

export const SelectItemCallbackContext = createContext<
    (state: itemState, productId: number, isRecommended: boolean) => void
>(() => {});
export const SelectedProductIdsContext = createContext<number[]>([]);
export const ReadMoreCallbackContext = createContext<(item?: SampleSelectionViewModel) => void>(() => {});
