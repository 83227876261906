import { ColorNordicGrey2, FontWeightSemiBold } from 'autogen/design-tokens/tokens';
import { MenuItemViewModel } from 'autogen/swagger/all';
import Tag from 'components/generic/tag';
import ListItem from 'components/List/components/ListItem';
import { ListItemType } from 'components/List/shared/types';
import WithIcon from 'components/hoc/withIcon/WithIcon';
import { ReactNode } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { News } from 'autogen/translation-keys/trans-website-shared';

export interface IMenuItemStyleRenderer extends ListItemType {
    styling: MenuItemViewModel['styling'];
    children: ReactNode;
}

const StyledListItem = styled(ListItem)`
    font-weight: ${FontWeightSemiBold};
`;

function HighlightedListItem({ children, ...restProps }) {
    return <StyledListItem {...restProps}>{children}</StyledListItem>;
}

function NewTagListItem({ children, ...restProps }) {
    const { t } = useTranslation();

    return (
        <ListItem {...restProps}>
            <WithIcon position="right" icon={<Tag backgroundColor={ColorNordicGrey2}>{t(News)}</Tag>}>
                {children}
            </WithIcon>
        </ListItem>
    );
}

/**
 * Abstraction on top of ListItem, to render styling variations of ListItem that can come from MenuItemViewModel
 */
export default function MenuItemStyleRenderer({ styling, children, ...restProps }: IMenuItemStyleRenderer) {
    switch (styling) {
        case 'Highlighted':
            return <HighlightedListItem {...restProps}>{children}</HighlightedListItem>;
        case 'New':
            return <NewTagListItem {...restProps}>{children}</NewTagListItem>;
        default:
            return <ListItem {...restProps}>{children}</ListItem>;
    }
}
