import type { MenuItemLinkViewModel } from 'autogen/swagger/all';
import useIsPathnameActive from 'components/sideMenu/shared/hooks/useIsPathnameActive';
import UAEventTracking from 'modules/tracking/UAEventTracking';
import { GA4Tracking } from 'modules/tracking/GA4';
import DefaultMenuItem from './DefaultMenuItem';
import type { DefaultMenuItemProps } from '../types';

type LinkMenuItemProps = {
    model: MenuItemLinkViewModel;
    defaultProps: DefaultMenuItemProps;
};

export default function LinkMenuItem({
    model: { linkAction, target, title, text, productGroupPath },
    /* default props */
    defaultProps: { iconUrl, styling, id },
    ...restProps
}: LinkMenuItemProps) {
    const isSelected = useIsPathnameActive(productGroupPath?.url);

    return (
        <DefaultMenuItem.Anchor
            anchorProps={{ action: linkAction, target, title }}
            selected={isSelected}
            styling={styling}
            iconUrl={iconUrl}
            onClick={() => {
                UAEventTracking({
                    eventCategory: 'sideMenu',
                    eventAction: 'link',
                    eventLabel: `${id}_${productGroupPath}`
                });

                GA4Tracking({
                    eventName: 'link_click',
                    context: 'menu',
                    category: 'navigation',
                    eventParams: [
                        { param_name: 'link_type', param_value: 'internal' },
                        { param_name: 'list_name', param_value: text },
                        { param_name: 'id', param_value: id }
                    ]
                });
            }}
            {...restProps}
        >
            {text}
        </DefaultMenuItem.Anchor>
    );
}
