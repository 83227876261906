import dynamic from 'helpers/dynamic';
import useSegmentation from 'hooks/user/useSegmentation';
import React, { type ComponentPropsWithoutRef, forwardRef } from 'react';
import styled from 'styled-components';

// Matas logos
const Matas = dynamic(() => import('icons/logos/matas/MatasLogo.svg?react'));
const MatasNegative = dynamic(() => import('icons/logos/matas/MatasLogoNegative.svg?react'));
const ClubMatasHorizontalLogo = dynamic(() => import('icons/logos/matas/ClubMatasHorizontal.svg?react'));
const ClubMatasHorizontalNegative = dynamic(() => import('icons/logos/matas/ClubMatasHorizontalNegative.svg?react'));
const ClubMatasHorizontalMono = dynamic(() => import('icons/logos/matas/ClubMatasHorizontalMono.svg?react'));
const ClubMatasHorizontalMonoNegative = dynamic(
    () => import('icons/logos/matas/ClubMatasHorizontalMonoNegative.svg?react')
);
const ClubMatasVerticalLogo = dynamic(() => import('icons/logos/matas/ClubMatasVertical.svg?react'));
const ClubMatasVerticalLogoNegative = dynamic(() => import('icons/logos/matas/ClubMatasVerticalNegative.svg?react'));
const ClubMatasVerticalLogoMono = dynamic(() => import('icons/logos/matas/ClubMatasVerticalMono.svg?react'));
const ClubMatasVerticalLogoMonoNegative = dynamic(
    () => import('icons/logos/matas/ClubMatasVerticalMonoNegative.svg?react')
);
const ClubMatasPlusHorizontalLogo = dynamic(() => import('icons/logos/matas/ClubMatasPlusHorizontal.svg?react'));
const ClubMatasPlusHorizontalLogoNegative = dynamic(
    () => import('icons/logos/matas/ClubMatasPlusHorizontalNegative.svg?react')
);
const ClubMatasPlusHorizontalLogoMono = dynamic(
    () => import('icons/logos/matas/ClubMatasPlusHorizontalMono.svg?react')
);
const ClubMatasPlusHorizontalLogoMonoNegative = dynamic(
    () => import('icons/logos/matas/ClubMatasPlusHorizontalMonoNegative.svg?react')
);
const ClubMatasPlusVerticalLogo = dynamic(() => import('icons/logos/matas/ClubMatasPlusVertical.svg?react'));
const ClubMatasPlusVerticalLogoNegative = dynamic(
    () => import('icons/logos/matas/ClubMatasPlusVerticalNegative.svg?react')
);
const ClubMatasPlusVerticalLogoMono = dynamic(() => import('icons/logos/matas/ClubMatasPlusVerticalMono.svg?react'));
const ClubMatasPlusVerticalLogoMonoNegative = dynamic(
    () => import('icons/logos/matas/ClubMatasPlusVerticalMonoNegative.svg?react')
);

// Kicks logos
const Kicks = dynamic(() => import('icons/logos/kicks/kicks.svg?react'));
const KicksLight = dynamic(() => import('icons/logos/kicks/kicksLight.svg?react'));
const KicksClubHorizontal = dynamic(() => import('icons/logos/kicks/kicksClubHorizontal.svg?react'));
const KicksClubVertical = dynamic(() => import('icons/logos/kicks/kicksClubVertical.svg?react'));

export type LogoType =
    | 'negative'
    | 'clubVertical'
    | 'clubVerticalNegative'
    | 'clubVerticalMono'
    | 'clubVerticalMonoNegative'
    | 'clubHorizontal'
    | 'clubHorizontalNegative'
    | 'clubHorizontalMono'
    | 'clubHorizontalMonoNegative'
    | 'clubPlusVertical'
    | 'clubPlusVerticalNegative'
    | 'clubPlusVerticalMono'
    | 'clubPlusVerticalMonoNegative'
    | 'clubPlusHorizontal'
    | 'clubPlusHorizontalNegative'
    | 'clubPlusHorizontalMono'
    | 'clubPlusHorizontalMonoNegative';

const useLogoType = (type?: LogoType) => {
    const { tenant } = useSegmentation();

    switch (type) {
        case 'negative':
            return tenant === 'Matas' ? MatasNegative : KicksLight;
        case 'clubHorizontal':
            return tenant === 'Matas' ? ClubMatasHorizontalLogo : KicksClubHorizontal;
        case 'clubHorizontalNegative':
            return tenant === 'Matas' ? ClubMatasHorizontalNegative : null;
        case 'clubHorizontalMono':
            return tenant === 'Matas' ? ClubMatasHorizontalMono : null;
        case 'clubHorizontalMonoNegative':
            return tenant === 'Matas' ? ClubMatasHorizontalMonoNegative : null;
        case 'clubVertical':
            return tenant === 'Matas' ? ClubMatasVerticalLogo : KicksClubVertical;
        case 'clubVerticalNegative':
            return tenant === 'Matas' ? ClubMatasVerticalLogoNegative : null;
        case 'clubVerticalMono':
            return tenant === 'Matas' ? ClubMatasVerticalLogoMono : null;
        case 'clubVerticalMonoNegative':
            return tenant === 'Matas' ? ClubMatasVerticalLogoMonoNegative : null;
        case 'clubPlusHorizontal':
            return tenant === 'Matas' ? ClubMatasPlusHorizontalLogo : null;
        case 'clubPlusHorizontalNegative':
            return tenant === 'Matas' ? ClubMatasPlusHorizontalLogoNegative : null;
        case 'clubPlusHorizontalMono':
            return tenant === 'Matas' ? ClubMatasPlusHorizontalLogoMono : null;
        case 'clubPlusHorizontalMonoNegative':
            return tenant === 'Matas' ? ClubMatasPlusHorizontalLogoMonoNegative : null;
        case 'clubPlusVertical':
            return tenant === 'Matas' ? ClubMatasPlusVerticalLogo : null;
        case 'clubPlusVerticalNegative':
            return tenant === 'Matas' ? ClubMatasPlusVerticalLogoNegative : null;
        case 'clubPlusVerticalMono':
            return tenant === 'Matas' ? ClubMatasPlusVerticalLogoMono : null;
        case 'clubPlusVerticalMonoNegative':
            return tenant === 'Matas' ? ClubMatasPlusVerticalLogoMonoNegative : null;
        default:
            return tenant === 'Matas' ? Matas : Kicks;
    }
};

type LogoProps = {
    type?: LogoType;
} & ComponentPropsWithoutRef<'svg'>;

const Component = forwardRef<SVGSVGElement, LogoProps>(({ type, ...restProps }, ref) => {
    const Logo = useLogoType(type);

    if (!Logo) return null;

    return <Logo ref={ref} {...restProps} />;
});

const Logo = styled(Component)`
    fill: var(--color-brand-blue-1);
`;

export default Logo;
