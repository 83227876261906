import type { MenuItemMyProfileViewModel } from 'autogen/swagger/all';
import MenuWithHeader from 'components/sideMenu/components/Menu/components/MenuWithHeader';
import styled from 'styled-components';
import format from 'helpers/translations';
import useIsClubMatasMember from 'hooks/user/useIsClubMatasMember';
import { ColorClubMatasPlus1, ColorClubMatas1, ColorBeauty2 } from 'autogen/design-tokens/tokens';
import useSmartviewManager from 'components/generic/smartview/shared/hooks/useSmartviewManager';
import dynamic from 'helpers/dynamic';
import DefaultMenuItem from './DefaultMenuItem';
import type { DefaultMenuItemProps } from '../types';
import { GA4Tracking } from 'modules/tracking/GA4';
import ListItem from 'components/List/components/ListItem';
import LinkAction from 'components/shared/Routing/components/LinkAction';

type MyProfileMenuItemProps = {
    model: MenuItemMyProfileViewModel;
    defaultProps: DefaultMenuItemProps;
};

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

const ClubMatasColor = styled.span<{ isClubMatasPlus: boolean }>`
    font-weight: bold;
    color: ${({ isClubMatasPlus }) => (isClubMatasPlus ? ColorClubMatasPlus1 : ColorClubMatas1)};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: right;
    max-width: 112px;
    line-height: normal;
`;

const Text = styled.span`
    white-space: nowrap;
`;

const Checkmark = styled(dynamic(() => import('icons/Checkmark.svg?react')))`
    fill: ${ColorBeauty2};
`;

const StyledLinkAction = styled(LinkAction)`
    display: block;
    width: 100%;

    &, &:hover, &:focus {
        text-decoration: none;
    }
`;

export default function MyProfileMenuItem({ model, defaultProps, ...restProps }: MyProfileMenuItemProps) {
    const { text, subText, isSelected, linkAction, target, isExpanded, isChecked, subMenu } = model;
    const { iconUrl, styling, id } = defaultProps;
    const { isMatasPlusMember } = useIsClubMatasMember();

    if (subMenu.length) {
        return <MyProfileSubMenuItem model={model} defaultProps={defaultProps} />;
    }

    const checkmark = !isExpanded && isChecked && isSelected && <Checkmark />;

    function tracking() {
        GA4Tracking({
            eventName: 'link_click',
            context: 'my_matas',
            category: 'navigation',
            eventParams: [
                { param_name: 'link_type', param_value: 'internal' },
                { param_name: 'list_name', param_value: text },
                { param_name: 'id', param_value: id }
            ]
        });
    }

    return (
        <DefaultMenuItem.Anchor
            anchorProps={{ action: linkAction, target }}
            selected={isSelected}
            styling={styling}
            iconUrl={iconUrl}
            beforeSlot={checkmark || undefined}
            onClick={tracking}
            {...restProps}
        >
            <Wrapper>
                <Text> {isSelected ? <b>{text}</b> : text}</Text>
                <ClubMatasColor isClubMatasPlus={isMatasPlusMember}> {format(subText?.text)}</ClubMatasColor>
            </Wrapper>
        </DefaultMenuItem.Anchor>
    );
}

const ArrowRight = dynamic(() => import('icons/ArrowRight.svg?react'), { ssr: false });

function MyProfileSubMenuItem({
    model: { text, isSelected, linkAction, subMenu },
    defaultProps: { iconUrl, styling, id },
    ...restProps
}: MyProfileMenuItemProps) {
    const smartSideDrawerId = `${subMenu[0].id}-myprofile-menu`;
    const { open: openSubMenu, close: closeSubMenu } = useSmartviewManager(smartSideDrawerId);

    return (
        <>
            {linkAction ? (
                <ListItem
                    afterSlot={<ArrowRight width={16} height={16} onClick={openSubMenu} {...restProps} />}
                    selected={isSelected}
                >
                    <StyledLinkAction action={linkAction}>{text}</StyledLinkAction>
                </ListItem>
            ) : (
                <DefaultMenuItem.Button
                    buttonProps={{ onClick: openSubMenu }}
                    afterSlot={<ArrowRight width={16} height={16} />}
                    selected={isSelected}
                    styling={styling}
                    iconUrl={iconUrl}
                    {...restProps}
                >
                    {text}
                </DefaultMenuItem.Button>
            )}

            <MenuWithHeader
                menu={subMenu[0]}
                smartSideDrawerId={smartSideDrawerId}
                backButtonCallback={closeSubMenu}
                animate={false}
            />
        </>
    );
}
