import { ChoosableSubscriptionFrequency, ChoosableSubscriptionFrequencyGroup } from 'autogen/swagger/all';

const ItemsToTakeForShortList = 4;

export function getSelected(groups: ChoosableSubscriptionFrequencyGroup[]) {
    const selected = groups.find((x) => x.isSelected);

    if (selected) return selected.frequencies.find((x) => x.isSelected);

    if (groups.some((x) => x.noPreselect)) return null;

    const recommended = groups.find((x) => x.isRecommended);

    if (recommended) return recommended.frequencies.find((x) => x.isRecommended);

    return groups[0].frequencies[0];
}

export function getSelectedGroup(groups: ChoosableSubscriptionFrequencyGroup[]) {
    const selected = groups.find((x) => x.isSelected);

    if (selected) return selected;

    if (groups.some((x) => x.noPreselect)) return null;

    const recommended = groups.find((x) => x.isRecommended);

    if (recommended) return recommended;

    return groups[0];
}

export function getShortList(
    frequencies: ChoosableSubscriptionFrequency[],
    selected: ChoosableSubscriptionFrequency
): ChoosableSubscriptionFrequency[] {
    if (!selected) return frequencies;

    const itemsIncludingSelected = ItemsToTakeForShortList + 1;
    if (itemsIncludingSelected >= frequencies.length) return frequencies;

    const itemsOnEachSide = ItemsToTakeForShortList / 2;
    let index = frequencies.indexOf(selected) - itemsOnEachSide;

    index = Math.min(Math.max(index, 0), frequencies.length - itemsIncludingSelected);

    return frequencies.slice(index, index + itemsIncludingSelected);
}
