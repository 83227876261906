import { type AdditionalSummaryLine } from 'autogen/swagger/all';
import { AmountPaidByGiftCard } from 'autogen/translation-keys/trans-website-basket';
import Layout from 'components/Layout';
import { Price } from 'components/generic/productPrice';
import { Typography } from 'components/generic/typography';
import { useTranslation } from 'react-i18next';

type AdditionalGiftCardSummaryLine = Record<string, any> & { model: AdditionalSummaryLine };

export default function AdditionalGiftCardSummaryLine({ model, ...restProps }: AdditionalGiftCardSummaryLine) {
    const { t } = useTranslation();

    return (
        <Layout.Flex.Row justifyContent="space-between" alignItems="center" {...restProps}>
            <Typography.Body as="span" themeTypes="mediumSemiBold">
                {model.text} <br />
                {t(AmountPaidByGiftCard)}
            </Typography.Body>
            <Price price={model.amount} />
        </Layout.Flex.Row>
    );
}
