import React from 'react';
import styled, { css } from 'styled-components';
import getTypeStyling from 'helpers/getTypeStyling';
import type { ListItemType, Size } from '../shared/types';
import { ListItemSizes, actionWrapperStyling } from '../shared/styles';
import { ColorNeutralBlack } from 'autogen/design-tokens/tokens';

export const StyledListItem = styled.li<Pick<ListItemType, 'selected'> & Pick<ListItemType, 'size'>>`
    display: flex;
    align-items: center;
    outline: none;
    line-height: 1;
    align-items: stretch;
    ${({ size, theme }) => {
        if (size) return getTypeStyling((resolvedSize: Size) => ListItemSizes[resolvedSize].item)(size as Size);
        if (theme.size) return getTypeStyling((resolvedSize: Size) => ListItemSizes[resolvedSize].item)(theme.size);
        return ListItemSizes.md.item;
    }}

    &:focus-within,
    &:active {
        background-color: rgb(from ${ColorNeutralBlack} r g b / 12%);
    }

    ${({ selected }) =>
        selected &&
        css`
            background-color: rgb(from ${ColorNeutralBlack} r g b / 12%);
        `}
`;

const SimpleWrapper = styled.span<Pick<ListItemType, 'size'>>`
    ${actionWrapperStyling}
    ${({ size, theme }) => {
        if (size)
            return getTypeStyling((resolvedSize: Size) => ListItemSizes[resolvedSize].actionWrapper)(size as Size);
        if (theme.size)
            return getTypeStyling((resolvedSize: Size) => ListItemSizes[resolvedSize].actionWrapper)(theme.size);
        return ListItemSizes.md.actionWrapper;
    }}
`;

const Content = styled.span<Pick<ListItemType, 'nowrap'>>`
    flex: 1 1 auto;
    min-width: 0;
`;

export const BeforeSlot = styled.span<Pick<ListItemType, 'size'>>`
    ${({ size, theme }) => {
        if (size) return getTypeStyling((resolvedSize: Size) => ListItemSizes[resolvedSize].beforeSlot)(size as Size);
        if (theme.size)
            return getTypeStyling((resolvedSize: Size) => ListItemSizes[resolvedSize].beforeSlot)(theme.size);
        return ListItemSizes.md.beforeSlot;
    }}
`;

const AfterSlot = styled.span<Pick<ListItemType, 'size'>>`
    ${({ size, theme }) => {
        if (size) return getTypeStyling((resolvedSize: Size) => ListItemSizes[resolvedSize].afterSlot)(size as Size);
        if (theme.size)
            return getTypeStyling((resolvedSize: Size) => ListItemSizes[resolvedSize].afterSlot)(theme.size);
        return ListItemSizes.md.afterSlot;
    }}
`;

export default function ListItem(props: ListItemType) {
    const { children, beforeSlot, afterSlot, wrapperSlot, size, selected, className, ...restProps } = props;
    const classNames = selected ? `selected ${className}` : className;

    const content = (
        <>
            {beforeSlot && <BeforeSlot size={size}>{beforeSlot}</BeforeSlot>}
            <Content>{children}</Content>
            {afterSlot && <AfterSlot size={size}>{afterSlot}</AfterSlot>}
        </>
    );

    return (
        <StyledListItem size={size} selected={selected} className={classNames} {...restProps}>
            {wrapperSlot ? (
                React.cloneElement(wrapperSlot, {}, content)
            ) : (
                <SimpleWrapper size={size}>{content}</SimpleWrapper>
            )}
        </StyledListItem>
    );
}
