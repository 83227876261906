import {
    type MenuItemViewModel,
    MenuItemContentViewModelDiscriminator as MenuItemViewModelcontentDiscriminator
} from 'autogen/swagger/all';
import React, { type HTMLAttributes, memo } from 'react';
import CustomActionMenuItemRenderer from './components/CustomActionMenuItemRenderer';
import SubMenuItem from './components/Items/SubMenuItem';
import LinkMenuItem from './components/Items/LinkMenuItem';
import LoginOrMyProfileMenuItem from './components/Items/LoginOrMyProfileMenuItem';
import CustomActionWithUrlMenuItemRenderer from './components/CustomActionWithUrlMenuItemRenderer';
import MyProfileMenuItem from './components/Items/MyProfileMenuItem';

const SideMenuItem = ({
    item: { content, ...defaultProps },
    ...restProps
}: { item: MenuItemViewModel } & HTMLAttributes<HTMLElement>) =>
    MenuItemViewModelcontentDiscriminator<JSX.Element | null>(
        content,
        (model) => <CustomActionMenuItemRenderer model={model} defaultProps={defaultProps} {...restProps} />, // Custom Action item, ex opening a chat.
        (model) => <CustomActionWithUrlMenuItemRenderer model={model} defaultProps={defaultProps} {...restProps} />, // Custom Action that includes a specific url.
        (model) => <LinkMenuItem model={model} defaultProps={defaultProps} {...restProps} />, // A normal link Action.
        (model) => <LoginOrMyProfileMenuItem model={model} defaultProps={defaultProps} {...restProps} />, // A login Action.
        (model) => <MyProfileMenuItem model={model} defaultProps={defaultProps} {...restProps} />, // MyProfileItem
        (model) => <SubMenuItem model={model} defaultProps={defaultProps} {...restProps} />, // A navigation to a subMenu Action.
        () => {
            console.error('Invalid MenuItemViewModelContent');
            return null;
        }
    );

export default memo(SideMenuItem);
