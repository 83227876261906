import React, { memo } from 'react';
import { useMenuQuery } from 'autogen/swagger/all/hooks';
import requestContext from 'modules/requestContext';
import SideMenuProvider from '../../shared/context/SideMenuProvider';
import { StyledSmartSideDrawerView } from '../../shared/styles';
import MenuError from './components/MenuError';
import MenuWithHeader from './components/MenuWithHeader';
import { MenuHeaderWithMatasLogo } from './components/MenuHeader';

const context = requestContext;

const RootMenu = () => {
    const {
        data: menu,
        isLoading,
        isError,
        refetch: retry
    } = useMenuQuery(
        {
            depth: 1,
            display: context.posRequest?.isPosRequest ? 'Pos' : 'Webshop'
        },
        { refetchOnWindowFocus: false }
    );

    if (isError && !isLoading) {
        return (
            <StyledSmartSideDrawerView name="side-menu">
                <MenuError retryFunction={retry} />
            </StyledSmartSideDrawerView>
        );
    }

    return (
        <SideMenuProvider>
            <MenuWithHeader
                smartSideDrawerId="side-menu"
                menu={menu}
                isLoading={isLoading}
                headerRenderer={(headerProps) => <MenuHeaderWithMatasLogo {...headerProps} />}
            />
        </SideMenuProvider>
    );
};

export default memo(RootMenu);
