import type { ProductListItemModel } from 'autogen/swagger/all';
import type { ThemeType } from 'components/shared/productList/shared/types';

// eslint-disable-next-line import/prefer-default-export
export enum TrackingEvent {
    AddToFavourites = 'AddToFavourites',
    AddToBasket = 'AddToBasket',
    ProductClick = 'ProductClick',
    ProductClickAndAddToBasket = 'ProductClickAndAddToBasket',
    ProductClickQuickSearch = 'ProductClickQuickSearch',
    SearchInitiated = 'SearchInitiated',
    Search = 'Search'
}

export type ProductItemProps = {
    model: ProductListItemModel;
    recommendationPlacement?: string;
    themeType?: ThemeType;
    pageContext?: 'ProductList';
};
