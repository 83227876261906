import { fetchJSON } from 'modules/helpers/fetch';
import { BambuserGetProductsUrl as GetProductsUrl } from 'autogen/swagger/all';
import type {
    IProductResult,
    IBambuserEvent,
    IBambuserCreateProduct,
    IBambuserCreateVariation,
    IBambuserCreateSize,
    IBambuserCreateAttribute,
    IBambuserCreatePrice,
    IBambuserPlayer
} from './types';

// Documentation for bambuser product can be found here: https://www.notion.so/Updating-product-details-7b8bb5dc9860484dbfb6fcfa9167849d
export async function provideProductData(event: IBambuserEvent, player: IBambuserPlayer): Promise<void> {
    const { products: bambuserProducts } = event;

    const url = `${GetProductsUrl}?${bambuserProducts.map(({ ref }): string => `productId=${ref}`).join('&')}`;
    // fetch our product data
    const products = (await fetchJSON(url)) as IProductResult[];

    bambuserProducts.forEach(({ id, ref }) => {
        const product = products.find((p): boolean => p.productId.toString() === ref);
        if (!product) return;

        // hydrate bambuser products with our data
        player.updateProduct(id, (factory) =>
            factory
                .currency('kr.')
                .locale('da-DK')
                .product(
                    (productData): IBambuserCreateProduct =>
                        productData
                            .brandName(product.brandName)
                            .defaultVariationIndex(0)
                            .description(product.description)
                            .name(product.name)
                            .sku(product.productId.toString())
                            .variations((createVariant): IBambuserCreateVariation[] =>
                                product.variants?.map(
                                    (variant): IBambuserCreateVariation =>
                                        createVariant()
                                            .attributes(
                                                (attributeData): IBambuserCreateAttribute =>
                                                    attributeData.colorName(variant.variantName)
                                            )
                                            .imageUrls(variant.imageUrls)
                                            .name(variant.variantName)
                                            .sku(variant.productId.toString())
                                            .sizes((createSize): IBambuserCreateSize[] => [
                                                createSize()
                                                    .name(variant.variantName)
                                                    .inStock(variant.inStock)
                                                    .price(
                                                        (price): IBambuserCreatePrice =>
                                                            price
                                                                .currency('kr.')
                                                                .current(variant.price)
                                                                .original(variant.priceBefore)
                                                    )
                                                    .sku(variant.productId.toString())
                                            ])
                                )
                            )
                )
        );
    });
}

export default provideProductData;
