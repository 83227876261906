/// This file is auto generated - do not edit manually

const images = {
  "advisor": {
    "specialists": "/images/advisor/specialists.png"
  },
  "advisor-toolbox": {
    "advisor-toolbox-booking-v1": "/images/advisor-toolbox/advisor-toolbox-booking-v1.jpg",
    "advisor-toolbox-chat-v1": "/images/advisor-toolbox/advisor-toolbox-chat-v1.jpg",
    "advisor-toolbox-questionnaire-v1": "/images/advisor-toolbox/advisor-toolbox-questionnaire-v1.jpg"
  },
  "AI": {
    "reviewReport": {
      "cover_VS_x2": "/images/AI/reviewReport/cover_VS_x2.jpg"
    }
  },
  "app": {
    "splashes": {
      "danger-corrosive": "/images/app/splashes/danger-corrosive.png",
      "danger-explosive": "/images/app/splashes/danger-explosive.png",
      "danger-flammable": "/images/app/splashes/danger-flammable.png",
      "danger-for-environment": "/images/app/splashes/danger-for-environment.png",
      "danger-gas-under-pressure": "/images/app/splashes/danger-gas-under-pressure.png",
      "danger-health-hazard": "/images/app/splashes/danger-health-hazard.png",
      "danger-oxidizing": "/images/app/splashes/danger-oxidizing.png",
      "danger-serious-health-hazard": "/images/app/splashes/danger-serious-health-hazard.png",
      "danger-to-life": "/images/app/splashes/danger-to-life.png",
      "derma-badge-dk": "/images/app/splashes/derma-badge-dk.png",
      "derma-badge-no": "/images/app/splashes/derma-badge-no.png",
      "derma-badge-se": "/images/app/splashes/derma-badge-se.png",
      "modiface": "/images/app/splashes/modiface.png",
      "pro-hair-care": "/images/app/splashes/pro-hair-care.png"
    }
  },
  "basket-aside": {
    "icons_delivery": "/images/basket-aside/icons_delivery.svg",
    "icons_lock-outline": "/images/basket-aside/icons_lock-outline.svg",
    "icons_phone": "/images/basket-aside/icons_phone.svg",
    "icons_return": "/images/basket-aside/icons_return.svg"
  },
  "brandclubs": {
    "Image_Gift_bonuscard_640x400px": "/images/brandclubs/Image_Gift_bonuscard_640x400px.jpg",
    "Image_Gift_bonuscard_mobile_450x260px": "/images/brandclubs/Image_Gift_bonuscard_mobile_450x260px.jpg"
  },
  "browsers": {
    "chromeLogo": "/images/browsers/chromeLogo.svg",
    "edgeLogo": "/images/browsers/edgeLogo.svg",
    "firefoxLogo": "/images/browsers/firefoxLogo.svg"
  },
  "ema": {
    "ema-logo-dk": "/images/ema/ema-logo-dk.png",
    "ema-logo-no": "/images/ema/ema-logo-no.png",
    "ema-logo-se": "/images/ema/ema-logo-se.png",
    "ema-vet-dk": "/images/ema/ema-vet-dk.png",
    "ema-vet-se": "/images/ema/ema-vet-se.png"
  },
  "giftcards": {
    "a5-type1": "/images/giftcards/a5-type1.jpg",
    "a5-type1_v1836": "/images/giftcards/a5-type1_v1836.jpg",
    "a6-type1": "/images/giftcards/a6-type1.jpg",
    "a6-type1_v1836": "/images/giftcards/a6-type1_v1836.jpg"
  },
  "mails": {
    "social-icons": {
      "facebook": "/images/mails/social-icons/facebook.png",
      "instagram": "/images/mails/social-icons/instagram.png",
      "tiktok": "/images/mails/social-icons/tiktok.png",
      "youtube": "/images/mails/social-icons/youtube.png"
    },
    "banner-matasplus-2021": "/images/mails/banner-matasplus-2021.jpg",
    "banner-matasplus-2022": "/images/mails/banner-matasplus-2022.jpg",
    "banner-matasplus-2023": "/images/mails/banner-matasplus-2023.jpg",
    "booking-ladies": "/images/mails/booking-ladies.jpg",
    "cards": "/images/mails/cards.png",
    "clubmatas-app": "/images/mails/clubmatas-app.png",
    "facebook-white": "/images/mails/facebook-white.png",
    "facebook": "/images/mails/facebook.png",
    "icon-call": "/images/mails/icon-call.png",
    "icon-email": "/images/mails/icon-email.png",
    "icon-subscription": "/images/mails/icon-subscription.png",
    "instagram-white": "/images/mails/instagram-white.png",
    "instagram": "/images/mails/instagram.png",
    "kicks-logo": "/images/mails/kicks-logo.svg",
    "kicks-white-logo": "/images/mails/kicks-white-logo.svg",
    "logo-clubmatas-white": "/images/mails/logo-clubmatas-white.png",
    "logo-clubmatas": "/images/mails/logo-clubmatas.png",
    "logo-matas": "/images/mails/logo-matas.png",
    "logo-matas_v1836-white": "/images/mails/logo-matas_v1836-white.png",
    "logo-matas_v1836": "/images/mails/logo-matas_v1836.png",
    "matas-plus-logo": "/images/mails/matas-plus-logo.png",
    "phone": "/images/mails/phone.png",
    "rating_active": "/images/mails/rating_active.png",
    "rating_inactive": "/images/mails/rating_inactive.png",
    "stribe-star": "/images/mails/stribe-star.png",
    "stripes": "/images/mails/stripes.png",
    "stripes2": "/images/mails/stripes2.png",
    "sub-usp-20percent": "/images/mails/sub-usp-20percent.jpg",
    "sub-usp-addtobasket": "/images/mails/sub-usp-addtobasket.jpg",
    "sub-usp-delivery": "/images/mails/sub-usp-delivery.jpg",
    "sub-usp-nonbinding": "/images/mails/sub-usp-nonbinding.jpg",
    "trustpilot-logo-white": "/images/mails/trustpilot-logo-white.jpg",
    "Youtube-white": "/images/mails/Youtube-white.png",
    "Youtube": "/images/mails/Youtube.png"
  },
  "my-matas": {
    "my_profile_front_2022": "/images/my-matas/my_profile_front_2022.jpg",
    "my_profile_front_mobile_plus_V3": "/images/my-matas/my_profile_front_mobile_plus_V3.jpg",
    "my_profile_front_mobile_usp_plus_V3": "/images/my-matas/my_profile_front_mobile_usp_plus_V3.jpg",
    "my_profile_front_plus_V3": "/images/my-matas/my_profile_front_plus_V3.jpg"
  },
  "my-matas-coupons": {
    "matasplus": {
      "april": {
        "april_297x215px": "/images/my-matas-coupons/matasplus/april/april_297x215px.jpg",
        "april_680x240px": "/images/my-matas-coupons/matasplus/april/april_680x240px.jpg",
        "april_827x780px": "/images/my-matas-coupons/matasplus/april/april_827x780px.jpg"
      }
    }
  },
  "photoswipe": {
    "default-skin": {}
  },
  "questionnaire": {
    "model": "/images/questionnaire/model.jpg"
  },
  "splashes": {
    "danger-corrosive": "/images/splashes/danger-corrosive.svg",
    "danger-explosive": "/images/splashes/danger-explosive.svg",
    "danger-flammable": "/images/splashes/danger-flammable.svg",
    "danger-for-environment": "/images/splashes/danger-for-environment.svg",
    "danger-gas-under-pressure": "/images/splashes/danger-gas-under-pressure.svg",
    "danger-health-hazard": "/images/splashes/danger-health-hazard.svg",
    "danger-oxidizing": "/images/splashes/danger-oxidizing.svg",
    "danger-serious-health-hazard": "/images/splashes/danger-serious-health-hazard.svg",
    "danger-to-life": "/images/splashes/danger-to-life.svg",
    "derma-badge-dk": "/images/splashes/derma-badge-dk.svg",
    "derma-badge-no": "/images/splashes/derma-badge-no.svg",
    "derma-badge-se": "/images/splashes/derma-badge-se.svg",
    "modiface": "/images/splashes/modiface.svg",
    "pro-hair-care": "/images/splashes/pro-hair-care.svg"
  },
  "visualSearch": {
    "intro": {
      "mobile": {
        "cover_VS_x1": "/images/visualSearch/intro/mobile/cover_VS_x1.jpg",
        "cover_VS_x2": "/images/visualSearch/intro/mobile/cover_VS_x2.jpg"
      }
    },
    "matas-carbamid-shampoo-250-ml_OCR-NO_CATCHOOM-NO": "/images/visualSearch/matas-carbamid-shampoo-250-ml_OCR-NO_CATCHOOM-NO.jpg"
  },
  "broken": "/images/broken.jpg",
  "chanel-logo": "/images/chanel-logo.svg",
  "cursor360": "/images/cursor360.svg",
  "dior-backstage-logo": "/images/dior-backstage-logo.png",
  "dior-logo-v2": "/images/dior-logo-v2.png",
  "footer_farma_grupper": "/images/footer_farma_grupper.png",
  "footer_matas_gruppe": "/images/footer_matas_gruppe.png",
  "footer_matas_gruppe_mobil": "/images/footer_matas_gruppe_mobil.png",
  "GiftCardTooltip": "/images/GiftCardTooltip.png",
  "gls-maps-marker": "/images/gls-maps-marker.svg",
  "GoWish": "/images/GoWish.svg",
  "hero-cta-box": "/images/hero-cta-box.svg",
  "hero-cta_expert-1": "/images/hero-cta_expert-1.png",
  "hero-cta_expert-2": "/images/hero-cta_expert-2.png",
  "hero-cta_expert-3": "/images/hero-cta_expert-3.png",
  "icons_arrow": "/images/icons_arrow.svg",
  "icons_checkmark-color-11": "/images/icons_checkmark-color-11.svg",
  "icons_checkmark": "/images/icons_checkmark.svg",
  "icons_input-error": "/images/icons_input-error.svg",
  "icons_input-success": "/images/icons_input-success.svg",
  "icons_missingImage": "/images/icons_missingImage.svg",
  "IU_arrow": "/images/IU_arrow.svg",
  "loading-spinner": "/images/loading-spinner.svg",
  "mac-cosmetics-logo": "/images/mac-cosmetics-logo.png",
  "matas-live-card-animated": "/images/matas-live-card-animated.svg",
  "matas-live-floating-pulse": "/images/matas-live-floating-pulse.svg",
  "matas-live-play-animated": "/images/matas-live-play-animated.svg",
  "matas-live-upcoming-animated": "/images/matas-live-upcoming-animated.svg",
  "matas-logo-xl": "/images/matas-logo-xl.jpg",
  "matas-logo": "/images/matas-logo.jpg",
  "matas-maps-marker": "/images/matas-maps-marker.png",
  "model_prelaunch_new": "/images/model_prelaunch_new.png",
  "nyx-logo-for-top-bar": "/images/nyx-logo-for-top-bar.png",
  "postnord-maps-marker": "/images/postnord-maps-marker.svg",
  "sampleOf": "/images/sampleOf.png",
  "shop_placeholder": "/images/shop_placeholder.jpg",
  "smiley-lup": "/images/smiley-lup.jpg",
  "spinner-primary": "/images/spinner-primary.svg",
  "stories-logo": "/images/stories-logo.svg",
  "stylebox-logo": "/images/stylebox-logo.svg",
  "ui-icons_444444_256x240": "/images/ui-icons_444444_256x240.png"
} as const; 

export default images;