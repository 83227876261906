import { useQuery } from '@tanstack/react-query';
import {
    ColorBrandBlue1,
    ColorNeutralWhite,
    ColorNordicGrey4,
    Spacing24,
    Spacing4
} from 'autogen/design-tokens/tokens';
import { OtcWarningModelMaybe } from 'autogen/swagger/all';
import { ReadMore } from 'autogen/translation-keys/trans-website-shared-buttons';
import InlineButton from 'components/generic/InlineButton';
import Card from 'components/generic/card';
import { Typography } from 'components/generic/typography';
import QuickView, { useQuickView } from 'components/quickView';
import { RoundSplashComponent } from 'components/shared/Splash/RoundSplash';
import dynamic from 'helpers/dynamic';
import { fetchHTML } from 'modules/helpers/fetch';
import { verticalEllipsis } from 'modules/helpers/mixins';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

const sizeStyling = {
    sm: {
        container: css`
            padding-right: 8px;
        `,
        roundSplash: css`
            right: -8px;
            bottom: -4px;
        `,
        icon: css`
            width: 28px;
            height: 28px;
        `
    },
    md: {
        container: css`
            padding-right: 8px;
        `,
        roundSplash: css`
            right: -16px;
            bottom: -8px;
        `,
        icon: css`
            width: 40px;
            height: 40px;
        `
    }
};

const StyledCard = styled(Card)`
    background-color: ${ColorNordicGrey4};
`;

const StyledCardContent = styled(Card.Content)`
    flex-direction: row;
    gap: ${Spacing24};
`;

const MediaContainer = styled.div<{ $size: Size }>`
    display: flex;
    flex-direction: column;
    position: relative;

    ${({ $size }) => sizeStyling[$size].container || sizeStyling.md.container}
`;

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: ${Spacing4};
`;

const EllipsisContainer = styled.div`
    ${verticalEllipsis(1)}
    overflow: hidden;
`;

const StyledRoundSplashComponent = styled(RoundSplashComponent)<{ $size: Size }>`
    position: relative;
    right: -16px;

    ${({ $size }) => sizeStyling[$size].roundSplash || sizeStyling.md.roundSplash}
`;

const StyledQuickView = styled(QuickView)`
    max-width: 624px;
`;

const QuickViewHtmlContent = styled.div`
    overflow: auto;
`;
type Size = 'sm' | 'md';

const OTCIcon = styled(dynamic(() => import('icons/OTC.svg?react')))<{
    backgroundColor: OtcWarningModelMaybe['color'];
    $size: Size;
}>`
    fill: ${({ backgroundColor }) => {
        switch (backgroundColor) {
            case 'Blue':
                return '#2065a6';
            case 'Yellow':
                return '#ffda0c';
            case 'Green':
                return '#3c875f';
            case 'LightGreen':
                return '#78d289';
            case 'Orange':
                return '#ff8a0c';
            case 'Grey':
                return '#7c7c7c';
            case 'Purple':
                return '#800080';
            case 'Brown':
                return '#808000';
            default:
                return 'transparent';
        }
    }};

    ${({ $size }) => sizeStyling[$size].icon || sizeStyling.md.icon}
`;

export function OTCSplash({ model, size = 'md', ...restProps }: { model: OtcWarningModelMaybe; size?: Size }) {
    const { color, minAge } = model;
    return (
        <MediaContainer $size={size} {...restProps}>
            <StyledRoundSplashComponent $textColor={ColorNeutralWhite} $backgroundColor={ColorBrandBlue1} $size={size}>
                {minAge}+
            </StyledRoundSplashComponent>
            <OTCIcon $size={size} backgroundColor={color} />
        </MediaContainer>
    );
}

export default function OTCNotification({ model }: { model: OtcWarningModelMaybe }) {
    const { headline, minAgeWarning, colorWarning, readMoreLinkAction } = model;
    const { refetch, data } = useQuery(['OtcInfoModal'], () => fetchHTML(readMoreLinkAction.url), { enabled: false });
    const { toggle } = useQuickView('OtcInfoModal');
    const { t } = useTranslation();

    return (
        <>
            <StyledCard>
                <StyledCardContent>
                    <OTCSplash model={model} />
                    <TextContainer>
                        <Typography.Body forwardedAs="span" themeTypes="mediumSemiBold">
                            {headline}
                        </Typography.Body>

                        <EllipsisContainer>
                            <Typography.Body as="span" themeTypes="medium">
                                {minAgeWarning} {colorWarning}
                            </Typography.Body>
                        </EllipsisContainer>

                        <InlineButton
                            onClick={() => {
                                refetch().then(() => toggle(true));
                            }}
                        >
                            {t(ReadMore)}
                        </InlineButton>
                    </TextContainer>
                </StyledCardContent>
            </StyledCard>
            <StyledQuickView quickViewId="OtcInfoModal">
                <QuickViewHtmlContent dangerouslySetInnerHTML={{ __html: data }} />
            </StyledQuickView>
        </>
    );
}
