import type { LoginInfoModel, _LoginInfoParams } from 'autogen/swagger/all';
import { use_LoginInfoQuery } from 'autogen/swagger/all/hooks';
import useMediatorState, { mediatorAtom, useSetMediatorState } from 'hooks/globals/useMediatorAtom';

export const LoginInfoQuickViewState = mediatorAtom<{ isOpen: boolean; loginInfo?: LoginInfoModel }>({ isOpen: false });

export default function useLoginInfoQuickView() {
    return useMediatorState(LoginInfoQuickViewState);
}

export function useOpenLoginQuickView(loginInfo: _LoginInfoParams) {
    const toggleQuickView = useSetMediatorState(LoginInfoQuickViewState);
    const { refetch: getLoginInfo } = use_LoginInfoQuery(loginInfo, { enabled: false });

    const openLoginInfoQuickView = async () => {
        try {
            const { data: loginInfoModel } = await getLoginInfo();
            toggleQuickView({ loginInfo: loginInfoModel, isOpen: true });
        } catch (error) {
            console.error('failed to fetch loginInfo');
        }
    };

    return openLoginInfoQuickView;
}
