import type { JavascriptHookCustomAction } from 'autogen/swagger/all';
import ChatTab from 'components/chat/models';
import mediator from 'modules/mediator';
import { forwardRef } from 'react';
import type { CustomActionProps } from '../CustomAction';
import { CustomActionButton } from '../shared/styles';

type CustomerServiceActionProps = { action: JavascriptHookCustomAction } & CustomActionProps;

const CustomerServiceAction = forwardRef<HTMLButtonElement, CustomerServiceActionProps>(
    ({ action, onClick, ...restProps }, ref) => {
        if (action.javascriptHookType === 'OpenCustomerServiceChat') {
            return (
                <CustomActionButton
                    ref={ref}
                    type="button"
                    onClick={(e) => {
                        if (onClick) {
                            onClick(e);
                        }
                        mediator.publish('openChat', ChatTab.CustomerService);
                    }}
                    {...restProps}
                />
            );
        }

        return null;
    }
);

export default CustomerServiceAction;
