/* eslint camelcase: "off" */
import fetch from 'cross-fetch';

interface IVimeoApiResponse {
    total: number;
    page: number;
    per_page: number;
    paging: {
        next: any;
        previous: any;
        first: string;
        last: string;
    };
    data: IThumbnail[];
}

interface IThumbnail {
    uri: string;
    active: boolean;
    type: string;
    base_link: string;
    sizes: ISizedThumbnail[];
    resource_key: string;
    default_picture: boolean;
}

interface ISizedThumbnail {
    width: number;
    height: number;
    link: string;
    link_with_play_button: string;
}

export async function getVimeoThumbnailUrl(videoId) {
    const apiBaseUrl = 'https://api.vimeo.com';
    const url = `${apiBaseUrl}/videos/${videoId}/pictures?sizes=480x360`;

    try {
        const response = await fetch(url, {
            headers: {
                Authorization: 'bearer c0d521db7627dbeaba0b4d875af79f38', // https://developer.vimeo.com/api/guides/start#generate-access-token
                Accept: 'application/vnd.vimeo.*+json;version=3.4'
            }
        });

        if (!response.ok) throw new Error(`Failed to fetch content from: ${url}`);

        const result: IVimeoApiResponse = await response.json();

        const {
            data: [first]
        } = result;
        const {
            sizes: [firstSize]
        } = first;
        const { link: thumbnail_url } = firstSize;

        return thumbnail_url;
    } catch (error) {
        console.error(error);
        throw new Error('could not get Vimeo thumbnail');
    }
}

export async function getVimeoVideo(id) {
    const apiUrl = `https://api.vimeo.com/videos/${id}?fields=name,player_embed_url,duration,pictures`;

    try {
        const response = await fetch(apiUrl, {
            headers: {
                Authorization: 'bearer c0d521db7627dbeaba0b4d875af79f38', // https://developer.vimeo.com/api/guides/start#generate-access-token
                Accept: 'application/vnd.vimeo.*+json;version=3.4'
            }
        });

        if (!response.ok) throw new Error(`Failed to fetch content from: ${apiUrl}`);

        const result = await response.json();

        return result;
    } catch (error) {
        console.error(error);
        return null;
    }
}

export default async function VimeoInfo(img): Promise<void> {
    const { jsVimeoId } = img.dataset;

    const thumbnail_url = await getVimeoThumbnailUrl(jsVimeoId);

    img.src = thumbnail_url;
}
