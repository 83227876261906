/* eslint-disable no-nested-ternary */
import { ColorHealth5, ColorNordicGrey4 } from 'autogen/design-tokens/tokens';
import type { BasketMessageGwpViewModel } from 'autogen/swagger/all';
import { Typography } from 'components/generic/typography';
import styled from 'styled-components';
import BasketMessageContainer from '../shared/styles';
import format from 'helpers/translations';

const StyledBasketMessageContainer = styled(BasketMessageContainer)<{ $type: BasketMessageGwpViewModel['type'] }>`
    background-color: ${({ $type }) => {
        switch ($type) {
            case 'Achievement':
                return ColorHealth5;
            default:
                return ColorNordicGrey4;
        }
    }};
`;

export default function GwpMessage({ model: { text, type } }: { model: BasketMessageGwpViewModel }) {
    return (
        <StyledBasketMessageContainer $type={type}>
            <Typography.Body as="span" themeTypes="small">
                {format(text)}
            </Typography.Body>
        </StyledBasketMessageContainer>
    );
}
