/* eslint-disable */
/// This file is auto generated - do not edit manually
export * as PriceInfo from './trans-website-basket-subscription-priceinfo';

export const Added = 'Website.Basket.Subscription.Added';
export const AddedAs = 'Website.Basket.Subscription.AddedAs';
export const AsSubscription = 'Website.Basket.Subscription.AsSubscription';
export const DeliveryHereafter = 'Website.Basket.Subscription.DeliveryHereafter';
export const SubPriceLabel = 'Website.Basket.Subscription.SubPriceLabel';
export const SubscriptionOnlyLabel = 'Website.Basket.Subscription.SubscriptionOnlyLabel';
