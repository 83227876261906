import { FontWeightSemiBold, Spacing8 } from 'autogen/design-tokens/tokens';
import { ProductNameLine } from 'autogen/swagger/all';
import { Typography } from 'components/generic/typography';
import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    display: grid;
    grid-template-columns: auto;
    grid-auto-columns: auto;
    row-gap: ${Spacing8};
`;

const Brand = styled(Typography.Overline)``;

const Title = styled(Typography.Headline)`
    font-weight: ${FontWeightSemiBold};
    margin-top: 0;
    margin-bottom: 0;
`;

function PageTitleNameRenderer({
    lines,
    ...restProps
}: { lines: ProductNameLine[] } & HTMLAttributes<HTMLDivElement>): JSX.Element {
    const brand = lines.find((line) => line.type === 'Brand');
    const name = lines.find((line) => line.type === 'Name');

    return (
        <Wrapper {...restProps}>
            {brand && <Brand size={14}>{brand.url ? <a href={brand.url}>{brand.value}</a> : brand.value}</Brand>}

            {name && (
                <Title forwardedAs="h1" size={{ xs: 24, lg: 32 }}>
                    {name.value}
                </Title>
            )}
        </Wrapper>
    );
}

export default PageTitleNameRenderer;
