/* eslint-disable class-methods-use-this */
import htmlModal, { Content } from 'modules/modal/htmlModal';
import DefaultLayout from 'modules/modal/layouts';
import track, { dataLayer, trackArray } from 'modules/tracking/dataLayer';
import UAEventTracking from 'modules/tracking/UAEventTracking';
import { hasParamByKey, hasParamsValueByKey } from 'modules/helpers/urlParams';
import breakpoint from 'modules/breakpoint';
import { rem } from 'modules/helpers/style';
import {
    AddOrUpdateQuantity,
    type AddOrUpdateQuantityParams,
    AddToBasket,
    type AddToBasketParams,
    DeleteFromBasket,
    type DeleteFromBasketParams
} from 'autogen/swagger/all';

import { fetchHTML } from 'modules/helpers/fetch';
import { GetPowerstepUrl } from 'autogen/swagger/all';
import { refreshBasketQuantityGlobally } from 'hooks/basket/useBasketQuantity';
import { legacyOpenPowerstep } from 'components/powerstep/shared/hooks/usePowerstep';
import RequestContext from 'modules/requestContext';
import { addProductAndSubscriptionToReceipt, addToReceipt } from 'pos/shared/fiftytwo/fiftyTwoApi';
import { toast } from 'react-toastify';
import { updateBasket } from 'components/basket/shared/basket';
import mediator from './mediator';
import Routes from './routes';
import FeatureToggles from './featureToggles';
import Translations from './translations';
import { GA4Tracking } from './tracking/GA4/GA4Tracking';

const loadRecommendations = async (scope) => {
    const recommendationSlider = $(scope).find('[data-js-async-product-recommendations]');
    if (recommendationSlider.length <= 0) return;

    const { default: AjaxProductRecommendations } = await import(
        /* webpackChunkName: "AjaxProductRecommendations" */ 'modules/ajaxProductRecommendations'
    );

    const ajaxProductRecommendations = new AjaxProductRecommendations(recommendationSlider);
    ajaxProductRecommendations.init();
};

const bindHighlightedCountdown = (wrapper) => {
    const scope = wrapper || document;
    const highlightCountdowns = scope.getElementsByClassName('js-highlight-countdown-container');

    if (highlightCountdowns?.length) {
        (async () => {
            const { default: HighlightCountdown } = await import(
                /* webpackChunkName: "highlightCountdown" */ 'modules/highlightCountdown'
            );
            highlightCountdowns.forEach((node) => {
                HighlightCountdown(node);
            });
        })();
    }
};

let powerstepModal = null;

export const isPowerstepActive = () => powerstepModal !== null;

export const openPowerstepModal = async (url, showRecommendations = false, placement = 'top') => {
    placement.toLowerCase();

    // This is a temporary solution untill we rewrite this file
    let shouldFetchInModal;
    let html;

    if (powerstepModal === null) {
        const width = showRecommendations ? rem(900) : rem(675);
        powerstepModal = htmlModal({
            layout: {
                component: <DefaultLayout className="js-modalContent" customTheme={{ width, placement }} />,
                trackingCategory: 'powerstep'
            }
        });
        shouldFetchInModal = true;
    }

    // if we open the modal the first time we want to skip the fetching from here, because we want to show the loader inside the htmlModal
    if (!shouldFetchInModal) {
        html = await fetchHTML(url);
    }

    powerstepModal({
        html,
        url: shouldFetchInModal && url,
        onAfterContent: async ({ wrapper }) => {
            shouldFetchInModal = false;

            if (!showRecommendations) return;
            loadRecommendations(wrapper);
            bindHighlightedCountdown(wrapper);

            const powerstep = wrapper.querySelector('.js-powerstep');
            const { datalayerArray } = powerstep.dataset;
            if (datalayerArray) {
                trackArray(JSON.parse(datalayerArray));
                powerstep.dataset.datalayerArray = '';
            }
        },
        onAfterClose: () => {
            powerstepModal = null;
        },
        ContentWrapper: Content
    });
};

export const getPowerstepUrl = (productIds: number[]) => {
    let productIdsParameter = '?';

    productIds.forEach((productId) => {
        productIdsParameter = `${productIdsParameter}&productIds=${productId}`;
    });

    return GetPowerstepUrl + productIdsParameter;
};

export const subsequentlyAddToBasket = (productIds = []) => {
    if (productIds.indexOf(undefined) !== -1 || !FeatureToggles.powerstepEnabled) {
        return;
    }

    legacyOpenPowerstep(productIds);
};

const openBundleModal = (urlAttr) => {
    const [url, modifiers = 'ui-modal--sticky-buttom'] = urlAttr.split(':');

    const modal = htmlModal({
        layout: { component: <DefaultLayout className={modifiers} /> }
    });

    modal({
        url
    });
};

export const submitToBasket = async (e, _url?) => {
    let url = _url;
    const $target = e.target ? $(e.target) : $(e);

    if ($target.closest('.js-powerstep').length) {
        $target.addClass('btn--spinner-active');
    }

    if ($target.hasClass('js-subscription-product') && $('#subscriptionRadioButton').is(':checked')) {
        url = (document.querySelector('.js-subscriptionFrequencyValue') as HTMLInputElement).value;
        const minimumSubscriptionQuantity =
            Number.parseInt((document.querySelector('.js-subscriptionMinimumQuantity') as HTMLInputElement).value) > 1;
        if (!url) {
            const subContainer = document.querySelector('#subscription-container');
            const elementClientRectTop = subContainer.getBoundingClientRect().top;
            const stickeyHeaderHeight = 110;
            const elementPositionTop = elementClientRectTop + window.pageYOffset - stickeyHeaderHeight;
            window.requestAnimationFrame(() => {
                subContainer.classList.add('subscription-frequency--error');
                if (breakpoint.max('lg')) {
                    window.scroll({ top: elementPositionTop, behavior: 'smooth' });
                }
            });
            return;
        }

        if (minimumSubscriptionQuantity) {
            if (isPowerstepActive()) {
                submitToBasket(e, url);
            } else {
                openBundleModal(url);
            }
            return;
        }
    } else if (e.target && e !== 'zmags') {
        e.preventDefault();
        url = url || $target.attr('data-js-cart');
    }

    if (RequestContext.posRequest?.isPosRequest) {
        const productId = $target.data('js-product-id');
        // will be changed soon with the mid being reactified.
        const quantity = Number.parseInt(new URLSearchParams(url).get('appquantity'));
        const subscriptionfrequencyid = new URLSearchParams(url).get('subscriptionfrequencyid');
        try {
            if (subscriptionfrequencyid) {
                await addProductAndSubscriptionToReceipt(productId, quantity, '0', subscriptionfrequencyid);
            } else {
                await addToReceipt(productId, quantity);
            }
            toast.success<string>(Translations.Website.Basket.AddToBasket, {
                position: toast.POSITION.TOP_RIGHT
            });
        } catch {
            toast.error<string>(Translations.Website.Basket.ErrorAddToBasket, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
        return;
    }

    $.ajax({
        type: 'POST',
        url,
        dataType: 'JSON',
        cache: false
    }).done((response) => {
        if (response.success) {
            if (hasParamByKey('subscription') && hasParamsValueByKey('subscriptionfrequencyid', url, false)) {
                GA4Tracking({
                    eventName: 'add_to_cart',
                    context: 'product_page',
                    category: 'ecommerce',
                    eventParams: [
                        {
                            param_name: 'is_subscription',
                            param_value: true
                        }
                    ]
                });

                UAEventTracking({
                    eventCategory: 'productPage',
                    eventAction: 'addProductSubscription'
                });
            }

            if (response.redirectUrl !== undefined && response.redirectUrl.length !== 0) {
                if (response.dataLayerDto) {
                    track(response.dataLayerDto);
                }

                if ($target.is('[data-js-virtual-pageview]')) {
                    const attr = $target.attr('data-js-virtual-pageview');
                    dataLayer.trackVirtualPageView(attr);
                }

                window.location.href = response.redirectUrl;
            } else {
                refreshBasketQuantityGlobally();
                mediator.publish('updateMiniBasketView');

                if (url.toLowerCase().indexOf('deletefrombasket') === -1) {
                    const productId = $target.attr('data-js-product-id');
                    subsequentlyAddToBasket([productId]);
                }

                if ($('#basketpage').length > 0) {
                    (async () => {
                        const { updateBasket: updateBasketOld } = await import(
                            /* webpackChunkName: "basket" */ '../views/basket/index'
                        );
                        updateBasketOld();
                    })();
                }
                updateBasket();

                if (response.dataLayerDto) {
                    track(response.dataLayerDto);
                }

                if ($target.is('[data-js-virtual-pageview]')) {
                    const attr = $target.attr('data-js-virtual-pageview');
                    dataLayer.trackVirtualPageView(attr);
                }
            }
        } else {
            const message = response.message || Translations.Website.Basket.ErrorAddToBasket;
            mediator.publish('growl', { message, remove: 2000 });
        }
    });
};

function rebindBasket() {
    mediator.publish('updateQuantities', 'basket');
    mediator.publish('updateMiniBasketView');

    if ($('#basketpage').length > 0) {
        (async () => {
            const { updateBasket: updateBasketOld } = await import(
                /* webpackChunkName: "basket" */ '../views/basket/index'
            );
            updateBasketOld();
        })();
    }

    updateBasket();
}

export async function addToBasket(params: AddToBasketParams) {
    if (RequestContext.posRequest?.isPosRequest) {
        await addToReceipt(params.productId.toString(), params.appQuantity);
    } else {
        const response = await AddToBasket({ ...params });

        if (response.success) {
            rebindBasket();
        }

        if (response.dataLayerDto) {
            track(response.dataLayerDto);
        }
        if (!response.success) {
            throw new Error('Could not update product');
        }

        return response;
    }
}

export async function addOrUpdateQuantity(
    productId: AddOrUpdateQuantityParams['productId'],
    quantity: AddOrUpdateQuantityParams['quantity'],
    addToBasketType: AddOrUpdateQuantityParams['addToBasketType']
): Promise<void> {
    const response = await AddOrUpdateQuantity({ productId, quantity, addToBasketType, fromPowerstep: false });

    return new Promise((resolve, reject) => {
        if (response.success) {
            rebindBasket();
            resolve();
        }

        if (response.dataLayerDto) {
            track(response.dataLayerDto);
        }

        if (!response.success) {
            reject(new Error('Could not update product'));
        }
    });
}

export function deleteFromBasket(productId: DeleteFromBasketParams['productId']): Promise<void> {
    return new Promise((resolve, reject) => {
        DeleteFromBasket({ productId })
            .then((response) => {
                if (response.success) {
                    rebindBasket();
                    resolve();
                }

                if (response.dataLayerDto) {
                    track(response.dataLayerDto);
                }
                if (!response.success) {
                    reject(new Error('Could not remove'));
                }
            })
            .catch(reject);
    });
}

export class AddAndRemoveFromBasket {
    init() {
        this.bindings();
    }

    bindings() {
        $(document).on('click', '[data-js-cart]', (event) => submitToBasket(event));
    }

    getBasketUrl() {
        return Routes.Basket.BasketPage;
    }

    submitByProductId(productId, elm, listName) {
        if (Number.isNaN(productId) && productId.length <= 0) return;

        const url = Routes.Basket.AddToBasketWithoutId + productId + (listName ? `&listName=${listName}` : '');

        submitToBasket(elm, url);
    }
}

window.AddAndRemoveFromBasket = new AddAndRemoveFromBasket();
