import type { CertificationCustomAction } from 'autogen/swagger/all';
import useCertificationDrawer from 'components/product/certification/useCertificationDrawer';
import React, { forwardRef } from 'react';
import type { CustomActionProps } from '../CustomAction';
import { onClickMerger } from '../shared/helpers';
import { CustomActionButton } from '../shared/styles';

type CertificationActionProps = { action: CertificationCustomAction } & CustomActionProps;

const CertificationAction = forwardRef<HTMLButtonElement, CertificationActionProps>(
    ({ action, onClick, ...restProps }, ref) => {
        const [, openCertificationDrawer] = useCertificationDrawer();

        return (
            <CustomActionButton
                ref={ref}
                type="button"
                onClick={(e) => {
                    if (onClick) {
                        onClick(e);
                    }
                    openCertificationDrawer({ isOpen: true, filterId: action.filterId });
                }}
                {...restProps}
            />
        );
    }
);

export default CertificationAction;
