/* eslint-disable react/no-array-index-key */
import type { BasketPrizeCertificateSummary, PrizeCertificateModel } from 'autogen/swagger/all';

import { type UseMutateFunction, useQueryClient } from '@tanstack/react-query';
import {
    PrizeCertificatesQueryKey,
    CheckoutDeliveryBasketQueryKey,
    useDeliveryRemovePrizeCertificateMutation
} from 'autogen/swagger/all/hooks';
import React, { type ReactNode } from 'react';
import type { RemovePrizeCertificateSuccess, RemovePrizeCertificateParams } from 'autogen/swagger/all';
import { updateBasket } from 'components/basket/shared/basket';
import ActivePrizeCertificateLine from './ActivePrizeCertifcateLine';

export type RemoveActivePrizeCertificate = UseMutateFunction<
    RemovePrizeCertificateSuccess,
    unknown,
    { params: RemovePrizeCertificateParams },
    unknown
>;

type ActivePrizeCertificatesSummaryRendererProps = Record<string, any> & {
    model: BasketPrizeCertificateSummary;
    renderer?: (
        model: PrizeCertificateModel,
        removeActivePrizeCertificateFunc: RemoveActivePrizeCertificate,
        props
    ) => ReactNode;
};

export default function ActivePrizeCertificatesSummaryRenderer({
    model: { prizeCertificates },
    renderer = (prizeCertificateModel, mutationFunc, props) => (
        <ActivePrizeCertificateLine
            {...props}
            model={prizeCertificateModel}
            removeActivePrizeCertificateFunc={mutationFunc}
        />
    ),
    ...restProps
}: ActivePrizeCertificatesSummaryRendererProps) {
    const queryClient = useQueryClient();
    const { mutate } = useDeliveryRemovePrizeCertificateMutation({
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [PrizeCertificatesQueryKey] });
            queryClient.invalidateQueries({ queryKey: [CheckoutDeliveryBasketQueryKey] });
            updateBasket();
        }
    });

    return (
        <>
            {prizeCertificates.map((prizeCertificateModel, index) => (
                <React.Fragment key={index}>{renderer(prizeCertificateModel, mutate, restProps)}</React.Fragment>
            ))}
        </>
    );
}
