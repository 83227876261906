import {
    ColorNeutralWhite,
    ColorBrandBlue1,
    ColorNeutralBlack,
    ColorNordicGrey1,
    ColorClubMatasPlus1,
    ColorClubMatasPlus2,
    ColorBeauty1,
    ColorSubscription1,
    ColorClubMatas2,
    ColorClubMatasFocus,
    ColorClubMatasHover,
    ColorBrandBlueHover,
    ColorFocusStateBorderOnLight,
    ColorBrandBlueFocus,
    ColorWhiteHover,
    ColorWhiteFocus,
    ColorBrandBluePressed,
    ColorWhitePressed,
    ColorNordicGreyHover,
    ColorNordicGreyFocus,
    ColorNordicGreyPressed,
    ColorOpacityOnDarkHover,
    ColorOpacityOnDarkFocus,
    ColorOpacityOnDarkPressed,
    ColorOpacityOnLightHover,
    ColorOpacityOnLightFocus,
    ColorOpacityOnLightPressed,
    ColorClubMatas1,
    ColorClubMatasPressed,
    ColorClubMatasPlusHover,
    ColorClubMatasPlusFocus,
    ColorClubMatasPlusPressed,
    ColorBeautyHover,
    ColorBeautyFocus,
    ColorBeautyPressed,
    ColorSubscriptionHover,
    ColorSubscriptionFocus,
    ColorSubscriptionActivated
} from 'autogen/design-tokens/tokens';
import { css } from 'styled-components';
import type ButtonVariation from './types/buttonVariation';

/* Remember to add your variant in the obj at the bottom */

const sharedDisableStyling = css`
    cursor: not-allowed;
    opacity: 0.4;
`;

const outlineStyling = css`
    outline-width: 2px;
    outline-offset: 1px;
    outline-color: ${ColorFocusStateBorderOnLight};
`;

// #region ButtonVariants
const primary: ButtonVariation = css<{ $disabled: boolean }>`
    color: ${ColorNeutralWhite};
    background-color: ${ColorBrandBlue1};

    // no href fix
    &:not([href]),
    &:not([href]):not([tabindex]) {
        color: ${ColorNeutralWhite};
        &:hover {
            color: ${ColorNeutralWhite};
        }
    }

    ${({ $disabled }) =>
        $disabled
            ? css`
                  ${sharedDisableStyling}
              `
            : css`
                  &:hover {
                      background-color: ${ColorBrandBlueHover};
                      color: ${ColorNeutralWhite};
                  }

                  &:focus {
                      background-color: ${ColorBrandBlueFocus};
                      color: ${ColorNeutralWhite};
                      ${outlineStyling}
                  }

                  &:active {
                      background-color: ${ColorBrandBluePressed};
                  }
              `}
`;

const secondary: ButtonVariation = css<{ $disabled: boolean }>`
    color: ${ColorNeutralBlack};
    background-color: var(--color-base, var(--color-cta-secondary-base));

    ${({ $disabled }) =>
        $disabled
            ? css`
                  ${sharedDisableStyling}
              `
            : css`
                  &:hover {
                      background-color: var(--color-hover, var(--color-cta-secondary-hover));
                      ${outlineStyling}
                  }

                  &:focus {
                      background-color: var(--color-focus, var(--color-cta-secondary-focus));
                  }

                  &:active {
                      background-color: var(--color-pressed, var(--color-cta-secondary-pressed));
                  }
              `}
`;

const negative: ButtonVariation = css<{ $disabled: boolean }>`
    color: ${ColorNeutralBlack};
    background-color: ${ColorNeutralWhite};

    ${({ $disabled }) =>
        $disabled
            ? css`
                  ${sharedDisableStyling}
              `
            : css`
                  &:hover {
                      background-color: ${ColorWhiteHover};
                  }

                  &:focus {
                      background-color: ${ColorWhiteFocus};
                      ${outlineStyling}
                  }

                  &:active {
                      background-color: ${ColorWhitePressed};
                  }
              `}
`;

const ghostOnLight: ButtonVariation = css<{ $disabled: boolean }>`
    color: ${ColorNeutralBlack};
    background-color: transparent;

    ${({ $disabled }) =>
        $disabled
            ? css`
                  ${sharedDisableStyling}
              `
            : css`
                  &:hover {
                      background-color: ${ColorOpacityOnLightHover};
                  }

                  &:focus {
                      background-color: ${ColorOpacityOnLightFocus};
                      ${outlineStyling}
                  }

                  &:active {
                      background-color: ${ColorOpacityOnLightPressed};
                  }
              `}
`;

const ghostOnDark: ButtonVariation = css<{ $disabled: boolean }>`
    color: ${ColorNeutralWhite};
    background-color: transparent;

    ${({ $disabled }) =>
        $disabled
            ? css`
                  ${sharedDisableStyling}
              `
            : css`
                  &:hover {
                      background-color: ${ColorOpacityOnDarkHover};
                  }

                  &:focus {
                      background-color: ${ColorOpacityOnDarkFocus};
                      ${outlineStyling}
                  }

                  &:active {
                      background-color: ${ColorOpacityOnDarkPressed};
                  }
              `}
`;

const club: ButtonVariation = css<{ $disabled: boolean }>`
    color: ${ColorNeutralBlack};
    background-color: ${ColorClubMatas2};

    ${({ $disabled }) =>
        $disabled
            ? css`
                  background-color: ${ColorClubMatas1};
                  ${sharedDisableStyling}
              `
            : css`
                  &:hover {
                      background-color: ${ColorClubMatasHover};
                  }

                  &:focus {
                      background-color: ${ColorClubMatasFocus};
                      ${outlineStyling}
                  }

                  &:active {
                      background-color: ${ColorClubMatasPressed};
                  }
              `}
`;

const plus: ButtonVariation = css<{ $disabled: boolean }>`
    color: ${ColorNeutralBlack};
    background-color: ${ColorClubMatasPlus2};

    ${({ $disabled }) =>
        $disabled
            ? css`
                  background-color: ${ColorClubMatasPlus1};
                  ${sharedDisableStyling}
              `
            : css`
                  &:hover {
                      background-color: ${ColorClubMatasPlusHover};
                  }

                  &:focus {
                      background-color: ${ColorClubMatasPlusFocus};
                      ${outlineStyling}
                  }

                  &:active {
                      background-color: ${ColorClubMatasPlusPressed};
                  }
              `}
`;

const beauty: ButtonVariation = css<{ theme }>`
    color: ${ColorNeutralBlack};
    background-color: ${ColorBeauty1};

    ${({ theme }) =>
        theme.disabled
            ? css`
                  ${sharedDisableStyling}
              `
            : css`
                  &:hover {
                      background-color: ${ColorBeautyHover};
                  }

                  &:focus {
                      background-color: ${ColorBeautyFocus};
                      ${outlineStyling}
                  }

                  &:active {
                      background-color: ${ColorBeautyPressed};
                  }
              `}
`;

const subscribe: ButtonVariation = css<{ $disabled: boolean }>`
    color: ${ColorNeutralBlack};
    background-color: ${ColorSubscription1};

    ${({ theme }) =>
        theme.disabled
            ? css`
                  ${sharedDisableStyling}
              `
            : css`
                  &:hover {
                      background-color: ${ColorSubscriptionHover};
                  }

                  &:focus {
                      background-color: ${ColorSubscriptionFocus};
                      ${outlineStyling}
                  }

                  &:active {
                      background-color: ${ColorSubscriptionActivated};
                  }
              `}
`;

const primaryText: ButtonVariation = css<{ $disabled: boolean }>`
    color: ${ColorNeutralBlack};
    background-color: transparent;
    text-decoration: underline;

    ${({ theme }) =>
        theme.disabled
            ? css`
                  ${sharedDisableStyling}
              `
            : css`
                  &:focus {
                      outline-color: ${ColorNeutralBlack};
                  }
              `}
`;

const primaryTextInline: ButtonVariation = css<{ $disabled: boolean }>`
    color: ${ColorNeutralBlack};
    background-color: transparent;
    text-decoration: underline;
    padding-inline: 0;

    ${({ theme }) =>
        theme.disabled
            ? css`
                  ${sharedDisableStyling}
              `
            : css`
                  &:focus {
                      outline-color: ${ColorNeutralBlack};
                  }
              `}
`;

const primaryTextNegative: ButtonVariation = css<{ $disabled: boolean }>`
    color: ${ColorNeutralWhite};
    background-color: transparent;
    text-decoration: underline;

    ${({ $disabled }) =>
        $disabled
            ? css`
                  ${sharedDisableStyling}
              `
            : css`
                  &:focus {
                      outline-color: ${ColorNeutralWhite};
                  }
              `}
`;

const outline: ButtonVariation = css<{ $disabled: boolean }>`
    color: ${ColorNeutralBlack};
    background-color: transparent;
    border: 1px solid ${ColorBrandBlue1};

    ${({ $disabled }) =>
        $disabled
            ? css`
                  ${sharedDisableStyling}
              `
            : css`
                  &:hover {
                      background-color: ${ColorOpacityOnLightHover};
                  }

                  &:focus {
                      background-color: ${ColorOpacityOnLightFocus};
                  }

                  &:active {
                      background-color: ${ColorOpacityOnLightPressed};
                  }
              `}
`;
// #endregion ButtonVariants

/* Remember to add your variant in the obj below */

const buttonVariants = {
    primary,
    secondary,
    negative,
    ghostOnLight,
    ghostOnDark,
    plus,
    beauty,
    subscribe,
    club,
    primaryText,
    primaryTextInline,
    primaryTextNegative,
    outline
} as const;

export type buttonVariantKeys = keyof typeof buttonVariants;

export type ButtonVariant = buttonVariantKeys | null;

export const buttonVariantStyling = css<{ variant?: ButtonVariant }>`
    ${({ variant }) => variant && buttonVariants[variant]}
`;

export default buttonVariants;
