import { MenuItemCustomActionViewModel } from 'autogen/swagger/all';
import ChatTab from 'components/chat/models';
import useClearAllSmartviews from 'components/generic/smartview/shared/hooks/useClearAllSmartviews';
import mediator from 'modules/mediator';
import UAEventTracking from 'modules/tracking/UAEventTracking';
import React from 'react';
import { GA4Tracking } from 'modules/tracking/GA4/GA4Tracking';
import DefaultMenuItem from './DefaultMenuItem';
import { DefaultMenuItemProps } from '../types';

type CustomerChatMenuItemProps = {
    model: MenuItemCustomActionViewModel;
    defaultProps: DefaultMenuItemProps;
};

export default function CustomerChatMenuItem({
    model: { text },
    defaultProps: { iconUrl, styling, id },
    ...restProps
}: CustomerChatMenuItemProps) {
    const closeSideMenu = useClearAllSmartviews();

    return (
        <DefaultMenuItem.Button
            buttonProps={{
                onClick: () => {
                    mediator.publish('openChat', ChatTab.CustomerService);

                    GA4Tracking({
                        eventName: 'link_click',
                        context: 'menu',
                        category: 'navigation',
                        eventParams: [
                            { param_name: 'link_type', param_value: 'internal' },
                            { param_name: 'list_name', param_value: text },
                            { param_name: 'id', param_value: id }
                        ]
                    });

                    UAEventTracking({
                        eventCategory: 'sideMenu',
                        eventAction: 'customAction',
                        eventLabel: text
                    });

                    closeSideMenu();
                }
            }}
            iconUrl={iconUrl}
            styling={styling}
            {...restProps}
        >
            {text}
        </DefaultMenuItem.Button>
    );
}
