import { Spacing16, Spacing32 } from 'autogen/design-tokens/tokens';
import { MenuSectionViewModel } from 'autogen/swagger/all';
import { MenuSectionHeader } from 'components/generic/menu/components/DefaultMenuSection';
import { IMenuSection } from 'components/generic/menu/shared/types';
import { Typography } from 'components/generic/typography';
import React from 'react';
import styled from 'styled-components';

export const GreyCapitalHeadline = styled(Typography.Overline)`
    font-weight: bold;
    color: #666;
    margin: ${Spacing16} ${Spacing32};
`;

type SectionHeadlineRenderer = Pick<IMenuSection, 'headline'> & Pick<MenuSectionViewModel, 'headlineStyling'>;

export default function SectionHeadlineRenderer(props: SectionHeadlineRenderer) {
    const { headline, headlineStyling } = props;

    switch (headlineStyling) {
        case 'GreyCapital':
            return <GreyCapitalHeadline size={14}>{headline}</GreyCapitalHeadline>;
        case 'Default':
            return <MenuSectionHeader>{headline}</MenuSectionHeader>;
        default:
            return null;
    }
}
