import { addOrUpdateQuantity, deleteFromBasket } from 'modules/addAndRemoveFromBasket';
import { GetBasketContent } from 'autogen/swagger/all';
import { AddOrUpdateQuantityParams } from 'autogen/swagger/all';
import { IBambuserProduct, IBambuserPlayer } from './types';

type AddToBasketType = AddOrUpdateQuantityParams['addToBasketType'];

export function addItemToCart(item: IBambuserProduct, addToBasketType: AddToBasketType, callback: (any) => void): void {
    addOrUpdateQuantity(parseInt(item.sku), 1, addToBasketType)
        .then((): void => {
            callback(true);
        })
        .catch((): void => {
            callback(false);
        });
}

export function updateItemInCart(
    item: IBambuserProduct,
    addToBasketType: AddToBasketType,
    callback: (any) => void
): void {
    if (item.quantity > 0) {
        addOrUpdateQuantity(parseInt(item.sku), item.quantity, addToBasketType)
            .then((): void => {
                callback(true);
            })
            .catch((): void => {
                callback(false);
            });
    }
    if (item.quantity === 0) {
        deleteFromBasket(parseInt(item.sku))
            .then((): void => {
                callback(true);
            })
            .catch((): void => {
                callback(false);
            });
    }
}

export function syncCartState(player: IBambuserPlayer): void {
    GetBasketContent()
        .then((response): void => {
            if (response.length < 1) {
                // Emptying the in-player cart (emptying the cart is currently the only supported option when updating)
                player.updateCart({
                    items: []
                });
            }
        })
        .catch((): void => {
            console.error('could not get bambuser content');
        });
}
