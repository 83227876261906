import dynamic from 'helpers/dynamic';
import {
    ColorNeutralWhite,
    ColorNordicGrey4,
    Spacing16,
    ColorBrandBlue1,
    Spacing12,
    Spacing4,
    Spacing24,
    Spacing32
} from 'autogen/design-tokens/tokens';
import { ButtonWithIcon } from 'components/Button';
import { Typography } from 'components/generic/typography';
import styled, { css } from 'styled-components';
import useClearAllSmartviews from 'components/generic/smartview/shared/hooks/useClearAllSmartviews';
import { breakpoint, sizes } from 'variables';
import type { MenuHeaderViewModel, MenuViewModel } from 'autogen/swagger/all';
import useIsAuthenticated from 'hooks/user/useIsAuthenticated';
import type { MouseEvent } from 'react';
import { GA4Tracking } from 'modules/tracking/GA4';
import usePointOfSaleRequest from 'hooks/user/usePointOfSaleRequest';
import LinkAction from 'components/shared/Routing/components/LinkAction';
import Logo from 'components/Icon/Logo';

const LineArrowRight = dynamic(() => import('icons/LineArrowRight.svg?react'), {
    ssr: false,
    fallback: <span style={{ width: 16 }} />
});

const Cross = dynamic(() => import('icons/Cross.svg?react'));

const StyledLogo = styled(Logo)`
    width: 135px;
`;

const MenuHeaderContainer = styled.div<{ styling?: MenuViewModel['styling'] }>`
    position: sticky;
    top: 0;
    display: grid;
    grid-template-columns: 40px 1fr 40px;
    gap: ${Spacing24};
    padding: ${Spacing24} ${Spacing16};
    background-color: ${({ styling }) => {
        switch (styling) {
            case 'White':
                return ColorNeutralWhite;
            default:
                return ColorNordicGrey4;
        }
    }};

    ${breakpoint.up(sizes.md)} {
        margin-bottom: 0;
        padding: ${Spacing32} ${Spacing16};
    }
`;

const HeadlineContainerStyling = css`
    padding: 0 ${Spacing16};
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
`;

const LogoContainer = styled.div`
    height: 24px;

    svg {
        height: inherit;
    }

    ${breakpoint.up(sizes.md)} {
        height: 32px;
    }
`;

const Headline = styled(Typography.Headline)`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

const StyledButtonWithIcon = styled(ButtonWithIcon)`
    grid-column: 1;

    &:hover,
    &:focus {
        background-color: transparent;
    }
`;

const Container = styled.div`
    grid-column: 2;
    overflow: hidden;

    ${HeadlineContainerStyling}
`;

const CloseButton = styled(StyledButtonWithIcon)`
    grid-column: 3;
    margin-left: auto;
`;

const LineArrowLeft = styled(LineArrowRight)`
    transform: rotate(180deg);
`;

const HeaderLinksContainer = styled(Typography.Body)`
    align-self: flex-end;
    margin-bottom: ${Spacing4};

    a:not(:first-child) {
        border-left: 1px solid #000;
    }

    a:first-child {
        padding-right: ${Spacing12};
    }

    a:last-child {
        padding-left: ${Spacing12};
    }
`;

const LogoLink = styled.a`
    display: block;
    height: inherit;
`;

export type MenuHeaderProps = {
    backButtonCallback?: () => void;
    closeButtonCallback?: () => void;
    headline?: string;
    styling?: MenuViewModel['styling'];
};

export default function MenuHeader({
    backButtonCallback,
    closeButtonCallback,
    headline,
    styling,
    ...restProps
}: MenuHeaderProps) {
    const closeAllSmartviews = useClearAllSmartviews();

    return (
        <MenuHeaderContainer styling={styling} {...restProps}>
            {backButtonCallback && (
                <StyledButtonWithIcon
                    onClick={backButtonCallback}
                    icon={<LineArrowLeft />}
                    size="md"
                    variant="ghostOnLight"
                />
            )}

            {headline && (
                <Container>
                    <Headline size={{ sm: 24, md: 32 }}>{headline}</Headline>
                </Container>
            )}

            <CloseButton
                onClick={closeButtonCallback || closeAllSmartviews}
                icon={<Cross />}
                size="md"
                variant="ghostOnLight"
            />
        </MenuHeaderContainer>
    );
}

const StyledMenuHeaderContainer = styled(MenuHeaderContainer)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
`;

type MenuHeaderWithHeaderLinksProps = MenuHeaderProps & { headerLinks?: MenuViewModel['headerLinks'] };

export function MenuHeaderWithMatasLogo({
    backButtonCallback,
    closeButtonCallback,
    headline,
    headerLinks,
    styling
}: MenuHeaderWithHeaderLinksProps) {
    const closeAllSmartviews = useClearAllSmartviews();
    const isAuthenticated = useIsAuthenticated();
    const { isPosRequest } = usePointOfSaleRequest();

    function headerLinksTracking(event: MouseEvent<HTMLAnchorElement>) {
        const {
            currentTarget: { textContent }
        } = event;

        if (textContent) {
            GA4Tracking({
                eventName: 'link_click',
                context: 'menu',
                category: 'navigation',
                eventParams: [{ param_name: 'link_text', param_value: textContent }]
            });
        }
    }

    return (
        <StyledMenuHeaderContainer styling={styling}>
            {backButtonCallback && (
                <StyledButtonWithIcon
                    onClick={backButtonCallback}
                    icon={<LineArrowLeft />}
                    size="md"
                    variant="ghostOnLight"
                />
            )}

            {headline && (
                <Container>
                    <Headline size={{ sm: 24, md: 32 }}>{headline}</Headline>
                </Container>
            )}
            {!headline && (
                <>
                    <LogoContainer>
                        <LogoLink href={isPosRequest ? undefined : '/'}>
                            <StyledLogo />
                        </LogoLink>
                    </LogoContainer>
                    {headerLinks && !isPosRequest && (
                        <HeaderLinksContainer forwardedAs="div" themeTypes="small">
                            {headerLinks
                                .reduce(
                                    (acc, curr) => [...acc, isAuthenticated ? curr.loggedInLink : curr.loggedOutLink],
                                    [] as (MenuHeaderViewModel['loggedInLink'] | MenuHeaderViewModel['loggedOutLink'])[]
                                )
                                .map((menuHeaderLink) => {
                                    const { link, text } = menuHeaderLink;

                                    return (
                                        <LinkAction action={link.linkAction} key={text} onClick={headerLinksTracking}>
                                            {text}
                                        </LinkAction>
                                    );
                                })}
                        </HeaderLinksContainer>
                    )}
                </>
            )}

            <CloseButton
                onClick={closeButtonCallback || closeAllSmartviews}
                icon={<Cross />}
                size="md"
                variant="ghostOnLight"
            />
        </StyledMenuHeaderContainer>
    );
}
