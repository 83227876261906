import { useTransition } from '@react-spring/web';
import {
    BorderRadiusMedium,
    ColorNordicGrey4,
    FontLineHeightTight,
    FontSize14,
    FontSize16
} from 'autogen/design-tokens/tokens';
import { DecreaseButtonLabel, InreaseButtonLabel } from 'autogen/translation-keys/trans-website-basket-powerstep';
import dynamic from 'helpers/dynamic';
import { mediaQueryStyling } from 'helpers/mediaQueryStyling';
import { focusVisibleOutline } from 'modules/helpers/mixins';
import { rem } from 'modules/helpers/style';
import UAEventTracking, {
    type TrackingCategory,
    type TrackingAction,
    type DepricatedStringConcatenation
} from 'modules/tracking/UAEventTracking';
import type React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import type { BreakPointsType } from 'variables/breakpoint';

type Size = keyof typeof sizeStyling;
type Part = keyof typeof sizeStyling.md;
type SizeQueryOrSize = BreakPointsType<Size> | Size;
export type ActionTypes = 'increment' | 'decrement' | 'input' | 'blur';
type OnValueChange = (value: number, action: ActionTypes) => void;

// #region Styles
const sizeDef = {
    sm: 32,
    md: 40,
    lg: 48
};

const sizeStyling = {
    sm: {
        wrapper: css`
            width: calc(${sizeDef.sm} * 3px);
        `,
        button: css`
            width: ${sizeDef.sm}px;
            height: ${sizeDef.sm}px;
        `,
        input: css`
            width: ${sizeDef.sm}px;
            height: ${sizeDef.sm}px;
            font-size: ${FontSize14};
        `
    },
    md: {
        wrapper: css`
            width: calc(${sizeDef.md} * 3px);
        `,
        button: css`
            width: ${sizeDef.md}px;
            height: ${sizeDef.md}px;
        `,
        input: css`
            width: ${sizeDef.md}px;
            height: ${sizeDef.md}px;
            font-size: ${FontSize16};
        `
    },
    lg: {
        wrapper: css`
            width: calc(${sizeDef.lg} * 3px);
        `,
        button: css`
            width: ${sizeDef.lg}px;
            height: ${sizeDef.lg}px;
        `,
        input: css`
            width: ${sizeDef.lg}px;
            height: ${sizeDef.lg}px;
            font-size: ${FontSize16};
        `
    }
};

export function getCounterInputMediaQueryStyling(sizesObject: BreakPointsType<Size>, part: Part) {
    const obj = {};
    Object.keys(sizesObject).forEach((key) => {
        obj[key] = sizeStyling[sizesObject[key]][part];
    });

    return mediaQueryStyling(obj);
}

const getSizeStyling = (size: SizeQueryOrSize, part: Part) =>
    typeof size === 'object' ? getCounterInputMediaQueryStyling(size, part) : sizeStyling[size][part];

const Wrapper = styled.div<{ size: SizeQueryOrSize }>`
    display: flex;
    justify-content: center;
    background-color: var(--color-cta-secondary-base);
    border-radius: ${BorderRadiusMedium};

    ${({ size }) => getSizeStyling(size, 'wrapper')}
`;

const Button = styled.button<{ size: SizeQueryOrSize }>`
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    font-size: ${rem(20)};
    padding: 8px 10px;
    margin: 0;
    background: transparent;
    font-weight: bold;
    line-height: ${FontLineHeightTight};
    z-index: 1;

    ${({ size }) => getSizeStyling(size, 'button')}

    ${focusVisibleOutline()}

    &:disabled {
        background: transparent;
        opacity: 0.2;
    }
`;

const InputContainer = styled.div<{ size: SizeQueryOrSize }>`
    border-radius: ${BorderRadiusMedium};
    ${({ size }) => getSizeStyling(size, 'input')}
`;

const Input = styled.input`
    border: none;
    background: transparent;
    width: 100%;
    height: 100%;
    text-align: center;

    line-height: ${FontLineHeightTight};
`;

const MinusIcon = styled(dynamic(() => import('icons/Minus.svg?react'), { ssr: false }))`
    width: ${rem(16)};
    height: ${rem(16)};
`;

const PlusIcon = styled(dynamic(() => import('icons/Plus.svg?react'), { ssr: false }))`
    width: ${rem(16)};
    height: ${rem(16)};
`;
// #endregion Styles

export interface IReactCounterInputProps {
    value: number;
    minValue?: number;
    maxValue?: number;
    onValueChange?: OnValueChange;
    onBlur?: OnValueChange;
    onFocus?: () => void;
    onEnter?: OnValueChange;
    trackingCategory?: TrackingCategory;
    trackingActionPre?: string;
    size?: SizeQueryOrSize;
    adjustCountButtons?: boolean;
    autoFocus?: boolean;
}

export function ControlledReactCounterInput(props: IReactCounterInputProps) {
    const {
        value,
        minValue,
        maxValue,
        onValueChange,
        onBlur,
        onFocus,
        onEnter,
        trackingCategory,
        trackingActionPre,
        size = 'md',
        adjustCountButtons = true,
        autoFocus,
        ...rest
    } = props;

    const { t } = useTranslation();
    const [inputValue, setInputValue] = useState<string>(value.toString());

    const track = (action: ActionTypes, label?: string): void => {
        if (!trackingCategory) return;
        const UAEventAction = (): TrackingAction => {
            switch (action) {
                case 'increment':
                    return 'plusClicked';
                case 'decrement':
                    return 'minusClicked';
                case 'input':
                default:
                    return 'inputChanged';
            }
        };

        UAEventTracking({
            eventCategory: trackingCategory,
            eventAction: trackingActionPre
                ? (`${trackingActionPre}${UAEventAction()}` as DepricatedStringConcatenation)
                : UAEventAction(),
            ...(label ? { eventLabel: label } : {})
        });
    };

    const changeValue = (val: number, action: ActionTypes, isChange = false): void => {
        let newValue = val;
        if ((minValue || minValue === 0) && val < minValue) {
            newValue = minValue;
            if (isChange) {
                setInputValue(val.toString());
                return;
            }
        }
        if ((maxValue || maxValue === 0) && val > maxValue) {
            newValue = maxValue;
        }

        if (value === newValue) {
            setInputValue(newValue.toString());
            return;
        }

        if (onValueChange) onValueChange(newValue, action);
    };

    const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const text = (e.target as HTMLInputElement).value;
        const val = Number.parseInt(text);

        if (val) {
            changeValue(val, 'input', true);
            track('input', text);
        } else {
            setInputValue('');
        }
    };

    const adjustValue = (action: ActionTypes): void => {
        const delta = action === 'increment' ? 1 : -1;
        const newValue = value + delta;
        changeValue(newValue, action);
    };

    const plusClick = (): void => {
        adjustValue('increment');
        track('increment');
    };

    const minusClick = (): void => {
        adjustValue('decrement');
        track('decrement');
    };

    const handleOnBlur = (): void => {
        changeValue(value, 'blur');
        if (typeof onBlur === 'function') onBlur(value, 'blur');
    };

    useEffect(() => {
        setInputValue(value.toString());
    }, [value]);

    if (!adjustCountButtons) {
        return (
            <InputContainer {...rest} size={size}>
                <Input
                    value={inputValue}
                    onChange={onChange}
                    type="tel"
                    onBlur={handleOnBlur}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && onEnter) {
                            onEnter(value, 'input');
                        }
                    }}
                    onFocus={onFocus}
                    autoFocus={autoFocus}
                />
            </InputContainer>
        );
    }

    return (
        <Wrapper {...rest} size={size}>
            {adjustCountButtons && (
                <Button
                    onClick={minusClick}
                    disabled={value === minValue}
                    size={size}
                    aria-label={t(DecreaseButtonLabel)}
                >
                    <MinusIcon />
                </Button>
            )}
            <InputContainer size={size}>
                <Input
                    value={inputValue}
                    onChange={onChange}
                    type="tel"
                    onBlur={handleOnBlur}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && onEnter) {
                            onEnter(value, 'input');
                        }
                    }}
                    onFocus={onFocus}
                    autoFocus={autoFocus}
                />
            </InputContainer>
            {adjustCountButtons && (
                <Button
                    onClick={plusClick}
                    disabled={value === maxValue}
                    size={size}
                    aria-label={t(InreaseButtonLabel)}
                >
                    <PlusIcon />
                </Button>
            )}
        </Wrapper>
    );
}

export const ReactCounterInput = (props: IReactCounterInputProps): JSX.Element => {
    const {
        value: initialValue,
        minValue,
        maxValue,
        onValueChange,
        trackingCategory,
        trackingActionPre,
        ...rest
    } = props;
    const [value, setValue] = useState(initialValue);

    useEffect(() => {
        if (onValueChange) {
            onValueChange(value, 'input');
        }
    }, [value, onValueChange]);

    return (
        <ControlledReactCounterInput
            value={value}
            onValueChange={setValue}
            minValue={minValue}
            maxValue={maxValue}
            trackingCategory={trackingCategory}
            trackingActionPre={trackingActionPre}
            {...rest}
        />
    );
};

export default ReactCounterInput;
