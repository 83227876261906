import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import {
    AddSampleToBasket,
    RemoveSampleFromBasket,
    ReplaceSampleInBasket,
    SampleGroupViewModel,
    SampleSelectionResponseViewModel
} from 'autogen/swagger/all';
import * as transKey from 'autogen/translation-keys/trans-root-website';
import { useTranslation } from 'react-i18next';
import { formatReact } from 'modules/helpers/strings';
import {
    ColorNordicGrey4,
    FontHeading1FamilySerif,
    Spacing48,
    Spacing16,
    Spacing24,
    Spacing32
} from 'autogen/design-tokens/tokens';
import { rem } from 'modules/helpers/style';
import { breakpoint, sizes } from 'variables';
import type { IModal } from 'modules/modal';
import UAEventTracking, { type TrackingAction } from 'modules/tracking/UAEventTracking';
import { Typography } from 'components/generic/typography';
import dynamic from 'helpers/dynamic';
import InlineButton from 'components/generic/InlineButton';
import { updateBasket } from 'components/basket/shared/basket';
import { SelectedProductIdsContext, SelectItemCallbackContext } from './hooks/contexts';
import SampleGroup from './components/SampleGroup';
import { itemState } from './shared/types';

const InfoIcon = dynamic(() => import('icons/Info.svg?react'));

// #region styles

const Header = styled.div`
    text-align: center;
    padding: ${Spacing24} ${Spacing16} ${Spacing32};

    ${breakpoint.up(sizes.xl)} {
        padding: ${Spacing24} ${Spacing48};
        text-align: initial;
    }
`;

const Body = styled.div`
    background-color: ${ColorNordicGrey4};
    padding: ${Spacing16};

    ${breakpoint.up(sizes.xl)} {
        padding: ${Spacing48};
    }
`;

const Footer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${ColorNordicGrey4};
    padding: 0 ${Spacing16} ${Spacing16};

    ${breakpoint.up(sizes.xl)} {
        padding: ${Spacing16} ${Spacing48};
    }
`;

const Heading = styled.div`
    font-size: ${rem(28)};
    line-height: ${rem(34)};
    letter-spacing: ${rem(0.4)};
    font-family: ${FontHeading1FamilySerif};
    margin-bottom: ${Spacing16};

    ${breakpoint.up(sizes.xl)} {
        font-size: ${rem(32)};
        line-height: ${rem(40)};
        letter-spacing: ${rem(0.8)};
    }
`;

const StyledBodyTypography = styled(Typography.Body)`
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;

    ${breakpoint.up(sizes.xl)} {
        margin-left: 0;
        margin-right: 0;
    }
`;

const StyledInlineButton = styled(InlineButton)`
    margin-left: auto;
    margin-right: auto;
    margin-bottom: ${Spacing16};
`;

const StyledSampleGroup = styled(SampleGroup)`
    & + & {
        margin-top: ${Spacing24};
    }
`;

const StyledInfoIcon = styled(InfoIcon)<{ $hasAction?: boolean }>`
    width: ${rem(16)};
    height: ${rem(16)};
    margin-bottom: ${rem(-2)}; // vertical align with text

    ${({ $hasAction }) => $hasAction && 'cursor: pointer;'}
`;

const ScrollContainer = styled.div`
    height: 100%;
    max-height: 770px; // hide disclaimer out if first view
    overflow: auto;
`;

// #endregion styles

function getLowestQualifyingAmount(sampleGroups: SampleGroupViewModel[]): string {
    const amounts = sampleGroups.map((sampleGroup) => parseInt(sampleGroup.qualifyingAmount));
    const lowestAmount = Math.min(...amounts);
    const groupWithLowestAmount = sampleGroups.filter(
        (sampleGroup) => parseInt(sampleGroup.qualifyingAmount) === lowestAmount
    )[0];
    const lowestAmountString = groupWithLowestAmount.qualifyingAmount;

    return lowestAmountString;
}

function getSelectedProductIds(sampleGroups: SampleGroupViewModel[]): number[] {
    const selectedProductIds: number[] = [];
    sampleGroups.forEach((sampleGroup) => {
        sampleGroup.samples.forEach((sample) => {
            if (sample.selected) selectedProductIds.push(sample.id);
        });
    });

    return selectedProductIds;
}

function getPositionIndex(productId: number, samplesGroups: SampleGroupViewModel[]): string {
    let positionIndex;

    samplesGroups.forEach((sampleGroup, groupIndex) => {
        sampleGroup.samples.forEach((sample, sampleIndex) => {
            if (productId === sample.id) {
                positionIndex = `groupIndex: ${groupIndex + 1}, sampleIndex: ${sampleIndex + 1}`;
            }
        });
    });

    return positionIndex;
}

function trackPickChange(eventAction: TrackingAction, eventLabel: string) {
    UAEventTracking({
        eventCategory: 'samples',
        eventAction,
        eventLabel
    });
}

function handleDenyButton(modal: IModal, eventLabel: string) {
    modal({ open: false });
    UAEventTracking({
        eventCategory: 'samples',
        eventAction: 'closeModal',
        eventLabel
    });
}

function trackDisclaimerAnchor() {
    UAEventTracking({
        eventCategory: 'samples',
        eventAction: 'anchorScroll',
        eventLabel: 'infoIcon'
    });
}

interface ISamplesPicker extends SampleSelectionResponseViewModel {
    modal: IModal;
}

export default function SamplesPicker(props: ISamplesPicker): JSX.Element {
    const { allowedQuantity, sampleGroups, modal, sampleDisclaimer } = props;
    const lowestQualifyingAmount = getLowestQualifyingAmount(sampleGroups);
    const { t } = useTranslation();
    const { CaptionWithIcon, Title: TitleTrans, DenySelectionButton } = transKey.Basket.Samples.SamplesPicker;
    const [selectedProductIds, setSelectedProductIds] = useState(getSelectedProductIds(sampleGroups));
    const scrollContainerRef = useRef<HTMLDivElement>(null);
    const sampleDisclaimerRef = useRef<HTMLDivElement>(null);

    function selectItemCallback(state: itemState, productId: number, isRecommended: boolean) {
        const fallbackIds = selectedProductIds;
        const positionIndex = getPositionIndex(productId, sampleGroups);
        modal({});

        // remove
        if (state === 'chosen') {
            setSelectedProductIds(selectedProductIds.filter((id) => id !== productId));
            RemoveSampleFromBasket({ productId })
                .then(updateBasket)
                .catch((err) => {
                    setSelectedProductIds(fallbackIds);
                    console.error(err);
                });

            trackPickChange('remove', positionIndex);

            return;
        }

        // replace
        if (allowedQuantity === 1 && selectedProductIds.length === 1) {
            ReplaceSampleInBasket({
                newProductId: productId,
                oldProductId: selectedProductIds[0],
                isRecommended
            })
                .then(updateBasket)
                .then(() => {
                    modal({ open: false });
                })
                .catch((err) => {
                    setSelectedProductIds(fallbackIds);
                    console.error(err);
                });

            setSelectedProductIds([productId]);
            trackPickChange('replace', positionIndex);

            return;
        }

        // add
        if (selectedProductIds.length < allowedQuantity) {
            setSelectedProductIds([...selectedProductIds, productId]);
            AddSampleToBasket({ productId, isRecommended })
                .then(updateBasket)
                .then(() => {
                    if (selectedProductIds.length + 1 === allowedQuantity) {
                        modal({ open: false });
                    }
                })
                .catch((err) => {
                    setSelectedProductIds(fallbackIds);
                    console.error(err);
                });

            trackPickChange('add', positionIndex);
        }
    }

    function goToDisclaimer() {
        if (!sampleDisclaimerRef?.current || !scrollContainerRef?.current) return;
        const { current: scrollContainer } = scrollContainerRef;
        const { current: sampleDisclaimerNode } = sampleDisclaimerRef;
        const { offsetTop: sampleDisclaimerTop } = sampleDisclaimerNode;

        scrollContainer.scrollTop = sampleDisclaimerTop;
    }

    return (
        <SelectedProductIdsContext.Provider value={selectedProductIds}>
            <SelectItemCallbackContext.Provider value={selectItemCallback}>
                <ScrollContainer ref={scrollContainerRef}>
                    <Header>
                        <Heading>{t(TitleTrans)}</Heading>
                        <StyledBodyTypography themeTypes="large">
                            {formatReact(t(CaptionWithIcon))(
                                <b>{lowestQualifyingAmount}</b>,
                                <StyledInfoIcon
                                    onClick={() => {
                                        goToDisclaimer();
                                        trackDisclaimerAnchor();
                                    }}
                                    $hasAction
                                />
                            )}
                        </StyledBodyTypography>
                    </Header>
                    <Body>
                        {sampleGroups.map((sampleGroup) => {
                            const { name } = sampleGroup;
                            return <StyledSampleGroup key={name} props={sampleGroup} />;
                        })}
                    </Body>
                    <Footer>
                        <StyledInlineButton
                            variant="dark"
                            size="lg"
                            fontWeight="semibold"
                            onClick={() => handleDenyButton(modal, t(DenySelectionButton))}
                        >
                            {t(DenySelectionButton)}
                        </StyledInlineButton>
                        <div ref={sampleDisclaimerRef}>
                            <Typography.Body themeTypes="small">
                                {formatReact(sampleDisclaimer)(<StyledInfoIcon />)}
                            </Typography.Body>
                        </div>
                    </Footer>
                </ScrollContainer>
            </SelectItemCallbackContext.Provider>
        </SelectedProductIdsContext.Provider>
    );
}
