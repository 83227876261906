
        /* eslint-disable camelcase */
        /* eslint-disable import/prefer-default-export */
        /* eslint-disable @typescript-eslint/no-unused-vars */
        // noinspection JSUnusedGlobalSymbols
        // noinspection ES6UnusedImports
        import { fetchJSON, fetchHTML, postFormData, formatParams, postJSON, postQuery, putQuery, deleteQuery } from 'modules/helpers/fetch';
        import type {components, paths} from './all';

        export type ResponsiveImage = components['schemas']['ResponsiveImage'];
                    export type ResponsiveImageBreakPoint = components['schemas']['ResponsiveImageBreakPoint'];
                    export type ResponsiveImageSource = components['schemas']['ResponsiveImageSource'];
                    export type ResponsiveImageModel = components['schemas']['ResponsiveImageModel'];
                    export type AdvisorDisplayApplication = components['schemas']['AdvisorDisplayApplication'];
                    export type AdvisorExpertiseType = components['schemas']['AdvisorExpertiseType'];
                    export type TextContent = components['schemas']['TextContent'];
                    export type IMedium = components['schemas']['IMedium'];
                    export type IMediumMaybe = components['schemas']['IMediumMaybe'];
                    export type MediumRatio = components['schemas']['MediumRatio'];
                    export type MediumDisplay = components['schemas']['MediumDisplay'];
                    export type MediumDisplayMaybe = components['schemas']['MediumDisplayMaybe'];
                    export type Advisor = components['schemas']['Advisor'];
                    export type ResponsiveImageModelMaybe = components['schemas']['ResponsiveImageModelMaybe'];
                    export type AdvisorLinkAction = components['schemas']['AdvisorLinkAction'];
                    export type ArticleSectionLinkAction = components['schemas']['ArticleSectionLinkAction'];
                    export type ArticleTagLinkAction = components['schemas']['ArticleTagLinkAction'];
                    export type BrandClubLinkAction = components['schemas']['BrandClubLinkAction'];
                    export type BrandLinkAction = components['schemas']['BrandLinkAction'];
                    export type CampaignOfferId = components['schemas']['CampaignOfferId'];
                    export type CampaignOfferLinkAction = components['schemas']['CampaignOfferLinkAction'];
                    export type CmsMenuItemLinkAction = components['schemas']['CmsMenuItemLinkAction'];
                    export type CmsPageLinkAction = components['schemas']['CmsPageLinkAction'];
                    export type DiscountLinkAction = components['schemas']['DiscountLinkAction'];
                    export type ExternalLinkAction = components['schemas']['ExternalLinkAction'];
                    export type FrontpageLinkAction = components['schemas']['FrontpageLinkAction'];
                    export type PlainLinkAction = components['schemas']['PlainLinkAction'];
                    export type ProductGroupLinkAction = components['schemas']['ProductGroupLinkAction'];
                    export type ProductLinkAction = components['schemas']['ProductLinkAction'];
                    export type SeriesLinkAction = components['schemas']['SeriesLinkAction'];
                    export type StaticPageType = components['schemas']['StaticPageType'];
                    export type StaticPageLinkAction = components['schemas']['StaticPageLinkAction'];
                    export type StoreLinkAction = components['schemas']['StoreLinkAction'];
                    export type AdvisorDetailedModel = components['schemas']['AdvisorDetailedModel'];
                    export type AdvisorChatType = components['schemas']['AdvisorChatType'];
                    export type HeroChatDepartment = components['schemas']['HeroChatDepartment'];
                    export type LinkActionMaybe = components['schemas']['LinkActionMaybe'];
                    export type AdvisorChatNudgeViewModel = components['schemas']['AdvisorChatNudgeViewModel'];
                    export type AdvisorChatNudgeViewModelMaybe = components['schemas']['AdvisorChatNudgeViewModelMaybe'];
                    export type AdvisorChatViewModel = components['schemas']['AdvisorChatViewModel'];
                    export type ZowieUseCaseModel = components['schemas']['ZowieUseCaseModel'];
                    export type AdvisorItemType = components['schemas']['AdvisorItemType'];
                    export type AdvisorToolboxQuickView = components['schemas']['AdvisorToolboxQuickView'];
                    export type AdvisorToolboxQuickViewMaybe = components['schemas']['AdvisorToolboxQuickViewMaybe'];
                    export type AdvisorToolboxItem = components['schemas']['AdvisorToolboxItem'];
                    export type AdvisorToolboxModel = components['schemas']['AdvisorToolboxModel'];
                    export type BambuserProductVariantModel = components['schemas']['BambuserProductVariantModel'];
                    export type BambuserProductModel = components['schemas']['BambuserProductModel'];
                    export type BambuserBasketLineModel = components['schemas']['BambuserBasketLineModel'];
                    export type UrlKey = components['schemas']['UrlKey'];
                    export type ProductImageType = components['schemas']['ProductImageType'];
                    export type ProductImage = components['schemas']['ProductImage'];
                    export type GiftSwapInfo = components['schemas']['GiftSwapInfo'];
                    export type WrapInfo = components['schemas']['WrapInfo'];
                    export type OtcColor = components['schemas']['OtcColor'];
                    export type OtcWarningModel = components['schemas']['OtcWarningModel'];
                    export type OtcWarningModelMaybe = components['schemas']['OtcWarningModelMaybe'];
                    export type BookingViewModel = components['schemas']['BookingViewModel'];
                    export type BookingViewModelMaybe = components['schemas']['BookingViewModelMaybe'];
                    export type ProductFavoriteStatus = components['schemas']['ProductFavoriteStatus'];
                    export type FavoriteStatusViewModel = components['schemas']['FavoriteStatusViewModel'];
                    export type UATrackingAction = components['schemas']['UATrackingAction'];
                    export type ModalLink = components['schemas']['ModalLink'];
                    export type ModalLinkMaybe = components['schemas']['ModalLinkMaybe'];
                    export type LoginDirectLink = components['schemas']['LoginDirectLink'];
                    export type DiscountTextType = components['schemas']['DiscountTextType'];
                    export type TriggeredDiscountViewModel = components['schemas']['TriggeredDiscountViewModel'];
                    export type PotentialDiscountViewModel = components['schemas']['PotentialDiscountViewModel'];
                    export type DiscountText = components['schemas']['DiscountText'];
                    export type ProgressiveDiscountBasketStepTextViewModel = components['schemas']['ProgressiveDiscountBasketStepTextViewModel'];
                    export type ProgressiveDiscountBasketStepTextViewModelMaybe = components['schemas']['ProgressiveDiscountBasketStepTextViewModelMaybe'];
                    export type BasketProgressiveDiscountViewModel = components['schemas']['BasketProgressiveDiscountViewModel'];
                    export type BasketProgressiveDiscountViewModelMaybe = components['schemas']['BasketProgressiveDiscountViewModelMaybe'];
                    export type PointDiscountViewModel = components['schemas']['PointDiscountViewModel'];
                    export type DiscountInfoViewModel = components['schemas']['DiscountInfoViewModel'];
                    export type ChoosableSubscriptionFrequency = components['schemas']['ChoosableSubscriptionFrequency'];
                    export type ChoosableSubscriptionFrequencyGroup = components['schemas']['ChoosableSubscriptionFrequencyGroup'];
                    export type ChoosableSubscriptionFrequencyMaybe = components['schemas']['ChoosableSubscriptionFrequencyMaybe'];
                    export type SubscriptionFrequencyInfo = components['schemas']['SubscriptionFrequencyInfo'];
                    export type BasketSubscriptionInfoViewModel = components['schemas']['BasketSubscriptionInfoViewModel'];
                    export type BasketSubscriptionInfoViewModelMaybe = components['schemas']['BasketSubscriptionInfoViewModelMaybe'];
                    export type ProductLinkedItemType = components['schemas']['ProductLinkedItemType'];
                    export type LinkedItemPriceInfoViewModel = components['schemas']['LinkedItemPriceInfoViewModel'];
                    export type BasketLineSubscriptionNudgePriceInfo = components['schemas']['BasketLineSubscriptionNudgePriceInfo'];
                    export type BasketLineSubscriptionNudgeTexts = components['schemas']['BasketLineSubscriptionNudgeTexts'];
                    export type BasketLineSubscriptionNudge = components['schemas']['BasketLineSubscriptionNudge'];
                    export type BasketLineSubscriptionNudgeMaybe = components['schemas']['BasketLineSubscriptionNudgeMaybe'];
                    export type ProductStockCheckMarkType = components['schemas']['ProductStockCheckMarkType'];
                    export type StockStatusViewModel = components['schemas']['StockStatusViewModel'];
                    export type ZipCodeSource = components['schemas']['ZipCodeSource'];
                    export type ZipCodeWithSourceViewModel = components['schemas']['ZipCodeWithSourceViewModel'];
                    export type ZipCodeWithSourceViewModelMaybe = components['schemas']['ZipCodeWithSourceViewModelMaybe'];
                    export type DeliveryCountdownModel = components['schemas']['DeliveryCountdownModel'];
                    export type DeliveryCountdownModelMaybe = components['schemas']['DeliveryCountdownModelMaybe'];
                    export type ExpectedDeliverySingleSupplierModel = components['schemas']['ExpectedDeliverySingleSupplierModel'];
                    export type ExpectedDeliverySingleSupplierModelMaybe = components['schemas']['ExpectedDeliverySingleSupplierModelMaybe'];
                    export type ExpectedDeliverySummaryViewModel = components['schemas']['ExpectedDeliverySummaryViewModel'];
                    export type StockStatusWithPreciseDeliveryAndModalViewModel = components['schemas']['StockStatusWithPreciseDeliveryAndModalViewModel'];
                    export type StockStatusWithPreciseDeliveryViewModel = components['schemas']['StockStatusWithPreciseDeliveryViewModel'];
                    export type IStockStatusViewModelMaybe = components['schemas']['IStockStatusViewModelMaybe'];
                    export type BasketLinePriceInfoViewModel = components['schemas']['BasketLinePriceInfoViewModel'];
                    export type Hyperlink = components['schemas']['Hyperlink'];
                    export type TextHyperlink = components['schemas']['TextHyperlink'];
                    export type MatasPlusBasketLineInfoModel = components['schemas']['MatasPlusBasketLineInfoModel'];
                    export type MatasPlusBasketLineInfoModelMaybe = components['schemas']['MatasPlusBasketLineInfoModelMaybe'];
                    export type DonationBasketLineInfoModel = components['schemas']['DonationBasketLineInfoModel'];
                    export type DonationBasketLineInfoModelMaybe = components['schemas']['DonationBasketLineInfoModelMaybe'];
                    export type BasketLineViewModel = components['schemas']['BasketLineViewModel'];
                    export type BasketGiftLineViewModel = components['schemas']['BasketGiftLineViewModel'];
                    export type BasketSampleLineViewModel = components['schemas']['BasketSampleLineViewModel'];
                    export type CheckoutGiftCardLine = components['schemas']['CheckoutGiftCardLine'];
                    export type CheckoutGiftCardInfo = components['schemas']['CheckoutGiftCardInfo'];
                    export type PaymentType = components['schemas']['PaymentType'];
                    export type PaymentCardType = components['schemas']['PaymentCardType'];
                    export type CheckoutPaymentTypeModel = components['schemas']['CheckoutPaymentTypeModel'];
                    export type CheckoutPaymentTypeList = components['schemas']['CheckoutPaymentTypeList'];
                    export type CheckoutStoreModel = components['schemas']['CheckoutStoreModel'];
                    export type CheckoutStoreList = components['schemas']['CheckoutStoreList'];
                    export type BoxButton = components['schemas']['BoxButton'];
                    export type PaymentCardChangeChoiceModel = components['schemas']['PaymentCardChangeChoiceModel'];
                    export type PaymentCardOptionModel = components['schemas']['PaymentCardOptionModel'];
                    export type BoxButtonWithCreditCardOption = components['schemas']['BoxButtonWithCreditCardOption'];
                    export type BasketInfoBoxType = components['schemas']['BasketInfoBoxType'];
                    export type BasketInfoBox = components['schemas']['BasketInfoBox'];
                    export type IBasketInfoBoxMaybe = components['schemas']['IBasketInfoBoxMaybe'];
                    export type BasketLineGroupViewModel = components['schemas']['BasketLineGroupViewModel'];
                    export type BasketSubTotalSummary = components['schemas']['BasketSubTotalSummary'];
                    export type BasketTotalSummary = components['schemas']['BasketTotalSummary'];
                    export type BasketShippingPriceSummary = components['schemas']['BasketShippingPriceSummary'];
                    export type BasketShippingPriceSummaryMaybe = components['schemas']['BasketShippingPriceSummaryMaybe'];
                    export type BasketDiscountSummary = components['schemas']['BasketDiscountSummary'];
                    export type BasketDiscountSummaryMaybe = components['schemas']['BasketDiscountSummaryMaybe'];
                    export type AdditionalSummaryLine = components['schemas']['AdditionalSummaryLine'];
                    export type BasketAdditionalSummary = components['schemas']['BasketAdditionalSummary'];
                    export type BasketAdditionalSummaryMaybe = components['schemas']['BasketAdditionalSummaryMaybe'];
                    export type BasketGiftCardSummary = components['schemas']['BasketGiftCardSummary'];
                    export type BasketGiftCardSummaryMaybe = components['schemas']['BasketGiftCardSummaryMaybe'];
                    export type VoucherSummaryLine = components['schemas']['VoucherSummaryLine'];
                    export type BasketVoucherSummary = components['schemas']['BasketVoucherSummary'];
                    export type BasketVoucherSummaryMaybe = components['schemas']['BasketVoucherSummaryMaybe'];
                    export type PrizeCertificateModel = components['schemas']['PrizeCertificateModel'];
                    export type BasketPrizeCertificateSummary = components['schemas']['BasketPrizeCertificateSummary'];
                    export type BasketPrizeCertificateSummaryMaybe = components['schemas']['BasketPrizeCertificateSummaryMaybe'];
                    export type BasketSummaryViewModel = components['schemas']['BasketSummaryViewModel'];
                    export type VoucherModel = components['schemas']['VoucherModel'];
                    export type ChoosablePrizeCertificateModel = components['schemas']['ChoosablePrizeCertificateModel'];
                    export type PointInfo = components['schemas']['PointInfo'];
                    export type BasketPointInfo = components['schemas']['BasketPointInfo'];
                    export type BasketPrizeCertificateModel = components['schemas']['BasketPrizeCertificateModel'];
                    export type BasketPrizeCertificateModelMaybe = components['schemas']['BasketPrizeCertificateModelMaybe'];
                    export type PersonalOfferInfoModelInfoUrls = components['schemas']['PersonalOfferInfoModelInfoUrls'];
                    export type PersonalOfferListItemListItemUrls = components['schemas']['PersonalOfferListItemListItemUrls'];
                    export type PersonalOfferListItemPinCodeTexts = components['schemas']['PersonalOfferListItemPinCodeTexts'];
                    export type PersonalOfferListItemPinCodeTextsMaybe = components['schemas']['PersonalOfferListItemPinCodeTextsMaybe'];
                    export type PersonalOfferListItem = components['schemas']['PersonalOfferListItem'];
                    export type PersonalOfferInfoModel = components['schemas']['PersonalOfferInfoModel'];
                    export type PersonalOfferInfoModelMaybe = components['schemas']['PersonalOfferInfoModelMaybe'];
                    export type BasketFooterViewModel = components['schemas']['BasketFooterViewModel'];
                    export type BasketValidationError = components['schemas']['BasketValidationError'];
                    export type BasketValidationInfo = components['schemas']['BasketValidationInfo'];
                    export type LoginDirectLinkMaybe = components['schemas']['LoginDirectLinkMaybe'];
                    export type BasketMessageDiscountViewModel = components['schemas']['BasketMessageDiscountViewModel'];
                    export type BasketMessageType = components['schemas']['BasketMessageType'];
                    export type BasketMessageGwpViewModel = components['schemas']['BasketMessageGwpViewModel'];
                    export type BasketMessageMatasPlusViewModel = components['schemas']['BasketMessageMatasPlusViewModel'];
                    export type BasketMessageSameDayDeliveryViewModel = components['schemas']['BasketMessageSameDayDeliveryViewModel'];
                    export type BasketMessageSampleGroupViewModel = components['schemas']['BasketMessageSampleGroupViewModel'];
                    export type BasketMessageShippingViewModel = components['schemas']['BasketMessageShippingViewModel'];
                    export type IBasketMessageMaybe = components['schemas']['IBasketMessageMaybe'];
                    export type BasketMessagesViewModel = components['schemas']['BasketMessagesViewModel'];
                    export type BasketMessagesViewModelMaybe = components['schemas']['BasketMessagesViewModelMaybe'];
                    export type SignupNudgingType = components['schemas']['SignupNudgingType'];
                    export type SignupNudgingFooter = components['schemas']['SignupNudgingFooter'];
                    export type SignupNudgingFooterMaybe = components['schemas']['SignupNudgingFooterMaybe'];
                    export type TextHyperlinkMaybe = components['schemas']['TextHyperlinkMaybe'];
                    export type SignupNudgingInfo = components['schemas']['SignupNudgingInfo'];
                    export type SignupNudgingInfoMaybe = components['schemas']['SignupNudgingInfoMaybe'];
                    export type SignupNudgingModel = components['schemas']['SignupNudgingModel'];
                    export type SignupNudgingModelMaybe = components['schemas']['SignupNudgingModelMaybe'];
                    export type DonationTexts = components['schemas']['DonationTexts'];
                    export type DonationOption = components['schemas']['DonationOption'];
                    export type DonationModel = components['schemas']['DonationModel'];
                    export type DonationModelMaybe = components['schemas']['DonationModelMaybe'];
                    export type BasketDetailedSamplesViewModel = components['schemas']['BasketDetailedSamplesViewModel'];
                    export type UnclaimedGiftViewModel = components['schemas']['UnclaimedGiftViewModel'];
                    export type BasketDetailedViewModel = components['schemas']['BasketDetailedViewModel'];
                    export type FavoriteStatusViewModelMaybe = components['schemas']['FavoriteStatusViewModelMaybe'];
                    export type BasketLineModifiedResultViewModel = components['schemas']['BasketLineModifiedResultViewModel'];
                    export type ModifiedBasketViewModel = components['schemas']['ModifiedBasketViewModel'];
                    export type BasketCheckoutMessageType = components['schemas']['BasketCheckoutMessageType'];
                    export type BasketCheckoutButtonMessageModel = components['schemas']['BasketCheckoutButtonMessageModel'];
                    export type LoginInfoType = components['schemas']['LoginInfoType'];
                    export type LoginMode = components['schemas']['LoginMode'];
                    export type LoginState = components['schemas']['LoginState'];
                    export type LoginModalRequest = components['schemas']['LoginModalRequest'];
                    export type BasketCheckoutButtonModel = components['schemas']['BasketCheckoutButtonModel'];
                    export type SplitTestVariant = components['schemas']['SplitTestVariant'];
                    export type BasketContentViewModel = components['schemas']['BasketContentViewModel'];
                    export type BasketViewModel = components['schemas']['BasketViewModel'];
                    export type ShippingSupplier = components['schemas']['ShippingSupplier'];
                    export type WebsiteIcon = components['schemas']['WebsiteIcon'];
                    export type ShippingSupplierGroupOptionModel = components['schemas']['ShippingSupplierGroupOptionModel'];
                    export type BasketShippingOptionModel = components['schemas']['BasketShippingOptionModel'];
                    export type DefaultDataLayerTrackingEvent = components['schemas']['DefaultDataLayerTrackingEvent'];
                    export type DefaultDataLayerTrackingEventMaybe = components['schemas']['DefaultDataLayerTrackingEventMaybe'];
                    export type CheckoutErrorImpressionEvent = components['schemas']['CheckoutErrorImpressionEvent'];
                    export type EcommerceBasketSize = components['schemas']['EcommerceBasketSize'];
                    export type ActionField = components['schemas']['ActionField'];
                    export type Product = components['schemas']['Product'];
                    export type Checkout = components['schemas']['Checkout'];
                    export type EcommerceCheckoutStep = components['schemas']['EcommerceCheckoutStep'];
                    export type AddRemove = components['schemas']['AddRemove'];
                    export type EcommerceProductAddToCartClick = components['schemas']['EcommerceProductAddToCartClick'];
                    export type Click = components['schemas']['Click'];
                    export type EcommerceProductClick = components['schemas']['EcommerceProductClick'];
                    export type Detail = components['schemas']['Detail'];
                    export type EcommerceProductDetail = components['schemas']['EcommerceProductDetail'];
                    export type EcommerceProductImpression = components['schemas']['EcommerceProductImpression'];
                    export type EcommerceProductRemoveFromCartClick = components['schemas']['EcommerceProductRemoveFromCartClick'];
                    export type Promotion = components['schemas']['Promotion'];
                    export type PromoView = components['schemas']['PromoView'];
                    export type EcommercePromotionClick = components['schemas']['EcommercePromotionClick'];
                    export type EcommercePromotionImpression = components['schemas']['EcommercePromotionImpression'];
                    export type Purchase = components['schemas']['Purchase'];
                    export type EcommercePurchase = components['schemas']['EcommercePurchase'];
                    export type CmsObjectTracking = components['schemas']['CmsObjectTracking'];
                    export type EnhancedEcommerceTrackingEvent = components['schemas']['EnhancedEcommerceTrackingEvent'];
                    export type VwoCampaignAssignmentTrackingEvent = components['schemas']['VwoCampaignAssignmentTrackingEvent'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceBasketSize = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceBasketSize'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceCheckoutStep = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceCheckoutStep'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceProductAddToCartClick = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductAddToCartClick'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceProductClick = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductClick'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceProductDetail = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductDetail'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceProductImpression = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductImpression'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceProductRemoveFromCartClick = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductRemoveFromCartClick'];
                    export type EnhancedEcommerceTrackingEventOfEcommercePromotionClick = components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePromotionClick'];
                    export type EnhancedEcommerceTrackingEventOfEcommercePromotionImpression = components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePromotionImpression'];
                    export type EnhancedEcommerceTrackingEventOfEcommercePurchase = components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePurchase'];
                    export type EnhancedEcommerceTrackingEventOfCmsObjectTracking = components['schemas']['EnhancedEcommerceTrackingEventOfCmsObjectTracking'];
                    export type DataLayerDto = components['schemas']['DataLayerDto'];
                    export type TrackingOperationResult = components['schemas']['TrackingOperationResult'];
                    export type OperationResult = components['schemas']['OperationResult'];
                    export type BundleType = components['schemas']['BundleType'];
                    export type AddToBasketType = components['schemas']['AddToBasketType'];
                    export type ValidationResult = components['schemas']['ValidationResult'];
                    export type SwaggerOperationResult = components['schemas']['SwaggerOperationResult'];
                    export type TrackingOperationResultWithRedirect = components['schemas']['TrackingOperationResultWithRedirect'];
                    export type IProductImage = components['schemas']['IProductImage'];
                    export type ProductNameLineType = components['schemas']['ProductNameLineType'];
                    export type ProductNameLine = components['schemas']['ProductNameLine'];
                    export type ProductName = components['schemas']['ProductName'];
                    export type SampleSelectionViewModel = components['schemas']['SampleSelectionViewModel'];
                    export type SampleGroupViewModel = components['schemas']['SampleGroupViewModel'];
                    export type SampleSelectionResponseViewModel = components['schemas']['SampleSelectionResponseViewModel'];
                    export type ActionWillAffectSampleResult = components['schemas']['ActionWillAffectSampleResult'];
                    export type BasketPointInfoModelPointInfoType = components['schemas']['BasketPointInfoModelPointInfoType'];
                    export type BasketPointInfoModelPlusAdvantages = components['schemas']['BasketPointInfoModelPlusAdvantages'];
                    export type BasketPointInfoModelPlusAdvantagesMaybe = components['schemas']['BasketPointInfoModelPlusAdvantagesMaybe'];
                    export type BasketPointInfoModel = components['schemas']['BasketPointInfoModel'];
                    export type BeautyTrendBoxNotifySignupResultViewModel = components['schemas']['BeautyTrendBoxNotifySignupResultViewModel'];
                    export type BeautyTrendBoxNotifyModalViewModel = components['schemas']['BeautyTrendBoxNotifyModalViewModel'];
                    export type BookingType = components['schemas']['BookingType'];
                    export type ConsultationCategoryModel = components['schemas']['ConsultationCategoryModel'];
                    export type StoreSimpleModel = components['schemas']['StoreSimpleModel'];
                    export type StoreSimpleModelMaybe = components['schemas']['StoreSimpleModelMaybe'];
                    export type BookingStatus = components['schemas']['BookingStatus'];
                    export type Location = components['schemas']['Location'];
                    export type BookingAddress = components['schemas']['BookingAddress'];
                    export type MyProfileBookingModel = components['schemas']['MyProfileBookingModel'];
                    export type MyProfileBookingListViewModel = components['schemas']['MyProfileBookingListViewModel'];
                    export type PagedMyProfileBookingListViewModel = components['schemas']['PagedMyProfileBookingListViewModel'];
                    export type BookingFeedbackStatus = components['schemas']['BookingFeedbackStatus'];
                    export type AddressModel = components['schemas']['AddressModel'];
                    export type TicketModel = components['schemas']['TicketModel'];
                    export type BookingInfoBoxModel = components['schemas']['BookingInfoBoxModel'];
                    export type ConnectedContentFullwidthLocationType = components['schemas']['ConnectedContentFullwidthLocationType'];
                    export type ConnectedContentAdformTrackingModel = components['schemas']['ConnectedContentAdformTrackingModel'];
                    export type ConnectedContentAdformTrackingModelMaybe = components['schemas']['ConnectedContentAdformTrackingModelMaybe'];
                    export type CertificationCustomAction = components['schemas']['CertificationCustomAction'];
                    export type JavascriptHookType = components['schemas']['JavascriptHookType'];
                    export type JavascriptHookCustomAction = components['schemas']['JavascriptHookCustomAction'];
                    export type LiveEventCustomAction = components['schemas']['LiveEventCustomAction'];
                    export type SurveyCustomAction = components['schemas']['SurveyCustomAction'];
                    export type VimeoCustomAction = components['schemas']['VimeoCustomAction'];
                    export type CustomActionType = components['schemas']['CustomActionType'];
                    export type LinkActionType = components['schemas']['LinkActionType'];
                    export type PageActionMaybe = components['schemas']['PageActionMaybe'];
                    export type LiveEventStatus = components['schemas']['LiveEventStatus'];
                    export type ProductLiveEventModel = components['schemas']['ProductLiveEventModel'];
                    export type ProductLiveEventModelMaybe = components['schemas']['ProductLiveEventModelMaybe'];
                    export type ConnectedContentLinkModel = components['schemas']['ConnectedContentLinkModel'];
                    export type ConnectedContentLinkModelMaybe = components['schemas']['ConnectedContentLinkModelMaybe'];
                    export type ConnectedContentLayoutType = components['schemas']['ConnectedContentLayoutType'];
                    export type TextAlignmentType = components['schemas']['TextAlignmentType'];
                    export type ConnectedContentDesignTemplateModel = components['schemas']['ConnectedContentDesignTemplateModel'];
                    export type ConnectedContentAdvisorModel = components['schemas']['ConnectedContentAdvisorModel'];
                    export type ConnectedContentAdvisorModelMaybe = components['schemas']['ConnectedContentAdvisorModelMaybe'];
                    export type IActiveTimeSpan = components['schemas']['IActiveTimeSpan'];
                    export type IActiveTimeSpanMaybe = components['schemas']['IActiveTimeSpanMaybe'];
                    export type ConnectedContentDebugInfo = components['schemas']['ConnectedContentDebugInfo'];
                    export type ConnectedContentDebugInfoMaybe = components['schemas']['ConnectedContentDebugInfoMaybe'];
                    export type ConnectedContentFullwidthModel = components['schemas']['ConnectedContentFullwidthModel'];
                    export type ConnectedContentWidthType = components['schemas']['ConnectedContentWidthType'];
                    export type ConnectedContentInlineModel = components['schemas']['ConnectedContentInlineModel'];
                    export type ConnectedContentInventoryLaneBlockModel = components['schemas']['ConnectedContentInventoryLaneBlockModel'];
                    export type ConnectedContentMenuItemModel = components['schemas']['ConnectedContentMenuItemModel'];
                    export type ConnectedContentProductDisplayModel = components['schemas']['ConnectedContentProductDisplayModel'];
                    export type ListItem = components['schemas']['ListItem'];
                    export type ListRowAlignment = components['schemas']['ListRowAlignment'];
                    export type ListRowItem = components['schemas']['ListRowItem'];
                    export type ProductAction = components['schemas']['ProductAction'];
                    export type PriceLabelType = components['schemas']['PriceLabelType'];
                    export type PriceLabelModel = components['schemas']['PriceLabelModel'];
                    export type PriceLabelModelMaybe = components['schemas']['PriceLabelModelMaybe'];
                    export type ProductListPriceInfo = components['schemas']['ProductListPriceInfo'];
                    export type SplashPlacement = components['schemas']['SplashPlacement'];
                    export type SplashDisplayType = components['schemas']['SplashDisplayType'];
                    export type SplashType = components['schemas']['SplashType'];
                    export type Splash = components['schemas']['Splash'];
                    export type SplashMaybe = components['schemas']['SplashMaybe'];
                    export type SplashModel = components['schemas']['SplashModel'];
                    export type VariantDisplayType = components['schemas']['VariantDisplayType'];
                    export type VariantListItem = components['schemas']['VariantListItem'];
                    export type ProductListVariantsModel = components['schemas']['ProductListVariantsModel'];
                    export type FavoriteStatusModel = components['schemas']['FavoriteStatusModel'];
                    export type RecommendationType = components['schemas']['RecommendationType'];
                    export type RecommendationTrackingModelDTO = components['schemas']['RecommendationTrackingModelDTO'];
                    export type SearchEventModelDTO = components['schemas']['SearchEventModelDTO'];
                    export type SearchOrigin = components['schemas']['SearchOrigin'];
                    export type SuggestedSearchEventModelDTO = components['schemas']['SuggestedSearchEventModelDTO'];
                    export type SearchTrackingModelDTO = components['schemas']['SearchTrackingModelDTO'];
                    export type PowerstepTrackingModelDTO = components['schemas']['PowerstepTrackingModelDTO'];
                    export type AdformProductTrackingModelDTO = components['schemas']['AdformProductTrackingModelDTO'];
                    export type TrackingModelDTO = components['schemas']['TrackingModelDTO'];
                    export type TrackingModelDTOMaybe = components['schemas']['TrackingModelDTOMaybe'];
                    export type IExplainedScore = components['schemas']['IExplainedScore'];
                    export type IExplainedScoreMaybe = components['schemas']['IExplainedScoreMaybe'];
                    export type IBundleInfo = components['schemas']['IBundleInfo'];
                    export type IBundleInfoMaybe = components['schemas']['IBundleInfoMaybe'];
                    export type UATrackingCategory = components['schemas']['UATrackingCategory'];
                    export type WrappingFlags = components['schemas']['WrappingFlags'];
                    export type ProductListItemModel = components['schemas']['ProductListItemModel'];
                    export type MyProfileBookingDetailedModel = components['schemas']['MyProfileBookingDetailedModel'];
                    export type MyProfileBookingDetailsViewModel = components['schemas']['MyProfileBookingDetailsViewModel'];
                    export type BookingOperationResultModel = components['schemas']['BookingOperationResultModel'];
                    export type BookingFeedbackRating = components['schemas']['BookingFeedbackRating'];
                    export type BookingFeedbackCreateRequestModel = components['schemas']['BookingFeedbackCreateRequestModel'];
                    export type BookingProductNewsItemModel = components['schemas']['BookingProductNewsItemModel'];
                    export type MyProfileBookingViewModel = components['schemas']['MyProfileBookingViewModel'];
                    export type MyProfileBookingPageViewModel = components['schemas']['MyProfileBookingPageViewModel'];
                    export type MyProfileBookingDetailsPageViewModel = components['schemas']['MyProfileBookingDetailsPageViewModel'];
                    export type BrandListItemModel = components['schemas']['BrandListItemModel'];
                    export type BrandListLetterSectionModel = components['schemas']['BrandListLetterSectionModel'];
                    export type BreadcrumbItemModel = components['schemas']['BreadcrumbItemModel'];
                    export type IProductGroupBreadcrumb = components['schemas']['IProductGroupBreadcrumb'];
                    export type IProductGroupBreadcrumbMaybe = components['schemas']['IProductGroupBreadcrumbMaybe'];
                    export type BreadcrumbModel = components['schemas']['BreadcrumbModel'];
                    export type BreadcrumbWebsiteModel = components['schemas']['BreadcrumbWebsiteModel'];
                    export type MetaRobotSetting = components['schemas']['MetaRobotSetting'];
                    export type LocalizedPageUrlModel = components['schemas']['LocalizedPageUrlModel'];
                    export type PageMetadataModel = components['schemas']['PageMetadataModel'];
                    export type ProductGroupType = components['schemas']['ProductGroupType'];
                    export type BrandListProductGroupModel = components['schemas']['BrandListProductGroupModel'];
                    export type BrandListModel = components['schemas']['BrandListModel'];
                    export type TooltipType = components['schemas']['TooltipType'];
                    export type TooltipModel = components['schemas']['TooltipModel'];
                    export type TooltipModelMaybe = components['schemas']['TooltipModelMaybe'];
                    export type CountdownSizeType = components['schemas']['CountdownSizeType'];
                    export type CountdownModel = components['schemas']['CountdownModel'];
                    export type CountdownModelMaybe = components['schemas']['CountdownModelMaybe'];
                    export type AmountBasedProgressBarModel = components['schemas']['AmountBasedProgressBarModel'];
                    export type ItemBasedProgressBarModel = components['schemas']['ItemBasedProgressBarModel'];
                    export type ProgressModel = components['schemas']['ProgressModel'];
                    export type ProgressModelMaybe = components['schemas']['ProgressModelMaybe'];
                    export type BrandClubMemberDrawerModel = components['schemas']['BrandClubMemberDrawerModel'];
                    export type BrandClubNotMemberDrawerModel = components['schemas']['BrandClubNotMemberDrawerModel'];
                    export type BrandClubSignOffModel = components['schemas']['BrandClubSignOffModel'];
                    export type FollowBrandClubModel = components['schemas']['FollowBrandClubModel'];
                    export type BrandClubSignupSource = components['schemas']['BrandClubSignupSource'];
                    export type BrandClubPointProductsModelTexts = components['schemas']['BrandClubPointProductsModelTexts'];
                    export type BrandClubPointProductsModel = components['schemas']['BrandClubPointProductsModel'];
                    export type BrandClubGiftModel = components['schemas']['BrandClubGiftModel'];
                    export type BrandClubGiftModelMaybe = components['schemas']['BrandClubGiftModelMaybe'];
                    export type AmountBasedActiveCard = components['schemas']['AmountBasedActiveCard'];
                    export type ItemBasedActiveCard = components['schemas']['ItemBasedActiveCard'];
                    export type ActiveCardBaseMaybe = components['schemas']['ActiveCardBaseMaybe'];
                    export type BrandClubPageModelTexts = components['schemas']['BrandClubPageModelTexts'];
                    export type HyperlinkMaybe = components['schemas']['HyperlinkMaybe'];
                    export type ResponsiveImageLinkModel = components['schemas']['ResponsiveImageLinkModel'];
                    export type BrandClubPageModelImages = components['schemas']['BrandClubPageModelImages'];
                    export type BrandClubPageModelLinks = components['schemas']['BrandClubPageModelLinks'];
                    export type BrandClubPageModel = components['schemas']['BrandClubPageModel'];
                    export type AvailableBrandClubModel = components['schemas']['AvailableBrandClubModel'];
                    export type ActiveCardListItem = components['schemas']['ActiveCardListItem'];
                    export type BonusGiftListItem = components['schemas']['BonusGiftListItem'];
                    export type DummyCardModel = components['schemas']['DummyCardModel'];
                    export type BrandClubFrontPageModelTexts = components['schemas']['BrandClubFrontPageModelTexts'];
                    export type BrandClubFrontPageModel = components['schemas']['BrandClubFrontPageModel'];
                    export type BrandClubBonusPageModelBonusGift = components['schemas']['BrandClubBonusPageModelBonusGift'];
                    export type BrandClubBonusPageModel = components['schemas']['BrandClubBonusPageModel'];
                    export type BrandClubSignupModelTexts = components['schemas']['BrandClubSignupModelTexts'];
                    export type BrandClubSignupModelFieldNames = components['schemas']['BrandClubSignupModelFieldNames'];
                    export type BrandClubSignupModel = components['schemas']['BrandClubSignupModel'];
                    export type BrandClubMessageModel = components['schemas']['BrandClubMessageModel'];
                    export type BrandClubPageViewModel = components['schemas']['BrandClubPageViewModel'];
                    export type CampaignInfoDetails = components['schemas']['CampaignInfoDetails'];
                    export type CampaignInfo = components['schemas']['CampaignInfo'];
                    export type ClubMatasUspModel = components['schemas']['ClubMatasUspModel'];
                    export type AcceptedTermType = components['schemas']['AcceptedTermType'];
                    export type AcceptedTermResult = components['schemas']['AcceptedTermResult'];
                    export type BookingStoreModel = components['schemas']['BookingStoreModel'];
                    export type BookingAddressMaybe = components['schemas']['BookingAddressMaybe'];
                    export type TimeSlotDetailed = components['schemas']['TimeSlotDetailed'];
                    export type DailyBookingSlotsModel = components['schemas']['DailyBookingSlotsModel'];
                    export type DailyStoreBookingSlotsListModel = components['schemas']['DailyStoreBookingSlotsListModel'];
                    export type BookingSlotsListByLocationViewModel = components['schemas']['BookingSlotsListByLocationViewModel'];
                    export type ClubMatasUser = components['schemas']['ClubMatasUser'];
                    export type BookingCategoryModel = components['schemas']['BookingCategoryModel'];
                    export type AddressModelMaybe = components['schemas']['AddressModelMaybe'];
                    export type BookingModel = components['schemas']['BookingModel'];
                    export type ExternalPartnerModel = components['schemas']['ExternalPartnerModel'];
                    export type ExternalPartnerModelMaybe = components['schemas']['ExternalPartnerModelMaybe'];
                    export type ConsultationProductModel = components['schemas']['ConsultationProductModel'];
                    export type EventTimeModel = components['schemas']['EventTimeModel'];
                    export type EventTimeModelMaybe = components['schemas']['EventTimeModelMaybe'];
                    export type BookingOverviewModel = components['schemas']['BookingOverviewModel'];
                    export type TotalTicketPriceModel = components['schemas']['TotalTicketPriceModel'];
                    export type BookingReceiptModel = components['schemas']['BookingReceiptModel'];
                    export type BookRequestModel = components['schemas']['BookRequestModel'];
                    export type BookingPosJournalModel = components['schemas']['BookingPosJournalModel'];
                    export type ConsultationCategoryFilterModel = components['schemas']['ConsultationCategoryFilterModel'];
                    export type ExternalPartnerFilterModel = components['schemas']['ExternalPartnerFilterModel'];
                    export type AvailableBookingSlotModel = components['schemas']['AvailableBookingSlotModel'];
                    export type AvailableBookingSlotModelMaybe = components['schemas']['AvailableBookingSlotModelMaybe'];
                    export type ConsultationProductListModel = components['schemas']['ConsultationProductListModel'];
                    export type ConsultationProductListViewModel = components['schemas']['ConsultationProductListViewModel'];
                    export type ConsultationProductRecommendationType = components['schemas']['ConsultationProductRecommendationType'];
                    export type ConsultationProductRecommendationModel = components['schemas']['ConsultationProductRecommendationModel'];
                    export type ConsultationProductStoreInfo = components['schemas']['ConsultationProductStoreInfo'];
                    export type ConsultationProductPageModel = components['schemas']['ConsultationProductPageModel'];
                    export type BookingStoreViewModel = components['schemas']['BookingStoreViewModel'];
                    export type BookingStoreViewModelMaybe = components['schemas']['BookingStoreViewModelMaybe'];
                    export type ConsultationProductListPageContentViewModel = components['schemas']['ConsultationProductListPageContentViewModel'];
                    export type CreditCardActionText = components['schemas']['CreditCardActionText'];
                    export type CustomProductType = components['schemas']['CustomProductType'];
                    export type PriceLabelVisibilityFlags = components['schemas']['PriceLabelVisibilityFlags'];
                    export type IPriceLabel = components['schemas']['IPriceLabel'];
                    export type IPriceLabelMaybe = components['schemas']['IPriceLabelMaybe'];
                    export type IPointInfo = components['schemas']['IPointInfo'];
                    export type IProgressiveDiscountStep = components['schemas']['IProgressiveDiscountStep'];
                    export type IProgressiveDiscountPriceInfo = components['schemas']['IProgressiveDiscountPriceInfo'];
                    export type IProgressiveDiscountPriceInfoMaybe = components['schemas']['IProgressiveDiscountPriceInfoMaybe'];
                    export type IPriceInfo = components['schemas']['IPriceInfo'];
                    export type CustomProductActionBaseModel = components['schemas']['CustomProductActionBaseModel'];
                    export type CustomDigitalGiftCardActionModel = components['schemas']['CustomDigitalGiftCardActionModel'];
                    export type CheckoutBasketModelsProgressiveDiscountBasketStepTextViewModel = components['schemas']['CheckoutBasketModelsProgressiveDiscountBasketStepTextViewModel'];
                    export type CheckoutBasketModelsProgressiveDiscountBasketStepTextViewModelMaybe = components['schemas']['CheckoutBasketModelsProgressiveDiscountBasketStepTextViewModelMaybe'];
                    export type CheckoutBasketProgressiveDiscountViewModel = components['schemas']['CheckoutBasketProgressiveDiscountViewModel'];
                    export type CheckoutBasketProgressiveDiscountViewModelMaybe = components['schemas']['CheckoutBasketProgressiveDiscountViewModelMaybe'];
                    export type CheckoutBasketTriggeredDiscountViewModel = components['schemas']['CheckoutBasketTriggeredDiscountViewModel'];
                    export type CheckoutBasketPotentialDiscountViewModel = components['schemas']['CheckoutBasketPotentialDiscountViewModel'];
                    export type CheckoutBasketDiscountInfoViewModel = components['schemas']['CheckoutBasketDiscountInfoViewModel'];
                    export type ProductNameLineModel = components['schemas']['ProductNameLineModel'];
                    export type ProductNameViewModel = components['schemas']['ProductNameViewModel'];
                    export type CheckoutBasketModelsSubscriptionInfoViewModel = components['schemas']['CheckoutBasketModelsSubscriptionInfoViewModel'];
                    export type CheckoutBasketModelsSubscriptionInfoViewModelMaybe = components['schemas']['CheckoutBasketModelsSubscriptionInfoViewModelMaybe'];
                    export type CheckoutBasketLineViewModel = components['schemas']['CheckoutBasketLineViewModel'];
                    export type SingleSubscriptionPrice = components['schemas']['SingleSubscriptionPrice'];
                    export type SingleSubscriptionPriceMaybe = components['schemas']['SingleSubscriptionPriceMaybe'];
                    export type DiscountType = components['schemas']['DiscountType'];
                    export type ISubscriptionIntroPriceInfo = components['schemas']['ISubscriptionIntroPriceInfo'];
                    export type ISubscriptionIntroPriceInfoMaybe = components['schemas']['ISubscriptionIntroPriceInfoMaybe'];
                    export type SubscriptionIntroPriceState = components['schemas']['SubscriptionIntroPriceState'];
                    export type SubscriptionPriceInfo = components['schemas']['SubscriptionPriceInfo'];
                    export type SubscriptionInfoModel = components['schemas']['SubscriptionInfoModel'];
                    export type SubscriptionInfoModelMaybe = components['schemas']['SubscriptionInfoModelMaybe'];
                    export type CheckoutBasketBookingLineViewModel = components['schemas']['CheckoutBasketBookingLineViewModel'];
                    export type CheckoutBasketGiftLineViewModel = components['schemas']['CheckoutBasketGiftLineViewModel'];
                    export type CheckoutBasketSampleLineViewModel = components['schemas']['CheckoutBasketSampleLineViewModel'];
                    export type CheckoutDeliveryBasketViewModel = components['schemas']['CheckoutDeliveryBasketViewModel'];
                    export type CityFromZipResult = components['schemas']['CityFromZipResult'];
                    export type CompanyAddress = components['schemas']['CompanyAddress'];
                    export type ParcelShop = components['schemas']['ParcelShop'];
                    export type PrivateAddress = components['schemas']['PrivateAddress'];
                    export type DeliveryType = components['schemas']['DeliveryType'];
                    export type DeliveryMethodTagType = components['schemas']['DeliveryMethodTagType'];
                    export type DeliveryMethodTag = components['schemas']['DeliveryMethodTag'];
                    export type DeliveryMethodTagMaybe = components['schemas']['DeliveryMethodTagMaybe'];
                    export type DeliveryPrice = components['schemas']['DeliveryPrice'];
                    export type ParcelShopPrice = components['schemas']['ParcelShopPrice'];
                    export type SubscriptionDeliveryPrice = components['schemas']['SubscriptionDeliveryPrice'];
                    export type UpsaleDeliveryMethod = components['schemas']['UpsaleDeliveryMethod'];
                    export type DeliveryMethod = components['schemas']['DeliveryMethod'];
                    export type SelectedDeliveryMethod = components['schemas']['SelectedDeliveryMethod'];
                    export type DeliveryMethodCategory = components['schemas']['DeliveryMethodCategory'];
                    export type FixedDeliveryMessage = components['schemas']['FixedDeliveryMessage'];
                    export type FreeDeliveryWithClubMatasMessage = components['schemas']['FreeDeliveryWithClubMatasMessage'];
                    export type SubscriptionsMessage = components['schemas']['SubscriptionsMessage'];
                    export type DeliveryGroup = components['schemas']['DeliveryGroup'];
                    export type DeliveryGroupsModel = components['schemas']['DeliveryGroupsModel'];
                    export type InvoiceAddressPostModel = components['schemas']['InvoiceAddressPostModel'];
                    export type AddressSuggestion = components['schemas']['AddressSuggestion'];
                    export type AddressSuggestionMaybe = components['schemas']['AddressSuggestionMaybe'];
                    export type UpdateInvoiceResponse = components['schemas']['UpdateInvoiceResponse'];
                    export type UpdateAddressResponseMessage = components['schemas']['UpdateAddressResponseMessage'];
                    export type UpdatePrivateAddressResponse = components['schemas']['UpdatePrivateAddressResponse'];
                    export type PrivateAddressPostModel = components['schemas']['PrivateAddressPostModel'];
                    export type CompanyAddressPostModel = components['schemas']['CompanyAddressPostModel'];
                    export type UpdateCompanyAddressResponse = components['schemas']['UpdateCompanyAddressResponse'];
                    export type UpdateParcelShopResponse = components['schemas']['UpdateParcelShopResponse'];
                    export type SelectParcelShopPostModel = components['schemas']['SelectParcelShopPostModel'];
                    export type JsonLocation = components['schemas']['JsonLocation'];
                    export type ParcelShopSearchOrigin = components['schemas']['ParcelShopSearchOrigin'];
                    export type AddressCleansingSuggestion = components['schemas']['AddressCleansingSuggestion'];
                    export type AddressCleansingSuggestionMaybe = components['schemas']['AddressCleansingSuggestionMaybe'];
                    export type ParcelShopSearchData = components['schemas']['ParcelShopSearchData'];
                    export type OpeningHourTexts = components['schemas']['OpeningHourTexts'];
                    export type ParcelShopRenderType = components['schemas']['ParcelShopRenderType'];
                    export type DeliveryParcelShopOption = components['schemas']['DeliveryParcelShopOption'];
                    export type ParcelShopSearchResponseModel = components['schemas']['ParcelShopSearchResponseModel'];
                    export type UpdateUpsaleResponse = components['schemas']['UpdateUpsaleResponse'];
                    export type AddressResponse = components['schemas']['AddressResponse'];
                    export type ProgressiveDiscountViewModel = components['schemas']['ProgressiveDiscountViewModel'];
                    export type ProgressiveDiscountStepViewModel = components['schemas']['ProgressiveDiscountStepViewModel'];
                    export type ProgressiveDiscountDetailsViewModel = components['schemas']['ProgressiveDiscountDetailsViewModel'];
                    export type ZipCodeWithSource = components['schemas']['ZipCodeWithSource'];
                    export type ExpectedDeliveryType = components['schemas']['ExpectedDeliveryType'];
                    export type ExpectedDeliverySingleMethodModel = components['schemas']['ExpectedDeliverySingleMethodModel'];
                    export type ExpectedDeliveryFullInformationModel = components['schemas']['ExpectedDeliveryFullInformationModel'];
                    export type SimpleDeliveryGroup = components['schemas']['SimpleDeliveryGroup'];
                    export type DropShipDisclaimer = components['schemas']['DropShipDisclaimer'];
                    export type DropShipDisclaimerMaybe = components['schemas']['DropShipDisclaimerMaybe'];
                    export type ExpectedDeliveryInformation = components['schemas']['ExpectedDeliveryInformation'];
                    export type MyFavoritesSortingType = components['schemas']['MyFavoritesSortingType'];
                    export type PageViewModel = components['schemas']['PageViewModel'];
                    export type PageViewModelMaybe = components['schemas']['PageViewModelMaybe'];
                    export type PagingViewModel = components['schemas']['PagingViewModel'];
                    export type MatasPlusCalculatorBannerSize = components['schemas']['MatasPlusCalculatorBannerSize'];
                    export type MatasPlusCalculatorBannerModelSavedModel = components['schemas']['MatasPlusCalculatorBannerModelSavedModel'];
                    export type MatasPlusCalculatorBannerModelSignupModel = components['schemas']['MatasPlusCalculatorBannerModelSignupModel'];
                    export type MatasPlusCalculatorBannerModelDetailsModel = components['schemas']['MatasPlusCalculatorBannerModelDetailsModel'];
                    export type MatasPlusCalculatorBannerModelDetailsToggleModel = components['schemas']['MatasPlusCalculatorBannerModelDetailsToggleModel'];
                    export type MatasPlusCalculatorBannerModelReadMoreModel = components['schemas']['MatasPlusCalculatorBannerModelReadMoreModel'];
                    export type MatasPlusCalculatorBannerModelReadMoreModelMaybe = components['schemas']['MatasPlusCalculatorBannerModelReadMoreModelMaybe'];
                    export type MatasPlusCalculatorBannerModel = components['schemas']['MatasPlusCalculatorBannerModel'];
                    export type MatasPlusSavingsValueType = components['schemas']['MatasPlusSavingsValueType'];
                    export type MatasPlusSavingsValueItem = components['schemas']['MatasPlusSavingsValueItem'];
                    export type MatasPlusSavingsBannerModel = components['schemas']['MatasPlusSavingsBannerModel'];
                    export type BannerModel = components['schemas']['BannerModel'];
                    export type ActiveBrandClubListModel = components['schemas']['ActiveBrandClubListModel'];
                    export type BrandClubListModel = components['schemas']['BrandClubListModel'];
                    export type FavoritesModel = components['schemas']['FavoritesModel'];
                    export type PointStatsModel = components['schemas']['PointStatsModel'];
                    export type MemberBenefitsModel = components['schemas']['MemberBenefitsModel'];
                    export type MatasPlusSavingsBannerModelMaybe = components['schemas']['MatasPlusSavingsBannerModelMaybe'];
                    export type HeaderModel = components['schemas']['HeaderModel'];
                    export type ImageModel = components['schemas']['ImageModel'];
                    export type MyProfileOrderPartModel = components['schemas']['MyProfileOrderPartModel'];
                    export type MyProfileOrderModel = components['schemas']['MyProfileOrderModel'];
                    export type FrontPageSeeAllLink = components['schemas']['FrontPageSeeAllLink'];
                    export type OrdersModel = components['schemas']['OrdersModel'];
                    export type PersonalOfferModel = components['schemas']['PersonalOfferModel'];
                    export type PersonalOffersModel = components['schemas']['PersonalOffersModel'];
                    export type ProductListModel = components['schemas']['ProductListModel'];
                    export type RecommendationsModel = components['schemas']['RecommendationsModel'];
                    export type ProductReviewRatingValueModel = components['schemas']['ProductReviewRatingValueModel'];
                    export type ProductReviewRatingSelectModel = components['schemas']['ProductReviewRatingSelectModel'];
                    export type ProductNameModel = components['schemas']['ProductNameModel'];
                    export type VideoPlatform = components['schemas']['VideoPlatform'];
                    export type ProductVideoType = components['schemas']['ProductVideoType'];
                    export type IProductVideo = components['schemas']['IProductVideo'];
                    export type ProductVideoModel = components['schemas']['ProductVideoModel'];
                    export type Product360Directory = components['schemas']['Product360Directory'];
                    export type ISupplier = components['schemas']['ISupplier'];
                    export type ISupplierGroup = components['schemas']['ISupplierGroup'];
                    export type IPrimaryProductGroupInfo = components['schemas']['IPrimaryProductGroupInfo'];
                    export type IPrimaryProductGroupInfoMaybe = components['schemas']['IPrimaryProductGroupInfoMaybe'];
                    export type ListDisplayType = components['schemas']['ListDisplayType'];
                    export type IBrandSimple = components['schemas']['IBrandSimple'];
                    export type DeliveryMinMax = components['schemas']['DeliveryMinMax'];
                    export type ISystemProductStock = components['schemas']['ISystemProductStock'];
                    export type SynonymGroup = components['schemas']['SynonymGroup'];
                    export type ISynonym = components['schemas']['ISynonym'];
                    export type ISerieSimple = components['schemas']['ISerieSimple'];
                    export type ISerieSimpleMaybe = components['schemas']['ISerieSimpleMaybe'];
                    export type IPageMetadata = components['schemas']['IPageMetadata'];
                    export type ProductNameRenderer = components['schemas']['ProductNameRenderer'];
                    export type ProductType = components['schemas']['ProductType'];
                    export type ProductBundleType = components['schemas']['ProductBundleType'];
                    export type DigitalProductType = components['schemas']['DigitalProductType'];
                    export type IDiscountInfo = components['schemas']['IDiscountInfo'];
                    export type ISubscriptionInfo = components['schemas']['ISubscriptionInfo'];
                    export type ISubscriptionInfoMaybe = components['schemas']['ISubscriptionInfoMaybe'];
                    export type IUnitInfo = components['schemas']['IUnitInfo'];
                    export type ReviewRating = components['schemas']['ReviewRating'];
                    export type IProductReviewRatingStatistic = components['schemas']['IProductReviewRatingStatistic'];
                    export type IProductReviewStatistics = components['schemas']['IProductReviewStatistics'];
                    export type IOtcInfo = components['schemas']['IOtcInfo'];
                    export type IProductVariant = components['schemas']['IProductVariant'];
                    export type ProductActions = components['schemas']['ProductActions'];
                    export type ProductShopStatus = components['schemas']['ProductShopStatus'];
                    export type GwpOfferType = components['schemas']['GwpOfferType'];
                    export type IGwpOffer = components['schemas']['IGwpOffer'];
                    export type IGwpOfferMaybe = components['schemas']['IGwpOfferMaybe'];
                    export type IAttributeString = components['schemas']['IAttributeString'];
                    export type Dimensions = components['schemas']['Dimensions'];
                    export type IProductPhysicalProperties = components['schemas']['IProductPhysicalProperties'];
                    export type HazardSymbolTypes = components['schemas']['HazardSymbolTypes'];
                    export type SampleType = components['schemas']['SampleType'];
                    export type ISampleInfo = components['schemas']['ISampleInfo'];
                    export type ISampleInfoMaybe = components['schemas']['ISampleInfoMaybe'];
                    export type ProductHighlightType = components['schemas']['ProductHighlightType'];
                    export type IProductHighlight = components['schemas']['IProductHighlight'];
                    export type IProductKeyIngredient = components['schemas']['IProductKeyIngredient'];
                    export type IProductTip = components['schemas']['IProductTip'];
                    export type IProductTipMaybe = components['schemas']['IProductTipMaybe'];
                    export type ProductFragranceType = components['schemas']['ProductFragranceType'];
                    export type IProductFragrance = components['schemas']['IProductFragrance'];
                    export type ProductDoseType = components['schemas']['ProductDoseType'];
                    export type QuantityInterval = components['schemas']['QuantityInterval'];
                    export type IProductDose = components['schemas']['IProductDose'];
                    export type ProductMicronutrientType = components['schemas']['ProductMicronutrientType'];
                    export type IProductMicronutrient = components['schemas']['IProductMicronutrient'];
                    export type IProductNutrient = components['schemas']['IProductNutrient'];
                    export type IProductNutrientMaybe = components['schemas']['IProductNutrientMaybe'];
                    export type BlacklistFlags = components['schemas']['BlacklistFlags'];
                    export type MediaType = components['schemas']['MediaType'];
                    export type IProductCustomMediaContent = components['schemas']['IProductCustomMediaContent'];
                    export type IProductMakeupInfo = components['schemas']['IProductMakeupInfo'];
                    export type IProductDetailed = components['schemas']['IProductDetailed'];
                    export type ReviewRequestModel = components['schemas']['ReviewRequestModel'];
                    export type ReviewRequestsModel = components['schemas']['ReviewRequestsModel'];
                    export type SubscriptionsModel = components['schemas']['SubscriptionsModel'];
                    export type MatasPlusUnboxingBoxType = components['schemas']['MatasPlusUnboxingBoxType'];
                    export type ActiveTimeSpan = components['schemas']['ActiveTimeSpan'];
                    export type MatasPlusUnboxingOfferModel = components['schemas']['MatasPlusUnboxingOfferModel'];
                    export type UnboxingOffersModel = components['schemas']['UnboxingOffersModel'];
                    export type MatasPlusNudgeMessage = components['schemas']['MatasPlusNudgeMessage'];
                    export type FrontPageSeeAllLinkMaybe = components['schemas']['FrontPageSeeAllLinkMaybe'];
                    export type FrontPageSectionIcon = components['schemas']['FrontPageSectionIcon'];
                    export type FrontPageSectionHeader = components['schemas']['FrontPageSectionHeader'];
                    export type FrontPageSectionHeaderMaybe = components['schemas']['FrontPageSectionHeaderMaybe'];
                    export type FrontPageSection = components['schemas']['FrontPageSection'];
                    export type FrontPageSectionMaybe = components['schemas']['FrontPageSectionMaybe'];
                    export type ResponsiveImageLinkModelMaybe = components['schemas']['ResponsiveImageLinkModelMaybe'];
                    export type ProductGroupModel = components['schemas']['ProductGroupModel'];
                    export type MyFavoritesSortingModel = components['schemas']['MyFavoritesSortingModel'];
                    export type FavoriteViewModel = components['schemas']['FavoriteViewModel'];
                    export type FindationBrandListItem = components['schemas']['FindationBrandListItem'];
                    export type FindationBrandListModel = components['schemas']['FindationBrandListModel'];
                    export type FindationProductListItem = components['schemas']['FindationProductListItem'];
                    export type FindationProductListModel = components['schemas']['FindationProductListModel'];
                    export type FindationShadeListItem = components['schemas']['FindationShadeListItem'];
                    export type FindationShadeListModel = components['schemas']['FindationShadeListModel'];
                    export type FindationSearchRequest = components['schemas']['FindationSearchRequest'];
                    export type ProductFindationSearchResultViewModel = components['schemas']['ProductFindationSearchResultViewModel'];
                    export type GiftCardStatus = components['schemas']['GiftCardStatus'];
                    export type GiftCard = components['schemas']['GiftCard'];
                    export type GiftCardTransactionType = components['schemas']['GiftCardTransactionType'];
                    export type GiftCardTransaction = components['schemas']['GiftCardTransaction'];
                    export type GiftCardTransactionModel = components['schemas']['GiftCardTransactionModel'];
                    export type GiftCardModel = components['schemas']['GiftCardModel'];
                    export type GiftCardBalancePlaceholderDTO = components['schemas']['GiftCardBalancePlaceholderDTO'];
                    export type MailCampaigns = components['schemas']['MailCampaigns'];
                    export type LandingPageViewModel = components['schemas']['LandingPageViewModel'];
                    export type PosAction = components['schemas']['PosAction'];
                    export type PosClubMatasMember = components['schemas']['PosClubMatasMember'];
                    export type RegisterReceiptRequest = components['schemas']['RegisterReceiptRequest'];
                    export type ReceiptHeader = components['schemas']['ReceiptHeader'];
                    export type ReceiptClubMatas = components['schemas']['ReceiptClubMatas'];
                    export type ReceiptExchangeSticker = components['schemas']['ReceiptExchangeSticker'];
                    export type ReceiptDiscount = components['schemas']['ReceiptDiscount'];
                    export type ReceiptLine = components['schemas']['ReceiptLine'];
                    export type ReceiptPayment = components['schemas']['ReceiptPayment'];
                    export type Receipt = components['schemas']['Receipt'];
                    export type ReceiptContainer = components['schemas']['ReceiptContainer'];
                    export type Vat = components['schemas']['Vat'];
                    export type BasketItem = components['schemas']['BasketItem'];
                    export type Details = components['schemas']['Details'];
                    export type UnclaimedGifts = components['schemas']['UnclaimedGifts'];
                    export type Summary = components['schemas']['Summary'];
                    export type FiftyTwoBasket = components['schemas']['FiftyTwoBasket'];
                    export type InitializeBasketRequest = components['schemas']['InitializeBasketRequest'];
                    export type RegisterBasketResponse = components['schemas']['RegisterBasketResponse'];
                    export type LiveEventFilterType = components['schemas']['LiveEventFilterType'];
                    export type ILiveEventFilterViewModel = components['schemas']['ILiveEventFilterViewModel'];
                    export type QueryStringParameter = components['schemas']['QueryStringParameter'];
                    export type LiveEventFilterViewModel = components['schemas']['LiveEventFilterViewModel'];
                    export type LiveEventBlockViewModel = components['schemas']['LiveEventBlockViewModel'];
                    export type SubscribeToNotificationsBlockViewModel = components['schemas']['SubscribeToNotificationsBlockViewModel'];
                    export type LiveEventSliderViewModel = components['schemas']['LiveEventSliderViewModel'];
                    export type LiveEventSliderViewModelMaybe = components['schemas']['LiveEventSliderViewModelMaybe'];
                    export type LiveEventFilteredResultViewModel = components['schemas']['LiveEventFilteredResultViewModel'];
                    export type LiveEventFilteredResultViewModelMaybe = components['schemas']['LiveEventFilteredResultViewModelMaybe'];
                    export type SortDirection = components['schemas']['SortDirection'];
                    export type LiveEventListUnsubscribeViewModel = components['schemas']['LiveEventListUnsubscribeViewModel'];
                    export type LiveEventListUnsubscribeViewModelMaybe = components['schemas']['LiveEventListUnsubscribeViewModelMaybe'];
                    export type LiveEventListViewModel = components['schemas']['LiveEventListViewModel'];
                    export type InspirationMatasLivePageViewModel = components['schemas']['InspirationMatasLivePageViewModel'];
                    export type Address = components['schemas']['Address'];
                    export type CustomerOrganization_entity_type = components['schemas']['CustomerOrganization_entity_type'];
                    export type Customer = components['schemas']['Customer'];
                    export type Merchant_urls = components['schemas']['Merchant_urls'];
                    export type Product_identifiers = components['schemas']['Product_identifiers'];
                    export type SubscriptionInterval = components['schemas']['SubscriptionInterval'];
                    export type Subscription = components['schemas']['Subscription'];
                    export type Order_line = components['schemas']['Order_line'];
                    export type Asset_urls = components['schemas']['Asset_urls'];
                    export type Payment_method_category = components['schemas']['Payment_method_category'];
                    export type Create_order_requestStatus = components['schemas']['Create_order_requestStatus'];
                    export type Create_order_request = components['schemas']['Create_order_request'];
                    export type MyFavoritesListType = components['schemas']['MyFavoritesListType'];
                    export type MyFavoritesListViewModel = components['schemas']['MyFavoritesListViewModel'];
                    export type CountDownBannerType = components['schemas']['CountDownBannerType'];
                    export type CountDownBannerModel = components['schemas']['CountDownBannerModel'];
                    export type LiveEventNotificationDeliveryType = components['schemas']['LiveEventNotificationDeliveryType'];
                    export type NotificationDeliveryTypeViewModel = components['schemas']['NotificationDeliveryTypeViewModel'];
                    export type LiveEventNotificationViewModel = components['schemas']['LiveEventNotificationViewModel'];
                    export type LiveEventNotificationViewModelMaybe = components['schemas']['LiveEventNotificationViewModelMaybe'];
                    export type LiveEventSubscriptionViewModel = components['schemas']['LiveEventSubscriptionViewModel'];
                    export type LiveEventSubscriberViewModel = components['schemas']['LiveEventSubscriberViewModel'];
                    export type LiveEventSubscriberOverviewViewModel = components['schemas']['LiveEventSubscriberOverviewViewModel'];
                    export type AddLiveEventNotificationPostModel = components['schemas']['AddLiveEventNotificationPostModel'];
                    export type LogLevelEnum = components['schemas']['LogLevelEnum'];
                    export type LogEventRequest = components['schemas']['LogEventRequest'];
                    export type LoginLevel = components['schemas']['LoginLevel'];
                    export type LoginInfoIcon = components['schemas']['LoginInfoIcon'];
                    export type LoginInfoHeaderModel = components['schemas']['LoginInfoHeaderModel'];
                    export type LoginInfoModel = components['schemas']['LoginInfoModel'];
                    export type CountdownViewModel = components['schemas']['CountdownViewModel'];
                    export type MatasPlusRecommendationViewModel = components['schemas']['MatasPlusRecommendationViewModel'];
                    export type MatasPlusSubscriptionType = components['schemas']['MatasPlusSubscriptionType'];
                    export type CancelViewModel = components['schemas']['CancelViewModel'];
                    export type MatasPlusAdvantageType = components['schemas']['MatasPlusAdvantageType'];
                    export type MatasPlusAdvantage = components['schemas']['MatasPlusAdvantage'];
                    export type MatasPlusInfoLineModel = components['schemas']['MatasPlusInfoLineModel'];
                    export type MatasPlusSubscriptionInfoModelSubscriptionTypeInfoModel = components['schemas']['MatasPlusSubscriptionInfoModelSubscriptionTypeInfoModel'];
                    export type MatasPlusSubscriptionInfoModel = components['schemas']['MatasPlusSubscriptionInfoModel'];
                    export type MatasPlusPageModelSubscriptionInfo = components['schemas']['MatasPlusPageModelSubscriptionInfo'];
                    export type ConfirmModal = components['schemas']['ConfirmModal'];
                    export type MatasPlusCancelSubscriptionInfo = components['schemas']['MatasPlusCancelSubscriptionInfo'];
                    export type PaymentCardOptionModelMaybe = components['schemas']['PaymentCardOptionModelMaybe'];
                    export type MatasPlusCreditCardInfoModel = components['schemas']['MatasPlusCreditCardInfoModel'];
                    export type MatasPlusPageModelCreditCardInfo = components['schemas']['MatasPlusPageModelCreditCardInfo'];
                    export type MatasPlusNudgeMessageMaybe = components['schemas']['MatasPlusNudgeMessageMaybe'];
                    export type MatasPlusChangeSubscriptionTypeModal = components['schemas']['MatasPlusChangeSubscriptionTypeModal'];
                    export type MatasPlusChangeSubscriptionTypeModalMaybe = components['schemas']['MatasPlusChangeSubscriptionTypeModalMaybe'];
                    export type MatasPlusPageModel = components['schemas']['MatasPlusPageModel'];
                    export type MatasPlusPendingConfirmCancellationViewModel = components['schemas']['MatasPlusPendingConfirmCancellationViewModel'];
                    export type MatasPlusSavingsBannerMode = components['schemas']['MatasPlusSavingsBannerMode'];
                    export type MatasPlusSavingsMonth = components['schemas']['MatasPlusSavingsMonth'];
                    export type MatasPlusSavingsDetails = components['schemas']['MatasPlusSavingsDetails'];
                    export type MatasPlusPointSavingsBannerModel = components['schemas']['MatasPlusPointSavingsBannerModel'];
                    export type MatasPlusSavingsDisclaimerModel = components['schemas']['MatasPlusSavingsDisclaimerModel'];
                    export type MemberLevelModel = components['schemas']['MemberLevelModel'];
                    export type MemberLevelInfoLine = components['schemas']['MemberLevelInfoLine'];
                    export type MemberLevelInfoModel = components['schemas']['MemberLevelInfoModel'];
                    export type MenuItemJsonModel = components['schemas']['MenuItemJsonModel'];
                    export type MenuStyling = components['schemas']['MenuStyling'];
                    export type MenuSectionHeadlineStyling = components['schemas']['MenuSectionHeadlineStyling'];
                    export type MenuSectionStyling = components['schemas']['MenuSectionStyling'];
                    export type MenuItemStyling = components['schemas']['MenuItemStyling'];
                    export type FrontendCustomMenuAction = components['schemas']['FrontendCustomMenuAction'];
                    export type MenuItemCustomActionViewModel = components['schemas']['MenuItemCustomActionViewModel'];
                    export type MenuItemCustomActionWithUrlViewModel = components['schemas']['MenuItemCustomActionWithUrlViewModel'];
                    export type MenuItemLinkViewModel = components['schemas']['MenuItemLinkViewModel'];
                    export type MenuItemLoginOrMyProfileViewModel = components['schemas']['MenuItemLoginOrMyProfileViewModel'];
                    export type MyProfileMenuItemSubTextViewModel = components['schemas']['MyProfileMenuItemSubTextViewModel'];
                    export type MyProfileMenuItemSubTextViewModelMaybe = components['schemas']['MyProfileMenuItemSubTextViewModelMaybe'];
                    export type MenuItemMyProfileViewModel = components['schemas']['MenuItemMyProfileViewModel'];
                    export type MenuItemSubMenuViewModel = components['schemas']['MenuItemSubMenuViewModel'];
                    export type MenuItemViewModel = components['schemas']['MenuItemViewModel'];
                    export type MenuSectionViewModel = components['schemas']['MenuSectionViewModel'];
                    export type MenuHeaderViewModel = components['schemas']['MenuHeaderViewModel'];
                    export type MenuViewModel = components['schemas']['MenuViewModel'];
                    export type MenuDisplayViewEnum = components['schemas']['MenuDisplayViewEnum'];
                    export type MiniBasketLineViewModel = components['schemas']['MiniBasketLineViewModel'];
                    export type MiniBasketGiftLineViewModel = components['schemas']['MiniBasketGiftLineViewModel'];
                    export type MiniBasketSampleLineViewModel = components['schemas']['MiniBasketSampleLineViewModel'];
                    export type MiniBasketSummaryViewModel = components['schemas']['MiniBasketSummaryViewModel'];
                    export type BasketMessageWrapper = components['schemas']['BasketMessageWrapper'];
                    export type BasketMessageWrapperMaybe = components['schemas']['BasketMessageWrapperMaybe'];
                    export type MiniBasketViewModel = components['schemas']['MiniBasketViewModel'];
                    export type AccordionThemeType = components['schemas']['AccordionThemeType'];
                    export type AccordionItemDTO = components['schemas']['AccordionItemDTO'];
                    export type AccordionDTO = components['schemas']['AccordionDTO'];
                    export type HighlightedCampaignBlockColorTheme = components['schemas']['HighlightedCampaignBlockColorTheme'];
                    export type HighlightedCampaignDTO = components['schemas']['HighlightedCampaignDTO'];
                    export type HighlightedCampaignBlockDTO = components['schemas']['HighlightedCampaignBlockDTO'];
                    export type MatasPlusUnboxingTitle = components['schemas']['MatasPlusUnboxingTitle'];
                    export type MatasPlusUnboxingOffer = components['schemas']['MatasPlusUnboxingOffer'];
                    export type MatasPlusUnboxing = components['schemas']['MatasPlusUnboxing'];
                    export type MatasPlusFaq = components['schemas']['MatasPlusFaq'];
                    export type MatasPlusFaqModel = components['schemas']['MatasPlusFaqModel'];
                    export type MatasPlusFaqBlockDTO = components['schemas']['MatasPlusFaqBlockDTO'];
                    export type GwpDiscountModel = components['schemas']['GwpDiscountModel'];
                    export type GwpDiscountsBlock = components['schemas']['GwpDiscountsBlock'];
                    export type TextContentViewModel = components['schemas']['TextContentViewModel'];
                    export type SvgSprite = components['schemas']['SvgSprite'];
                    export type AdvisorExpertiseViewModel = components['schemas']['AdvisorExpertiseViewModel'];
                    export type AdvisorStoreDetailsViewModel = components['schemas']['AdvisorStoreDetailsViewModel'];
                    export type AdvisorStoreDetailsViewModelMaybe = components['schemas']['AdvisorStoreDetailsViewModelMaybe'];
                    export type AdvisorBlockViewModel = components['schemas']['AdvisorBlockViewModel'];
                    export type AdvisorDTO = components['schemas']['AdvisorDTO'];
                    export type SimpleFocusbannerDTO = components['schemas']['SimpleFocusbannerDTO'];
                    export type SimpleFocusbannerDTOMaybe = components['schemas']['SimpleFocusbannerDTOMaybe'];
                    export type AdvisorListBlockDTO = components['schemas']['AdvisorListBlockDTO'];
                    export type FooterUspViewModel = components['schemas']['FooterUspViewModel'];
                    export type FooterMenuItem = components['schemas']['FooterMenuItem'];
                    export type FooterMenuViewModel = components['schemas']['FooterMenuViewModel'];
                    export type FooterContactViewModel = components['schemas']['FooterContactViewModel'];
                    export type OpeningHourViewModel = components['schemas']['OpeningHourViewModel'];
                    export type EmaLogoViewModel = components['schemas']['EmaLogoViewModel'];
                    export type EmaLogoViewModelMaybe = components['schemas']['EmaLogoViewModelMaybe'];
                    export type SmileyLogoViewModel = components['schemas']['SmileyLogoViewModel'];
                    export type SmileyLogoViewModelMaybe = components['schemas']['SmileyLogoViewModelMaybe'];
                    export type FooterBottomViewModel = components['schemas']['FooterBottomViewModel'];
                    export type FooterViewModel = components['schemas']['FooterViewModel'];
                    export type StepLayoutFooterViewModel = components['schemas']['StepLayoutFooterViewModel'];
                    export type FavoriteIconUrlViewModel = components['schemas']['FavoriteIconUrlViewModel'];
                    export type GenderType = components['schemas']['GenderType'];
                    export type MatasPlusStatus = components['schemas']['MatasPlusStatus'];
                    export type MatasPlusMemberInfo = components['schemas']['MatasPlusMemberInfo'];
                    export type VoyadoMemberLevel = components['schemas']['VoyadoMemberLevel'];
                    export type VoyadoMemberLevelInfo = components['schemas']['VoyadoMemberLevelInfo'];
                    export type ClubMatasMember = components['schemas']['ClubMatasMember'];
                    export type LoginDirectRequest = components['schemas']['LoginDirectRequest'];
                    export type MyProfileViewModel = components['schemas']['MyProfileViewModel'];
                    export type MenuTooltipsViewModel = components['schemas']['MenuTooltipsViewModel'];
                    export type SessionMenuViewModel = components['schemas']['SessionMenuViewModel'];
                    export type PrimaryHeaderViewModel = components['schemas']['PrimaryHeaderViewModel'];
                    export type ProductCustomerNotificationCreateModel = components['schemas']['ProductCustomerNotificationCreateModel'];
                    export type ProductReviewHeaderModel = components['schemas']['ProductReviewHeaderModel'];
                    export type ProductSliderStyling = components['schemas']['ProductSliderStyling'];
                    export type ProductSliderBlockDTO = components['schemas']['ProductSliderBlockDTO'];
                    export type ProductReviewSource = components['schemas']['ProductReviewSource'];
                    export type ProductReviewCreateViewModel = components['schemas']['ProductReviewCreateViewModel'];
                    export type BasketShippingSupplierOption = components['schemas']['BasketShippingSupplierOption'];
                    export type FooterLinkElementViewModel = components['schemas']['FooterLinkElementViewModel'];
                    export type FeaturedFooterViewModel = components['schemas']['FeaturedFooterViewModel'];
                    export type InPageNotificationViewModel = components['schemas']['InPageNotificationViewModel'];
                    export type DisclaimerButtonLocation = components['schemas']['DisclaimerButtonLocation'];
                    export type StripeDTO = components['schemas']['StripeDTO'];
                    export type StripeDTOMaybe = components['schemas']['StripeDTOMaybe'];
                    export type ImageWithDisclaimerPlaceholderModel = components['schemas']['ImageWithDisclaimerPlaceholderModel'];
                    export type PlaceholderLinkType = components['schemas']['PlaceholderLinkType'];
                    export type LinkKey = components['schemas']['LinkKey'];
                    export type LinkElementModel = components['schemas']['LinkElementModel'];
                    export type LinkElementModelMaybe = components['schemas']['LinkElementModelMaybe'];
                    export type GlobalUspItemModel = components['schemas']['GlobalUspItemModel'];
                    export type GlobalUspViewModel = components['schemas']['GlobalUspViewModel'];
                    export type ProductCardSizeType = components['schemas']['ProductCardSizeType'];
                    export type ProductRecommendationModel = components['schemas']['ProductRecommendationModel'];
                    export type ProductRecommendationListModel = components['schemas']['ProductRecommendationListModel'];
                    export type ProductRecommendationListModelMaybe = components['schemas']['ProductRecommendationListModelMaybe'];
                    export type ProductListBonusGiftHeaderModel = components['schemas']['ProductListBonusGiftHeaderModel'];
                    export type ProductListBrandHeaderModel = components['schemas']['ProductListBrandHeaderModel'];
                    export type ExtendedPlaceholderHeaderModel = components['schemas']['ExtendedPlaceholderHeaderModel'];
                    export type ExtendedPlaceholderHeaderModelMaybe = components['schemas']['ExtendedPlaceholderHeaderModelMaybe'];
                    export type ExtendedPlaceholderContentItemModel = components['schemas']['ExtendedPlaceholderContentItemModel'];
                    export type ExtendedPlaceholderContentModel = components['schemas']['ExtendedPlaceholderContentModel'];
                    export type ExtendedPlaceholderContentModelMaybe = components['schemas']['ExtendedPlaceholderContentModelMaybe'];
                    export type ExtendedPlaceholderShopByItemModel = components['schemas']['ExtendedPlaceholderShopByItemModel'];
                    export type ExtendedPlaceholderShopByModel = components['schemas']['ExtendedPlaceholderShopByModel'];
                    export type ExtendedPlaceholderShopByModelMaybe = components['schemas']['ExtendedPlaceholderShopByModelMaybe'];
                    export type ExtendedPlaceholderProductsModel = components['schemas']['ExtendedPlaceholderProductsModel'];
                    export type ExtendedPlaceholderProductsModelMaybe = components['schemas']['ExtendedPlaceholderProductsModelMaybe'];
                    export type InventoryLaneModel = components['schemas']['InventoryLaneModel'];
                    export type InventoryLaneModelMaybe = components['schemas']['InventoryLaneModelMaybe'];
                    export type ExtendedPlaceholderInventoryModel = components['schemas']['ExtendedPlaceholderInventoryModel'];
                    export type ExtendedPlaceholderInventoryModelMaybe = components['schemas']['ExtendedPlaceholderInventoryModelMaybe'];
                    export type ExtendedPlaceholderModel = components['schemas']['ExtendedPlaceholderModel'];
                    export type ProductListOfferHeaderModel = components['schemas']['ProductListOfferHeaderModel'];
                    export type ProductListRebuyHeaderModel = components['schemas']['ProductListRebuyHeaderModel'];
                    export type ProductListTextHeaderModel = components['schemas']['ProductListTextHeaderModel'];
                    export type IProductListHeaderMaybe = components['schemas']['IProductListHeaderMaybe'];
                    export type NoRebuyAvailableModel = components['schemas']['NoRebuyAvailableModel'];
                    export type MultiBuyProduct = components['schemas']['MultiBuyProduct'];
                    export type MiniSplash = components['schemas']['MiniSplash'];
                    export type MultiBuyModel = components['schemas']['MultiBuyModel'];
                    export type StripedVerticalSpacerModel = components['schemas']['StripedVerticalSpacerModel'];
                    export type UserMessageType = components['schemas']['UserMessageType'];
                    export type UserMessage = components['schemas']['UserMessage'];
                    export type UserMessageMaybe = components['schemas']['UserMessageMaybe'];
                    export type SystemSplitTestKey = components['schemas']['SystemSplitTestKey'];
                    export type JavascriptSplitTestAssignmentViewModel = components['schemas']['JavascriptSplitTestAssignmentViewModel'];
                    export type UserContextViewModel = components['schemas']['UserContextViewModel'];
                    export type AppOperatingSystem = components['schemas']['AppOperatingSystem'];
                    export type ClubMatasLoginStatus = components['schemas']['ClubMatasLoginStatus'];
                    export type IPosRequest = components['schemas']['IPosRequest'];
                    export type IPosRequestMaybe = components['schemas']['IPosRequestMaybe'];
                    export type CookieCategory = components['schemas']['CookieCategory'];
                    export type RequestContextViewModel = components['schemas']['RequestContextViewModel'];
                    export type JavascriptFeatureToggles = components['schemas']['JavascriptFeatureToggles'];
                    export type JavascriptWebsiteSettingsLiveChat = components['schemas']['JavascriptWebsiteSettingsLiveChat'];
                    export type JavascriptWebsiteSettings = components['schemas']['JavascriptWebsiteSettings'];
                    export type PageType = components['schemas']['PageType'];
                    export type IJavascriptPageContext = components['schemas']['IJavascriptPageContext'];
                    export type TenantType = components['schemas']['TenantType'];
                    export type JavascriptSegmentation = components['schemas']['JavascriptSegmentation'];
                    export type JavascriptWebsiteTime = components['schemas']['JavascriptWebsiteTime'];
                    export type JavascriptRequestDataModel = components['schemas']['JavascriptRequestDataModel'];
                    export type BreadcrumbItemViewModel = components['schemas']['BreadcrumbItemViewModel'];
                    export type BreadcrumbViewModel = components['schemas']['BreadcrumbViewModel'];
                    export type CmsButtonVariant = components['schemas']['CmsButtonVariant'];
                    export type AdformButtonModel = components['schemas']['AdformButtonModel'];
                    export type LinkButtonModel = components['schemas']['LinkButtonModel'];
                    export type SignupButtonModel = components['schemas']['SignupButtonModel'];
                    export type LoginButtonModel = components['schemas']['LoginButtonModel'];
                    export type NoButtonModel = components['schemas']['NoButtonModel'];
                    export type CustomButtonModel = components['schemas']['CustomButtonModel'];
                    export type CmsButtonSize = components['schemas']['CmsButtonSize'];
                    export type CmsButtonAlignment = components['schemas']['CmsButtonAlignment'];
                    export type CmsButtonGrid = components['schemas']['CmsButtonGrid'];
                    export type WriteReviewButton = components['schemas']['WriteReviewButton'];
                    export type ProductPrelaunchBannerModel = components['schemas']['ProductPrelaunchBannerModel'];
                    export type LiveEventInfo = components['schemas']['LiveEventInfo'];
                    export type DateTimeTranslations = components['schemas']['DateTimeTranslations'];
                    export type BambuserViewModel = components['schemas']['BambuserViewModel'];
                    export type VimeoVideoFileModel = components['schemas']['VimeoVideoFileModel'];
                    export type VideoViewPresentation = components['schemas']['VideoViewPresentation'];
                    export type VimeoBlockDTO = components['schemas']['VimeoBlockDTO'];
                    export type HorizontalAlignment = components['schemas']['HorizontalAlignment'];
                    export type FloatBehaviorCmsModel = components['schemas']['FloatBehaviorCmsModel'];
                    export type YouTubeBlockDTO = components['schemas']['YouTubeBlockDTO'];
                    export type ArticleTagModel = components['schemas']['ArticleTagModel'];
                    export type ArticleType = components['schemas']['ArticleType'];
                    export type ArticleTypeModel = components['schemas']['ArticleTypeModel'];
                    export type IBrandSimpleMaybe = components['schemas']['IBrandSimpleMaybe'];
                    export type ArticleSectionType = components['schemas']['ArticleSectionType'];
                    export type ArticleTagSimple = components['schemas']['ArticleTagSimple'];
                    export type ArticleSection = components['schemas']['ArticleSection'];
                    export type InspirationArticleLinkModel = components['schemas']['InspirationArticleLinkModel'];
                    export type InspirationArticleLeftHandBlockDTO = components['schemas']['InspirationArticleLeftHandBlockDTO'];
                    export type ShippingType = components['schemas']['ShippingType'];
                    export type ShippingDeliveryType = components['schemas']['ShippingDeliveryType'];
                    export type ShippingManagementSystem = components['schemas']['ShippingManagementSystem'];
                    export type IShippingBasketConstraints = components['schemas']['IShippingBasketConstraints'];
                    export type ShippingProductPrice = components['schemas']['ShippingProductPrice'];
                    export type ShippingPriceIntervalConstraints = components['schemas']['ShippingPriceIntervalConstraints'];
                    export type ShippingPriceInterval = components['schemas']['ShippingPriceInterval'];
                    export type Time = components['schemas']['Time'];
                    export type TimeInterval = components['schemas']['TimeInterval'];
                    export type IClosedIntervalOfZipCode = components['schemas']['IClosedIntervalOfZipCode'];
                    export type ZipCodeIntervalCollection = components['schemas']['ZipCodeIntervalCollection'];
                    export type IShippingZipCodeConstraints = components['schemas']['IShippingZipCodeConstraints'];
                    export type ShippingDailyLimits = components['schemas']['ShippingDailyLimits'];
                    export type ShippingActualLimit = components['schemas']['ShippingActualLimit'];
                    export type ShippingActualLimitMaybe = components['schemas']['ShippingActualLimitMaybe'];
                    export type ISingleShipping = components['schemas']['ISingleShipping'];
                    export type ShippingPriceIntervalMaybe = components['schemas']['ShippingPriceIntervalMaybe'];
                    export type IDeliveryInfo = components['schemas']['IDeliveryInfo'];
                    export type IDeliveryInfoMaybe = components['schemas']['IDeliveryInfoMaybe'];
                    export type ShippingPickupDay = components['schemas']['ShippingPickupDay'];
                    export type ShippingPickupWeek = components['schemas']['ShippingPickupWeek'];
                    export type ShippingPickup = components['schemas']['ShippingPickup'];
                    export type ShippingPickupChange = components['schemas']['ShippingPickupChange'];
                    export type ShippingPickupAdditionalPackagingBuffer = components['schemas']['ShippingPickupAdditionalPackagingBuffer'];
                    export type ExpectedPickupInfo = components['schemas']['ExpectedPickupInfo'];
                    export type ExpectedPickupInfoMaybe = components['schemas']['ExpectedPickupInfoMaybe'];
                    export type SubscriptionScheduleDates = components['schemas']['SubscriptionScheduleDates'];
                    export type ShippingSubscriptionInfo = components['schemas']['ShippingSubscriptionInfo'];
                    export type FixedFreeShippingReason = components['schemas']['FixedFreeShippingReason'];
                    export type DeliveryTime = components['schemas']['DeliveryTime'];
                    export type InstaboxSupplierResponse = components['schemas']['InstaboxSupplierResponse'];
                    export type InstaboxSupplierResponseMaybe = components['schemas']['InstaboxSupplierResponseMaybe'];
                    export type ShippingDetailed = components['schemas']['ShippingDetailed'];
                    export type ShippingDetailedMaybe = components['schemas']['ShippingDetailedMaybe'];
                    export type FreeShippingInfo = components['schemas']['FreeShippingInfo'];
                    export type BasketShippingSummary = components['schemas']['BasketShippingSummary'];
                    export type ShippingSummaryModel = components['schemas']['ShippingSummaryModel'];
                    export type HyperlinkTarget = components['schemas']['HyperlinkTarget'];
                    export type InspirationAuthorInfoDTO = components['schemas']['InspirationAuthorInfoDTO'];
                    export type BrandDetailedModel = components['schemas']['BrandDetailedModel'];
                    export type BrandDetailedModelMaybe = components['schemas']['BrandDetailedModelMaybe'];
                    export type InspirationArticleRightHandBlockDTO = components['schemas']['InspirationArticleRightHandBlockDTO'];
                    export type VerticalSpacerContentBlockValueCmsModel = components['schemas']['VerticalSpacerContentBlockValueCmsModel'];
                    export type HorizontalSpaceBlockDTO = components['schemas']['HorizontalSpaceBlockDTO'];
                    export type AdvisorBlockDTO = components['schemas']['AdvisorBlockDTO'];
                    export type ImageBannerButtonModel = components['schemas']['ImageBannerButtonModel'];
                    export type ImageBannerButtonModelMaybe = components['schemas']['ImageBannerButtonModelMaybe'];
                    export type ImageBannerElementModel = components['schemas']['ImageBannerElementModel'];
                    export type ImageBannerModel = components['schemas']['ImageBannerModel'];
                    export type AlignmentType = components['schemas']['AlignmentType'];
                    export type FollowBrandClubBlockDTO = components['schemas']['FollowBrandClubBlockDTO'];
                    export type QuestionnaireState = components['schemas']['QuestionnaireState'];
                    export type QuestionnaireBrand = components['schemas']['QuestionnaireBrand'];
                    export type QuestionnaireContentBlockDTO = components['schemas']['QuestionnaireContentBlockDTO'];
                    export type MyProfileSettingPageViewModel = components['schemas']['MyProfileSettingPageViewModel'];
                    export type SignoffUseCaseModel = components['schemas']['SignoffUseCaseModel'];
                    export type SignoffPageViewModel = components['schemas']['SignoffPageViewModel'];
                    export type SignoffConfirmDialogueModel = components['schemas']['SignoffConfirmDialogueModel'];
                    export type PermissionModel = components['schemas']['PermissionModel'];
                    export type PermissionGroupModel = components['schemas']['PermissionGroupModel'];
                    export type PermissionListModel = components['schemas']['PermissionListModel'];
                    export type PermissionViewModel = components['schemas']['PermissionViewModel'];
                    export type StoreViewModel = components['schemas']['StoreViewModel'];
                    export type UpdateMyStoreViewModel = components['schemas']['UpdateMyStoreViewModel'];
                    export type RevieveMakeupAdvisorViewModel = components['schemas']['RevieveMakeupAdvisorViewModel'];
                    export type ShippingOption = components['schemas']['ShippingOption'];
                    export type ShippingOptionGroup = components['schemas']['ShippingOptionGroup'];
                    export type ShippingOptionsBlock = components['schemas']['ShippingOptionsBlock'];
                    export type KicksIpadPosReceiptViewModel = components['schemas']['KicksIpadPosReceiptViewModel'];
                    export type AlsoReadBlockType = components['schemas']['AlsoReadBlockType'];
                    export type InspirationAlsoReadBlockDTO = components['schemas']['InspirationAlsoReadBlockDTO'];
                    export type LoginModalRequestMaybe = components['schemas']['LoginModalRequestMaybe'];
                    export type BeautyTrendBoxNotifyButtonLoginInfo = components['schemas']['BeautyTrendBoxNotifyButtonLoginInfo'];
                    export type BeautyTrendBoxNotifyButtonDTO = components['schemas']['BeautyTrendBoxNotifyButtonDTO'];
                    export type LiveEventDTO = components['schemas']['LiveEventDTO'];
                    export type ShippingListItem = components['schemas']['ShippingListItem'];
                    export type ShippingKeyGroup = components['schemas']['ShippingKeyGroup'];
                    export type ShippingGroupInfo = components['schemas']['ShippingGroupInfo'];
                    export type ShippingInfoType = components['schemas']['ShippingInfoType'];
                    export type ShippingInfo = components['schemas']['ShippingInfo'];
                    export type ShippingInfoMaybe = components['schemas']['ShippingInfoMaybe'];
                    export type CheckoutShippingModel = components['schemas']['CheckoutShippingModel'];
                    export type BundleInfo = components['schemas']['BundleInfo'];
                    export type BundleInfoMaybe = components['schemas']['BundleInfoMaybe'];
                    export type IProductBundleChild = components['schemas']['IProductBundleChild'];
                    export type BasketLine = components['schemas']['BasketLine'];
                    export type BasketLinePriceInfo = components['schemas']['BasketLinePriceInfo'];
                    export type CampaignType = components['schemas']['CampaignType'];
                    export type HighlightDiscountCore = components['schemas']['HighlightDiscountCore'];
                    export type HighlightDiscountCoreMaybe = components['schemas']['HighlightDiscountCoreMaybe'];
                    export type CustomSplash = components['schemas']['CustomSplash'];
                    export type CustomSplashMaybe = components['schemas']['CustomSplashMaybe'];
                    export type DiscountClubType = components['schemas']['DiscountClubType'];
                    export type DiscountCore = components['schemas']['DiscountCore'];
                    export type Discount = components['schemas']['Discount'];
                    export type DiscountMaybe = components['schemas']['DiscountMaybe'];
                    export type BasketCouponVoucher = components['schemas']['BasketCouponVoucher'];
                    export type BasketCouponVoucherMaybe = components['schemas']['BasketCouponVoucherMaybe'];
                    export type VoucherType = components['schemas']['VoucherType'];
                    export type VoucherFlowType = components['schemas']['VoucherFlowType'];
                    export type PersonalOfferImages = components['schemas']['PersonalOfferImages'];
                    export type PersonalOfferFutureTexts = components['schemas']['PersonalOfferFutureTexts'];
                    export type PersonalOfferFutureTextsMaybe = components['schemas']['PersonalOfferFutureTextsMaybe'];
                    export type PersonalOffer = components['schemas']['PersonalOffer'];
                    export type ClubMatasMemberVoucher = components['schemas']['ClubMatasMemberVoucher'];
                    export type PersonalOfferDetailed = components['schemas']['PersonalOfferDetailed'];
                    export type PersonalOfferDetailedMaybe = components['schemas']['PersonalOfferDetailedMaybe'];
                    export type TriggeredDiscount = components['schemas']['TriggeredDiscount'];
                    export type PotentialDiscount = components['schemas']['PotentialDiscount'];
                    export type ProgressiveDiscountBasketStepText = components['schemas']['ProgressiveDiscountBasketStepText'];
                    export type ProgressiveDiscountBasketStepTextMaybe = components['schemas']['ProgressiveDiscountBasketStepTextMaybe'];
                    export type ProgressiveDiscountBasketInfo = components['schemas']['ProgressiveDiscountBasketInfo'];
                    export type ProgressiveDiscountBasketInfoMaybe = components['schemas']['ProgressiveDiscountBasketInfoMaybe'];
                    export type BasketLineDiscountInfo = components['schemas']['BasketLineDiscountInfo'];
                    export type ReadyToPackBuffers = components['schemas']['ReadyToPackBuffers'];
                    export type OldDeliveryInfo = components['schemas']['OldDeliveryInfo'];
                    export type ProductBundleChildDetailed = components['schemas']['ProductBundleChildDetailed'];
                    export type BasketLineBooking = components['schemas']['BasketLineBooking'];
                    export type BasketLineBookingMaybe = components['schemas']['BasketLineBookingMaybe'];
                    export type BasketLineLinkedItemPriceInfo = components['schemas']['BasketLineLinkedItemPriceInfo'];
                    export type BasketLineDetailed = components['schemas']['BasketLineDetailed'];
                    export type DeliveryTexts = components['schemas']['DeliveryTexts'];
                    export type ExpectedDeliverySummaryModel = components['schemas']['ExpectedDeliverySummaryModel'];
                    export type ExpectedDeliverySummaryModelMaybe = components['schemas']['ExpectedDeliverySummaryModelMaybe'];
                    export type ProductStockStatusModel = components['schemas']['ProductStockStatusModel'];
                    export type ProductStockStatusModelMaybe = components['schemas']['ProductStockStatusModelMaybe'];
                    export type SwapCertificateModel = components['schemas']['SwapCertificateModel'];
                    export type BasketLineDiscountInfoModel = components['schemas']['BasketLineDiscountInfoModel'];
                    export type BasketLineModel = components['schemas']['BasketLineModel'];
                    export type DeliveryGroupSettings = components['schemas']['DeliveryGroupSettings'];
                    export type CheckoutDeliveryGroupModel = components['schemas']['CheckoutDeliveryGroupModel'];
                    export type CheckoutDeliveryPostModel = components['schemas']['CheckoutDeliveryPostModel'];
                    export type CheckoutInvoiceAddress = components['schemas']['CheckoutInvoiceAddress'];
                    export type CheckoutDeliveryPreview = components['schemas']['CheckoutDeliveryPreview'];
                    export type NonurgentDelivery = components['schemas']['NonurgentDelivery'];
                    export type NonurgentDeliveryMaybe = components['schemas']['NonurgentDeliveryMaybe'];
                    export type CheckoutDeliveryViewModel = components['schemas']['CheckoutDeliveryViewModel'];
                    export type CheckoutTrackingInfo = components['schemas']['CheckoutTrackingInfo'];
                    export type CheckoutUserCommentInfo = components['schemas']['CheckoutUserCommentInfo'];
                    export type CheckoutOtcTerms = components['schemas']['CheckoutOtcTerms'];
                    export type CheckoutPermissionInfo = components['schemas']['CheckoutPermissionInfo'];
                    export type CheckoutBasketGroupViewModel = components['schemas']['CheckoutBasketGroupViewModel'];
                    export type CheckoutGroupedBasketViewModel = components['schemas']['CheckoutGroupedBasketViewModel'];
                    export type GoToPaymentButtonViewModel = components['schemas']['GoToPaymentButtonViewModel'];
                    export type CheckoutSummaryViewModel = components['schemas']['CheckoutSummaryViewModel'];
                    export type CheckoutPermissionInfoCore = components['schemas']['CheckoutPermissionInfoCore'];
                    export type CheckoutSummaryPostModel = components['schemas']['CheckoutSummaryPostModel'];
                    export type PaymentGatewayModel = components['schemas']['PaymentGatewayModel'];
                    export type TrackingInfo = components['schemas']['TrackingInfo'];
                    export type PaymentViewModel = components['schemas']['PaymentViewModel'];
                    export type PaymentReepayCallbackViewModel = components['schemas']['PaymentReepayCallbackViewModel'];
                    export type ReceiptPageInfoModel = components['schemas']['ReceiptPageInfoModel'];
                    export type ReceiptOrderInfoViewModel = components['schemas']['ReceiptOrderInfoViewModel'];
                    export type ReceiptOrderInfoViewModelMaybe = components['schemas']['ReceiptOrderInfoViewModelMaybe'];
                    export type ReceiptViewModel = components['schemas']['ReceiptViewModel'];
                    export type AddToBasketRequest = components['schemas']['AddToBasketRequest'];
                    export type ModifacePropertyModel = components['schemas']['ModifacePropertyModel'];
                    export type ModifaceVariantModel = components['schemas']['ModifaceVariantModel'];
                    export type ModifaceUseCaseModelTexts = components['schemas']['ModifaceUseCaseModelTexts'];
                    export type ModifaceUseCaseModel = components['schemas']['ModifaceUseCaseModel'];
                    export type MyFavoritesViewModel = components['schemas']['MyFavoritesViewModel'];
                    export type OrderStatus = components['schemas']['OrderStatus'];
                    export type OrderLogModel = components['schemas']['OrderLogModel'];
                    export type DayOfWeek = components['schemas']['DayOfWeek'];
                    export type ParcelShopOpeningHoursModel = components['schemas']['ParcelShopOpeningHoursModel'];
                    export type ParcelShopOpeningHoursIntervalModel = components['schemas']['ParcelShopOpeningHoursIntervalModel'];
                    export type ParcelShopModel = components['schemas']['ParcelShopModel'];
                    export type ParcelShopModelMaybe = components['schemas']['ParcelShopModelMaybe'];
                    export type DeliveryTimeModel = components['schemas']['DeliveryTimeModel'];
                    export type TrackAndTraceModel = components['schemas']['TrackAndTraceModel'];
                    export type ShippingInfoModel = components['schemas']['ShippingInfoModel'];
                    export type OrderPartStatus = components['schemas']['OrderPartStatus'];
                    export type OrderDropshipStatusDetailedModel = components['schemas']['OrderDropshipStatusDetailedModel'];
                    export type OrderStatusDetailedModel = components['schemas']['OrderStatusDetailedModel'];
                    export type OrderFollowupLineActionType = components['schemas']['OrderFollowupLineActionType'];
                    export type IProductImageMaybe = components['schemas']['IProductImageMaybe'];
                    export type OrderFollowupActionModel = components['schemas']['OrderFollowupActionModel'];
                    export type OrderFollowupDetailedModel = components['schemas']['OrderFollowupDetailedModel'];
                    export type OrderFollowupDetailedViewModel = components['schemas']['OrderFollowupDetailedViewModel'];
                    export type OrderLineType = components['schemas']['OrderLineType'];
                    export type OrderLineStatus = components['schemas']['OrderLineStatus'];
                    export type OrderLineSimpleModel = components['schemas']['OrderLineSimpleModel'];
                    export type OfflineOrderSimpleModel = components['schemas']['OfflineOrderSimpleModel'];
                    export type OrderSimpleModel = components['schemas']['OrderSimpleModel'];
                    export type UpsaleOrderStatus = components['schemas']['UpsaleOrderStatus'];
                    export type UpsaleOrderSimpleModel = components['schemas']['UpsaleOrderSimpleModel'];
                    export type MyProfileHeaderViewModel = components['schemas']['MyProfileHeaderViewModel'];
                    export type MyProfileHeaderViewModelMaybe = components['schemas']['MyProfileHeaderViewModelMaybe'];
                    export type PageStyleType = components['schemas']['PageStyleType'];
                    export type MyProfileLayoutViewModel = components['schemas']['MyProfileLayoutViewModel'];
                    export type MatasPlusResubscribeNudgeMessage = components['schemas']['MatasPlusResubscribeNudgeMessage'];
                    export type MatasPlusResubscribeNudgeMessageMaybe = components['schemas']['MatasPlusResubscribeNudgeMessageMaybe'];
                    export type MyProfileFrontPageViewModel = components['schemas']['MyProfileFrontPageViewModel'];
                    export type HtmlString = components['schemas']['HtmlString'];
                    export type ReviewViewModel = components['schemas']['ReviewViewModel'];
                    export type ReviewListViewModel = components['schemas']['ReviewListViewModel'];
                    export type ReviewViewModelWrapper = components['schemas']['ReviewViewModelWrapper'];
                    export type SampleRating = components['schemas']['SampleRating'];
                    export type SampleRegisterType = components['schemas']['SampleRegisterType'];
                    export type SampleReviewModel = components['schemas']['SampleReviewModel'];
                    export type AdvisorToolboxModelMaybe = components['schemas']['AdvisorToolboxModelMaybe'];
                    export type SampleProfilePageModel = components['schemas']['SampleProfilePageModel'];
                    export type OrderFilterType = components['schemas']['OrderFilterType'];
                    export type AbstractOrderSimpleModel = components['schemas']['AbstractOrderSimpleModel'];
                    export type OrderListModel = components['schemas']['OrderListModel'];
                    export type ProductNameModelMaybe = components['schemas']['ProductNameModelMaybe'];
                    export type OrderLineBookingModel = components['schemas']['OrderLineBookingModel'];
                    export type OrderLineBookingModelMaybe = components['schemas']['OrderLineBookingModelMaybe'];
                    export type OrderLineModel = components['schemas']['OrderLineModel'];
                    export type StoreOpeningHoursModel = components['schemas']['StoreOpeningHoursModel'];
                    export type StoreModel = components['schemas']['StoreModel'];
                    export type StoreModelMaybe = components['schemas']['StoreModelMaybe'];
                    export type ClubMatasRegisterStatus = components['schemas']['ClubMatasRegisterStatus'];
                    export type ClubMatasInfoModel = components['schemas']['ClubMatasInfoModel'];
                    export type ClubMatasInfoModelMaybe = components['schemas']['ClubMatasInfoModelMaybe'];
                    export type OfflineOrderDetailedModel = components['schemas']['OfflineOrderDetailedModel'];
                    export type TotalPriceWrappingModel = components['schemas']['TotalPriceWrappingModel'];
                    export type TotalPriceWrappingModelMaybe = components['schemas']['TotalPriceWrappingModelMaybe'];
                    export type TotalPricePrizeCertificateModel = components['schemas']['TotalPricePrizeCertificateModel'];
                    export type TotalPriceModel = components['schemas']['TotalPriceModel'];
                    export type OrderInvoiceAddressModel = components['schemas']['OrderInvoiceAddressModel'];
                    export type DeliveryInfoModel = components['schemas']['DeliveryInfoModel'];
                    export type OrderPartModel = components['schemas']['OrderPartModel'];
                    export type OrderPartModelMaybe = components['schemas']['OrderPartModelMaybe'];
                    export type DropshipOrderPartModel = components['schemas']['DropshipOrderPartModel'];
                    export type OrderDetailedModel = components['schemas']['OrderDetailedModel'];
                    export type UpsaleOrderDetailedModel = components['schemas']['UpsaleOrderDetailedModel'];
                    export type MyProfileOrderDetailsViewModel = components['schemas']['MyProfileOrderDetailsViewModel'];
                    export type PendingSubscriptionState = components['schemas']['PendingSubscriptionState'];
                    export type ExplanationModel = components['schemas']['ExplanationModel'];
                    export type SubscriptionWeekInfo = components['schemas']['SubscriptionWeekInfo'];
                    export type SubscriptionFrequency = components['schemas']['SubscriptionFrequency'];
                    export type PendingSubscriptionLineViewModel = components['schemas']['PendingSubscriptionLineViewModel'];
                    export type CreditCardModel = components['schemas']['CreditCardModel'];
                    export type PendingSubscriptionActivationModel = components['schemas']['PendingSubscriptionActivationModel'];
                    export type FilterModel = components['schemas']['FilterModel'];
                    export type FilterCategory = components['schemas']['FilterCategory'];
                    export type PersonalFiltersUseCaseModel = components['schemas']['PersonalFiltersUseCaseModel'];
                    export type InternalErrorResponse = components['schemas']['InternalErrorResponse'];
                    export type PersonalFiltersPostModel = components['schemas']['PersonalFiltersPostModel'];
                    export type TimeOutErrorResponse = components['schemas']['TimeOutErrorResponse'];
                    export type PointExpiryInfoViewModel = components['schemas']['PointExpiryInfoViewModel'];
                    export type PointExpiryInfoViewModelMaybe = components['schemas']['PointExpiryInfoViewModelMaybe'];
                    export type PointTransactionInfoViewModel = components['schemas']['PointTransactionInfoViewModel'];
                    export type PointTransactionInfoViewModelMaybe = components['schemas']['PointTransactionInfoViewModelMaybe'];
                    export type PointTransactionViewModel = components['schemas']['PointTransactionViewModel'];
                    export type PointTransactionGroupViewModel = components['schemas']['PointTransactionGroupViewModel'];
                    export type MatasPlusCalculatorBannerModelMaybe = components['schemas']['MatasPlusCalculatorBannerModelMaybe'];
                    export type MatasPlusPointSavingsBannerModelMaybe = components['schemas']['MatasPlusPointSavingsBannerModelMaybe'];
                    export type MemberRecommendationType = components['schemas']['MemberRecommendationType'];
                    export type PointInfoRecommendationModel = components['schemas']['PointInfoRecommendationModel'];
                    export type PointInfoRecommendationModelMaybe = components['schemas']['PointInfoRecommendationModelMaybe'];
                    export type DropDownListItemViewModel = components['schemas']['DropDownListItemViewModel'];
                    export type DropDownListViewModel = components['schemas']['DropDownListViewModel'];
                    export type MyProfilePointInfoViewModel = components['schemas']['MyProfilePointInfoViewModel'];
                    export type PosFrontpageViewModel = components['schemas']['PosFrontpageViewModel'];
                    export type PosPaymentViewModel = components['schemas']['PosPaymentViewModel'];
                    export type FormattedPriceInfo = components['schemas']['FormattedPriceInfo'];
                    export type PowerstepSubscriptionInfoModel = components['schemas']['PowerstepSubscriptionInfoModel'];
                    export type PowerstepSubscriptionInfoModelMaybe = components['schemas']['PowerstepSubscriptionInfoModelMaybe'];
                    export type BasketLineDiscountInfoModelMaybe = components['schemas']['BasketLineDiscountInfoModelMaybe'];
                    export type LinkedItemPriceInfo = components['schemas']['LinkedItemPriceInfo'];
                    export type PowerstepProductViewModel = components['schemas']['PowerstepProductViewModel'];
                    export type PowerstepInformationFromBasketViewModel = components['schemas']['PowerstepInformationFromBasketViewModel'];
                    export type PowerstepInformationFromBasketViewModelMaybe = components['schemas']['PowerstepInformationFromBasketViewModelMaybe'];
                    export type BrandClubPowerstepViewModel = components['schemas']['BrandClubPowerstepViewModel'];
                    export type BasketMessage = components['schemas']['BasketMessage'];
                    export type PowerstepNudgingsViewModel = components['schemas']['PowerstepNudgingsViewModel'];
                    export type PowerstepNudgingsViewModelMaybe = components['schemas']['PowerstepNudgingsViewModelMaybe'];
                    export type StepNudgingItemViewModel = components['schemas']['StepNudgingItemViewModel'];
                    export type StepNudgingViewModel = components['schemas']['StepNudgingViewModel'];
                    export type StepNudgingViewModelMaybe = components['schemas']['StepNudgingViewModelMaybe'];
                    export type PowerstepViewModel = components['schemas']['PowerstepViewModel'];
                    export type MatasPlusPowerStepAdvantage = components['schemas']['MatasPlusPowerStepAdvantage'];
                    export type MatasPlusPowerStep = components['schemas']['MatasPlusPowerStep'];
                    export type StepNudgingItem = components['schemas']['StepNudgingItem'];
                    export type StepNudgingModel = components['schemas']['StepNudgingModel'];
                    export type PriceLabelInfoModel = components['schemas']['PriceLabelInfoModel'];
                    export type VariantLayoutType = components['schemas']['VariantLayoutType'];
                    export type VariantCategory = components['schemas']['VariantCategory'];
                    export type VariantPickerListItem = components['schemas']['VariantPickerListItem'];
                    export type VariantSectionModel = components['schemas']['VariantSectionModel'];
                    export type ProductCustomerNotificationModel = components['schemas']['ProductCustomerNotificationModel'];
                    export type ProductCustomerNotificationModelMaybe = components['schemas']['ProductCustomerNotificationModelMaybe'];
                    export type VariantPickerModel = components['schemas']['VariantPickerModel'];
                    export type ProductVariantsPdpModel = components['schemas']['ProductVariantsPdpModel'];
                    export type ProductUrlSource = components['schemas']['ProductUrlSource'];
                    export type ProductDetailedImageModel = components['schemas']['ProductDetailedImageModel'];
                    export type ProductJsonModel = components['schemas']['ProductJsonModel'];
                    export type ProductReviewListSort = components['schemas']['ProductReviewListSort'];
                    export type ProductReviewRatingStatisticModel = components['schemas']['ProductReviewRatingStatisticModel'];
                    export type ProductReviewStatisticsModel = components['schemas']['ProductReviewStatisticsModel'];
                    export type ProductVariantSimpleModel = components['schemas']['ProductVariantSimpleModel'];
                    export type ProductVariantSimpleModelMaybe = components['schemas']['ProductVariantSimpleModelMaybe'];
                    export type ProductReviewModel = components['schemas']['ProductReviewModel'];
                    export type ProductReviewListModel = components['schemas']['ProductReviewListModel'];
                    export type StandardPointInfoModel = components['schemas']['StandardPointInfoModel'];
                    export type MatasPlusPointInfoModel = components['schemas']['MatasPlusPointInfoModel'];
                    export type PointInfoViewModel = components['schemas']['PointInfoViewModel'];
                    export type PointInfoViewModelMaybe = components['schemas']['PointInfoViewModelMaybe'];
                    export type PriceLabelViewModel = components['schemas']['PriceLabelViewModel'];
                    export type PriceLabelViewModelMaybe = components['schemas']['PriceLabelViewModelMaybe'];
                    export type PriceInfoViewModel = components['schemas']['PriceInfoViewModel'];
                    export type PriceInfoVariant = components['schemas']['PriceInfoVariant'];
                    export type RegimePriceInfoViewModel = components['schemas']['RegimePriceInfoViewModel'];
                    export type ProductRegimeAdvisorModel = components['schemas']['ProductRegimeAdvisorModel'];
                    export type ProductRegimeSectionModel = components['schemas']['ProductRegimeSectionModel'];
                    export type ProductRegimeProductModel = components['schemas']['ProductRegimeProductModel'];
                    export type ProductRegimeModel = components['schemas']['ProductRegimeModel'];
                    export type CampaignInfoMaybe = components['schemas']['CampaignInfoMaybe'];
                    export type ProductGwpOfferViewModel = components['schemas']['ProductGwpOfferViewModel'];
                    export type ProductGwpOfferViewModelMaybe = components['schemas']['ProductGwpOfferViewModelMaybe'];
                    export type CounterSplashRenderType = components['schemas']['CounterSplashRenderType'];
                    export type CounterSplashItem = components['schemas']['CounterSplashItem'];
                    export type CounterSplash = components['schemas']['CounterSplash'];
                    export type CounterSplashMaybe = components['schemas']['CounterSplashMaybe'];
                    export type ProductOfferModel = components['schemas']['ProductOfferModel'];
                    export type ProductOfferModelMaybe = components['schemas']['ProductOfferModelMaybe'];
                    export type ProductVariantsPdpModelMaybe = components['schemas']['ProductVariantsPdpModelMaybe'];
                    export type ProductReviewListModelMaybe = components['schemas']['ProductReviewListModelMaybe'];
                    export type ProductTabType = components['schemas']['ProductTabType'];
                    export type ProductTabBlock = components['schemas']['ProductTabBlock'];
                    export type ProductTabBlockListModel = components['schemas']['ProductTabBlockListModel'];
                    export type ProductTabContentBrandTeaserModel = components['schemas']['ProductTabContentBrandTeaserModel'];
                    export type ProductTabDeliveryAndReturnModel = components['schemas']['ProductTabDeliveryAndReturnModel'];
                    export type ProductTabTableItem = components['schemas']['ProductTabTableItem'];
                    export type ProductTabTableModel = components['schemas']['ProductTabTableModel'];
                    export type ProductTabTableModelMaybe = components['schemas']['ProductTabTableModelMaybe'];
                    export type ProductCertificationHighlight = components['schemas']['ProductCertificationHighlight'];
                    export type ProductTabHighlightsModel = components['schemas']['ProductTabHighlightsModel'];
                    export type ProductTabHighlightsModelMaybe = components['schemas']['ProductTabHighlightsModelMaybe'];
                    export type ProductTabInstructionsModel = components['schemas']['ProductTabInstructionsModel'];
                    export type ProductTabDescriptionModel = components['schemas']['ProductTabDescriptionModel'];
                    export type ProductTabIconItemModel = components['schemas']['ProductTabIconItemModel'];
                    export type ProductTabIconListModel = components['schemas']['ProductTabIconListModel'];
                    export type ProductTabPropertyListItem = components['schemas']['ProductTabPropertyListItem'];
                    export type PropertyListStylingType = components['schemas']['PropertyListStylingType'];
                    export type ProductTabPropertyListModel = components['schemas']['ProductTabPropertyListModel'];
                    export type ProductTabTableListModel = components['schemas']['ProductTabTableListModel'];
                    export type IProductVideoMaybe = components['schemas']['IProductVideoMaybe'];
                    export type ProductTabUsageModel = components['schemas']['ProductTabUsageModel'];
                    export type ProductTabModel = components['schemas']['ProductTabModel'];
                    export type ProductReviewModelMaybe = components['schemas']['ProductReviewModelMaybe'];
                    export type ProgressiveDiscountViewModelMaybe = components['schemas']['ProgressiveDiscountViewModelMaybe'];
                    export type ProductButtonContext = components['schemas']['ProductButtonContext'];
                    export type ProductStoreStockStatus = components['schemas']['ProductStoreStockStatus'];
                    export type ProductPosStockViewModel = components['schemas']['ProductPosStockViewModel'];
                    export type ProductPosStockViewModelMaybe = components['schemas']['ProductPosStockViewModelMaybe'];
                    export type ProductReviewSummaryViewModel = components['schemas']['ProductReviewSummaryViewModel'];
                    export type ProductReviewSummaryOverviewViewModel = components['schemas']['ProductReviewSummaryOverviewViewModel'];
                    export type ProductReviewSummaryOverviewViewModelMaybe = components['schemas']['ProductReviewSummaryOverviewViewModelMaybe'];
                    export type ImageContent = components['schemas']['ImageContent'];
                    export type VideoContent = components['schemas']['VideoContent'];
                    export type ProductSubscriptionPriceInfoViewModel = components['schemas']['ProductSubscriptionPriceInfoViewModel'];
                    export type ProductSubscriptionInfoViewModel = components['schemas']['ProductSubscriptionInfoViewModel'];
                    export type ProductSubscriptionInfoViewModelMaybe = components['schemas']['ProductSubscriptionInfoViewModelMaybe'];
                    export type ProductStoreStockRequest = components['schemas']['ProductStoreStockRequest'];
                    export type ProductStoreStockViewModel = components['schemas']['ProductStoreStockViewModel'];
                    export type ProductStoreStockViewModelMaybe = components['schemas']['ProductStoreStockViewModelMaybe'];
                    export type ProductExpectedDeliveryToStoreInfoViewModel = components['schemas']['ProductExpectedDeliveryToStoreInfoViewModel'];
                    export type ProductExpectedDeliveryToStoreInfoViewModelMaybe = components['schemas']['ProductExpectedDeliveryToStoreInfoViewModelMaybe'];
                    export type ProductExpectedDeliveryInfoViewModel = components['schemas']['ProductExpectedDeliveryInfoViewModel'];
                    export type ProductExpectedDeliveryInfoViewModelMaybe = components['schemas']['ProductExpectedDeliveryInfoViewModelMaybe'];
                    export type ProductExpectedDeliveryViewModel = components['schemas']['ProductExpectedDeliveryViewModel'];
                    export type ProductExpectedDeliveryViewModelMaybe = components['schemas']['ProductExpectedDeliveryViewModelMaybe'];
                    export type RevieveVirtualTryOnConfig = components['schemas']['RevieveVirtualTryOnConfig'];
                    export type RevieveVirtualTryOnConfigMaybe = components['schemas']['RevieveVirtualTryOnConfigMaybe'];
                    export type RevieveVirtualTryOnViewModel = components['schemas']['RevieveVirtualTryOnViewModel'];
                    export type FollowBrandClubModelMaybe = components['schemas']['FollowBrandClubModelMaybe'];
                    export type ProductInformationViewModel = components['schemas']['ProductInformationViewModel'];
                    export type ProductReviewListViewModel = components['schemas']['ProductReviewListViewModel'];
                    export type ProductAdvisorModel = components['schemas']['ProductAdvisorModel'];
                    export type ProductAdvisorModelMaybe = components['schemas']['ProductAdvisorModelMaybe'];
                    export type ProductRegimeModelMaybe = components['schemas']['ProductRegimeModelMaybe'];
                    export type ProductRecommendationRequest = components['schemas']['ProductRecommendationRequest'];
                    export type BasketRecommendationRequest = components['schemas']['BasketRecommendationRequest'];
                    export type ProductGroupRecommendationRequest = components['schemas']['ProductGroupRecommendationRequest'];
                    export type SearchResultRecommendationRequest = components['schemas']['SearchResultRecommendationRequest'];
                    export type ZeroSearchResultRecommendationRequest = components['schemas']['ZeroSearchResultRecommendationRequest'];
                    export type HomePageRecommendationRequest = components['schemas']['HomePageRecommendationRequest'];
                    export type PowerstepRecommendationRequest = components['schemas']['PowerstepRecommendationRequest'];
                    export type PowerstepTwoRecommendationRequest = components['schemas']['PowerstepTwoRecommendationRequest'];
                    export type ReceiptPageRecommendationRequest = components['schemas']['ReceiptPageRecommendationRequest'];
                    export type RecommendationProductSliderCustomModuleType = components['schemas']['RecommendationProductSliderCustomModuleType'];
                    export type CustomRecommendationRequest = components['schemas']['CustomRecommendationRequest'];
                    export type RecommendationRequestWrapper = components['schemas']['RecommendationRequestWrapper'];
                    export type RecommendationRequestWrapperMaybe = components['schemas']['RecommendationRequestWrapperMaybe'];
                    export type ProductReviewHeaderModelMaybe = components['schemas']['ProductReviewHeaderModelMaybe'];
                    export type MerchantReturnPolicyStructuredData = components['schemas']['MerchantReturnPolicyStructuredData'];
                    export type BrandStructuredData = components['schemas']['BrandStructuredData'];
                    export type AggregateRatingStructuredData = components['schemas']['AggregateRatingStructuredData'];
                    export type AggregateRatingStructuredDataMaybe = components['schemas']['AggregateRatingStructuredDataMaybe'];
                    export type QuantitativeValueStructuredData = components['schemas']['QuantitativeValueStructuredData'];
                    export type UnitPriceSpecificationStructuredData = components['schemas']['UnitPriceSpecificationStructuredData'];
                    export type OfferStructuredData = components['schemas']['OfferStructuredData'];
                    export type PersonStructuredData = components['schemas']['PersonStructuredData'];
                    export type RatingStructuredData = components['schemas']['RatingStructuredData'];
                    export type ReviewStructuredData = components['schemas']['ReviewStructuredData'];
                    export type ProductStructuredData = components['schemas']['ProductStructuredData'];
                    export type GoogleTagParams = components['schemas']['GoogleTagParams'];
                    export type RemarketingData = components['schemas']['RemarketingData'];
                    export type ProductPrelaunchBannerModelMaybe = components['schemas']['ProductPrelaunchBannerModelMaybe'];
                    export type ProductPageViewModel = components['schemas']['ProductPageViewModel'];
                    export type CertificationModel = components['schemas']['CertificationModel'];
                    export type ProductExpectedDeliveryToStoreInfoModel = components['schemas']['ProductExpectedDeliveryToStoreInfoModel'];
                    export type ProductExpectedDeliveryToStoreInfoModelMaybe = components['schemas']['ProductExpectedDeliveryToStoreInfoModelMaybe'];
                    export type ProductExpectedDeliveryInfoModel = components['schemas']['ProductExpectedDeliveryInfoModel'];
                    export type ProductExpectedDeliveryInfoModelMaybe = components['schemas']['ProductExpectedDeliveryInfoModelMaybe'];
                    export type AttributeLocation = components['schemas']['AttributeLocation'];
                    export type AttributeViewModel = components['schemas']['AttributeViewModel'];
                    export type RangeFilterViewModel = components['schemas']['RangeFilterViewModel'];
                    export type BoolBaseFilterOption = components['schemas']['BoolBaseFilterOption'];
                    export type BoolFilterViewModel = components['schemas']['BoolFilterViewModel'];
                    export type PriceFilterViewModel = components['schemas']['PriceFilterViewModel'];
                    export type BrandFilterViewModel = components['schemas']['BrandFilterViewModel'];
                    export type BoolStarBaseFilterOption = components['schemas']['BoolStarBaseFilterOption'];
                    export type BoolStarFilterViewModel = components['schemas']['BoolStarFilterViewModel'];
                    export type ColorBaseFilterOption = components['schemas']['ColorBaseFilterOption'];
                    export type ColorFilterViewModel = components['schemas']['ColorFilterViewModel'];
                    export type LinkBaseFilterOption = components['schemas']['LinkBaseFilterOption'];
                    export type LinkFilterViewModel = components['schemas']['LinkFilterViewModel'];
                    export type SimpleActiveFilter = components['schemas']['SimpleActiveFilter'];
                    export type ColorActiveFilter = components['schemas']['ColorActiveFilter'];
                    export type TextElementViewModel = components['schemas']['TextElementViewModel'];
                    export type LinkElementViewModel = components['schemas']['LinkElementViewModel'];
                    export type ProductListMenuItemViewModel = components['schemas']['ProductListMenuItemViewModel'];
                    export type MenuRootViewModel = components['schemas']['MenuRootViewModel'];
                    export type MenuRootViewModelMaybe = components['schemas']['MenuRootViewModelMaybe'];
                    export type FilterOptionViewModel = components['schemas']['FilterOptionViewModel'];
                    export type ProductListResultViewModel = components['schemas']['ProductListResultViewModel'];
                    export type ProductGroupViewModel = components['schemas']['ProductGroupViewModel'];
                    export type ProductListType = components['schemas']['ProductListType'];
                    export type ListMenuKeysViewModel = components['schemas']['ListMenuKeysViewModel'];
                    export type Subsite = components['schemas']['Subsite'];
                    export type ProductListPartialViewModel = components['schemas']['ProductListPartialViewModel'];
                    export type IProductGroupSimple = components['schemas']['IProductGroupSimple'];
                    export type IProductGroupSimpleMaybe = components['schemas']['IProductGroupSimpleMaybe'];
                    export type ProductGroupRecommendationRequestMaybe = components['schemas']['ProductGroupRecommendationRequestMaybe'];
                    export type RevieveMakeupAdvisorViewModelMaybe = components['schemas']['RevieveMakeupAdvisorViewModelMaybe'];
                    export type ProductListViewModel = components['schemas']['ProductListViewModel'];
                    export type ProductRecommendationAIResultViewModel = components['schemas']['ProductRecommendationAIResultViewModel'];
                    export type ProductReviewField = components['schemas']['ProductReviewField'];
                    export type ValidatedOperationResult = components['schemas']['ValidatedOperationResult'];
                    export type IProductReviewLikeResult = components['schemas']['IProductReviewLikeResult'];
                    export type QuantityModel = components['schemas']['QuantityModel'];
                    export type QuestionnaireResultFilterType = components['schemas']['QuestionnaireResultFilterType'];
                    export type QuestionnaireResultModel = components['schemas']['QuestionnaireResultModel'];
                    export type QuestionnaireResultListModel = components['schemas']['QuestionnaireResultListModel'];
                    export type QuestionnaireResultListViewModel = components['schemas']['QuestionnaireResultListViewModel'];
                    export type CheckoutQuickModelLoginInfo = components['schemas']['CheckoutQuickModelLoginInfo'];
                    export type MatasPlusTermInfo = components['schemas']['MatasPlusTermInfo'];
                    export type MatasPlusQuickCheckoutInfoLine = components['schemas']['MatasPlusQuickCheckoutInfoLine'];
                    export type MatasPlusAddionalInfoLineModel = components['schemas']['MatasPlusAddionalInfoLineModel'];
                    export type IMatasPlusSubscriptionTypeModel = components['schemas']['IMatasPlusSubscriptionTypeModel'];
                    export type MatasPlusCheckoutSubscriptionInfoModel = components['schemas']['MatasPlusCheckoutSubscriptionInfoModel'];
                    export type MatasPlusCreditCardInfoModelMaybe = components['schemas']['MatasPlusCreditCardInfoModelMaybe'];
                    export type CheckoutQuickPaymentType = components['schemas']['CheckoutQuickPaymentType'];
                    export type CheckoutQuickModelPaymentFormChoice = components['schemas']['CheckoutQuickModelPaymentFormChoice'];
                    export type CheckoutQuickModelNoCreditCardInfo = components['schemas']['CheckoutQuickModelNoCreditCardInfo'];
                    export type CheckoutQuickModelNoCreditCardInfoMaybe = components['schemas']['CheckoutQuickModelNoCreditCardInfoMaybe'];
                    export type CheckoutQuickModel = components['schemas']['CheckoutQuickModel'];
                    export type ReceiptOrderDetailsViewModel = components['schemas']['ReceiptOrderDetailsViewModel'];
                    export type PowerstepTwoType = components['schemas']['PowerstepTwoType'];
                    export type ProductRecommendationListPowerstepTwoModel = components['schemas']['ProductRecommendationListPowerstepTwoModel'];
                    export type ReviewRequestListModel = components['schemas']['ReviewRequestListModel'];
                    export type ReviewRequestViewModel = components['schemas']['ReviewRequestViewModel'];
                    export type QuickSearchScopedSuggestionViewModel = components['schemas']['QuickSearchScopedSuggestionViewModel'];
                    export type QuickSearchSuggestionViewModel = components['schemas']['QuickSearchSuggestionViewModel'];
                    export type QuickSearchSectionViewModelOfQuickSearchSuggestionViewModel = components['schemas']['QuickSearchSectionViewModelOfQuickSearchSuggestionViewModel'];
                    export type QuickSearchSectionViewModelOfQuickSearchSuggestionViewModelMaybe = components['schemas']['QuickSearchSectionViewModelOfQuickSearchSuggestionViewModelMaybe'];
                    export type QuickSearchArticleViewModel = components['schemas']['QuickSearchArticleViewModel'];
                    export type QuickSearchSectionViewModelOfQuickSearchArticleViewModel = components['schemas']['QuickSearchSectionViewModelOfQuickSearchArticleViewModel'];
                    export type QuickSearchSectionViewModelOfQuickSearchArticleViewModelMaybe = components['schemas']['QuickSearchSectionViewModelOfQuickSearchArticleViewModelMaybe'];
                    export type SimplePriceInfoViewModel = components['schemas']['SimplePriceInfoViewModel'];
                    export type QuickSearchProductViewModel = components['schemas']['QuickSearchProductViewModel'];
                    export type QuickSearchSectionViewModelOfQuickSearchProductViewModel = components['schemas']['QuickSearchSectionViewModelOfQuickSearchProductViewModel'];
                    export type QuickSearchSectionViewModelOfQuickSearchProductViewModelMaybe = components['schemas']['QuickSearchSectionViewModelOfQuickSearchProductViewModelMaybe'];
                    export type QuickSearchBrandViewModel = components['schemas']['QuickSearchBrandViewModel'];
                    export type QuickSearchSectionViewModelOfQuickSearchBrandViewModel = components['schemas']['QuickSearchSectionViewModelOfQuickSearchBrandViewModel'];
                    export type QuickSearchSectionViewModelOfQuickSearchBrandViewModelMaybe = components['schemas']['QuickSearchSectionViewModelOfQuickSearchBrandViewModelMaybe'];
                    export type QuickSearchNextQueryViewModel = components['schemas']['QuickSearchNextQueryViewModel'];
                    export type QuickSearchSectionViewModelOfQuickSearchNextQueryViewModel = components['schemas']['QuickSearchSectionViewModelOfQuickSearchNextQueryViewModel'];
                    export type QuickSearchSectionViewModelOfQuickSearchNextQueryViewModelMaybe = components['schemas']['QuickSearchSectionViewModelOfQuickSearchNextQueryViewModelMaybe'];
                    export type QuickSearchViewModel = components['schemas']['QuickSearchViewModel'];
                    export type IProductDetailedMaybe = components['schemas']['IProductDetailedMaybe'];
                    export type SearchSuggestion = components['schemas']['SearchSuggestion'];
                    export type SearchSuggestionMaybe = components['schemas']['SearchSuggestionMaybe'];
                    export type RelatedTagModel = components['schemas']['RelatedTagModel'];
                    export type AttributeType = components['schemas']['AttributeType'];
                    export type AttributeSource = components['schemas']['AttributeSource'];
                    export type AttributeBehavior = components['schemas']['AttributeBehavior'];
                    export type AttributeKey = components['schemas']['AttributeKey'];
                    export type FilterType = components['schemas']['FilterType'];
                    export type IFilterModel = components['schemas']['IFilterModel'];
                    export type IFilterContainer = components['schemas']['IFilterContainer'];
                    export type ActiveFilterModel = components['schemas']['ActiveFilterModel'];
                    export type IMenuItemElementModel = components['schemas']['IMenuItemElementModel'];
                    export type IIdentifiableMenuItem = components['schemas']['IIdentifiableMenuItem'];
                    export type IMenuItemModel = components['schemas']['IMenuItemModel'];
                    export type IMenuRootModel = components['schemas']['IMenuRootModel'];
                    export type IMenuRootModelMaybe = components['schemas']['IMenuRootModelMaybe'];
                    export type PersonalFiltersModel = components['schemas']['PersonalFiltersModel'];
                    export type PersonalFiltersModelMaybe = components['schemas']['PersonalFiltersModelMaybe'];
                    export type PageModel = components['schemas']['PageModel'];
                    export type PageModelMaybe = components['schemas']['PageModelMaybe'];
                    export type PagingModel = components['schemas']['PagingModel'];
                    export type FilterOption = components['schemas']['FilterOption'];
                    export type ProductListResult = components['schemas']['ProductListResult'];
                    export type ListMenuKeys = components['schemas']['ListMenuKeys'];
                    export type ProductListPartialModel = components['schemas']['ProductListPartialModel'];
                    export type SearchModel = components['schemas']['SearchModel'];
                    export type SearchResultRecommendationRequestMaybe = components['schemas']['SearchResultRecommendationRequestMaybe'];
                    export type SearchViewModel = components['schemas']['SearchViewModel'];
                    export type ClubMatasSignupTermsModel = components['schemas']['ClubMatasSignupTermsModel'];
                    export type SponsoredProductDisclaimerViewModel = components['schemas']['SponsoredProductDisclaimerViewModel'];
                    export type CheckoutStateField = components['schemas']['CheckoutStateField'];
                    export type CheckoutDeliveryResponseItem = components['schemas']['CheckoutDeliveryResponseItem'];
                    export type CheckoutDeliveryResponseModel = components['schemas']['CheckoutDeliveryResponseModel'];
                    export type StaticImageType = components['schemas']['StaticImageType'];
                    export type ProductStockStatusType = components['schemas']['ProductStockStatusType'];
                    export type StoreParcelShopResult = components['schemas']['StoreParcelShopResult'];
                    export type FindStoreResult = components['schemas']['FindStoreResult'];
                    export type ProductInfoModel = components['schemas']['ProductInfoModel'];
                    export type BookingStoreModelMaybe = components['schemas']['BookingStoreModelMaybe'];
                    export type BookingStoreWithDistanceModel = components['schemas']['BookingStoreWithDistanceModel'];
                    export type StoreListViewModel = components['schemas']['StoreListViewModel'];
                    export type SubscriptionProductModalPriceViewModel = components['schemas']['SubscriptionProductModalPriceViewModel'];
                    export type SubscriptionProductModalStockViewModel = components['schemas']['SubscriptionProductModalStockViewModel'];
                    export type SubscriptionProductModalLinksViewModel = components['schemas']['SubscriptionProductModalLinksViewModel'];
                    export type SubscriptionProductModalTranslationsViewModel = components['schemas']['SubscriptionProductModalTranslationsViewModel'];
                    export type SubscriptionProductModalViewModel = components['schemas']['SubscriptionProductModalViewModel'];
                    export type ReceiptContainerRequest = components['schemas']['ReceiptContainerRequest'];
                    export type ProductResponse = components['schemas']['ProductResponse'];
                    export type SubscriptionResponse = components['schemas']['SubscriptionResponse'];
                    export type SubscriptionOverviewUpsaleBannerViewModel = components['schemas']['SubscriptionOverviewUpsaleBannerViewModel'];
                    export type SubscriptionOverviewUpsaleBannerViewModelMaybe = components['schemas']['SubscriptionOverviewUpsaleBannerViewModelMaybe'];
                    export type ProductDetailedViewModel = components['schemas']['ProductDetailedViewModel'];
                    export type PendingSubscriptionOverviewViewModel = components['schemas']['PendingSubscriptionOverviewViewModel'];
                    export type SubscriptionInfoLinkActions = components['schemas']['SubscriptionInfoLinkActions'];
                    export type PaymentMethodError = components['schemas']['PaymentMethodError'];
                    export type SubscriptionAdhocOrderViewModel = components['schemas']['SubscriptionAdhocOrderViewModel'];
                    export type SubscriptionAdhocOrderViewModelMaybe = components['schemas']['SubscriptionAdhocOrderViewModelMaybe'];
                    export type SubscriptionViewModel = components['schemas']['SubscriptionViewModel'];
                    export type SubscriptionInfoViewModel = components['schemas']['SubscriptionInfoViewModel'];
                    export type SubscriptionPriceSummaryViewModel = components['schemas']['SubscriptionPriceSummaryViewModel'];
                    export type SubscriptionMyProfileViewModel = components['schemas']['SubscriptionMyProfileViewModel'];
                    export type SubscriptionListViewModel = components['schemas']['SubscriptionListViewModel'];
                    export type SubscriptionOverviewViewModel = components['schemas']['SubscriptionOverviewViewModel'];
                    export type SubscriptionOverviewViewModelMaybe = components['schemas']['SubscriptionOverviewViewModelMaybe'];
                    export type OverviewViewModel = components['schemas']['OverviewViewModel'];
                    export type ChoosableSubscriptionFrequencyGroupViewModel = components['schemas']['ChoosableSubscriptionFrequencyGroupViewModel'];
                    export type UserMessageViewModel = components['schemas']['UserMessageViewModel'];
                    export type SubscriptionPackageViewModel = components['schemas']['SubscriptionPackageViewModel'];
                    export type SubscriptionPackageListViewModel = components['schemas']['SubscriptionPackageListViewModel'];
                    export type SubscriptionDetailsViewModel = components['schemas']['SubscriptionDetailsViewModel'];
                    export type SubscriptionAdhocOrderAction = components['schemas']['SubscriptionAdhocOrderAction'];
                    export type SubscriptionAdhocOrderActionModel = components['schemas']['SubscriptionAdhocOrderActionModel'];
                    export type SubscriptionAdhocOrderUseCaseModel = components['schemas']['SubscriptionAdhocOrderUseCaseModel'];
                    export type SubscriptionAdhocOrderUseCaseModelMaybe = components['schemas']['SubscriptionAdhocOrderUseCaseModelMaybe'];
                    export type AdhocOrderViewModel = components['schemas']['AdhocOrderViewModel'];
                    export type ConfirmUpdateViewModel = components['schemas']['ConfirmUpdateViewModel'];
                    export type SubscriptionUpdateResult = components['schemas']['SubscriptionUpdateResult'];
                    export type SubscriptionPriceViewModel = components['schemas']['SubscriptionPriceViewModel'];
                    export type CheckoutGroupedBasketViewModelMaybe = components['schemas']['CheckoutGroupedBasketViewModelMaybe'];
                    export type GroupedBasketResultModel = components['schemas']['GroupedBasketResultModel'];
                    export type RedirectGroupedBasketResultModel = components['schemas']['RedirectGroupedBasketResultModel'];
                    export type SurveyGizmoCompletePostModel = components['schemas']['SurveyGizmoCompletePostModel'];
                    export type SurveyAnswerOptionStyle = components['schemas']['SurveyAnswerOptionStyle'];
                    export type SurveyAnswerOptionInputType = components['schemas']['SurveyAnswerOptionInputType'];
                    export type SurveyAnswerOptionDynamicDataViewModel = components['schemas']['SurveyAnswerOptionDynamicDataViewModel'];
                    export type SurveyAnswerOptionDynamicDataViewModelMaybe = components['schemas']['SurveyAnswerOptionDynamicDataViewModelMaybe'];
                    export type SurveyAnswerOptionViewModel = components['schemas']['SurveyAnswerOptionViewModel'];
                    export type SurveyQuestionViewModel = components['schemas']['SurveyQuestionViewModel'];
                    export type SurveyPageViewModel = components['schemas']['SurveyPageViewModel'];
                    export type SurveyResponseViewModel = components['schemas']['SurveyResponseViewModel'];
                    export type SurveyAnswerOptionResponseModel = components['schemas']['SurveyAnswerOptionResponseModel'];
                    export type SurveyQuestionResponseModel = components['schemas']['SurveyQuestionResponseModel'];
                    export type SurveyResponsePostModel = components['schemas']['SurveyResponsePostModel'];
                    export type SurveyResponseViewModelMaybe = components['schemas']['SurveyResponseViewModelMaybe'];
                    export type SurveyResponseUpdateResult = components['schemas']['SurveyResponseUpdateResult'];
                    export type ClubMatasTermsViewModel = components['schemas']['ClubMatasTermsViewModel'];
                    export type TopBannerType = components['schemas']['TopBannerType'];
                    export type TopBannerModel = components['schemas']['TopBannerModel'];
                    export type TopBannerListModel = components['schemas']['TopBannerListModel'];
                    export type AdformImpressionTrackingViewModel = components['schemas']['AdformImpressionTrackingViewModel'];
                    export type TrackingEventType = components['schemas']['TrackingEventType'];
                    export type BasketLineWrapping = components['schemas']['BasketLineWrapping'];
                    export type BasketLineWrappingUpdateViewModel = components['schemas']['BasketLineWrappingUpdateViewModel'];
                    export type BasketLineGiveAsGiftUpdateViewModel = components['schemas']['BasketLineGiveAsGiftUpdateViewModel'];
                    

        
        

        export type AdvisorModalParams = paths['/internal/Advisor/AdvisorModal']['get']['parameters']['query'];

        export type AdvisorModalSuccess = paths['/internal/Advisor/AdvisorModal']['get']['responses']['200']['content']['text/plain'];

        export const AdvisorModalUrl = '/internal/Advisor/AdvisorModal';

        
        export function AdvisorModal(params: AdvisorModalParams): Promise<AdvisorModalSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<AdvisorModalSuccess>(`/internal/Advisor/AdvisorModal${formatParams(params)}`)
        }
    
    
        

        

        export type AdvisorChatIndexSuccess = paths['/internal/AdvisorChat/Index']['get']['responses']['200']['content']['text/plain'];

        export const AdvisorChatIndexUrl = '/internal/AdvisorChat/Index';

        
        export function AdvisorChatIndex(): Promise<AdvisorChatIndexSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<AdvisorChatIndexSuccess>(`/internal/AdvisorChat/Index`)
        }
    
    
        

        

        

        export const AdvisorChatPageUrl = '/internal/AdvisorChat/AdvisorChatPage';

        
        export function AdvisorChatPage(): Promise<unknown> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<unknown>(`/internal/AdvisorChat/AdvisorChatPage`)
        }
    
    
        

        

        export type ZowieSettingsSuccess = paths['/internal/AdvisorChat/ZowieSettings']['get']['responses']['200']['content']['text/plain'];

        export const ZowieSettingsUrl = '/internal/AdvisorChat/ZowieSettings';

        
        export function ZowieSettings(): Promise<ZowieSettingsSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<ZowieSettingsSuccess>(`/internal/AdvisorChat/ZowieSettings`)
        }
    
    
        

        export type AdvisorToolboxIndexParams = paths['/internal/AdvisorToolbox/Index']['get']['parameters']['query'];

        export type AdvisorToolboxIndexSuccess = paths['/internal/AdvisorToolbox/Index']['get']['responses']['200']['content']['text/plain'];

        export const AdvisorToolboxIndexUrl = '/internal/AdvisorToolbox/Index';

        
        export function AdvisorToolboxIndex(params: AdvisorToolboxIndexParams): Promise<AdvisorToolboxIndexSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<AdvisorToolboxIndexSuccess>(`/internal/AdvisorToolbox/Index${formatParams(params)}`)
        }
    
    
        

        

        export type AdvisorToolboxPreviewSuccess = paths['/internal/AdvisorToolbox/Preview']['get']['responses']['200']['content']['text/plain'];

        export const AdvisorToolboxPreviewUrl = '/internal/AdvisorToolbox/Preview';

        
        export function AdvisorToolboxPreview(): Promise<AdvisorToolboxPreviewSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<AdvisorToolboxPreviewSuccess>(`/internal/AdvisorToolbox/Preview`)
        }
    
    
        

        export type BambuserGetProductsParams = paths['/internal/Bambuser/GetProducts']['get']['parameters']['query'];

        export type BambuserGetProductsSuccess = paths['/internal/Bambuser/GetProducts']['get']['responses']['200']['content']['text/plain'];

        export const BambuserGetProductsUrl = '/internal/Bambuser/GetProducts';

        
        export function BambuserGetProducts(params: BambuserGetProductsParams): Promise<BambuserGetProductsSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<BambuserGetProductsSuccess>(`/internal/Bambuser/GetProducts${formatParams(params)}`)
        }
    
    
        

        

        export type GetBasketContentSuccess = paths['/internal/Bambuser/GetBasketContent']['get']['responses']['200']['content']['text/plain'];

        export const GetBasketContentUrl = '/internal/Bambuser/GetBasketContent';

        
        export function GetBasketContent(): Promise<GetBasketContentSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetBasketContentSuccess>(`/internal/Bambuser/GetBasketContent`)
        }
    
    
        

        export type LookupUrlParams = paths['/internal/Bambuser/LookupUrl']['get']['parameters']['query'];

        export type LookupUrlSuccess = paths['/internal/Bambuser/LookupUrl']['get']['responses']['200']['content']['text/plain'];

        export const LookupUrlUrl = '/internal/Bambuser/LookupUrl';

        
        export function LookupUrl(params: LookupUrlParams): Promise<LookupUrlSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<LookupUrlSuccess>(`/internal/Bambuser/LookupUrl${formatParams(params)}`)
        }
    
    
        

        

        export type GetBasketSuccess = paths['/internal/Basket/GetBasket']['get']['responses']['200']['content']['text/plain'];

        export const GetBasketUrl = '/internal/Basket/GetBasket';

        
        export function GetBasket(): Promise<GetBasketSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetBasketSuccess>(`/internal/Basket/GetBasket`)
        }
    
    
        

        

        export type _BasketFooterSuccess = paths['/internal/Basket/_BasketFooter']['get']['responses']['200']['content']['text/html'];

        export const _BasketFooterUrl = '/internal/Basket/_BasketFooter';

        
        export function _BasketFooter(): Promise<_BasketFooterSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchHTML(`/internal/Basket/_BasketFooter`)
        }
    
    
        

        

        export type _ShippingOptionsSuccess = paths['/internal/Basket/_ShippingOptions']['get']['responses']['200']['content']['text/plain'];

        export const _ShippingOptionsUrl = '/internal/Basket/_ShippingOptions';

        
        export function _ShippingOptions(): Promise<_ShippingOptionsSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<_ShippingOptionsSuccess>(`/internal/Basket/_ShippingOptions`)
        }
    
    
        

        export type DeleteFromBasketParams = paths['/internal/Basket/DeleteFromBasket']['post']['parameters']['query'];

        export type DeleteFromBasketSuccess = paths['/internal/Basket/DeleteFromBasket']['post']['responses']['200']['content']['text/plain'];

        export const DeleteFromBasketUrl = '/internal/Basket/DeleteFromBasket';

        
        export function DeleteFromBasket(params: DeleteFromBasketParams): Promise<DeleteFromBasketSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<DeleteFromBasketSuccess>(`/internal/Basket/DeleteFromBasket${formatParams(params)}`)
        }
    
    
        

        

        export type DeleteAllFromBasketSuccess = paths['/internal/Basket/DeleteAllFromBasket']['post']['responses']['200']['content']['text/plain'];

        export const DeleteAllFromBasketUrl = '/internal/Basket/DeleteAllFromBasket';

        
        export function DeleteAllFromBasket(): Promise<DeleteAllFromBasketSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<DeleteAllFromBasketSuccess>(`/internal/Basket/DeleteAllFromBasket`)
        }
    
    
        

        export type DeleteGiftFromBasketParams = paths['/internal/Basket/DeleteGiftFromBasket']['post']['parameters']['query'];

        

        export const DeleteGiftFromBasketUrl = '/internal/Basket/DeleteGiftFromBasket';

        
        export function DeleteGiftFromBasket(params: DeleteGiftFromBasketParams): Promise<unknown> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<unknown>(`/internal/Basket/DeleteGiftFromBasket${formatParams(params)}`)
        }
    
    
        

        export type AddToBasketParams = paths['/internal/Basket/AddToBasket']['post']['parameters']['query'];

        export type AddToBasketSuccess = paths['/internal/Basket/AddToBasket']['post']['responses']['200']['content']['text/plain'];

        export const AddToBasketUrl = '/internal/Basket/AddToBasket';

        
        export function AddToBasket(params: AddToBasketParams): Promise<AddToBasketSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<AddToBasketSuccess>(`/internal/Basket/AddToBasket${formatParams(params)}`)
        }
    
    
        

        export type AddMultipleToBasketParams = paths['/internal/Basket/AddMultipleToBasket']['post']['parameters']['query'];

        export type AddMultipleToBasketSuccess = paths['/internal/Basket/AddMultipleToBasket']['post']['responses']['200']['content']['text/plain'];

        export const AddMultipleToBasketUrl = '/internal/Basket/AddMultipleToBasket';

        
        export function AddMultipleToBasket(params: AddMultipleToBasketParams): Promise<AddMultipleToBasketSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<AddMultipleToBasketSuccess>(`/internal/Basket/AddMultipleToBasket${formatParams(params)}`)
        }
    
    
        

        export type AddOrUpdateQuantityParams = paths['/internal/Basket/AddOrUpdateQuantity']['post']['parameters']['query'];

        export type AddOrUpdateQuantitySuccess = paths['/internal/Basket/AddOrUpdateQuantity']['post']['responses']['200']['content']['text/plain'];

        export const AddOrUpdateQuantityUrl = '/internal/Basket/AddOrUpdateQuantity';

        
        export function AddOrUpdateQuantity(params: AddOrUpdateQuantityParams): Promise<AddOrUpdateQuantitySuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<AddOrUpdateQuantitySuccess>(`/internal/Basket/AddOrUpdateQuantity${formatParams(params)}`)
        }
    
    
        

        export type UpdateQuantityParams = paths['/internal/Basket/UpdateQuantity']['post']['parameters']['query'];

        export type UpdateQuantitySuccess = paths['/internal/Basket/UpdateQuantity']['post']['responses']['200']['content']['text/plain'];

        export const UpdateQuantityUrl = '/internal/Basket/UpdateQuantity';

        
        export function UpdateQuantity(params: UpdateQuantityParams): Promise<UpdateQuantitySuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<UpdateQuantitySuccess>(`/internal/Basket/UpdateQuantity${formatParams(params)}`)
        }
    
    
        

        export type UpdateFrequencyParams = paths['/internal/Basket/UpdateFrequency']['post']['parameters']['query'];

        export type UpdateFrequencySuccess = paths['/internal/Basket/UpdateFrequency']['post']['responses']['200']['content']['text/plain'];

        export const UpdateFrequencyUrl = '/internal/Basket/UpdateFrequency';

        
        export function UpdateFrequency(params: UpdateFrequencyParams): Promise<UpdateFrequencySuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<UpdateFrequencySuccess>(`/internal/Basket/UpdateFrequency${formatParams(params)}`)
        }
    
    
        

        export type ToggleSwapCertificatesParams = paths['/internal/Basket/ToggleSwapCertificates']['post']['parameters']['query'];

        export type ToggleSwapCertificatesSuccess = paths['/internal/Basket/ToggleSwapCertificates']['post']['responses']['200']['content']['text/plain'];

        export const ToggleSwapCertificatesUrl = '/internal/Basket/ToggleSwapCertificates';

        
        export function ToggleSwapCertificates(params: ToggleSwapCertificatesParams): Promise<ToggleSwapCertificatesSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<ToggleSwapCertificatesSuccess>(`/internal/Basket/ToggleSwapCertificates${formatParams(params)}`)
        }
    
    
        

        export type UpdateVoucherCodeParams = paths['/internal/Basket/UpdateVoucherCode']['post']['parameters']['query'];

        export type UpdateVoucherCodeSuccess = paths['/internal/Basket/UpdateVoucherCode']['post']['responses']['200']['content']['text/plain'];

        export const UpdateVoucherCodeUrl = '/internal/Basket/UpdateVoucherCode';

        
        export function UpdateVoucherCode(params: UpdateVoucherCodeParams): Promise<UpdateVoucherCodeSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<UpdateVoucherCodeSuccess>(`/internal/Basket/UpdateVoucherCode${formatParams(params)}`)
        }
    
    
        

        

        export type RemoveVoucherSuccess = paths['/internal/Basket/RemoveVoucher']['post']['responses']['200']['content']['text/plain'];

        export const RemoveVoucherUrl = '/internal/Basket/RemoveVoucher';

        
        export function RemoveVoucher(): Promise<RemoveVoucherSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<RemoveVoucherSuccess>(`/internal/Basket/RemoveVoucher`)
        }
    
    
        

        export type _BasketAddMinimumBuyParams = paths['/internal/Basket/_BasketAddMinimumBuy']['get']['parameters']['query'];

        export type _BasketAddMinimumBuySuccess = paths['/internal/Basket/_BasketAddMinimumBuy']['get']['responses']['200']['content']['text/html'];

        export const _BasketAddMinimumBuyUrl = '/internal/Basket/_BasketAddMinimumBuy';

        
        export function _BasketAddMinimumBuy(params: _BasketAddMinimumBuyParams): Promise<_BasketAddMinimumBuySuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchHTML(`/internal/Basket/_BasketAddMinimumBuy${formatParams(params)}`)
        }
    
    
        

        export type AddDigitalProductParams = paths['/internal/Basket/AddDigitalProduct']['post']['parameters']['query'];

        export type AddDigitalProductSuccess = paths['/internal/Basket/AddDigitalProduct']['post']['responses']['200']['content']['text/plain'];

        export const AddDigitalProductUrl = '/internal/Basket/AddDigitalProduct';

        
        export function AddDigitalProduct(params: AddDigitalProductParams): Promise<AddDigitalProductSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<AddDigitalProductSuccess>(`/internal/Basket/AddDigitalProduct${formatParams(params)}`)
        }
    
    
        

        export type RemovePrizeCertificateParams = paths['/internal/Basket/RemovePrizeCertificate']['post']['parameters']['query'];

        export type RemovePrizeCertificateSuccess = paths['/internal/Basket/RemovePrizeCertificate']['post']['responses']['200']['content']['text/plain'];

        export const RemovePrizeCertificateUrl = '/internal/Basket/RemovePrizeCertificate';

        
        export function RemovePrizeCertificate(params: RemovePrizeCertificateParams): Promise<RemovePrizeCertificateSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<RemovePrizeCertificateSuccess>(`/internal/Basket/RemovePrizeCertificate${formatParams(params)}`)
        }
    
    
        

        export type BasketAddPrizeCertificateParams = paths['/internal/Basket/AddPrizeCertificate']['post']['parameters']['query'];

        export type BasketAddPrizeCertificateSuccess = paths['/internal/Basket/AddPrizeCertificate']['post']['responses']['200']['content']['text/plain'];

        export const BasketAddPrizeCertificateUrl = '/internal/Basket/AddPrizeCertificate';

        
        export function BasketAddPrizeCertificate(params: BasketAddPrizeCertificateParams): Promise<BasketAddPrizeCertificateSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<BasketAddPrizeCertificateSuccess>(`/internal/Basket/AddPrizeCertificate${formatParams(params)}`)
        }
    
    
        

        

        export type GetSampleSelectionSuccess = paths['/internal/Basket/GetSampleSelection']['get']['responses']['200']['content']['text/plain'];

        export const GetSampleSelectionUrl = '/internal/Basket/GetSampleSelection';

        
        export function GetSampleSelection(): Promise<GetSampleSelectionSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetSampleSelectionSuccess>(`/internal/Basket/GetSampleSelection`)
        }
    
    
        

        export type ActionWillAffectSampleParams = paths['/internal/Basket/ActionWillAffectSample']['get']['parameters']['query'];

        export type ActionWillAffectSampleSuccess = paths['/internal/Basket/ActionWillAffectSample']['get']['responses']['200']['content']['text/plain'];

        export const ActionWillAffectSampleUrl = '/internal/Basket/ActionWillAffectSample';

        
        export function ActionWillAffectSample(params: ActionWillAffectSampleParams): Promise<ActionWillAffectSampleSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<ActionWillAffectSampleSuccess>(`/internal/Basket/ActionWillAffectSample${formatParams(params)}`)
        }
    
    
        

        export type AddSampleToBasketParams = paths['/internal/Basket/AddSampleToBasket']['post']['parameters']['query'];

        export type AddSampleToBasketSuccess = paths['/internal/Basket/AddSampleToBasket']['post']['responses']['200']['content']['text/plain'];

        export const AddSampleToBasketUrl = '/internal/Basket/AddSampleToBasket';

        
        export function AddSampleToBasket(params: AddSampleToBasketParams): Promise<AddSampleToBasketSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<AddSampleToBasketSuccess>(`/internal/Basket/AddSampleToBasket${formatParams(params)}`)
        }
    
    
        

        export type RemoveSampleFromBasketParams = paths['/internal/Basket/RemoveSampleFromBasket']['post']['parameters']['query'];

        export type RemoveSampleFromBasketSuccess = paths['/internal/Basket/RemoveSampleFromBasket']['post']['responses']['200']['content']['text/plain'];

        export const RemoveSampleFromBasketUrl = '/internal/Basket/RemoveSampleFromBasket';

        
        export function RemoveSampleFromBasket(params: RemoveSampleFromBasketParams): Promise<RemoveSampleFromBasketSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<RemoveSampleFromBasketSuccess>(`/internal/Basket/RemoveSampleFromBasket${formatParams(params)}`)
        }
    
    
        

        export type ReplaceSampleInBasketParams = paths['/internal/Basket/ReplaceSampleInBasket']['post']['parameters']['query'];

        export type ReplaceSampleInBasketSuccess = paths['/internal/Basket/ReplaceSampleInBasket']['post']['responses']['200']['content']['text/plain'];

        export const ReplaceSampleInBasketUrl = '/internal/Basket/ReplaceSampleInBasket';

        
        export function ReplaceSampleInBasket(params: ReplaceSampleInBasketParams): Promise<ReplaceSampleInBasketSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<ReplaceSampleInBasketSuccess>(`/internal/Basket/ReplaceSampleInBasket${formatParams(params)}`)
        }
    
    
        

        

        export type GetPointInfoModelSuccess = paths['/internal/Basket/GetPointInfoModel']['get']['responses']['200']['content']['text/plain'];

        export const GetPointInfoModelUrl = '/internal/Basket/GetPointInfoModel';

        
        export function GetPointInfoModel(): Promise<GetPointInfoModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetPointInfoModelSuccess>(`/internal/Basket/GetPointInfoModel`)
        }
    
    
        

        

        export type SignupForNotificationsSuccess = paths['/internal/BeautyTrendBox/SignupForNotifications']['post']['responses']['200']['content']['text/plain'];

        export const SignupForNotificationsUrl = '/internal/BeautyTrendBox/SignupForNotifications';

        
        export function SignupForNotifications(): Promise<SignupForNotificationsSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<SignupForNotificationsSuccess>(`/internal/BeautyTrendBox/SignupForNotifications`)
        }
    
    
        

        

        export type GetNotifyMeModelsSuccess = paths['/internal/BeautyTrendBox/GetNotifyMeModels']['get']['responses']['200']['content']['text/plain'];

        export const GetNotifyMeModelsUrl = '/internal/BeautyTrendBox/GetNotifyMeModels';

        
        export function GetNotifyMeModels(): Promise<GetNotifyMeModelsSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetNotifyMeModelsSuccess>(`/internal/BeautyTrendBox/GetNotifyMeModels`)
        }
    
    
        

        

        export type GetFutureBookingsSuccess = paths['/internal/myprofile/Booking/GetFutureBookings']['get']['responses']['200']['content']['text/plain'];

        export const GetFutureBookingsUrl = '/internal/myprofile/Booking/GetFutureBookings';

        
        export function GetFutureBookings(): Promise<GetFutureBookingsSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetFutureBookingsSuccess>(`/internal/myprofile/Booking/GetFutureBookings`)
        }
    
    
        

        export type GetPreviousBookingsParams = paths['/internal/myprofile/Booking/GetPreviousBookings']['get']['parameters']['query'];

        export type GetPreviousBookingsSuccess = paths['/internal/myprofile/Booking/GetPreviousBookings']['get']['responses']['200']['content']['text/plain'];

        export const GetPreviousBookingsUrl = '/internal/myprofile/Booking/GetPreviousBookings';

        
        export function GetPreviousBookings(params: GetPreviousBookingsParams): Promise<GetPreviousBookingsSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetPreviousBookingsSuccess>(`/internal/myprofile/Booking/GetPreviousBookings${formatParams(params)}`)
        }
    
    
        

        export type GetBookingDetailsParams = paths['/internal/myprofile/Booking/GetBookingDetails']['get']['parameters']['query'];

        export type GetBookingDetailsSuccess = paths['/internal/myprofile/Booking/GetBookingDetails']['get']['responses']['200']['content']['text/plain'];

        export const GetBookingDetailsUrl = '/internal/myprofile/Booking/GetBookingDetails';

        
        export function GetBookingDetails(params: GetBookingDetailsParams): Promise<GetBookingDetailsSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetBookingDetailsSuccess>(`/internal/myprofile/Booking/GetBookingDetails${formatParams(params)}`)
        }
    
    
        

        export type CancelBookingParams = paths['/internal/myprofile/Booking/CancelBooking']['post']['parameters']['query'];

        export type CancelBookingSuccess = paths['/internal/myprofile/Booking/CancelBooking']['post']['responses']['200']['content']['text/plain'];

        export const CancelBookingUrl = '/internal/myprofile/Booking/CancelBooking';

        
        export function CancelBooking(params: CancelBookingParams): Promise<CancelBookingSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<CancelBookingSuccess>(`/internal/myprofile/Booking/CancelBooking${formatParams(params)}`)
        }
    
    
        

        export type UpdateBookingParams = paths['/internal/myprofile/Booking/UpdateBooking']['post']['parameters']['query'];

        export type UpdateBookingSuccess = paths['/internal/myprofile/Booking/UpdateBooking']['post']['responses']['200']['content']['text/plain'];

        export const UpdateBookingUrl = '/internal/myprofile/Booking/UpdateBooking';

        
        export function UpdateBooking(params: UpdateBookingParams): Promise<UpdateBookingSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<UpdateBookingSuccess>(`/internal/myprofile/Booking/UpdateBooking${formatParams(params)}`)
        }
    
    
        export type CreateFeedbackRequestBody = paths['/internal/myprofile/Booking/CreateFeedback']['post']['requestBody']['content']['application/json'];

        

        export type CreateFeedbackSuccess = paths['/internal/myprofile/Booking/CreateFeedback']['post']['responses']['200']['content']['text/plain'];

        export const CreateFeedbackUrl = '/internal/myprofile/Booking/CreateFeedback';

        
        export function CreateFeedback(requestBody: CreateFeedbackRequestBody): Promise<CreateFeedbackSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postJSON<CreateFeedbackSuccess>(`/internal/myprofile/Booking/CreateFeedback`, requestBody)
        }
    
    
        

        export type GetTicketListPdfParams = paths['/internal/myprofile/Booking/GetTicketListPdf']['get']['parameters']['query'];

        export type GetTicketListPdfSuccess = paths['/internal/myprofile/Booking/GetTicketListPdf']['get']['responses']['200']['content']['text/plain'];

        export const GetTicketListPdfUrl = '/internal/myprofile/Booking/GetTicketListPdf';

        
        export function GetTicketListPdf(params: GetTicketListPdfParams): Promise<GetTicketListPdfSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetTicketListPdfSuccess>(`/internal/myprofile/Booking/GetTicketListPdf${formatParams(params)}`)
        }
    
    
        

        export type GetTicketPdfParams = paths['/internal/myprofile/Booking/GetTicketPdf']['get']['parameters']['query'];

        export type GetTicketPdfSuccess = paths['/internal/myprofile/Booking/GetTicketPdf']['get']['responses']['200']['content']['text/plain'];

        export const GetTicketPdfUrl = '/internal/myprofile/Booking/GetTicketPdf';

        
        export function GetTicketPdf(params: GetTicketPdfParams): Promise<GetTicketPdfSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetTicketPdfSuccess>(`/internal/myprofile/Booking/GetTicketPdf${formatParams(params)}`)
        }
    
    
        

        

        export type MyProfileBookingModelSuccess = paths['/internal/Booking/MyProfileBookingModel']['get']['responses']['200']['content']['text/plain'];

        export const MyProfileBookingModelUrl = '/internal/Booking/MyProfileBookingModel';

        
        export function MyProfileBookingModel(): Promise<MyProfileBookingModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<MyProfileBookingModelSuccess>(`/internal/Booking/MyProfileBookingModel`)
        }
    
    
        

        

        export type MyProfileBookingPageViewModelSuccess = paths['/internal/Booking/MyProfileBookingPageViewModel']['get']['responses']['200']['content']['text/plain'];

        export const MyProfileBookingPageViewModelUrl = '/internal/Booking/MyProfileBookingPageViewModel';

        
        export function MyProfileBookingPageViewModel(): Promise<MyProfileBookingPageViewModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<MyProfileBookingPageViewModelSuccess>(`/internal/Booking/MyProfileBookingPageViewModel`)
        }
    
    
        

        

        export type MyProfileBookingDetailsPageViewModelSuccess = paths['/internal/Booking/MyProfileBookingDetailsPageViewModel']['get']['responses']['200']['content']['text/plain'];

        export const MyProfileBookingDetailsPageViewModelUrl = '/internal/Booking/MyProfileBookingDetailsPageViewModel';

        
        export function MyProfileBookingDetailsPageViewModel(): Promise<MyProfileBookingDetailsPageViewModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<MyProfileBookingDetailsPageViewModelSuccess>(`/internal/Booking/MyProfileBookingDetailsPageViewModel`)
        }
    
    
        

        

        export type BrandListModelSuccess = paths['/internal/Brand/BrandListModel']['get']['responses']['200']['content']['text/plain'];

        export const BrandListModelUrl = '/internal/Brand/BrandListModel';

        
        export function BrandListModel(): Promise<BrandListModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<BrandListModelSuccess>(`/internal/Brand/BrandListModel`)
        }
    
    
        

        export type GetDrawerForMemberParams = paths['/internal/myprofile/BrandClub/GetDrawerForMember']['get']['parameters']['query'];

        export type GetDrawerForMemberSuccess = paths['/internal/myprofile/BrandClub/GetDrawerForMember']['get']['responses']['200']['content']['text/plain'];

        export const GetDrawerForMemberUrl = '/internal/myprofile/BrandClub/GetDrawerForMember';

        
        export function GetDrawerForMember(params: GetDrawerForMemberParams): Promise<GetDrawerForMemberSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetDrawerForMemberSuccess>(`/internal/myprofile/BrandClub/GetDrawerForMember${formatParams(params)}`)
        }
    
    
        

        export type GetDrawerForNotMemberParams = paths['/internal/myprofile/BrandClub/GetDrawerForNotMember']['get']['parameters']['query'];

        export type GetDrawerForNotMemberSuccess = paths['/internal/myprofile/BrandClub/GetDrawerForNotMember']['get']['responses']['200']['content']['text/plain'];

        export const GetDrawerForNotMemberUrl = '/internal/myprofile/BrandClub/GetDrawerForNotMember';

        
        export function GetDrawerForNotMember(params: GetDrawerForNotMemberParams): Promise<GetDrawerForNotMemberSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetDrawerForNotMemberSuccess>(`/internal/myprofile/BrandClub/GetDrawerForNotMember${formatParams(params)}`)
        }
    
    
        

        export type BrandClubGetSignOffModelParams = paths['/internal/myprofile/BrandClub/GetSignOffModel']['get']['parameters']['query'];

        export type BrandClubGetSignOffModelSuccess = paths['/internal/myprofile/BrandClub/GetSignOffModel']['get']['responses']['200']['content']['text/plain'];

        export const BrandClubGetSignOffModelUrl = '/internal/myprofile/BrandClub/GetSignOffModel';

        
        export function BrandClubGetSignOffModel(params: BrandClubGetSignOffModelParams): Promise<BrandClubGetSignOffModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<BrandClubGetSignOffModelSuccess>(`/internal/myprofile/BrandClub/GetSignOffModel${formatParams(params)}`)
        }
    
    
        

        export type GetFollowBrandClubModelParams = paths['/internal/myprofile/BrandClub/GetFollowBrandClubModel']['get']['parameters']['query'];

        export type GetFollowBrandClubModelSuccess = paths['/internal/myprofile/BrandClub/GetFollowBrandClubModel']['get']['responses']['200']['content']['text/plain'];

        export const GetFollowBrandClubModelUrl = '/internal/myprofile/BrandClub/GetFollowBrandClubModel';

        
        export function GetFollowBrandClubModel(params: GetFollowBrandClubModelParams): Promise<GetFollowBrandClubModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetFollowBrandClubModelSuccess>(`/internal/myprofile/BrandClub/GetFollowBrandClubModel${formatParams(params)}`)
        }
    
    
        

        export type _NewsParams = paths['/internal/myprofile/BrandClub/_News']['get']['parameters']['query'];

        export type _NewsSuccess = paths['/internal/myprofile/BrandClub/_News']['get']['responses']['200']['content']['text/plain'];

        export const _NewsUrl = '/internal/myprofile/BrandClub/_News';

        
        export function _News(params: _NewsParams): Promise<_NewsSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<_NewsSuccess>(`/internal/myprofile/BrandClub/_News${formatParams(params)}`)
        }
    
    
        

        export type BrandClubSignupParams = paths['/internal/myprofile/BrandClub/Signup']['post']['parameters']['query'];

        

        export const BrandClubSignupUrl = '/internal/myprofile/BrandClub/Signup';

        
        export function BrandClubSignup(params: BrandClubSignupParams): Promise<unknown> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<unknown>(`/internal/myprofile/BrandClub/Signup${formatParams(params)}`)
        }
    
    
        

        export type BrandClubSignoffParams = paths['/internal/myprofile/BrandClub/Signoff']['post']['parameters']['query'];

        

        export const BrandClubSignoffUrl = '/internal/myprofile/BrandClub/Signoff';

        
        export function BrandClubSignoff(params: BrandClubSignoffParams): Promise<unknown> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<unknown>(`/internal/myprofile/BrandClub/Signoff${formatParams(params)}`)
        }
    
    
        

        

        export type BrandClubPageModelSuccess = paths['/internal/myprofile/BrandClub/BrandClubPageModel']['get']['responses']['200']['content']['text/plain'];

        export const BrandClubPageModelUrl = '/internal/myprofile/BrandClub/BrandClubPageModel';

        
        export function BrandClubPageModel(): Promise<BrandClubPageModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<BrandClubPageModelSuccess>(`/internal/myprofile/BrandClub/BrandClubPageModel`)
        }
    
    
        

        

        export type BrandClubFrontPageModelSuccess = paths['/internal/myprofile/BrandClub/BrandClubFrontPageModel']['get']['responses']['200']['content']['text/plain'];

        export const BrandClubFrontPageModelUrl = '/internal/myprofile/BrandClub/BrandClubFrontPageModel';

        
        export function BrandClubFrontPageModel(): Promise<BrandClubFrontPageModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<BrandClubFrontPageModelSuccess>(`/internal/myprofile/BrandClub/BrandClubFrontPageModel`)
        }
    
    
        

        

        export type BrandClubBonusPageModelSuccess = paths['/internal/myprofile/BrandClub/BrandClubBonusPageModel']['get']['responses']['200']['content']['text/plain'];

        export const BrandClubBonusPageModelUrl = '/internal/myprofile/BrandClub/BrandClubBonusPageModel';

        
        export function BrandClubBonusPageModel(): Promise<BrandClubBonusPageModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<BrandClubBonusPageModelSuccess>(`/internal/myprofile/BrandClub/BrandClubBonusPageModel`)
        }
    
    
        

        

        export type BrandClubSignupModelSuccess = paths['/internal/myprofile/BrandClub/BrandClubSignupModel']['get']['responses']['200']['content']['text/plain'];

        export const BrandClubSignupModelUrl = '/internal/myprofile/BrandClub/BrandClubSignupModel';

        
        export function BrandClubSignupModel(): Promise<BrandClubSignupModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<BrandClubSignupModelSuccess>(`/internal/myprofile/BrandClub/BrandClubSignupModel`)
        }
    
    
        

        

        export type BrandClubMessageModelSuccess = paths['/internal/myprofile/BrandClub/BrandClubMessageModel']['get']['responses']['200']['content']['text/plain'];

        export const BrandClubMessageModelUrl = '/internal/myprofile/BrandClub/BrandClubMessageModel';

        
        export function BrandClubMessageModel(): Promise<BrandClubMessageModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<BrandClubMessageModelSuccess>(`/internal/myprofile/BrandClub/BrandClubMessageModel`)
        }
    
    
        

        

        export type BrandClubPageViewModelSuccess = paths['/internal/myprofile/BrandClub/BrandClubPageViewModel']['get']['responses']['200']['content']['text/plain'];

        export const BrandClubPageViewModelUrl = '/internal/myprofile/BrandClub/BrandClubPageViewModel';

        
        export function BrandClubPageViewModel(): Promise<BrandClubPageViewModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<BrandClubPageViewModelSuccess>(`/internal/myprofile/BrandClub/BrandClubPageViewModel`)
        }
    
    
        

        export type CampaignInfoDetailsByDiscountIdParams = paths['/internal/CampaignInfo/CampaignInfoDetailsByDiscountId']['get']['parameters']['query'];

        export type CampaignInfoDetailsByDiscountIdSuccess = paths['/internal/CampaignInfo/CampaignInfoDetailsByDiscountId']['get']['responses']['200']['content']['text/plain'];

        export const CampaignInfoDetailsByDiscountIdUrl = '/internal/CampaignInfo/CampaignInfoDetailsByDiscountId';

        
        export function CampaignInfoDetailsByDiscountId(params: CampaignInfoDetailsByDiscountIdParams): Promise<CampaignInfoDetailsByDiscountIdSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<CampaignInfoDetailsByDiscountIdSuccess>(`/internal/CampaignInfo/CampaignInfoDetailsByDiscountId${formatParams(params)}`)
        }
    
    
        

        export type CampaignInfoDetailsByProductIdParams = paths['/internal/CampaignInfo/CampaignInfoDetailsByProductId']['get']['parameters']['query'];

        export type CampaignInfoDetailsByProductIdSuccess = paths['/internal/CampaignInfo/CampaignInfoDetailsByProductId']['get']['responses']['200']['content']['text/plain'];

        export const CampaignInfoDetailsByProductIdUrl = '/internal/CampaignInfo/CampaignInfoDetailsByProductId';

        
        export function CampaignInfoDetailsByProductId(params: CampaignInfoDetailsByProductIdParams): Promise<CampaignInfoDetailsByProductIdSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<CampaignInfoDetailsByProductIdSuccess>(`/internal/CampaignInfo/CampaignInfoDetailsByProductId${formatParams(params)}`)
        }
    
    
        

        export type CampaignInfoByProductIdParams = paths['/internal/CampaignInfo/CampaignInfoByProductId']['get']['parameters']['query'];

        export type CampaignInfoByProductIdSuccess = paths['/internal/CampaignInfo/CampaignInfoByProductId']['get']['responses']['200']['content']['text/plain'];

        export const CampaignInfoByProductIdUrl = '/internal/CampaignInfo/CampaignInfoByProductId';

        
        export function CampaignInfoByProductId(params: CampaignInfoByProductIdParams): Promise<CampaignInfoByProductIdSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<CampaignInfoByProductIdSuccess>(`/internal/CampaignInfo/CampaignInfoByProductId${formatParams(params)}`)
        }
    
    
        

        

        export type GetUspListModelSuccess = paths['/internal/ClubMatas/GetUspListModel']['get']['responses']['200']['content']['text/plain'];

        export const GetUspListModelUrl = '/internal/ClubMatas/GetUspListModel';

        
        export function GetUspListModel(): Promise<GetUspListModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetUspListModelSuccess>(`/internal/ClubMatas/GetUspListModel`)
        }
    
    
        

        

        export type FullwidthSuccess = paths['/internal/ConnectedContentBlockModel/Fullwidth']['get']['responses']['200']['content']['text/plain'];

        export const FullwidthUrl = '/internal/ConnectedContentBlockModel/Fullwidth';

        
        export function Fullwidth(): Promise<FullwidthSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<FullwidthSuccess>(`/internal/ConnectedContentBlockModel/Fullwidth`)
        }
    
    
        

        

        export type InlineSuccess = paths['/internal/ConnectedContentBlockModel/Inline']['get']['responses']['200']['content']['text/plain'];

        export const InlineUrl = '/internal/ConnectedContentBlockModel/Inline';

        
        export function Inline(): Promise<InlineSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<InlineSuccess>(`/internal/ConnectedContentBlockModel/Inline`)
        }
    
    
        

        

        export type PdpSuccess = paths['/internal/ConnectedContentBlockModel/Pdp']['get']['responses']['200']['content']['text/plain'];

        export const PdpUrl = '/internal/ConnectedContentBlockModel/Pdp';

        
        export function Pdp(): Promise<PdpSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<PdpSuccess>(`/internal/ConnectedContentBlockModel/Pdp`)
        }
    
    
        

        export type AcceptTermsParams = paths['/internal/Consent/AcceptTerms']['post']['parameters']['query'];

        export type AcceptTermsSuccess = paths['/internal/Consent/AcceptTerms']['post']['responses']['200']['content']['text/plain'];

        export const AcceptTermsUrl = '/internal/Consent/AcceptTerms';

        
        export function AcceptTerms(params: AcceptTermsParams): Promise<AcceptTermsSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<AcceptTermsSuccess>(`/internal/Consent/AcceptTerms${formatParams(params)}`)
        }
    
    
        

        export type SearchBookingSlotsParams = paths['/internal/booking/ConsultationBooking/SearchBookingSlots']['get']['parameters']['query'];

        export type SearchBookingSlotsSuccess = paths['/internal/booking/ConsultationBooking/SearchBookingSlots']['get']['responses']['200']['content']['text/plain'];

        export const SearchBookingSlotsUrl = '/internal/booking/ConsultationBooking/SearchBookingSlots';

        
        export function SearchBookingSlots(params: SearchBookingSlotsParams): Promise<SearchBookingSlotsSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<SearchBookingSlotsSuccess>(`/internal/booking/ConsultationBooking/SearchBookingSlots${formatParams(params)}`)
        }
    
    
        

        export type SearchBookingSlotByLocationParams = paths['/internal/booking/ConsultationBooking/SearchBookingSlotByLocation']['get']['parameters']['query'];

        export type SearchBookingSlotByLocationSuccess = paths['/internal/booking/ConsultationBooking/SearchBookingSlotByLocation']['get']['responses']['200']['content']['text/plain'];

        export const SearchBookingSlotByLocationUrl = '/internal/booking/ConsultationBooking/SearchBookingSlotByLocation';

        
        export function SearchBookingSlotByLocation(params: SearchBookingSlotByLocationParams): Promise<SearchBookingSlotByLocationSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<SearchBookingSlotByLocationSuccess>(`/internal/booking/ConsultationBooking/SearchBookingSlotByLocation${formatParams(params)}`)
        }
    
    
        

        export type GetBookingOverviewParams = paths['/internal/booking/ConsultationBooking/GetBookingOverview']['get']['parameters']['query'];

        export type GetBookingOverviewSuccess = paths['/internal/booking/ConsultationBooking/GetBookingOverview']['get']['responses']['200']['content']['text/plain'];

        export const GetBookingOverviewUrl = '/internal/booking/ConsultationBooking/GetBookingOverview';

        
        export function GetBookingOverview(params: GetBookingOverviewParams): Promise<GetBookingOverviewSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetBookingOverviewSuccess>(`/internal/booking/ConsultationBooking/GetBookingOverview${formatParams(params)}`)
        }
    
    
        

        export type BookParams = paths['/internal/booking/ConsultationBooking/Book']['post']['parameters']['query'];

        export type BookSuccess = paths['/internal/booking/ConsultationBooking/Book']['post']['responses']['200']['content']['text/plain'];

        export const BookUrl = '/internal/booking/ConsultationBooking/Book';

        
        export function Book(params: BookParams): Promise<BookSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<BookSuccess>(`/internal/booking/ConsultationBooking/Book${formatParams(params)}`)
        }
    
    
        

        

        

        export const GetBookingEarlyStageUrl = '/internal/booking/ConsultationBooking/GetBookingEarlyStage';

        
        export function GetBookingEarlyStage(): Promise<unknown> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<unknown>(`/internal/booking/ConsultationBooking/GetBookingEarlyStage`)
        }
    
    
        

        export type GetTotalTicketPriceParams = paths['/internal/booking/ConsultationBooking/GetTotalTicketPrice']['get']['parameters']['query'];

        export type GetTotalTicketPriceSuccess = paths['/internal/booking/ConsultationBooking/GetTotalTicketPrice']['get']['responses']['200']['content']['text/plain'];

        export const GetTotalTicketPriceUrl = '/internal/booking/ConsultationBooking/GetTotalTicketPrice';

        
        export function GetTotalTicketPrice(params: GetTotalTicketPriceParams): Promise<GetTotalTicketPriceSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetTotalTicketPriceSuccess>(`/internal/booking/ConsultationBooking/GetTotalTicketPrice${formatParams(params)}`)
        }
    
    
        

        

        export type BookingReceiptModelSuccess = paths['/internal/ConsultationBooking/BookingReceiptModel']['get']['responses']['200']['content']['text/plain'];

        export const BookingReceiptModelUrl = '/internal/ConsultationBooking/BookingReceiptModel';

        
        export function BookingReceiptModel(): Promise<BookingReceiptModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<BookingReceiptModelSuccess>(`/internal/ConsultationBooking/BookingReceiptModel`)
        }
    
    
        

        

        export type BookRequestModelSuccess = paths['/internal/ConsultationBooking/BookRequestModel']['get']['responses']['200']['content']['text/plain'];

        export const BookRequestModelUrl = '/internal/ConsultationBooking/BookRequestModel';

        
        export function BookRequestModel(): Promise<BookRequestModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<BookRequestModelSuccess>(`/internal/ConsultationBooking/BookRequestModel`)
        }
    
    
        

        

        export type BookingPosJournalModelSuccess = paths['/internal/ConsultationBooking/BookingPosJournalModel']['get']['responses']['200']['content']['text/plain'];

        export const BookingPosJournalModelUrl = '/internal/ConsultationBooking/BookingPosJournalModel';

        
        export function BookingPosJournalModel(): Promise<BookingPosJournalModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<BookingPosJournalModelSuccess>(`/internal/ConsultationBooking/BookingPosJournalModel`)
        }
    
    
        

        export type ListConsultationProductsParams = paths['/internal/booking/ConsultationProduct/ListConsultationProducts']['get']['parameters']['query'];

        export type ListConsultationProductsSuccess = paths['/internal/booking/ConsultationProduct/ListConsultationProducts']['get']['responses']['200']['content']['text/plain'];

        export const ListConsultationProductsUrl = '/internal/booking/ConsultationProduct/ListConsultationProducts';

        
        export function ListConsultationProducts(params: ListConsultationProductsParams): Promise<ListConsultationProductsSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<ListConsultationProductsSuccess>(`/internal/booking/ConsultationProduct/ListConsultationProducts${formatParams(params)}`)
        }
    
    
        

        

        export type ConsultationProductPageModelSuccess = paths['/internal/ConsultationProduct/ConsultationProductPageModel']['get']['responses']['200']['content']['text/plain'];

        export const ConsultationProductPageModelUrl = '/internal/ConsultationProduct/ConsultationProductPageModel';

        
        export function ConsultationProductPageModel(): Promise<ConsultationProductPageModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<ConsultationProductPageModelSuccess>(`/internal/ConsultationProduct/ConsultationProductPageModel`)
        }
    
    
        

        

        export type ConsultationProductListPageContentViewModelSuccess = paths['/internal/ConsultationProduct/ConsultationProductListPageContentViewModel']['get']['responses']['200']['content']['text/plain'];

        export const ConsultationProductListPageContentViewModelUrl = '/internal/ConsultationProduct/ConsultationProductListPageContentViewModel';

        
        export function ConsultationProductListPageContentViewModel(): Promise<ConsultationProductListPageContentViewModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<ConsultationProductListPageContentViewModelSuccess>(`/internal/ConsultationProduct/ConsultationProductListPageContentViewModel`)
        }
    
    
        

        export type ChangeCreditCardChoiceParams = paths['/internal/myprofile/CreditCard/ChangeCreditCardChoice']['get']['parameters']['query'];

        export type ChangeCreditCardChoiceSuccess = paths['/internal/myprofile/CreditCard/ChangeCreditCardChoice']['get']['responses']['200']['content']['text/plain'];

        export const ChangeCreditCardChoiceUrl = '/internal/myprofile/CreditCard/ChangeCreditCardChoice';

        
        export function ChangeCreditCardChoice(params: ChangeCreditCardChoiceParams): Promise<ChangeCreditCardChoiceSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<ChangeCreditCardChoiceSuccess>(`/internal/myprofile/CreditCard/ChangeCreditCardChoice${formatParams(params)}`)
        }
    
    
        

        export type _CustomizeProductParams = paths['/internal/CustomProductAction/_CustomizeProduct']['get']['parameters']['query'];

        export type _CustomizeProductSuccess = paths['/internal/CustomProductAction/_CustomizeProduct']['get']['responses']['200']['content']['text/plain'];

        export const _CustomizeProductUrl = '/internal/CustomProductAction/_CustomizeProduct';

        
        export function _CustomizeProduct(params: _CustomizeProductParams): Promise<_CustomizeProductSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<_CustomizeProductSuccess>(`/internal/CustomProductAction/_CustomizeProduct${formatParams(params)}`)
        }
    
    
        export type AddCustomProductToBasketRequestBody = paths['/internal/CustomProductAction/AddCustomProductToBasket']['post']['requestBody']['content']['multipart/form-data'];

        export type AddCustomProductToBasketParams = paths['/internal/CustomProductAction/AddCustomProductToBasket']['post']['parameters']['query'];

        export type AddCustomProductToBasketSuccess = paths['/internal/CustomProductAction/AddCustomProductToBasket']['post']['responses']['200']['content']['text/plain'];

        export const AddCustomProductToBasketUrl = '/internal/CustomProductAction/AddCustomProductToBasket';

        
        export function AddCustomProductToBasket(params: AddCustomProductToBasketParams, requestBody: AddCustomProductToBasketRequestBody): Promise<AddCustomProductToBasketSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postFormData<AddCustomProductToBasketSuccess>(`/internal/CustomProductAction/AddCustomProductToBasket${formatParams(params)}`, requestBody)
        }
    
    
        

        

        export type CustomizeDigitalGiftCardModelSuccess = paths['/internal/CustomProductAction/CustomizeDigitalGiftCardModel']['get']['responses']['200']['content']['text/plain'];

        export const CustomizeDigitalGiftCardModelUrl = '/internal/CustomProductAction/CustomizeDigitalGiftCardModel';

        
        export function CustomizeDigitalGiftCardModel(): Promise<CustomizeDigitalGiftCardModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<CustomizeDigitalGiftCardModelSuccess>(`/internal/CustomProductAction/CustomizeDigitalGiftCardModel`)
        }
    
    
        

        

        export type PrizeCertificatesSuccess = paths['/internal/checkout/Delivery/PrizeCertificates']['get']['responses']['200']['content']['text/plain'];

        export const PrizeCertificatesUrl = '/internal/checkout/Delivery/PrizeCertificates';

        
        export function PrizeCertificates(): Promise<PrizeCertificatesSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<PrizeCertificatesSuccess>(`/internal/checkout/Delivery/PrizeCertificates`)
        }
    
    
        

        export type AddPrizeCertificateParams = paths['/internal/checkout/Delivery/AddPrizeCertificate']['post']['parameters']['query'];

        export type AddPrizeCertificateSuccess = paths['/internal/checkout/Delivery/AddPrizeCertificate']['post']['responses']['200']['content']['text/plain'];

        export const AddPrizeCertificateUrl = '/internal/checkout/Delivery/AddPrizeCertificate';

        
        export function AddPrizeCertificate(params: AddPrizeCertificateParams): Promise<AddPrizeCertificateSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<AddPrizeCertificateSuccess>(`/internal/checkout/Delivery/AddPrizeCertificate${formatParams(params)}`)
        }
    
    
        

        export type DeliveryRemovePrizeCertificateParams = paths['/internal/checkout/Delivery/RemovePrizeCertificate']['post']['parameters']['query'];

        export type DeliveryRemovePrizeCertificateSuccess = paths['/internal/checkout/Delivery/RemovePrizeCertificate']['post']['responses']['200']['content']['text/plain'];

        export const DeliveryRemovePrizeCertificateUrl = '/internal/checkout/Delivery/RemovePrizeCertificate';

        
        export function DeliveryRemovePrizeCertificate(params: DeliveryRemovePrizeCertificateParams): Promise<DeliveryRemovePrizeCertificateSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<DeliveryRemovePrizeCertificateSuccess>(`/internal/checkout/Delivery/RemovePrizeCertificate${formatParams(params)}`)
        }
    
    
        

        

        export type CheckoutDeliveryBasketSuccess = paths['/internal/checkout/Delivery/CheckoutDeliveryBasket']['get']['responses']['200']['content']['text/plain'];

        export const CheckoutDeliveryBasketUrl = '/internal/checkout/Delivery/CheckoutDeliveryBasket';

        
        export function CheckoutDeliveryBasket(): Promise<CheckoutDeliveryBasketSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<CheckoutDeliveryBasketSuccess>(`/internal/checkout/Delivery/CheckoutDeliveryBasket`)
        }
    
    
        

        export type GetCityFromZipCodeParams = paths['/internal/checkout/Delivery/GetCityFromZipCode']['get']['parameters']['query'];

        export type GetCityFromZipCodeSuccess = paths['/internal/checkout/Delivery/GetCityFromZipCode']['get']['responses']['200']['content']['text/plain'];

        export const GetCityFromZipCodeUrl = '/internal/checkout/Delivery/GetCityFromZipCode';

        
        export function GetCityFromZipCode(params: GetCityFromZipCodeParams): Promise<GetCityFromZipCodeSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetCityFromZipCodeSuccess>(`/internal/checkout/Delivery/GetCityFromZipCode${formatParams(params)}`)
        }
    
    
        

        

        export type GetDeliveryGroupsSuccess = paths['/internal/checkout/Delivery/GetDeliveryGroups']['get']['responses']['200']['content']['text/plain'];

        export const GetDeliveryGroupsUrl = '/internal/checkout/Delivery/GetDeliveryGroups';

        
        export function GetDeliveryGroups(): Promise<GetDeliveryGroupsSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetDeliveryGroupsSuccess>(`/internal/checkout/Delivery/GetDeliveryGroups`)
        }
    
    
        

        export type GetDeliveryGroupParams = paths['/internal/checkout/Delivery/GetDeliveryGroup']['get']['parameters']['query'];

        export type GetDeliveryGroupSuccess = paths['/internal/checkout/Delivery/GetDeliveryGroup']['get']['responses']['200']['content']['text/plain'];

        export const GetDeliveryGroupUrl = '/internal/checkout/Delivery/GetDeliveryGroup';

        
        export function GetDeliveryGroup(params: GetDeliveryGroupParams): Promise<GetDeliveryGroupSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetDeliveryGroupSuccess>(`/internal/checkout/Delivery/GetDeliveryGroup${formatParams(params)}`)
        }
    
    
        export type UpdateInvoiceAddressRequestBody = paths['/internal/checkout/Delivery/UpdateInvoiceAddress']['post']['requestBody']['content']['application/json'];

        

        export type UpdateInvoiceAddressSuccess = paths['/internal/checkout/Delivery/UpdateInvoiceAddress']['post']['responses']['200']['content']['text/plain'];

        export const UpdateInvoiceAddressUrl = '/internal/checkout/Delivery/UpdateInvoiceAddress';

        
        export function UpdateInvoiceAddress(requestBody: UpdateInvoiceAddressRequestBody): Promise<UpdateInvoiceAddressSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postJSON<UpdateInvoiceAddressSuccess>(`/internal/checkout/Delivery/UpdateInvoiceAddress`, requestBody)
        }
    
    
        

        export type SelectPrivateDeliveryParams = paths['/internal/checkout/Delivery/SelectPrivateDelivery']['post']['parameters']['query'];

        export type SelectPrivateDeliverySuccess = paths['/internal/checkout/Delivery/SelectPrivateDelivery']['post']['responses']['200']['content']['text/plain'];

        export const SelectPrivateDeliveryUrl = '/internal/checkout/Delivery/SelectPrivateDelivery';

        
        export function SelectPrivateDelivery(params: SelectPrivateDeliveryParams): Promise<SelectPrivateDeliverySuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<SelectPrivateDeliverySuccess>(`/internal/checkout/Delivery/SelectPrivateDelivery${formatParams(params)}`)
        }
    
    
        export type UpdatePrivateDeliveryAddressRequestBody = paths['/internal/checkout/Delivery/UpdatePrivateDeliveryAddress']['post']['requestBody']['content']['application/json'];

        

        export type UpdatePrivateDeliveryAddressSuccess = paths['/internal/checkout/Delivery/UpdatePrivateDeliveryAddress']['post']['responses']['200']['content']['text/plain'];

        export const UpdatePrivateDeliveryAddressUrl = '/internal/checkout/Delivery/UpdatePrivateDeliveryAddress';

        
        export function UpdatePrivateDeliveryAddress(requestBody: UpdatePrivateDeliveryAddressRequestBody): Promise<UpdatePrivateDeliveryAddressSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postJSON<UpdatePrivateDeliveryAddressSuccess>(`/internal/checkout/Delivery/UpdatePrivateDeliveryAddress`, requestBody)
        }
    
    
        export type SelectAndUpdateCompanyDeliveryAddressRequestBody = paths['/internal/checkout/Delivery/SelectAndUpdateCompanyDeliveryAddress']['post']['requestBody']['content']['application/json'];

        

        export type SelectAndUpdateCompanyDeliveryAddressSuccess = paths['/internal/checkout/Delivery/SelectAndUpdateCompanyDeliveryAddress']['post']['responses']['200']['content']['text/plain'];

        export const SelectAndUpdateCompanyDeliveryAddressUrl = '/internal/checkout/Delivery/SelectAndUpdateCompanyDeliveryAddress';

        
        export function SelectAndUpdateCompanyDeliveryAddress(requestBody: SelectAndUpdateCompanyDeliveryAddressRequestBody): Promise<SelectAndUpdateCompanyDeliveryAddressSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postJSON<SelectAndUpdateCompanyDeliveryAddressSuccess>(`/internal/checkout/Delivery/SelectAndUpdateCompanyDeliveryAddress`, requestBody)
        }
    
    
        

        export type SelectParcelShopDeliveryParams = paths['/internal/checkout/Delivery/SelectParcelShopDelivery']['post']['parameters']['query'];

        export type SelectParcelShopDeliverySuccess = paths['/internal/checkout/Delivery/SelectParcelShopDelivery']['post']['responses']['200']['content']['text/plain'];

        export const SelectParcelShopDeliveryUrl = '/internal/checkout/Delivery/SelectParcelShopDelivery';

        
        export function SelectParcelShopDelivery(params: SelectParcelShopDeliveryParams): Promise<SelectParcelShopDeliverySuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<SelectParcelShopDeliverySuccess>(`/internal/checkout/Delivery/SelectParcelShopDelivery${formatParams(params)}`)
        }
    
    
        export type SelectAndUpdateParcelShopDeliveryRequestBody = paths['/internal/checkout/Delivery/SelectAndUpdateParcelShopDelivery']['post']['requestBody']['content']['application/json'];

        

        export type SelectAndUpdateParcelShopDeliverySuccess = paths['/internal/checkout/Delivery/SelectAndUpdateParcelShopDelivery']['post']['responses']['200']['content']['text/plain'];

        export const SelectAndUpdateParcelShopDeliveryUrl = '/internal/checkout/Delivery/SelectAndUpdateParcelShopDelivery';

        
        export function SelectAndUpdateParcelShopDelivery(requestBody: SelectAndUpdateParcelShopDeliveryRequestBody): Promise<SelectAndUpdateParcelShopDeliverySuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postJSON<SelectAndUpdateParcelShopDeliverySuccess>(`/internal/checkout/Delivery/SelectAndUpdateParcelShopDelivery`, requestBody)
        }
    
    
        

        export type ListParcelShopsParams = paths['/internal/checkout/Delivery/ListParcelShops']['get']['parameters']['query'];

        export type ListParcelShopsSuccess = paths['/internal/checkout/Delivery/ListParcelShops']['get']['responses']['200']['content']['text/plain'];

        export const ListParcelShopsUrl = '/internal/checkout/Delivery/ListParcelShops';

        
        export function ListParcelShops(params: ListParcelShopsParams): Promise<ListParcelShopsSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<ListParcelShopsSuccess>(`/internal/checkout/Delivery/ListParcelShops${formatParams(params)}`)
        }
    
    
        

        export type ListParcelShopsByAddressParams = paths['/internal/checkout/Delivery/ListParcelShopsByAddress']['get']['parameters']['query'];

        export type ListParcelShopsByAddressSuccess = paths['/internal/checkout/Delivery/ListParcelShopsByAddress']['get']['responses']['200']['content']['text/plain'];

        export const ListParcelShopsByAddressUrl = '/internal/checkout/Delivery/ListParcelShopsByAddress';

        
        export function ListParcelShopsByAddress(params: ListParcelShopsByAddressParams): Promise<ListParcelShopsByAddressSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<ListParcelShopsByAddressSuccess>(`/internal/checkout/Delivery/ListParcelShopsByAddress${formatParams(params)}`)
        }
    
    
        

        export type ListParcelShopsByCoordinatesParams = paths['/internal/checkout/Delivery/ListParcelShopsByCoordinates']['get']['parameters']['query'];

        export type ListParcelShopsByCoordinatesSuccess = paths['/internal/checkout/Delivery/ListParcelShopsByCoordinates']['get']['responses']['200']['content']['text/plain'];

        export const ListParcelShopsByCoordinatesUrl = '/internal/checkout/Delivery/ListParcelShopsByCoordinates';

        
        export function ListParcelShopsByCoordinates(params: ListParcelShopsByCoordinatesParams): Promise<ListParcelShopsByCoordinatesSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<ListParcelShopsByCoordinatesSuccess>(`/internal/checkout/Delivery/ListParcelShopsByCoordinates${formatParams(params)}`)
        }
    
    
        

        export type SelectMatasDeliveryParams = paths['/internal/checkout/Delivery/SelectMatasDelivery']['post']['parameters']['query'];

        export type SelectMatasDeliverySuccess = paths['/internal/checkout/Delivery/SelectMatasDelivery']['post']['responses']['200']['content']['text/plain'];

        export const SelectMatasDeliveryUrl = '/internal/checkout/Delivery/SelectMatasDelivery';

        
        export function SelectMatasDelivery(params: SelectMatasDeliveryParams): Promise<SelectMatasDeliverySuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<SelectMatasDeliverySuccess>(`/internal/checkout/Delivery/SelectMatasDelivery${formatParams(params)}`)
        }
    
    
        export type SelectAndUpdateMatasDeliveryRequestBody = paths['/internal/checkout/Delivery/SelectAndUpdateMatasDelivery']['post']['requestBody']['content']['application/json'];

        

        export type SelectAndUpdateMatasDeliverySuccess = paths['/internal/checkout/Delivery/SelectAndUpdateMatasDelivery']['post']['responses']['200']['content']['text/plain'];

        export const SelectAndUpdateMatasDeliveryUrl = '/internal/checkout/Delivery/SelectAndUpdateMatasDelivery';

        
        export function SelectAndUpdateMatasDelivery(requestBody: SelectAndUpdateMatasDeliveryRequestBody): Promise<SelectAndUpdateMatasDeliverySuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postJSON<SelectAndUpdateMatasDeliverySuccess>(`/internal/checkout/Delivery/SelectAndUpdateMatasDelivery`, requestBody)
        }
    
    
        

        export type ListMatasParcelShopsParams = paths['/internal/checkout/Delivery/ListMatasParcelShops']['get']['parameters']['query'];

        export type ListMatasParcelShopsSuccess = paths['/internal/checkout/Delivery/ListMatasParcelShops']['get']['responses']['200']['content']['text/plain'];

        export const ListMatasParcelShopsUrl = '/internal/checkout/Delivery/ListMatasParcelShops';

        
        export function ListMatasParcelShops(params: ListMatasParcelShopsParams): Promise<ListMatasParcelShopsSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<ListMatasParcelShopsSuccess>(`/internal/checkout/Delivery/ListMatasParcelShops${formatParams(params)}`)
        }
    
    
        

        export type ListMatasParcelShopsByAddressParams = paths['/internal/checkout/Delivery/ListMatasParcelShopsByAddress']['get']['parameters']['query'];

        export type ListMatasParcelShopsByAddressSuccess = paths['/internal/checkout/Delivery/ListMatasParcelShopsByAddress']['get']['responses']['200']['content']['text/plain'];

        export const ListMatasParcelShopsByAddressUrl = '/internal/checkout/Delivery/ListMatasParcelShopsByAddress';

        
        export function ListMatasParcelShopsByAddress(params: ListMatasParcelShopsByAddressParams): Promise<ListMatasParcelShopsByAddressSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<ListMatasParcelShopsByAddressSuccess>(`/internal/checkout/Delivery/ListMatasParcelShopsByAddress${formatParams(params)}`)
        }
    
    
        

        export type ListMatasParcelShopsByCoordinatesParams = paths['/internal/checkout/Delivery/ListMatasParcelShopsByCoordinates']['get']['parameters']['query'];

        export type ListMatasParcelShopsByCoordinatesSuccess = paths['/internal/checkout/Delivery/ListMatasParcelShopsByCoordinates']['get']['responses']['200']['content']['text/plain'];

        export const ListMatasParcelShopsByCoordinatesUrl = '/internal/checkout/Delivery/ListMatasParcelShopsByCoordinates';

        
        export function ListMatasParcelShopsByCoordinates(params: ListMatasParcelShopsByCoordinatesParams): Promise<ListMatasParcelShopsByCoordinatesSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<ListMatasParcelShopsByCoordinatesSuccess>(`/internal/checkout/Delivery/ListMatasParcelShopsByCoordinates${formatParams(params)}`)
        }
    
    
        

        export type SelectUpsaleDeliveryParams = paths['/internal/checkout/Delivery/SelectUpsaleDelivery']['post']['parameters']['query'];

        export type SelectUpsaleDeliverySuccess = paths['/internal/checkout/Delivery/SelectUpsaleDelivery']['post']['responses']['200']['content']['text/plain'];

        export const SelectUpsaleDeliveryUrl = '/internal/checkout/Delivery/SelectUpsaleDelivery';

        
        export function SelectUpsaleDelivery(params: SelectUpsaleDeliveryParams): Promise<SelectUpsaleDeliverySuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<SelectUpsaleDeliverySuccess>(`/internal/checkout/Delivery/SelectUpsaleDelivery${formatParams(params)}`)
        }
    
    
        

        export type ToggleNonurgentDeliveryParams = paths['/internal/checkout/Delivery/ToggleNonurgentDelivery']['get']['parameters']['query'];

        export type ToggleNonurgentDeliverySuccess = paths['/internal/checkout/Delivery/ToggleNonurgentDelivery']['get']['responses']['200']['content']['text/plain'];

        export const ToggleNonurgentDeliveryUrl = '/internal/checkout/Delivery/ToggleNonurgentDelivery';

        
        export function ToggleNonurgentDelivery(params: ToggleNonurgentDeliveryParams): Promise<ToggleNonurgentDeliverySuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<ToggleNonurgentDeliverySuccess>(`/internal/checkout/Delivery/ToggleNonurgentDelivery${formatParams(params)}`)
        }
    
    
        

        export type KicksIpadPosSearchAddressParams = paths['/internal/checkout/Delivery/KicksIpadPosSearchAddress']['get']['parameters']['query'];

        export type KicksIpadPosSearchAddressSuccess = paths['/internal/checkout/Delivery/KicksIpadPosSearchAddress']['get']['responses']['200']['content']['text/plain'];

        export const KicksIpadPosSearchAddressUrl = '/internal/checkout/Delivery/KicksIpadPosSearchAddress';

        
        export function KicksIpadPosSearchAddress(params: KicksIpadPosSearchAddressParams): Promise<KicksIpadPosSearchAddressSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<KicksIpadPosSearchAddressSuccess>(`/internal/checkout/Delivery/KicksIpadPosSearchAddress${formatParams(params)}`)
        }
    
    
        

        export type ProgressiveDiscountForProductParams = paths['/internal/Discount/ProgressiveDiscountForProduct']['get']['parameters']['query'];

        export type ProgressiveDiscountForProductSuccess = paths['/internal/Discount/ProgressiveDiscountForProduct']['get']['responses']['200']['content']['text/plain'];

        export const ProgressiveDiscountForProductUrl = '/internal/Discount/ProgressiveDiscountForProduct';

        
        export function ProgressiveDiscountForProduct(params: ProgressiveDiscountForProductParams): Promise<ProgressiveDiscountForProductSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<ProgressiveDiscountForProductSuccess>(`/internal/Discount/ProgressiveDiscountForProduct${formatParams(params)}`)
        }
    
    
        

        export type ProgressiveDiscountDetailsForProductParams = paths['/internal/Discount/ProgressiveDiscountDetailsForProduct']['get']['parameters']['query'];

        export type ProgressiveDiscountDetailsForProductSuccess = paths['/internal/Discount/ProgressiveDiscountDetailsForProduct']['get']['responses']['200']['content']['text/plain'];

        export const ProgressiveDiscountDetailsForProductUrl = '/internal/Discount/ProgressiveDiscountDetailsForProduct';

        
        export function ProgressiveDiscountDetailsForProduct(params: ProgressiveDiscountDetailsForProductParams): Promise<ProgressiveDiscountDetailsForProductSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<ProgressiveDiscountDetailsForProductSuccess>(`/internal/Discount/ProgressiveDiscountDetailsForProduct${formatParams(params)}`)
        }
    
    
        

        export type SetZipCodeParams = paths['/internal/ExpectedDelivery/SetZipCode']['post']['parameters']['query'];

        export type SetZipCodeSuccess = paths['/internal/ExpectedDelivery/SetZipCode']['post']['responses']['200']['content']['text/plain'];

        export const SetZipCodeUrl = '/internal/ExpectedDelivery/SetZipCode';

        
        export function SetZipCode(params: SetZipCodeParams): Promise<SetZipCodeSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<SetZipCodeSuccess>(`/internal/ExpectedDelivery/SetZipCode${formatParams(params)}`)
        }
    
    
        

        export type SetZipCodeByCoordinatesParams = paths['/internal/ExpectedDelivery/SetZipCodeByCoordinates']['post']['parameters']['query'];

        export type SetZipCodeByCoordinatesSuccess = paths['/internal/ExpectedDelivery/SetZipCodeByCoordinates']['post']['responses']['200']['content']['text/plain'];

        export const SetZipCodeByCoordinatesUrl = '/internal/ExpectedDelivery/SetZipCodeByCoordinates';

        
        export function SetZipCodeByCoordinates(params: SetZipCodeByCoordinatesParams): Promise<SetZipCodeByCoordinatesSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<SetZipCodeByCoordinatesSuccess>(`/internal/ExpectedDelivery/SetZipCodeByCoordinates${formatParams(params)}`)
        }
    
    
        

        export type GetExpectedDeliverySummaryForBasketParams = paths['/internal/ExpectedDelivery/GetExpectedDeliverySummaryForBasket']['get']['parameters']['query'];

        export type GetExpectedDeliverySummaryForBasketSuccess = paths['/internal/ExpectedDelivery/GetExpectedDeliverySummaryForBasket']['get']['responses']['200']['content']['text/plain'];

        export const GetExpectedDeliverySummaryForBasketUrl = '/internal/ExpectedDelivery/GetExpectedDeliverySummaryForBasket';

        
        export function GetExpectedDeliverySummaryForBasket(params: GetExpectedDeliverySummaryForBasketParams): Promise<GetExpectedDeliverySummaryForBasketSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetExpectedDeliverySummaryForBasketSuccess>(`/internal/ExpectedDelivery/GetExpectedDeliverySummaryForBasket${formatParams(params)}`)
        }
    
    
        

        export type GetExpectedDeliverySummaryForProductPageParams = paths['/internal/ExpectedDelivery/GetExpectedDeliverySummaryForProductPage']['get']['parameters']['query'];

        export type GetExpectedDeliverySummaryForProductPageSuccess = paths['/internal/ExpectedDelivery/GetExpectedDeliverySummaryForProductPage']['get']['responses']['200']['content']['text/plain'];

        export const GetExpectedDeliverySummaryForProductPageUrl = '/internal/ExpectedDelivery/GetExpectedDeliverySummaryForProductPage';

        
        export function GetExpectedDeliverySummaryForProductPage(params: GetExpectedDeliverySummaryForProductPageParams): Promise<GetExpectedDeliverySummaryForProductPageSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetExpectedDeliverySummaryForProductPageSuccess>(`/internal/ExpectedDelivery/GetExpectedDeliverySummaryForProductPage${formatParams(params)}`)
        }
    
    
        

        export type GetExpectedDeliveryFullInformationParams = paths['/internal/ExpectedDelivery/GetExpectedDeliveryFullInformation']['get']['parameters']['query'];

        export type GetExpectedDeliveryFullInformationSuccess = paths['/internal/ExpectedDelivery/GetExpectedDeliveryFullInformation']['get']['responses']['200']['content']['text/plain'];

        export const GetExpectedDeliveryFullInformationUrl = '/internal/ExpectedDelivery/GetExpectedDeliveryFullInformation';

        
        export function GetExpectedDeliveryFullInformation(params: GetExpectedDeliveryFullInformationParams): Promise<GetExpectedDeliveryFullInformationSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetExpectedDeliveryFullInformationSuccess>(`/internal/ExpectedDelivery/GetExpectedDeliveryFullInformation${formatParams(params)}`)
        }
    
    
        

        export type GetExpectedDeliveryInformationForProductPageParams = paths['/internal/ExpectedDelivery/GetExpectedDeliveryInformationForProductPage']['get']['parameters']['query'];

        export type GetExpectedDeliveryInformationForProductPageSuccess = paths['/internal/ExpectedDelivery/GetExpectedDeliveryInformationForProductPage']['get']['responses']['200']['content']['text/plain'];

        export const GetExpectedDeliveryInformationForProductPageUrl = '/internal/ExpectedDelivery/GetExpectedDeliveryInformationForProductPage';

        
        export function GetExpectedDeliveryInformationForProductPage(params: GetExpectedDeliveryInformationForProductPageParams): Promise<GetExpectedDeliveryInformationForProductPageSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetExpectedDeliveryInformationForProductPageSuccess>(`/internal/ExpectedDelivery/GetExpectedDeliveryInformationForProductPage${formatParams(params)}`)
        }
    
    
        

        

        export type GetExpectedDeliveryInformationForBasketSuccess = paths['/internal/ExpectedDelivery/GetExpectedDeliveryInformationForBasket']['get']['responses']['200']['content']['text/plain'];

        export const GetExpectedDeliveryInformationForBasketUrl = '/internal/ExpectedDelivery/GetExpectedDeliveryInformationForBasket';

        
        export function GetExpectedDeliveryInformationForBasket(): Promise<GetExpectedDeliveryInformationForBasketSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetExpectedDeliveryInformationForBasketSuccess>(`/internal/ExpectedDelivery/GetExpectedDeliveryInformationForBasket`)
        }
    
    
        

        export type UpdateStatusParams = paths['/internal/Favorite/UpdateStatus']['post']['parameters']['query'];

        export type UpdateStatusSuccess = paths['/internal/Favorite/UpdateStatus']['post']['responses']['200']['content']['text/plain'];

        export const UpdateStatusUrl = '/internal/Favorite/UpdateStatus';

        
        export function UpdateStatus(params: UpdateStatusParams): Promise<UpdateStatusSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<UpdateStatusSuccess>(`/internal/Favorite/UpdateStatus${formatParams(params)}`)
        }
    
    
        

        export type FavoriteGetParams = paths['/internal/Favorite/Get']['get']['parameters']['query'];

        export type FavoriteGetSuccess = paths['/internal/Favorite/Get']['get']['responses']['200']['content']['text/plain'];

        export const FavoriteGetUrl = '/internal/Favorite/Get';

        
        export function FavoriteGet(params: FavoriteGetParams): Promise<FavoriteGetSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<FavoriteGetSuccess>(`/internal/Favorite/Get${formatParams(params)}`)
        }
    
    
        

        

        export type GetBrandsSuccess = paths['/internal/Findation/GetBrands']['get']['responses']['200']['content']['text/plain'];

        export const GetBrandsUrl = '/internal/Findation/GetBrands';

        
        export function GetBrands(): Promise<GetBrandsSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetBrandsSuccess>(`/internal/Findation/GetBrands`)
        }
    
    
        

        export type FindationGetProductsParams = paths['/internal/Findation/GetProducts']['get']['parameters']['query'];

        export type FindationGetProductsSuccess = paths['/internal/Findation/GetProducts']['get']['responses']['200']['content']['text/plain'];

        export const FindationGetProductsUrl = '/internal/Findation/GetProducts';

        
        export function FindationGetProducts(params: FindationGetProductsParams): Promise<FindationGetProductsSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<FindationGetProductsSuccess>(`/internal/Findation/GetProducts${formatParams(params)}`)
        }
    
    
        

        export type GetShadesParams = paths['/internal/Findation/GetShades']['get']['parameters']['query'];

        export type GetShadesSuccess = paths['/internal/Findation/GetShades']['get']['responses']['200']['content']['text/plain'];

        export const GetShadesUrl = '/internal/Findation/GetShades';

        
        export function GetShades(params: GetShadesParams): Promise<GetShadesSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetShadesSuccess>(`/internal/Findation/GetShades${formatParams(params)}`)
        }
    
    
        export type FindationSearchRequestBody = paths['/internal/Findation/Search']['post']['requestBody']['content']['application/json'];

        

        export type FindationSearchSuccess = paths['/internal/Findation/Search']['post']['responses']['200']['content']['text/plain'];

        export const FindationSearchUrl = '/internal/Findation/Search';

        
        export function FindationSearch(requestBody: FindationSearchRequestBody): Promise<FindationSearchSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postJSON<FindationSearchSuccess>(`/internal/Findation/Search`, requestBody)
        }
    
    
        export type GetCardBalanceV2RequestBody = paths['/internal/GiftCardBalance/GetCardBalanceV2']['post']['requestBody']['content']['multipart/form-data'];

        

        export type GetCardBalanceV2Success = paths['/internal/GiftCardBalance/GetCardBalanceV2']['post']['responses']['200']['content']['text/plain'];

        export const GetCardBalanceV2Url = '/internal/GiftCardBalance/GetCardBalanceV2';

        
        export function GetCardBalanceV2(requestBody: GetCardBalanceV2RequestBody): Promise<GetCardBalanceV2Success> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postFormData<GetCardBalanceV2Success>(`/internal/GiftCardBalance/GetCardBalanceV2`, requestBody)
        }
    
    
        

        export type UpdateGranularMailCampaignPermissionsParams = paths['/internal/mail/GranularCampaignPermission/UpdateGranularMailCampaignPermissions']['post']['parameters']['query'];

        export type UpdateGranularMailCampaignPermissionsSuccess = paths['/internal/mail/GranularCampaignPermission/UpdateGranularMailCampaignPermissions']['post']['responses']['200']['content']['text/plain'];

        export const UpdateGranularMailCampaignPermissionsUrl = '/internal/mail/GranularCampaignPermission/UpdateGranularMailCampaignPermissions';

        
        export function UpdateGranularMailCampaignPermissions(params: UpdateGranularMailCampaignPermissionsParams): Promise<UpdateGranularMailCampaignPermissionsSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<UpdateGranularMailCampaignPermissionsSuccess>(`/internal/mail/GranularCampaignPermission/UpdateGranularMailCampaignPermissions${formatParams(params)}`)
        }
    
    
        export type RegisterReceiptRequestBody = paths['/internal/pos/Init/RegisterReceipt']['post']['requestBody']['content']['application/json'];

        

        export type RegisterReceiptSuccess = paths['/internal/pos/Init/RegisterReceipt']['post']['responses']['200']['content']['text/plain'];

        export const RegisterReceiptUrl = '/internal/pos/Init/RegisterReceipt';

        
        export function RegisterReceipt(requestBody: RegisterReceiptRequestBody): Promise<RegisterReceiptSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postJSON<RegisterReceiptSuccess>(`/internal/pos/Init/RegisterReceipt`, requestBody)
        }
    
    
        export type RegisterBasketRequestBody = paths['/internal/pos/Init/RegisterBasket']['post']['requestBody']['content']['application/json'];

        export type RegisterBasketParams = paths['/internal/pos/Init/RegisterBasket']['post']['parameters']['query'];

        export type RegisterBasketSuccess = paths['/internal/pos/Init/RegisterBasket']['post']['responses']['200']['content']['text/plain'];

        export const RegisterBasketUrl = '/internal/pos/Init/RegisterBasket';

        
        export function RegisterBasket(params: RegisterBasketParams, requestBody: RegisterBasketRequestBody): Promise<RegisterBasketSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postJSON<RegisterBasketSuccess>(`/internal/pos/Init/RegisterBasket${formatParams(params)}`, requestBody)
        }
    
    
        

        export type ToggleInPageNotificationParams = paths['/internal/InPageNotification/ToggleInPageNotification']['get']['parameters']['query'];

        export type ToggleInPageNotificationSuccess = paths['/internal/InPageNotification/ToggleInPageNotification']['get']['responses']['200']['content']['text/plain'];

        export const ToggleInPageNotificationUrl = '/internal/InPageNotification/ToggleInPageNotification';

        
        export function ToggleInPageNotification(params: ToggleInPageNotificationParams): Promise<ToggleInPageNotificationSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<ToggleInPageNotificationSuccess>(`/internal/InPageNotification/ToggleInPageNotification${formatParams(params)}`)
        }
    
    
        

        

        export type InspirationMatasLivePageViewModelSuccess = paths['/internal/Inspiration/InspirationMatasLivePageViewModel']['get']['responses']['200']['content']['text/plain'];

        export const InspirationMatasLivePageViewModelUrl = '/internal/Inspiration/InspirationMatasLivePageViewModel';

        
        export function InspirationMatasLivePageViewModel(): Promise<InspirationMatasLivePageViewModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<InspirationMatasLivePageViewModelSuccess>(`/internal/Inspiration/InspirationMatasLivePageViewModel`)
        }
    
    
        

        export type SaveSessionTokenParams = paths['/internal/KlarnaExpress/SaveSessionToken']['get']['parameters']['query'];

        

        export const SaveSessionTokenUrl = '/internal/KlarnaExpress/SaveSessionToken';

        
        export function SaveSessionToken(params: SaveSessionTokenParams): Promise<unknown> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<unknown>(`/internal/KlarnaExpress/SaveSessionToken${formatParams(params)}`)
        }
    
    
        

        

        export type KlarnaExpressOrderPayloadSuccess = paths['/internal/KlarnaExpress/KlarnaExpressOrderPayload']['get']['responses']['200']['content']['text/plain'];

        export const KlarnaExpressOrderPayloadUrl = '/internal/KlarnaExpress/KlarnaExpressOrderPayload';

        
        export function KlarnaExpressOrderPayload(): Promise<KlarnaExpressOrderPayloadSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<KlarnaExpressOrderPayloadSuccess>(`/internal/KlarnaExpress/KlarnaExpressOrderPayload`)
        }
    
    
        

        export type GetParams = paths['/internal/LastSeenProduct/Get']['get']['parameters']['query'];

        export type GetSuccess = paths['/internal/LastSeenProduct/Get']['get']['responses']['200']['content']['text/plain'];

        export const GetUrl = '/internal/LastSeenProduct/Get';

        
        export function Get(params: GetParams): Promise<GetSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetSuccess>(`/internal/LastSeenProduct/Get${formatParams(params)}`)
        }
    
    
        

        

        export type HideCountdownBannerSuccess = paths['/internal/Layout/HideCountdownBanner']['post']['responses']['200']['content']['text/plain'];

        export const HideCountdownBannerUrl = '/internal/Layout/HideCountdownBanner';

        
        export function HideCountdownBanner(): Promise<HideCountdownBannerSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<HideCountdownBannerSuccess>(`/internal/Layout/HideCountdownBanner`)
        }
    
    
        

        

        export type CountDownBannerModelSuccess = paths['/internal/Layout/CountDownBannerModel']['post']['responses']['200']['content']['text/plain'];

        export const CountDownBannerModelUrl = '/internal/Layout/CountDownBannerModel';

        
        export function CountDownBannerModel(): Promise<CountDownBannerModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<CountDownBannerModelSuccess>(`/internal/Layout/CountDownBannerModel`)
        }
    
    
        

        export type UnsubscribeAllParams = paths['/internal/LiveEvent/UnsubscribeAll']['post']['parameters']['query'];

        export type UnsubscribeAllSuccess = paths['/internal/LiveEvent/UnsubscribeAll']['post']['responses']['200']['content']['text/plain'];

        export const UnsubscribeAllUrl = '/internal/LiveEvent/UnsubscribeAll';

        
        export function UnsubscribeAll(params: UnsubscribeAllParams): Promise<UnsubscribeAllSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<UnsubscribeAllSuccess>(`/internal/LiveEvent/UnsubscribeAll${formatParams(params)}`)
        }
    
    
        

        export type GetEventSubscriberInfoParams = paths['/internal/LiveEvent/GetEventSubscriberInfo']['get']['parameters']['query'];

        export type GetEventSubscriberInfoSuccess = paths['/internal/LiveEvent/GetEventSubscriberInfo']['get']['responses']['200']['content']['text/plain'];

        export const GetEventSubscriberInfoUrl = '/internal/LiveEvent/GetEventSubscriberInfo';

        
        export function GetEventSubscriberInfo(params: GetEventSubscriberInfoParams): Promise<GetEventSubscriberInfoSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetEventSubscriberInfoSuccess>(`/internal/LiveEvent/GetEventSubscriberInfo${formatParams(params)}`)
        }
    
    
        export type AddEventNotificationRequestBody = paths['/internal/LiveEvent/AddEventNotification']['post']['requestBody']['content']['application/json'];

        

        

        export const AddEventNotificationUrl = '/internal/LiveEvent/AddEventNotification';

        
        export function AddEventNotification(requestBody: AddEventNotificationRequestBody): Promise<unknown> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postJSON<unknown>(`/internal/LiveEvent/AddEventNotification`, requestBody)
        }
    
    
        

        export type GetLiveEventStatusModelParams = paths['/internal/LiveEvent/GetLiveEventStatusModel']['get']['parameters']['query'];

        export type GetLiveEventStatusModelSuccess = paths['/internal/LiveEvent/GetLiveEventStatusModel']['get']['responses']['200']['content']['text/plain'];

        export const GetLiveEventStatusModelUrl = '/internal/LiveEvent/GetLiveEventStatusModel';

        
        export function GetLiveEventStatusModel(params: GetLiveEventStatusModelParams): Promise<GetLiveEventStatusModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetLiveEventStatusModelSuccess>(`/internal/LiveEvent/GetLiveEventStatusModel${formatParams(params)}`)
        }
    
    
        

        

        export type LiveEventListViewModelSuccess = paths['/internal/LiveEvent/LiveEventListViewModel']['get']['responses']['200']['content']['text/plain'];

        export const LiveEventListViewModelUrl = '/internal/LiveEvent/LiveEventListViewModel';

        
        export function LiveEventListViewModel(): Promise<LiveEventListViewModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<LiveEventListViewModelSuccess>(`/internal/LiveEvent/LiveEventListViewModel`)
        }
    
    
        

        

        export type LiveEventFilteredResultViewModelSuccess = paths['/internal/LiveEvent/LiveEventFilteredResultViewModel']['get']['responses']['200']['content']['text/plain'];

        export const LiveEventFilteredResultViewModelUrl = '/internal/LiveEvent/LiveEventFilteredResultViewModel';

        
        export function LiveEventFilteredResultViewModel(): Promise<LiveEventFilteredResultViewModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<LiveEventFilteredResultViewModelSuccess>(`/internal/LiveEvent/LiveEventFilteredResultViewModel`)
        }
    
    
        export type LogPosEventRequestBody = paths['/internal/pos/Log/LogPosEvent']['post']['requestBody']['content']['application/json'];

        

        

        export const LogPosEventUrl = '/internal/pos/Log/LogPosEvent';

        
        export function LogPosEvent(requestBody: LogPosEventRequestBody): Promise<unknown> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postJSON<unknown>(`/internal/pos/Log/LogPosEvent`, requestBody)
        }
    
    
        

        export type _LoginInfoParams = paths['/internal/Login/_LoginInfo']['get']['parameters']['query'];

        export type _LoginInfoSuccess = paths['/internal/Login/_LoginInfo']['get']['responses']['200']['content']['text/plain'];

        export const _LoginInfoUrl = '/internal/Login/_LoginInfo';

        
        export function _LoginInfo(params: _LoginInfoParams): Promise<_LoginInfoSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<_LoginInfoSuccess>(`/internal/Login/_LoginInfo${formatParams(params)}`)
        }
    
    
        

        

        export type MatasPlusRecommendationsSuccess = paths['/internal/MatasPlus/MatasPlusRecommendations']['get']['responses']['200']['content']['text/plain'];

        export const MatasPlusRecommendationsUrl = '/internal/MatasPlus/MatasPlusRecommendations';

        
        export function MatasPlusRecommendations(): Promise<MatasPlusRecommendationsSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<MatasPlusRecommendationsSuccess>(`/internal/MatasPlus/MatasPlusRecommendations`)
        }
    
    
        

        export type ChangeSubscriptionTypeParams = paths['/internal/myprofile/MatasPlus/ChangeSubscriptionType']['post']['parameters']['query'];

        export type ChangeSubscriptionTypeSuccess = paths['/internal/myprofile/MatasPlus/ChangeSubscriptionType']['post']['responses']['200']['content']['text/plain'];

        export const ChangeSubscriptionTypeUrl = '/internal/myprofile/MatasPlus/ChangeSubscriptionType';

        
        export function ChangeSubscriptionType(params: ChangeSubscriptionTypeParams): Promise<ChangeSubscriptionTypeSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<ChangeSubscriptionTypeSuccess>(`/internal/myprofile/MatasPlus/ChangeSubscriptionType${formatParams(params)}`)
        }
    
    
        

        

        export type CancelSubscriptionSuccess = paths['/internal/myprofile/MatasPlus/CancelSubscription']['get']['responses']['200']['content']['text/plain'];

        export const CancelSubscriptionUrl = '/internal/myprofile/MatasPlus/CancelSubscription';

        
        export function CancelSubscription(): Promise<CancelSubscriptionSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<CancelSubscriptionSuccess>(`/internal/myprofile/MatasPlus/CancelSubscription`)
        }
    
    
        

        export type MatasPlusConfirmCancelParams = paths['/internal/myprofile/MatasPlus/ConfirmCancel']['get']['parameters']['query'];

        export type MatasPlusConfirmCancelSuccess = paths['/internal/myprofile/MatasPlus/ConfirmCancel']['get']['responses']['200']['content']['text/plain'];

        export const MatasPlusConfirmCancelUrl = '/internal/myprofile/MatasPlus/ConfirmCancel';

        
        export function MatasPlusConfirmCancel(params: MatasPlusConfirmCancelParams): Promise<MatasPlusConfirmCancelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<MatasPlusConfirmCancelSuccess>(`/internal/myprofile/MatasPlus/ConfirmCancel${formatParams(params)}`)
        }
    
    
        

        

        

        export const UndoCancelSubscriptionUrl = '/internal/myprofile/MatasPlus/UndoCancelSubscription';

        
        export function UndoCancelSubscription(): Promise<unknown> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<unknown>(`/internal/myprofile/MatasPlus/UndoCancelSubscription`)
        }
    
    
        

        

        export type GetMatasPlusPageModelSuccess = paths['/internal/myprofile/MatasPlus/GetMatasPlusPageModel']['get']['responses']['200']['content']['text/plain'];

        export const GetMatasPlusPageModelUrl = '/internal/myprofile/MatasPlus/GetMatasPlusPageModel';

        
        export function GetMatasPlusPageModel(): Promise<GetMatasPlusPageModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetMatasPlusPageModelSuccess>(`/internal/myprofile/MatasPlus/GetMatasPlusPageModel`)
        }
    
    
        

        

        export type MatasPlusCalculatorIndexSuccess = paths['/internal/MatasPlusCalculator/Index']['get']['responses']['200']['content']['text/plain'];

        export const MatasPlusCalculatorIndexUrl = '/internal/MatasPlusCalculator/Index';

        
        export function MatasPlusCalculatorIndex(): Promise<MatasPlusCalculatorIndexSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<MatasPlusCalculatorIndexSuccess>(`/internal/MatasPlusCalculator/Index`)
        }
    
    
        

        

        export type GetConfirmCancellationModalSuccess = paths['/internal/myprofile/MatasPlusPendingTermination/GetConfirmCancellationModal']['get']['responses']['200']['content']['text/plain'];

        export const GetConfirmCancellationModalUrl = '/internal/myprofile/MatasPlusPendingTermination/GetConfirmCancellationModal';

        
        export function GetConfirmCancellationModal(): Promise<GetConfirmCancellationModalSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetConfirmCancellationModalSuccess>(`/internal/myprofile/MatasPlusPendingTermination/GetConfirmCancellationModal`)
        }
    
    
        

        export type MatasPlusSavingsPreviewParams = paths['/internal/MatasPlusSavings/Preview']['get']['parameters']['query'];

        export type MatasPlusSavingsPreviewSuccess = paths['/internal/MatasPlusSavings/Preview']['get']['responses']['200']['content']['text/plain'];

        export const MatasPlusSavingsPreviewUrl = '/internal/MatasPlusSavings/Preview';

        
        export function MatasPlusSavingsPreview(params: MatasPlusSavingsPreviewParams): Promise<MatasPlusSavingsPreviewSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<MatasPlusSavingsPreviewSuccess>(`/internal/MatasPlusSavings/Preview${formatParams(params)}`)
        }
    
    
        

        

        export type MatasPlusSavingsDetailsSuccess = paths['/internal/MatasPlusSavings/Details']['get']['responses']['200']['content']['text/plain'];

        export const MatasPlusSavingsDetailsUrl = '/internal/MatasPlusSavings/Details';

        
        export function MatasPlusSavingsDetails(): Promise<MatasPlusSavingsDetailsSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<MatasPlusSavingsDetailsSuccess>(`/internal/MatasPlusSavings/Details`)
        }
    
    
        

        

        export type PointSavingsSuccess = paths['/internal/MatasPlusSavings/PointSavings']['get']['responses']['200']['content']['text/plain'];

        export const PointSavingsUrl = '/internal/MatasPlusSavings/PointSavings';

        
        export function PointSavings(): Promise<PointSavingsSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<PointSavingsSuccess>(`/internal/MatasPlusSavings/PointSavings`)
        }
    
    
        

        

        export type MatasPlusSavingsDisclaimerModelSuccess = paths['/internal/MatasPlusSavings/MatasPlusSavingsDisclaimerModel']['get']['responses']['200']['content']['text/plain'];

        export const MatasPlusSavingsDisclaimerModelUrl = '/internal/MatasPlusSavings/MatasPlusSavingsDisclaimerModel';

        
        export function MatasPlusSavingsDisclaimerModel(): Promise<MatasPlusSavingsDisclaimerModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<MatasPlusSavingsDisclaimerModelSuccess>(`/internal/MatasPlusSavings/MatasPlusSavingsDisclaimerModel`)
        }
    
    
        

        

        export type MemberLevelInfoSuccess = paths['/internal/myprofile/MemberLevel/MemberLevelInfo']['get']['responses']['200']['content']['text/plain'];

        export const MemberLevelInfoUrl = '/internal/myprofile/MemberLevel/MemberLevelInfo';

        
        export function MemberLevelInfo(): Promise<MemberLevelInfoSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<MemberLevelInfoSuccess>(`/internal/myprofile/MemberLevel/MemberLevelInfo`)
        }
    
    
        

        

        export type MegaSuccess = paths['/internal/Menu/Mega']['get']['responses']['200']['content']['text/plain'];

        export const MegaUrl = '/internal/Menu/Mega';

        
        export function Mega(): Promise<MegaSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<MegaSuccess>(`/internal/Menu/Mega`)
        }
    
    
        

        

        export type FooterSuccess = paths['/internal/Menu/Footer']['get']['responses']['200']['content']['text/plain'];

        export const FooterUrl = '/internal/Menu/Footer';

        
        export function Footer(): Promise<FooterSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<FooterSuccess>(`/internal/Menu/Footer`)
        }
    
    
        

        export type ListParams = paths['/internal/Menu/List']['get']['parameters']['query'];

        export type ListSuccess = paths['/internal/Menu/List']['get']['responses']['200']['content']['text/plain'];

        export const ListUrl = '/internal/Menu/List';

        
        export function List(params: ListParams): Promise<ListSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<ListSuccess>(`/internal/Menu/List${formatParams(params)}`)
        }
    
    
        

        export type MenuForWebshopParams = paths['/internal/Menu/MenuForWebshop']['get']['parameters']['query'];

        export type MenuForWebshopSuccess = paths['/internal/Menu/MenuForWebshop']['get']['responses']['200']['content']['text/plain'];

        export const MenuForWebshopUrl = '/internal/Menu/MenuForWebshop';

        
        export function MenuForWebshop(params: MenuForWebshopParams): Promise<MenuForWebshopSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<MenuForWebshopSuccess>(`/internal/Menu/MenuForWebshop${formatParams(params)}`)
        }
    
    
        

        export type MenuParams = paths['/internal/Menu/Menu']['get']['parameters']['query'];

        export type MenuSuccess = paths['/internal/Menu/Menu']['get']['responses']['200']['content']['text/plain'];

        export const MenuUrl = '/internal/Menu/Menu';

        
        export function Menu(params: MenuParams): Promise<MenuSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<MenuSuccess>(`/internal/Menu/Menu${formatParams(params)}`)
        }
    
    
        

        

        export type _MiniBasketSuccess = paths['/internal/MiniBasket/_MiniBasket']['get']['responses']['200']['content']['text/html'];

        export const _MiniBasketUrl = '/internal/MiniBasket/_MiniBasket';

        
        export function _MiniBasket(): Promise<_MiniBasketSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchHTML(`/internal/MiniBasket/_MiniBasket`)
        }
    
    
        

        

        export type MiniBasketIndexSuccess = paths['/internal/MiniBasket/Index']['get']['responses']['200']['content']['text/plain'];

        export const MiniBasketIndexUrl = '/internal/MiniBasket/Index';

        
        export function MiniBasketIndex(): Promise<MiniBasketIndexSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<MiniBasketIndexSuccess>(`/internal/MiniBasket/Index`)
        }
    
    
        

        

        export type AccordionDTOSuccess = paths['/internal/ModelOnly/AccordionDTO']['get']['responses']['200']['content']['text/plain'];

        export const AccordionDTOUrl = '/internal/ModelOnly/AccordionDTO';

        
        export function AccordionDTO(): Promise<AccordionDTOSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<AccordionDTOSuccess>(`/internal/ModelOnly/AccordionDTO`)
        }
    
    
        

        

        export type HighlightedCampaignBlockDTOSuccess = paths['/internal/ModelOnly/HighlightedCampaignBlockDTO']['get']['responses']['200']['content']['text/plain'];

        export const HighlightedCampaignBlockDTOUrl = '/internal/ModelOnly/HighlightedCampaignBlockDTO';

        
        export function HighlightedCampaignBlockDTO(): Promise<HighlightedCampaignBlockDTOSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<HighlightedCampaignBlockDTOSuccess>(`/internal/ModelOnly/HighlightedCampaignBlockDTO`)
        }
    
    
        

        

        export type MatasPlusUnboxingModelSuccess = paths['/internal/ModelOnly/MatasPlusUnboxingModel']['get']['responses']['200']['content']['text/plain'];

        export const MatasPlusUnboxingModelUrl = '/internal/ModelOnly/MatasPlusUnboxingModel';

        
        export function MatasPlusUnboxingModel(): Promise<MatasPlusUnboxingModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<MatasPlusUnboxingModelSuccess>(`/internal/ModelOnly/MatasPlusUnboxingModel`)
        }
    
    
        

        

        export type MatasPlusFaqBlockDTOSuccess = paths['/internal/ModelOnly/MatasPlusFaqBlockDTO']['get']['responses']['200']['content']['text/plain'];

        export const MatasPlusFaqBlockDTOUrl = '/internal/ModelOnly/MatasPlusFaqBlockDTO';

        
        export function MatasPlusFaqBlockDTO(): Promise<MatasPlusFaqBlockDTOSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<MatasPlusFaqBlockDTOSuccess>(`/internal/ModelOnly/MatasPlusFaqBlockDTO`)
        }
    
    
        

        

        export type GwpDiscountsBlockSuccess = paths['/internal/ModelOnly/GwpDiscountsBlock']['get']['responses']['200']['content']['text/plain'];

        export const GwpDiscountsBlockUrl = '/internal/ModelOnly/GwpDiscountsBlock';

        
        export function GwpDiscountsBlock(): Promise<GwpDiscountsBlockSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GwpDiscountsBlockSuccess>(`/internal/ModelOnly/GwpDiscountsBlock`)
        }
    
    
        

        

        export type AdvisorBlockSuccess = paths['/internal/ModelOnly/AdvisorBlock']['get']['responses']['200']['content']['text/plain'];

        export const AdvisorBlockUrl = '/internal/ModelOnly/AdvisorBlock';

        
        export function AdvisorBlock(): Promise<AdvisorBlockSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<AdvisorBlockSuccess>(`/internal/ModelOnly/AdvisorBlock`)
        }
    
    
        

        

        export type AdvisorListBlockDTOSuccess = paths['/internal/ModelOnly/AdvisorListBlockDTO']['get']['responses']['200']['content']['text/plain'];

        export const AdvisorListBlockDTOUrl = '/internal/ModelOnly/AdvisorListBlockDTO';

        
        export function AdvisorListBlockDTO(): Promise<AdvisorListBlockDTOSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<AdvisorListBlockDTOSuccess>(`/internal/ModelOnly/AdvisorListBlockDTO`)
        }
    
    
        

        

        export type FooterViewModelSuccess = paths['/internal/ModelOnly/FooterViewModel']['get']['responses']['200']['content']['text/plain'];

        export const FooterViewModelUrl = '/internal/ModelOnly/FooterViewModel';

        
        export function FooterViewModel(): Promise<FooterViewModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<FooterViewModelSuccess>(`/internal/ModelOnly/FooterViewModel`)
        }
    
    
        

        

        export type StepLayoutFooterViewModelSuccess = paths['/internal/ModelOnly/StepLayoutFooterViewModel']['get']['responses']['200']['content']['text/plain'];

        export const StepLayoutFooterViewModelUrl = '/internal/ModelOnly/StepLayoutFooterViewModel';

        
        export function StepLayoutFooterViewModel(): Promise<StepLayoutFooterViewModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<StepLayoutFooterViewModelSuccess>(`/internal/ModelOnly/StepLayoutFooterViewModel`)
        }
    
    
        

        

        export type PrimaryHeaderViewModelSuccess = paths['/internal/ModelOnly/PrimaryHeaderViewModel']['get']['responses']['200']['content']['text/plain'];

        export const PrimaryHeaderViewModelUrl = '/internal/ModelOnly/PrimaryHeaderViewModel';

        
        export function PrimaryHeaderViewModel(): Promise<PrimaryHeaderViewModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<PrimaryHeaderViewModelSuccess>(`/internal/ModelOnly/PrimaryHeaderViewModel`)
        }
    
    
        

        

        export type ProductCustomerNotificationCreateModelSuccess = paths['/internal/ModelOnly/ProductCustomerNotificationCreateModel']['get']['responses']['200']['content']['text/plain'];

        export const ProductCustomerNotificationCreateModelUrl = '/internal/ModelOnly/ProductCustomerNotificationCreateModel';

        
        export function ProductCustomerNotificationCreateModel(): Promise<ProductCustomerNotificationCreateModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<ProductCustomerNotificationCreateModelSuccess>(`/internal/ModelOnly/ProductCustomerNotificationCreateModel`)
        }
    
    
        

        

        export type ProductReviewHeaderModelSuccess = paths['/internal/ModelOnly/ProductReviewHeaderModel']['get']['responses']['200']['content']['text/plain'];

        export const ProductReviewHeaderModelUrl = '/internal/ModelOnly/ProductReviewHeaderModel';

        
        export function ProductReviewHeaderModel(): Promise<ProductReviewHeaderModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<ProductReviewHeaderModelSuccess>(`/internal/ModelOnly/ProductReviewHeaderModel`)
        }
    
    
        

        

        export type AdvisorToolboxModelSuccess = paths['/internal/ModelOnly/AdvisorToolboxModel']['get']['responses']['200']['content']['text/plain'];

        export const AdvisorToolboxModelUrl = '/internal/ModelOnly/AdvisorToolboxModel';

        
        export function AdvisorToolboxModel(): Promise<AdvisorToolboxModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<AdvisorToolboxModelSuccess>(`/internal/ModelOnly/AdvisorToolboxModel`)
        }
    
    
        

        

        export type ProductSliderBlockDTOModelSuccess = paths['/internal/ModelOnly/ProductSliderBlockDTOModel']['get']['responses']['200']['content']['text/plain'];

        export const ProductSliderBlockDTOModelUrl = '/internal/ModelOnly/ProductSliderBlockDTOModel';

        
        export function ProductSliderBlockDTOModel(): Promise<ProductSliderBlockDTOModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<ProductSliderBlockDTOModelSuccess>(`/internal/ModelOnly/ProductSliderBlockDTOModel`)
        }
    
    
        

        

        export type ProductReviewCreateViewModelSuccess = paths['/internal/ModelOnly/ProductReviewCreateViewModel']['get']['responses']['200']['content']['text/plain'];

        export const ProductReviewCreateViewModelUrl = '/internal/ModelOnly/ProductReviewCreateViewModel';

        
        export function ProductReviewCreateViewModel(): Promise<ProductReviewCreateViewModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<ProductReviewCreateViewModelSuccess>(`/internal/ModelOnly/ProductReviewCreateViewModel`)
        }
    
    
        

        

        export type DonationModelSuccess = paths['/internal/ModelOnly/DonationModel']['get']['responses']['200']['content']['text/plain'];

        export const DonationModelUrl = '/internal/ModelOnly/DonationModel';

        
        export function DonationModel(): Promise<DonationModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<DonationModelSuccess>(`/internal/ModelOnly/DonationModel`)
        }
    
    
        

        

        export type BasketLineSubscriptionNudgeSuccess = paths['/internal/ModelOnly/BasketLineSubscriptionNudge']['get']['responses']['200']['content']['text/plain'];

        export const BasketLineSubscriptionNudgeUrl = '/internal/ModelOnly/BasketLineSubscriptionNudge';

        
        export function BasketLineSubscriptionNudge(): Promise<BasketLineSubscriptionNudgeSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<BasketLineSubscriptionNudgeSuccess>(`/internal/ModelOnly/BasketLineSubscriptionNudge`)
        }
    
    
        

        

        export type BasketMessageDiscountViewModelSuccess = paths['/internal/ModelOnly/BasketMessageDiscountViewModel']['get']['responses']['200']['content']['text/plain'];

        export const BasketMessageDiscountViewModelUrl = '/internal/ModelOnly/BasketMessageDiscountViewModel';

        
        export function BasketMessageDiscountViewModel(): Promise<BasketMessageDiscountViewModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<BasketMessageDiscountViewModelSuccess>(`/internal/ModelOnly/BasketMessageDiscountViewModel`)
        }
    
    
        

        

        export type BasketShippingSupplierOptionSuccess = paths['/internal/ModelOnly/BasketShippingSupplierOption']['get']['responses']['200']['content']['text/plain'];

        export const BasketShippingSupplierOptionUrl = '/internal/ModelOnly/BasketShippingSupplierOption';

        
        export function BasketShippingSupplierOption(): Promise<BasketShippingSupplierOptionSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<BasketShippingSupplierOptionSuccess>(`/internal/ModelOnly/BasketShippingSupplierOption`)
        }
    
    
        

        

        export type FeaturedFooterViewModelSuccess = paths['/internal/ModelOnly/FeaturedFooterViewModel']['get']['responses']['200']['content']['text/plain'];

        export const FeaturedFooterViewModelUrl = '/internal/ModelOnly/FeaturedFooterViewModel';

        
        export function FeaturedFooterViewModel(): Promise<FeaturedFooterViewModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<FeaturedFooterViewModelSuccess>(`/internal/ModelOnly/FeaturedFooterViewModel`)
        }
    
    
        

        

        export type MenuTooltipsViewModelSuccess = paths['/internal/ModelOnly/MenuTooltipsViewModel']['get']['responses']['200']['content']['text/plain'];

        export const MenuTooltipsViewModelUrl = '/internal/ModelOnly/MenuTooltipsViewModel';

        
        export function MenuTooltipsViewModel(): Promise<MenuTooltipsViewModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<MenuTooltipsViewModelSuccess>(`/internal/ModelOnly/MenuTooltipsViewModel`)
        }
    
    
        

        

        export type InPageNotificationViewModelSuccess = paths['/internal/ModelOnly/InPageNotificationViewModel']['get']['responses']['200']['content']['text/plain'];

        export const InPageNotificationViewModelUrl = '/internal/ModelOnly/InPageNotificationViewModel';

        
        export function InPageNotificationViewModel(): Promise<InPageNotificationViewModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<InPageNotificationViewModelSuccess>(`/internal/ModelOnly/InPageNotificationViewModel`)
        }
    
    
        

        

        export type ImageWithDisclaimerPlaceholderModelSuccess = paths['/internal/ModelOnly/ImageWithDisclaimerPlaceholderModel']['get']['responses']['200']['content']['text/plain'];

        export const ImageWithDisclaimerPlaceholderModelUrl = '/internal/ModelOnly/ImageWithDisclaimerPlaceholderModel';

        
        export function ImageWithDisclaimerPlaceholderModel(): Promise<ImageWithDisclaimerPlaceholderModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<ImageWithDisclaimerPlaceholderModelSuccess>(`/internal/ModelOnly/ImageWithDisclaimerPlaceholderModel`)
        }
    
    
        

        

        export type GlobalUspViewModelSuccess = paths['/internal/ModelOnly/GlobalUspViewModel']['get']['responses']['200']['content']['text/plain'];

        export const GlobalUspViewModelUrl = '/internal/ModelOnly/GlobalUspViewModel';

        
        export function GlobalUspViewModel(): Promise<GlobalUspViewModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GlobalUspViewModelSuccess>(`/internal/ModelOnly/GlobalUspViewModel`)
        }
    
    
        

        

        export type NoRebuysAvailableViewModelSuccess = paths['/internal/ModelOnly/NoRebuysAvailableViewModel']['get']['responses']['200']['content']['text/plain'];

        export const NoRebuysAvailableViewModelUrl = '/internal/ModelOnly/NoRebuysAvailableViewModel';

        
        export function NoRebuysAvailableViewModel(): Promise<NoRebuysAvailableViewModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<NoRebuysAvailableViewModelSuccess>(`/internal/ModelOnly/NoRebuysAvailableViewModel`)
        }
    
    
        

        

        export type ExtendedPlaceholderModelSuccess = paths['/internal/ModelOnly/ExtendedPlaceholderModel']['get']['responses']['200']['content']['text/plain'];

        export const ExtendedPlaceholderModelUrl = '/internal/ModelOnly/ExtendedPlaceholderModel';

        
        export function ExtendedPlaceholderModel(): Promise<ExtendedPlaceholderModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<ExtendedPlaceholderModelSuccess>(`/internal/ModelOnly/ExtendedPlaceholderModel`)
        }
    
    
        

        

        export type CountdownModelSuccess = paths['/internal/ModelOnly/CountdownModel']['get']['responses']['200']['content']['text/plain'];

        export const CountdownModelUrl = '/internal/ModelOnly/CountdownModel';

        
        export function CountdownModel(): Promise<CountdownModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<CountdownModelSuccess>(`/internal/ModelOnly/CountdownModel`)
        }
    
    
        

        

        export type MultiBuyModelSuccess = paths['/internal/ModelOnly/MultiBuyModel']['get']['responses']['200']['content']['text/plain'];

        export const MultiBuyModelUrl = '/internal/ModelOnly/MultiBuyModel';

        
        export function MultiBuyModel(): Promise<MultiBuyModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<MultiBuyModelSuccess>(`/internal/ModelOnly/MultiBuyModel`)
        }
    
    
        

        

        export type StripedVerticalSpacerSuccess = paths['/internal/ModelOnly/StripedVerticalSpacer']['get']['responses']['200']['content']['text/plain'];

        export const StripedVerticalSpacerUrl = '/internal/ModelOnly/StripedVerticalSpacer';

        
        export function StripedVerticalSpacer(): Promise<StripedVerticalSpacerSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<StripedVerticalSpacerSuccess>(`/internal/ModelOnly/StripedVerticalSpacer`)
        }
    
    
        

        

        export type JavascriptRequestDataModelSuccess = paths['/internal/ModelOnly/JavascriptRequestDataModel']['get']['responses']['200']['content']['text/plain'];

        export const JavascriptRequestDataModelUrl = '/internal/ModelOnly/JavascriptRequestDataModel';

        
        export function JavascriptRequestDataModel(): Promise<JavascriptRequestDataModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<JavascriptRequestDataModelSuccess>(`/internal/ModelOnly/JavascriptRequestDataModel`)
        }
    
    
        

        

        export type BreadcrumbViewModelSuccess = paths['/internal/ModelOnly/BreadcrumbViewModel']['get']['responses']['200']['content']['text/plain'];

        export const BreadcrumbViewModelUrl = '/internal/ModelOnly/BreadcrumbViewModel';

        
        export function BreadcrumbViewModel(): Promise<BreadcrumbViewModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<BreadcrumbViewModelSuccess>(`/internal/ModelOnly/BreadcrumbViewModel`)
        }
    
    
        

        

        export type CmsButtonModelSuccess = paths['/internal/ModelOnly/CmsButtonModel']['get']['responses']['200']['content']['text/plain'];

        export const CmsButtonModelUrl = '/internal/ModelOnly/CmsButtonModel';

        
        export function CmsButtonModel(): Promise<CmsButtonModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<CmsButtonModelSuccess>(`/internal/ModelOnly/CmsButtonModel`)
        }
    
    
        

        

        export type CmsButtonGridModelSuccess = paths['/internal/ModelOnly/CmsButtonGridModel']['get']['responses']['200']['content']['text/plain'];

        export const CmsButtonGridModelUrl = '/internal/ModelOnly/CmsButtonGridModel';

        
        export function CmsButtonGridModel(): Promise<CmsButtonGridModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<CmsButtonGridModelSuccess>(`/internal/ModelOnly/CmsButtonGridModel`)
        }
    
    
        

        

        export type ProductPrelaunchBannerModelSuccess = paths['/internal/ModelOnly/ProductPrelaunchBannerModel']['get']['responses']['200']['content']['text/plain'];

        export const ProductPrelaunchBannerModelUrl = '/internal/ModelOnly/ProductPrelaunchBannerModel';

        
        export function ProductPrelaunchBannerModel(): Promise<ProductPrelaunchBannerModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<ProductPrelaunchBannerModelSuccess>(`/internal/ModelOnly/ProductPrelaunchBannerModel`)
        }
    
    
        

        

        export type BambuserViewModelSuccess = paths['/internal/ModelOnly/BambuserViewModel']['get']['responses']['200']['content']['text/plain'];

        export const BambuserViewModelUrl = '/internal/ModelOnly/BambuserViewModel';

        
        export function BambuserViewModel(): Promise<BambuserViewModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<BambuserViewModelSuccess>(`/internal/ModelOnly/BambuserViewModel`)
        }
    
    
        

        

        export type VimeoBlockDTOModelSuccess = paths['/internal/ModelOnly/VimeoBlockDTOModel']['get']['responses']['200']['content']['text/plain'];

        export const VimeoBlockDTOModelUrl = '/internal/ModelOnly/VimeoBlockDTOModel';

        
        export function VimeoBlockDTOModel(): Promise<VimeoBlockDTOModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<VimeoBlockDTOModelSuccess>(`/internal/ModelOnly/VimeoBlockDTOModel`)
        }
    
    
        

        

        export type YouTubeBlockDTOModelSuccess = paths['/internal/ModelOnly/YouTubeBlockDTOModel']['get']['responses']['200']['content']['text/plain'];

        export const YouTubeBlockDTOModelUrl = '/internal/ModelOnly/YouTubeBlockDTOModel';

        
        export function YouTubeBlockDTOModel(): Promise<YouTubeBlockDTOModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<YouTubeBlockDTOModelSuccess>(`/internal/ModelOnly/YouTubeBlockDTOModel`)
        }
    
    
        

        

        export type InspirationArticleLeftHandModelSuccess = paths['/internal/ModelOnly/InspirationArticleLeftHandModel']['get']['responses']['200']['content']['text/plain'];

        export const InspirationArticleLeftHandModelUrl = '/internal/ModelOnly/InspirationArticleLeftHandModel';

        
        export function InspirationArticleLeftHandModel(): Promise<InspirationArticleLeftHandModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<InspirationArticleLeftHandModelSuccess>(`/internal/ModelOnly/InspirationArticleLeftHandModel`)
        }
    
    
        

        

        export type ShippingSummaryModelSuccess = paths['/internal/ModelOnly/ShippingSummaryModel']['get']['responses']['200']['content']['text/plain'];

        export const ShippingSummaryModelUrl = '/internal/ModelOnly/ShippingSummaryModel';

        
        export function ShippingSummaryModel(): Promise<ShippingSummaryModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<ShippingSummaryModelSuccess>(`/internal/ModelOnly/ShippingSummaryModel`)
        }
    
    
        

        

        export type InspirationArticleRightHandModelSuccess = paths['/internal/ModelOnly/InspirationArticleRightHandModel']['get']['responses']['200']['content']['text/plain'];

        export const InspirationArticleRightHandModelUrl = '/internal/ModelOnly/InspirationArticleRightHandModel';

        
        export function InspirationArticleRightHandModel(): Promise<InspirationArticleRightHandModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<InspirationArticleRightHandModelSuccess>(`/internal/ModelOnly/InspirationArticleRightHandModel`)
        }
    
    
        

        

        export type VerticalSpacerContentBlockModelSuccess = paths['/internal/ModelOnly/VerticalSpacerContentBlockModel']['get']['responses']['200']['content']['text/plain'];

        export const VerticalSpacerContentBlockModelUrl = '/internal/ModelOnly/VerticalSpacerContentBlockModel';

        
        export function VerticalSpacerContentBlockModel(): Promise<VerticalSpacerContentBlockModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<VerticalSpacerContentBlockModelSuccess>(`/internal/ModelOnly/VerticalSpacerContentBlockModel`)
        }
    
    
        

        

        export type HorizontalSpaceBlockDTOModelSuccess = paths['/internal/ModelOnly/HorizontalSpaceBlockDTOModel']['get']['responses']['200']['content']['text/plain'];

        export const HorizontalSpaceBlockDTOModelUrl = '/internal/ModelOnly/HorizontalSpaceBlockDTOModel';

        
        export function HorizontalSpaceBlockDTOModel(): Promise<HorizontalSpaceBlockDTOModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<HorizontalSpaceBlockDTOModelSuccess>(`/internal/ModelOnly/HorizontalSpaceBlockDTOModel`)
        }
    
    
        

        

        export type AdvisorBlockDTOModelSuccess = paths['/internal/ModelOnly/AdvisorBlockDTOModel']['get']['responses']['200']['content']['text/plain'];

        export const AdvisorBlockDTOModelUrl = '/internal/ModelOnly/AdvisorBlockDTOModel';

        
        export function AdvisorBlockDTOModel(): Promise<AdvisorBlockDTOModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<AdvisorBlockDTOModelSuccess>(`/internal/ModelOnly/AdvisorBlockDTOModel`)
        }
    
    
        

        

        export type IStockStatusViewModelSuccess = paths['/internal/ModelOnly/IStockStatusViewModel']['get']['responses']['200']['content']['text/plain'];

        export const IStockStatusViewModelUrl = '/internal/ModelOnly/IStockStatusViewModel';

        
        export function IStockStatusViewModel(): Promise<IStockStatusViewModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<IStockStatusViewModelSuccess>(`/internal/ModelOnly/IStockStatusViewModel`)
        }
    
    
        

        

        export type ImageBannerModelSuccess = paths['/internal/ModelOnly/ImageBannerModel']['get']['responses']['200']['content']['text/plain'];

        export const ImageBannerModelUrl = '/internal/ModelOnly/ImageBannerModel';

        
        export function ImageBannerModel(): Promise<ImageBannerModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<ImageBannerModelSuccess>(`/internal/ModelOnly/ImageBannerModel`)
        }
    
    
        

        

        export type FollowBrandClubBlockDTOSuccess = paths['/internal/ModelOnly/FollowBrandClubBlockDTO']['get']['responses']['200']['content']['text/plain'];

        export const FollowBrandClubBlockDTOUrl = '/internal/ModelOnly/FollowBrandClubBlockDTO';

        
        export function FollowBrandClubBlockDTO(): Promise<FollowBrandClubBlockDTOSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<FollowBrandClubBlockDTOSuccess>(`/internal/ModelOnly/FollowBrandClubBlockDTO`)
        }
    
    
        

        

        export type QuestionnaireContentBlockDTOSuccess = paths['/internal/ModelOnly/QuestionnaireContentBlockDTO']['get']['responses']['200']['content']['text/plain'];

        export const QuestionnaireContentBlockDTOUrl = '/internal/ModelOnly/QuestionnaireContentBlockDTO';

        
        export function QuestionnaireContentBlockDTO(): Promise<QuestionnaireContentBlockDTOSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<QuestionnaireContentBlockDTOSuccess>(`/internal/ModelOnly/QuestionnaireContentBlockDTO`)
        }
    
    
        

        

        export type PageActionModelSuccess = paths['/internal/ModelOnly/PageActionModel']['get']['responses']['200']['content']['text/plain'];

        export const PageActionModelUrl = '/internal/ModelOnly/PageActionModel';

        
        export function PageActionModel(): Promise<PageActionModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<PageActionModelSuccess>(`/internal/ModelOnly/PageActionModel`)
        }
    
    
        

        

        export type MyProfileSettingPageViewModelSuccess = paths['/internal/ModelOnly/MyProfileSettingPageViewModel']['get']['responses']['200']['content']['text/plain'];

        export const MyProfileSettingPageViewModelUrl = '/internal/ModelOnly/MyProfileSettingPageViewModel';

        
        export function MyProfileSettingPageViewModel(): Promise<MyProfileSettingPageViewModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<MyProfileSettingPageViewModelSuccess>(`/internal/ModelOnly/MyProfileSettingPageViewModel`)
        }
    
    
        

        

        export type SignoffPageViewModelSuccess = paths['/internal/ModelOnly/SignoffPageViewModel']['get']['responses']['200']['content']['text/plain'];

        export const SignoffPageViewModelUrl = '/internal/ModelOnly/SignoffPageViewModel';

        
        export function SignoffPageViewModel(): Promise<SignoffPageViewModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<SignoffPageViewModelSuccess>(`/internal/ModelOnly/SignoffPageViewModel`)
        }
    
    
        

        

        export type SignoffConfirmDialogueModelSuccess = paths['/internal/ModelOnly/SignoffConfirmDialogueModel']['get']['responses']['200']['content']['text/plain'];

        export const SignoffConfirmDialogueModelUrl = '/internal/ModelOnly/SignoffConfirmDialogueModel';

        
        export function SignoffConfirmDialogueModel(): Promise<SignoffConfirmDialogueModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<SignoffConfirmDialogueModelSuccess>(`/internal/ModelOnly/SignoffConfirmDialogueModel`)
        }
    
    
        

        

        export type PermissionViewModelSuccess = paths['/internal/ModelOnly/PermissionViewModel']['get']['responses']['200']['content']['text/plain'];

        export const PermissionViewModelUrl = '/internal/ModelOnly/PermissionViewModel';

        
        export function PermissionViewModel(): Promise<PermissionViewModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<PermissionViewModelSuccess>(`/internal/ModelOnly/PermissionViewModel`)
        }
    
    
        

        

        export type UpdateMyStoreViewModelSuccess = paths['/internal/ModelOnly/UpdateMyStoreViewModel']['get']['responses']['200']['content']['text/plain'];

        export const UpdateMyStoreViewModelUrl = '/internal/ModelOnly/UpdateMyStoreViewModel';

        
        export function UpdateMyStoreViewModel(): Promise<UpdateMyStoreViewModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<UpdateMyStoreViewModelSuccess>(`/internal/ModelOnly/UpdateMyStoreViewModel`)
        }
    
    
        

        

        export type RevieveMakeupAdvisorViewModelSuccess = paths['/internal/ModelOnly/RevieveMakeupAdvisorViewModel']['get']['responses']['200']['content']['text/plain'];

        export const RevieveMakeupAdvisorViewModelUrl = '/internal/ModelOnly/RevieveMakeupAdvisorViewModel';

        
        export function RevieveMakeupAdvisorViewModel(): Promise<RevieveMakeupAdvisorViewModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<RevieveMakeupAdvisorViewModelSuccess>(`/internal/ModelOnly/RevieveMakeupAdvisorViewModel`)
        }
    
    
        

        

        export type ShippingOptionsModelSuccess = paths['/internal/ModelOnly/ShippingOptionsModel']['get']['responses']['200']['content']['text/plain'];

        export const ShippingOptionsModelUrl = '/internal/ModelOnly/ShippingOptionsModel';

        
        export function ShippingOptionsModel(): Promise<ShippingOptionsModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<ShippingOptionsModelSuccess>(`/internal/ModelOnly/ShippingOptionsModel`)
        }
    
    
        

        

        export type KicksIpadPosReceiptViewModelSuccess = paths['/internal/ModelOnly/KicksIpadPosReceiptViewModel']['get']['responses']['200']['content']['text/plain'];

        export const KicksIpadPosReceiptViewModelUrl = '/internal/ModelOnly/KicksIpadPosReceiptViewModel';

        
        export function KicksIpadPosReceiptViewModel(): Promise<KicksIpadPosReceiptViewModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<KicksIpadPosReceiptViewModelSuccess>(`/internal/ModelOnly/KicksIpadPosReceiptViewModel`)
        }
    
    
        

        

        export type InspirationAlsoReadModelSuccess = paths['/internal/ModelOnly/InspirationAlsoReadModel']['get']['responses']['200']['content']['text/plain'];

        export const InspirationAlsoReadModelUrl = '/internal/ModelOnly/InspirationAlsoReadModel';

        
        export function InspirationAlsoReadModel(): Promise<InspirationAlsoReadModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<InspirationAlsoReadModelSuccess>(`/internal/ModelOnly/InspirationAlsoReadModel`)
        }
    
    
        

        

        export type BeautyTrendBoxNotifyButtonModelSuccess = paths['/internal/ModelOnly/BeautyTrendBoxNotifyButtonModel']['get']['responses']['200']['content']['text/plain'];

        export const BeautyTrendBoxNotifyButtonModelUrl = '/internal/ModelOnly/BeautyTrendBoxNotifyButtonModel';

        
        export function BeautyTrendBoxNotifyButtonModel(): Promise<BeautyTrendBoxNotifyButtonModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<BeautyTrendBoxNotifyButtonModelSuccess>(`/internal/ModelOnly/BeautyTrendBoxNotifyButtonModel`)
        }
    
    
        

        

        export type GiftCardBalancePlaceholderModelSuccess = paths['/internal/ModelOnly/GiftCardBalancePlaceholderModel']['get']['responses']['200']['content']['text/plain'];

        export const GiftCardBalancePlaceholderModelUrl = '/internal/ModelOnly/GiftCardBalancePlaceholderModel';

        
        export function GiftCardBalancePlaceholderModel(): Promise<GiftCardBalancePlaceholderModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GiftCardBalancePlaceholderModelSuccess>(`/internal/ModelOnly/GiftCardBalancePlaceholderModel`)
        }
    
    
        

        

        export type LiveEventDTOModelSuccess = paths['/internal/ModelOnly/LiveEventDTOModel']['get']['responses']['200']['content']['text/plain'];

        export const LiveEventDTOModelUrl = '/internal/ModelOnly/LiveEventDTOModel';

        
        export function LiveEventDTOModel(): Promise<LiveEventDTOModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<LiveEventDTOModelSuccess>(`/internal/ModelOnly/LiveEventDTOModel`)
        }
    
    
        

        

        export type CheckoutDeliveryGroupModelSuccess = paths['/internal/ModelOnly/CheckoutDeliveryGroupModel']['get']['responses']['200']['content']['text/plain'];

        export const CheckoutDeliveryGroupModelUrl = '/internal/ModelOnly/CheckoutDeliveryGroupModel';

        
        export function CheckoutDeliveryGroupModel(): Promise<CheckoutDeliveryGroupModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<CheckoutDeliveryGroupModelSuccess>(`/internal/ModelOnly/CheckoutDeliveryGroupModel`)
        }
    
    
        

        

        export type CheckoutDeliveryPostModelSuccess = paths['/internal/ModelOnly/CheckoutDeliveryPostModel']['get']['responses']['200']['content']['text/plain'];

        export const CheckoutDeliveryPostModelUrl = '/internal/ModelOnly/CheckoutDeliveryPostModel';

        
        export function CheckoutDeliveryPostModel(): Promise<CheckoutDeliveryPostModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<CheckoutDeliveryPostModelSuccess>(`/internal/ModelOnly/CheckoutDeliveryPostModel`)
        }
    
    
        

        

        export type CheckoutDeliveryViewModelSuccess = paths['/internal/ModelOnly/CheckoutDeliveryViewModel']['get']['responses']['200']['content']['text/plain'];

        export const CheckoutDeliveryViewModelUrl = '/internal/ModelOnly/CheckoutDeliveryViewModel';

        
        export function CheckoutDeliveryViewModel(): Promise<CheckoutDeliveryViewModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<CheckoutDeliveryViewModelSuccess>(`/internal/ModelOnly/CheckoutDeliveryViewModel`)
        }
    
    
        

        

        export type CheckoutSummaryViewModelSuccess = paths['/internal/ModelOnly/CheckoutSummaryViewModel']['get']['responses']['200']['content']['text/plain'];

        export const CheckoutSummaryViewModelUrl = '/internal/ModelOnly/CheckoutSummaryViewModel';

        
        export function CheckoutSummaryViewModel(): Promise<CheckoutSummaryViewModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<CheckoutSummaryViewModelSuccess>(`/internal/ModelOnly/CheckoutSummaryViewModel`)
        }
    
    
        

        

        export type CheckoutSummaryPostModelSuccess = paths['/internal/ModelOnly/CheckoutSummaryPostModel']['get']['responses']['200']['content']['text/plain'];

        export const CheckoutSummaryPostModelUrl = '/internal/ModelOnly/CheckoutSummaryPostModel';

        
        export function CheckoutSummaryPostModel(): Promise<CheckoutSummaryPostModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<CheckoutSummaryPostModelSuccess>(`/internal/ModelOnly/CheckoutSummaryPostModel`)
        }
    
    
        

        

        export type PaymentViewModelSuccess = paths['/internal/ModelOnly/PaymentViewModel']['get']['responses']['200']['content']['text/plain'];

        export const PaymentViewModelUrl = '/internal/ModelOnly/PaymentViewModel';

        
        export function PaymentViewModel(): Promise<PaymentViewModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<PaymentViewModelSuccess>(`/internal/ModelOnly/PaymentViewModel`)
        }
    
    
        

        

        export type PaymentReepayCallbackViewModelSuccess = paths['/internal/ModelOnly/PaymentReepayCallbackViewModel']['get']['responses']['200']['content']['text/plain'];

        export const PaymentReepayCallbackViewModelUrl = '/internal/ModelOnly/PaymentReepayCallbackViewModel';

        
        export function PaymentReepayCallbackViewModel(): Promise<PaymentReepayCallbackViewModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<PaymentReepayCallbackViewModelSuccess>(`/internal/ModelOnly/PaymentReepayCallbackViewModel`)
        }
    
    
        

        

        export type ReceiptPageViewModelSuccess = paths['/internal/ModelOnly/ReceiptPageViewModel']['get']['responses']['200']['content']['text/plain'];

        export const ReceiptPageViewModelUrl = '/internal/ModelOnly/ReceiptPageViewModel';

        
        export function ReceiptPageViewModel(): Promise<ReceiptPageViewModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<ReceiptPageViewModelSuccess>(`/internal/ModelOnly/ReceiptPageViewModel`)
        }
    
    
        

        export type ModifaceIndexParams = paths['/internal/Modiface/Index']['get']['parameters']['query'];

        export type ModifaceIndexSuccess = paths['/internal/Modiface/Index']['get']['responses']['200']['content']['text/plain'];

        export const ModifaceIndexUrl = '/internal/Modiface/Index';

        
        export function ModifaceIndex(params: ModifaceIndexParams): Promise<ModifaceIndexSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<ModifaceIndexSuccess>(`/internal/Modiface/Index${formatParams(params)}`)
        }
    
    
        

        

        export type MyFavoritesGetSuccess = paths['/internal/MyFavorites/Get']['get']['responses']['200']['content']['text/plain'];

        export const MyFavoritesGetUrl = '/internal/MyFavorites/Get';

        
        export function MyFavoritesGet(): Promise<MyFavoritesGetSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<MyFavoritesGetSuccess>(`/internal/MyFavorites/Get`)
        }
    
    
        

        export type _StatusDetailsParams = paths['/internal/MyOrder/_StatusDetails']['get']['parameters']['query'];

        export type _StatusDetailsSuccess = paths['/internal/MyOrder/_StatusDetails']['get']['responses']['200']['content']['text/html'];

        export const _StatusDetailsUrl = '/internal/MyOrder/_StatusDetails';

        
        export function _StatusDetails(params: _StatusDetailsParams): Promise<_StatusDetailsSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchHTML(`/internal/MyOrder/_StatusDetails${formatParams(params)}`)
        }
    
    
        

        export type GetStatusDetailsParams = paths['/internal/MyOrder/GetStatusDetails']['get']['parameters']['query'];

        export type GetStatusDetailsSuccess = paths['/internal/MyOrder/GetStatusDetails']['get']['responses']['200']['content']['text/plain'];

        export const GetStatusDetailsUrl = '/internal/MyOrder/GetStatusDetails';

        
        export function GetStatusDetails(params: GetStatusDetailsParams): Promise<GetStatusDetailsSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetStatusDetailsSuccess>(`/internal/MyOrder/GetStatusDetails${formatParams(params)}`)
        }
    
    
        

        export type _FollowupDetailsParams = paths['/internal/MyOrder/_FollowupDetails']['post']['parameters']['query'];

        export type _FollowupDetailsSuccess = paths['/internal/MyOrder/_FollowupDetails']['post']['responses']['200']['content']['text/plain'];

        export const _FollowupDetailsUrl = '/internal/MyOrder/_FollowupDetails';

        
        export function _FollowupDetails(params: _FollowupDetailsParams): Promise<_FollowupDetailsSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<_FollowupDetailsSuccess>(`/internal/MyOrder/_FollowupDetails${formatParams(params)}`)
        }
    
    
        

        export type MyOrderCancelParams = paths['/internal/MyOrder/Cancel']['post']['parameters']['query'];

        

        export const MyOrderCancelUrl = '/internal/MyOrder/Cancel';

        
        export function MyOrderCancel(params: MyOrderCancelParams): Promise<unknown> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<unknown>(`/internal/MyOrder/Cancel${formatParams(params)}`)
        }
    
    
        

        export type DownloadDigitalProductParams = paths['/internal/MyOrder/DownloadDigitalProduct']['get']['parameters']['query'];

        

        export const DownloadDigitalProductUrl = '/internal/MyOrder/DownloadDigitalProduct';

        
        export function DownloadDigitalProduct(params: DownloadDigitalProductParams): Promise<unknown> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<unknown>(`/internal/MyOrder/DownloadDigitalProduct${formatParams(params)}`)
        }
    
    
        

        export type DownloadInvoiceParams = paths['/internal/MyOrder/DownloadInvoice']['get']['parameters']['query'];

        

        export const DownloadInvoiceUrl = '/internal/MyOrder/DownloadInvoice';

        
        export function DownloadInvoice(params: DownloadInvoiceParams): Promise<unknown> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<unknown>(`/internal/MyOrder/DownloadInvoice${formatParams(params)}`)
        }
    
    
        

        export type DownloadSwapCertificateParams = paths['/internal/MyOrder/DownloadSwapCertificate']['get']['parameters']['query'];

        

        export const DownloadSwapCertificateUrl = '/internal/MyOrder/DownloadSwapCertificate';

        
        export function DownloadSwapCertificate(params: DownloadSwapCertificateParams): Promise<unknown> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<unknown>(`/internal/MyOrder/DownloadSwapCertificate${formatParams(params)}`)
        }
    
    
        

        export type DownloadSwapCertificateLineParams = paths['/internal/MyOrder/DownloadSwapCertificateLine']['get']['parameters']['query'];

        

        export const DownloadSwapCertificateLineUrl = '/internal/MyOrder/DownloadSwapCertificateLine';

        
        export function DownloadSwapCertificateLine(params: DownloadSwapCertificateLineParams): Promise<unknown> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<unknown>(`/internal/MyOrder/DownloadSwapCertificateLine${formatParams(params)}`)
        }
    
    
        

        

        export type OrderSimpleModelSuccess = paths['/internal/MyOrder/OrderSimpleModel']['get']['responses']['200']['content']['text/plain'];

        export const OrderSimpleModelUrl = '/internal/MyOrder/OrderSimpleModel';

        
        export function OrderSimpleModel(): Promise<OrderSimpleModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<OrderSimpleModelSuccess>(`/internal/MyOrder/OrderSimpleModel`)
        }
    
    
        

        

        export type HeaderSuccess = paths['/internal/myprofile/MyProfile/Header']['get']['responses']['200']['content']['text/plain'];

        export const HeaderUrl = '/internal/myprofile/MyProfile/Header';

        
        export function Header(): Promise<HeaderSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<HeaderSuccess>(`/internal/myprofile/MyProfile/Header`)
        }
    
    
        

        

        export type MyProfileLayoutViewModelSuccess = paths['/internal/myprofile/MyProfile/MyProfileLayoutViewModel']['get']['responses']['200']['content']['text/plain'];

        export const MyProfileLayoutViewModelUrl = '/internal/myprofile/MyProfile/MyProfileLayoutViewModel';

        
        export function MyProfileLayoutViewModel(): Promise<MyProfileLayoutViewModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<MyProfileLayoutViewModelSuccess>(`/internal/myprofile/MyProfile/MyProfileLayoutViewModel`)
        }
    
    
        

        

        export type MyProfileFrontPageViewModelSuccess = paths['/internal/myprofile/MyProfile/MyProfileFrontPageViewModel']['get']['responses']['200']['content']['text/plain'];

        export const MyProfileFrontPageViewModelUrl = '/internal/myprofile/MyProfile/MyProfileFrontPageViewModel';

        
        export function MyProfileFrontPageViewModel(): Promise<MyProfileFrontPageViewModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<MyProfileFrontPageViewModelSuccess>(`/internal/myprofile/MyProfile/MyProfileFrontPageViewModel`)
        }
    
    
        

        export type GetProductReviewParams = paths['/internal/myprofile/MyReview/GetProductReview']['get']['parameters']['query'];

        export type GetProductReviewSuccess = paths['/internal/myprofile/MyReview/GetProductReview']['get']['responses']['200']['content']['text/plain'];

        export const GetProductReviewUrl = '/internal/myprofile/MyReview/GetProductReview';

        
        export function GetProductReview(params: GetProductReviewParams): Promise<GetProductReviewSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetProductReviewSuccess>(`/internal/myprofile/MyReview/GetProductReview${formatParams(params)}`)
        }
    
    
        

        

        export type ReviewViewModelWrapperSuccess = paths['/internal/myprofile/MyReview/ReviewViewModelWrapper']['get']['responses']['200']['content']['text/plain'];

        export const ReviewViewModelWrapperUrl = '/internal/myprofile/MyReview/ReviewViewModelWrapper';

        
        export function ReviewViewModelWrapper(): Promise<ReviewViewModelWrapperSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<ReviewViewModelWrapperSuccess>(`/internal/myprofile/MyReview/ReviewViewModelWrapper`)
        }
    
    
        

        

        export type GetSampleProfilePageModelSuccess = paths['/internal/myprofile/MySample/GetSampleProfilePageModel']['get']['responses']['200']['content']['text/plain'];

        export const GetSampleProfilePageModelUrl = '/internal/myprofile/MySample/GetSampleProfilePageModel';

        
        export function GetSampleProfilePageModel(): Promise<GetSampleProfilePageModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetSampleProfilePageModelSuccess>(`/internal/myprofile/MySample/GetSampleProfilePageModel`)
        }
    
    
        

        export type RateSampleParams = paths['/internal/myprofile/MySample/RateSample']['put']['parameters']['query'];

        export type RateSampleSuccess = paths['/internal/myprofile/MySample/RateSample']['put']['responses']['200']['content']['text/plain'];

        export const RateSampleUrl = '/internal/myprofile/MySample/RateSample';

        
        export function RateSample(params: RateSampleParams): Promise<RateSampleSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return putQuery<RateSampleSuccess>(`/internal/myprofile/MySample/RateSample${formatParams(params)}`)
        }
    
    
        

        export type UpdateFavoriteStatusParams = paths['/internal/myprofile/MySample/UpdateFavoriteStatus']['post']['parameters']['query'];

        export type UpdateFavoriteStatusSuccess = paths['/internal/myprofile/MySample/UpdateFavoriteStatus']['post']['responses']['200']['content']['text/plain'];

        export const UpdateFavoriteStatusUrl = '/internal/myprofile/MySample/UpdateFavoriteStatus';

        
        export function UpdateFavoriteStatus(params: UpdateFavoriteStatusParams): Promise<UpdateFavoriteStatusSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<UpdateFavoriteStatusSuccess>(`/internal/myprofile/MySample/UpdateFavoriteStatus${formatParams(params)}`)
        }
    
    
        

        export type GetOrderListModelParams = paths['/internal/myprofile/Order/GetOrderListModel']['get']['parameters']['query'];

        export type GetOrderListModelSuccess = paths['/internal/myprofile/Order/GetOrderListModel']['get']['responses']['200']['content']['text/plain'];

        export const GetOrderListModelUrl = '/internal/myprofile/Order/GetOrderListModel';

        
        export function GetOrderListModel(params: GetOrderListModelParams): Promise<GetOrderListModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetOrderListModelSuccess>(`/internal/myprofile/Order/GetOrderListModel${formatParams(params)}`)
        }
    
    
        

        export type ReceiptFileParams = paths['/internal/myprofile/Order/ReceiptFile']['get']['parameters']['query'];

        export type ReceiptFileSuccess = paths['/internal/myprofile/Order/ReceiptFile']['get']['responses']['200']['content']['text/plain'];

        export const ReceiptFileUrl = '/internal/myprofile/Order/ReceiptFile';

        
        export function ReceiptFile(params: ReceiptFileParams): Promise<ReceiptFileSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<ReceiptFileSuccess>(`/internal/myprofile/Order/ReceiptFile${formatParams(params)}`)
        }
    
    
        

        export type GetReceiptParams = paths['/internal/myprofile/Order/GetReceipt']['get']['parameters']['query'];

        export type GetReceiptSuccess = paths['/internal/myprofile/Order/GetReceipt']['get']['responses']['200']['content']['text/plain'];

        export const GetReceiptUrl = '/internal/myprofile/Order/GetReceipt';

        
        export function GetReceipt(params: GetReceiptParams): Promise<GetReceiptSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetReceiptSuccess>(`/internal/myprofile/Order/GetReceipt${formatParams(params)}`)
        }
    
    
        

        export type _ReceiptParams = paths['/internal/myprofile/Order/_Receipt']['get']['parameters']['query'];

        

        export const _ReceiptUrl = '/internal/myprofile/Order/_Receipt';

        
        export function _Receipt(params: _ReceiptParams): Promise<unknown> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<unknown>(`/internal/myprofile/Order/_Receipt${formatParams(params)}`)
        }
    
    
        

        

        export type MyProfileOrderDetailsViewModelSuccess = paths['/internal/myprofile/Order/MyProfileOrderDetailsViewModel']['get']['responses']['200']['content']['text/plain'];

        export const MyProfileOrderDetailsViewModelUrl = '/internal/myprofile/Order/MyProfileOrderDetailsViewModel';

        
        export function MyProfileOrderDetailsViewModel(): Promise<MyProfileOrderDetailsViewModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<MyProfileOrderDetailsViewModelSuccess>(`/internal/myprofile/Order/MyProfileOrderDetailsViewModel`)
        }
    
    
        

        export type RenderSubPageParams = paths['/internal/Page/RenderSubPage']['get']['parameters']['query'];

        export type RenderSubPageSuccess = paths['/internal/Page/RenderSubPage']['get']['responses']['200']['content']['text/html'];

        export const RenderSubPageUrl = '/internal/Page/RenderSubPage';

        
        export function RenderSubPage(params: RenderSubPageParams): Promise<RenderSubPageSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchHTML(`/internal/Page/RenderSubPage${formatParams(params)}`)
        }
    
    
        

        export type ReepayAcceptParams = paths['/internal/PaymentCallback/ReepayAccept']['post']['parameters']['query'];

        

        export const ReepayAcceptUrl = '/internal/PaymentCallback/ReepayAccept';

        
        export function ReepayAccept(params: ReepayAcceptParams): Promise<unknown> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<unknown>(`/internal/PaymentCallback/ReepayAccept${formatParams(params)}`)
        }
    
    
        

        export type ApproveParams = paths['/internal/myprofile/PendingSubscription/Approve']['get']['parameters']['query'];

        export type ApproveSuccess = paths['/internal/myprofile/PendingSubscription/Approve']['get']['responses']['200']['content']['text/plain'];

        export const ApproveUrl = '/internal/myprofile/PendingSubscription/Approve';

        
        export function Approve(params: ApproveParams): Promise<ApproveSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<ApproveSuccess>(`/internal/myprofile/PendingSubscription/Approve${formatParams(params)}`)
        }
    
    
        

        export type PendingSubscriptionCancelParams = paths['/internal/myprofile/PendingSubscription/Cancel']['get']['parameters']['query'];

        export type PendingSubscriptionCancelSuccess = paths['/internal/myprofile/PendingSubscription/Cancel']['get']['responses']['200']['content']['text/plain'];

        export const PendingSubscriptionCancelUrl = '/internal/myprofile/PendingSubscription/Cancel';

        
        export function PendingSubscriptionCancel(params: PendingSubscriptionCancelParams): Promise<PendingSubscriptionCancelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<PendingSubscriptionCancelSuccess>(`/internal/myprofile/PendingSubscription/Cancel${formatParams(params)}`)
        }
    
    
        

        export type PendingSubscriptionGetModelParams = paths['/internal/myprofile/PendingSubscription/GetModel']['get']['parameters']['query'];

        export type PendingSubscriptionGetModelSuccess = paths['/internal/myprofile/PendingSubscription/GetModel']['get']['responses']['200']['content']['text/plain'];

        export const PendingSubscriptionGetModelUrl = '/internal/myprofile/PendingSubscription/GetModel';

        
        export function PendingSubscriptionGetModel(params: PendingSubscriptionGetModelParams): Promise<PendingSubscriptionGetModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<PendingSubscriptionGetModelSuccess>(`/internal/myprofile/PendingSubscription/GetModel${formatParams(params)}`)
        }
    
    
        

        

        export type PermissionGetModelSuccess = paths['/internal/myprofile/Permission/GetModel']['get']['responses']['200']['content']['text/plain'];

        export const PermissionGetModelUrl = '/internal/myprofile/Permission/GetModel';

        
        export function PermissionGetModel(): Promise<PermissionGetModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<PermissionGetModelSuccess>(`/internal/myprofile/Permission/GetModel`)
        }
    
    
        

        export type PermissionUpdateParams = paths['/internal/myprofile/Permission/Update']['post']['parameters']['query'];

        

        export const PermissionUpdateUrl = '/internal/myprofile/Permission/Update';

        
        export function PermissionUpdate(params: PermissionUpdateParams): Promise<unknown> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<unknown>(`/internal/myprofile/Permission/Update${formatParams(params)}`)
        }
    
    
        

        

        export type PersonalFiltersIndexSuccess = paths['/internal/PersonalFilters/Index']['get']['responses']['200']['content']['text/plain'];

        export const PersonalFiltersIndexUrl = '/internal/PersonalFilters/Index';

        
        export function PersonalFiltersIndex(): Promise<PersonalFiltersIndexSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<PersonalFiltersIndexSuccess>(`/internal/PersonalFilters/Index`)
        }
    
    
        export type UpdatePreferenceModelRequestBody = paths['/internal/PersonalFilters/UpdatePreferenceModel']['post']['requestBody']['content']['application/json'];

        

        export type UpdatePreferenceModelSuccess = paths['/internal/PersonalFilters/UpdatePreferenceModel']['post']['responses']['200']['content']['text/plain'];

        export const UpdatePreferenceModelUrl = '/internal/PersonalFilters/UpdatePreferenceModel';

        
        export function UpdatePreferenceModel(requestBody: UpdatePreferenceModelRequestBody): Promise<UpdatePreferenceModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postJSON<UpdatePreferenceModelSuccess>(`/internal/PersonalFilters/UpdatePreferenceModel`, requestBody)
        }
    
    
        

        

        export type SetIntroducedSuccess = paths['/internal/PersonalFilters/SetIntroduced']['get']['responses']['200']['content']['text/plain'];

        export const SetIntroducedUrl = '/internal/PersonalFilters/SetIntroduced';

        
        export function SetIntroduced(): Promise<SetIntroducedSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<SetIntroducedSuccess>(`/internal/PersonalFilters/SetIntroduced`)
        }
    
    
        

        export type ActivateEmployeeLoginParams = paths['/internal/PersonalOffer/ActivateEmployeeLogin']['post']['parameters']['query'];

        export type ActivateEmployeeLoginSuccess = paths['/internal/PersonalOffer/ActivateEmployeeLogin']['post']['responses']['200']['content']['text/plain'];

        export const ActivateEmployeeLoginUrl = '/internal/PersonalOffer/ActivateEmployeeLogin';

        
        export function ActivateEmployeeLogin(params: ActivateEmployeeLoginParams): Promise<ActivateEmployeeLoginSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<ActivateEmployeeLoginSuccess>(`/internal/PersonalOffer/ActivateEmployeeLogin${formatParams(params)}`)
        }
    
    
        

        export type EnableParams = paths['/internal/PersonalOffer/Enable']['post']['parameters']['query'];

        export type EnableSuccess = paths['/internal/PersonalOffer/Enable']['post']['responses']['200']['content']['text/plain'];

        export const EnableUrl = '/internal/PersonalOffer/Enable';

        
        export function Enable(params: EnableParams): Promise<EnableSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<EnableSuccess>(`/internal/PersonalOffer/Enable${formatParams(params)}`)
        }
    
    
        

        export type DisableParams = paths['/internal/PersonalOffer/Disable']['post']['parameters']['query'];

        export type DisableSuccess = paths['/internal/PersonalOffer/Disable']['post']['responses']['200']['content']['text/plain'];

        export const DisableUrl = '/internal/PersonalOffer/Disable';

        
        export function Disable(params: DisableParams): Promise<DisableSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<DisableSuccess>(`/internal/PersonalOffer/Disable${formatParams(params)}`)
        }
    
    
        

        

        export type PersonalOfferGetModelSuccess = paths['/internal/PersonalOffer/GetModel']['get']['responses']['200']['content']['text/plain'];

        export const PersonalOfferGetModelUrl = '/internal/PersonalOffer/GetModel';

        
        export function PersonalOfferGetModel(): Promise<PersonalOfferGetModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<PersonalOfferGetModelSuccess>(`/internal/PersonalOffer/GetModel`)
        }
    
    
        

        

        export type DeleteStateSuccess = paths['/internal/PersonalOffer/DeleteState']['post']['responses']['200']['content']['text/plain'];

        export const DeleteStateUrl = '/internal/PersonalOffer/DeleteState';

        
        export function DeleteState(): Promise<DeleteStateSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<DeleteStateSuccess>(`/internal/PersonalOffer/DeleteState`)
        }
    
    
        

        export type MyProfilePointInfoViewModelParams = paths['/internal/myprofile/PointInfo/MyProfilePointInfoViewModel']['get']['parameters']['query'];

        export type MyProfilePointInfoViewModelSuccess = paths['/internal/myprofile/PointInfo/MyProfilePointInfoViewModel']['get']['responses']['200']['content']['text/plain'];

        export const MyProfilePointInfoViewModelUrl = '/internal/myprofile/PointInfo/MyProfilePointInfoViewModel';

        
        export function MyProfilePointInfoViewModel(params: MyProfilePointInfoViewModelParams): Promise<MyProfilePointInfoViewModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<MyProfilePointInfoViewModelSuccess>(`/internal/myprofile/PointInfo/MyProfilePointInfoViewModel${formatParams(params)}`)
        }
    
    
        

        

        

        export const HidePointExpiryInfoUrl = '/internal/myprofile/PointInfo/HidePointExpiryInfo';

        
        export function HidePointExpiryInfo(): Promise<unknown> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<unknown>(`/internal/myprofile/PointInfo/HidePointExpiryInfo`)
        }
    
    
        

        

        export type PosFrontpageViewModelSuccess = paths['/internal/pos/PosFrontpage/PosFrontpageViewModel']['get']['responses']['200']['content']['text/plain'];

        export const PosFrontpageViewModelUrl = '/internal/pos/PosFrontpage/PosFrontpageViewModel';

        
        export function PosFrontpageViewModel(): Promise<PosFrontpageViewModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<PosFrontpageViewModelSuccess>(`/internal/pos/PosFrontpage/PosFrontpageViewModel`)
        }
    
    
        

        

        export type GetOrderNumberSuccess = paths['/internal/PosPayment/GetOrderNumber']['get']['responses']['200']['content']['text/plain'];

        export const GetOrderNumberUrl = '/internal/PosPayment/GetOrderNumber';

        
        export function GetOrderNumber(): Promise<GetOrderNumberSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetOrderNumberSuccess>(`/internal/PosPayment/GetOrderNumber`)
        }
    
    
        

        export type GetPowerstepParams = paths['/internal/Powerstep/GetPowerstep']['get']['parameters']['query'];

        export type GetPowerstepSuccess = paths['/internal/Powerstep/GetPowerstep']['get']['responses']['200']['content']['text/plain'];

        export const GetPowerstepUrl = '/internal/Powerstep/GetPowerstep';

        
        export function GetPowerstep(params: GetPowerstepParams): Promise<GetPowerstepSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetPowerstepSuccess>(`/internal/Powerstep/GetPowerstep${formatParams(params)}`)
        }
    
    
        

        

        export type GetMatasPlusInfoSuccess = paths['/internal/Powerstep/GetMatasPlusInfo']['get']['responses']['200']['content']['text/plain'];

        export const GetMatasPlusInfoUrl = '/internal/Powerstep/GetMatasPlusInfo';

        
        export function GetMatasPlusInfo(): Promise<GetMatasPlusInfoSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetMatasPlusInfoSuccess>(`/internal/Powerstep/GetMatasPlusInfo`)
        }
    
    
        

        

        export type StepNudgingViewModelSuccess = paths['/internal/Powerstep/StepNudgingViewModel']['get']['responses']['200']['content']['text/plain'];

        export const StepNudgingViewModelUrl = '/internal/Powerstep/StepNudgingViewModel';

        
        export function StepNudgingViewModel(): Promise<StepNudgingViewModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<StepNudgingViewModelSuccess>(`/internal/Powerstep/StepNudgingViewModel`)
        }
    
    
        

        

        export type GetForFixedLowPriceSuccess = paths['/internal/PriceLabelInfo/GetForFixedLowPrice']['get']['responses']['200']['content']['text/plain'];

        export const GetForFixedLowPriceUrl = '/internal/PriceLabelInfo/GetForFixedLowPrice';

        
        export function GetForFixedLowPrice(): Promise<GetForFixedLowPriceSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetForFixedLowPriceSuccess>(`/internal/PriceLabelInfo/GetForFixedLowPrice`)
        }
    
    
        

        export type GetVariantColorPickerForPdpParams = paths['/internal/Product/GetVariantColorPickerForPdp']['get']['parameters']['query'];

        export type GetVariantColorPickerForPdpSuccess = paths['/internal/Product/GetVariantColorPickerForPdp']['get']['responses']['200']['content']['text/plain'];

        export const GetVariantColorPickerForPdpUrl = '/internal/Product/GetVariantColorPickerForPdp';

        
        export function GetVariantColorPickerForPdp(params: GetVariantColorPickerForPdpParams): Promise<GetVariantColorPickerForPdpSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetVariantColorPickerForPdpSuccess>(`/internal/Product/GetVariantColorPickerForPdp${formatParams(params)}`)
        }
    
    
        

        export type GetVariantPickerForPlpParams = paths['/internal/Product/GetVariantPickerForPlp']['get']['parameters']['query'];

        export type GetVariantPickerForPlpSuccess = paths['/internal/Product/GetVariantPickerForPlp']['get']['responses']['200']['content']['text/plain'];

        export const GetVariantPickerForPlpUrl = '/internal/Product/GetVariantPickerForPlp';

        
        export function GetVariantPickerForPlp(params: GetVariantPickerForPlpParams): Promise<GetVariantPickerForPlpSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetVariantPickerForPlpSuccess>(`/internal/Product/GetVariantPickerForPlp${formatParams(params)}`)
        }
    
    
        

        

        export type ProductVariantsPdpModelSuccess = paths['/internal/Product/ProductVariantsPdpModel']['get']['responses']['200']['content']['text/plain'];

        export const ProductVariantsPdpModelUrl = '/internal/Product/ProductVariantsPdpModel';

        
        export function ProductVariantsPdpModel(): Promise<ProductVariantsPdpModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<ProductVariantsPdpModelSuccess>(`/internal/Product/ProductVariantsPdpModel`)
        }
    
    
        

        export type GetProductJsonParams = paths['/internal/Product/GetProductJson']['get']['parameters']['query'];

        export type GetProductJsonSuccess = paths['/internal/Product/GetProductJson']['get']['responses']['200']['content']['text/plain'];

        export const GetProductJsonUrl = '/internal/Product/GetProductJson';

        
        export function GetProductJson(params: GetProductJsonParams): Promise<GetProductJsonSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetProductJsonSuccess>(`/internal/Product/GetProductJson${formatParams(params)}`)
        }
    
    
        

        export type _GetAllProductReviewsParams = paths['/internal/Product/_GetAllProductReviews']['get']['parameters']['query'];

        export type _GetAllProductReviewsSuccess = paths['/internal/Product/_GetAllProductReviews']['get']['responses']['200']['content']['text/plain'];

        export const _GetAllProductReviewsUrl = '/internal/Product/_GetAllProductReviews';

        
        export function _GetAllProductReviews(params: _GetAllProductReviewsParams): Promise<_GetAllProductReviewsSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<_GetAllProductReviewsSuccess>(`/internal/Product/_GetAllProductReviews${formatParams(params)}`)
        }
    
    
        export type CreateCustomerNotificationRequestBody = paths['/internal/Product/CreateCustomerNotification']['post']['requestBody']['content']['application/json'];

        

        export type CreateCustomerNotificationSuccess = paths['/internal/Product/CreateCustomerNotification']['post']['responses']['200']['content']['text/plain'];

        export const CreateCustomerNotificationUrl = '/internal/Product/CreateCustomerNotification';

        
        export function CreateCustomerNotification(requestBody: CreateCustomerNotificationRequestBody): Promise<CreateCustomerNotificationSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postJSON<CreateCustomerNotificationSuccess>(`/internal/Product/CreateCustomerNotification`, requestBody)
        }
    
    
        

        export type GetRegimePriceParams = paths['/internal/Product/GetRegimePrice']['get']['parameters']['query'];

        export type GetRegimePriceSuccess = paths['/internal/Product/GetRegimePrice']['get']['responses']['200']['content']['text/plain'];

        export const GetRegimePriceUrl = '/internal/Product/GetRegimePrice';

        
        export function GetRegimePrice(params: GetRegimePriceParams): Promise<GetRegimePriceSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetRegimePriceSuccess>(`/internal/Product/GetRegimePrice${formatParams(params)}`)
        }
    
    
        

        export type GetProductRegimeParams = paths['/internal/Product/GetProductRegime']['get']['parameters']['query'];

        export type GetProductRegimeSuccess = paths['/internal/Product/GetProductRegime']['get']['responses']['200']['content']['text/plain'];

        export const GetProductRegimeUrl = '/internal/Product/GetProductRegime';

        
        export function GetProductRegime(params: GetProductRegimeParams): Promise<GetProductRegimeSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetProductRegimeSuccess>(`/internal/Product/GetProductRegime${formatParams(params)}`)
        }
    
    
        

        

        export type ProductInformationViewModelSuccess = paths['/internal/Product/ProductInformationViewModel']['get']['responses']['200']['content']['text/plain'];

        export const ProductInformationViewModelUrl = '/internal/Product/ProductInformationViewModel';

        
        export function ProductInformationViewModel(): Promise<ProductInformationViewModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<ProductInformationViewModelSuccess>(`/internal/Product/ProductInformationViewModel`)
        }
    
    
        

        export type ProductPageViewModelParams = paths['/internal/Product/ProductPageViewModel']['get']['parameters']['query'];

        export type ProductPageViewModelSuccess = paths['/internal/Product/ProductPageViewModel']['get']['responses']['200']['content']['text/plain'];

        export const ProductPageViewModelUrl = '/internal/Product/ProductPageViewModel';

        
        export function ProductPageViewModel(params: ProductPageViewModelParams): Promise<ProductPageViewModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<ProductPageViewModelSuccess>(`/internal/Product/ProductPageViewModel${formatParams(params)}`)
        }
    
    
        

        

        

        export const _GetProductReturnInformationUrl = '/internal/Product/_GetProductReturnInformation';

        
        export function _GetProductReturnInformation(): Promise<unknown> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<unknown>(`/internal/Product/_GetProductReturnInformation`)
        }
    
    
        

        export type GetCertificationParams = paths['/internal/Product/GetCertification']['get']['parameters']['query'];

        export type GetCertificationSuccess = paths['/internal/Product/GetCertification']['get']['responses']['200']['content']['text/plain'];

        export const GetCertificationUrl = '/internal/Product/GetCertification';

        
        export function GetCertification(params: GetCertificationParams): Promise<GetCertificationSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetCertificationSuccess>(`/internal/Product/GetCertification${formatParams(params)}`)
        }
    
    
        

        

        export type PageActionsSuccess = paths['/internal/Product/PageActions']['get']['responses']['200']['content']['text/plain'];

        export const PageActionsUrl = '/internal/Product/PageActions';

        
        export function PageActions(): Promise<PageActionsSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<PageActionsSuccess>(`/internal/Product/PageActions`)
        }
    
    
        

        export type ProductDeliveryToStoreInfoViewModelParams = paths['/internal/Product/ProductDeliveryToStoreInfoViewModel']['get']['parameters']['query'];

        export type ProductDeliveryToStoreInfoViewModelSuccess = paths['/internal/Product/ProductDeliveryToStoreInfoViewModel']['get']['responses']['200']['content']['text/plain'];

        export const ProductDeliveryToStoreInfoViewModelUrl = '/internal/Product/ProductDeliveryToStoreInfoViewModel';

        
        export function ProductDeliveryToStoreInfoViewModel(params: ProductDeliveryToStoreInfoViewModelParams): Promise<ProductDeliveryToStoreInfoViewModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<ProductDeliveryToStoreInfoViewModelSuccess>(`/internal/Product/ProductDeliveryToStoreInfoViewModel${formatParams(params)}`)
        }
    
    
        

        export type ProductDeliveryInfoViewModelParams = paths['/internal/Product/ProductDeliveryInfoViewModel']['get']['parameters']['query'];

        export type ProductDeliveryInfoViewModelSuccess = paths['/internal/Product/ProductDeliveryInfoViewModel']['get']['responses']['200']['content']['text/plain'];

        export const ProductDeliveryInfoViewModelUrl = '/internal/Product/ProductDeliveryInfoViewModel';

        
        export function ProductDeliveryInfoViewModel(params: ProductDeliveryInfoViewModelParams): Promise<ProductDeliveryInfoViewModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<ProductDeliveryInfoViewModelSuccess>(`/internal/Product/ProductDeliveryInfoViewModel${formatParams(params)}`)
        }
    
    
        

        export type ProductListItemsParams = paths['/internal/ProductList/ProductListItems']['get']['parameters']['query'];

        export type ProductListItemsSuccess = paths['/internal/ProductList/ProductListItems']['get']['responses']['200']['content']['text/plain'];

        export const ProductListItemsUrl = '/internal/ProductList/ProductListItems';

        
        export function ProductListItems(params: ProductListItemsParams): Promise<ProductListItemsSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<ProductListItemsSuccess>(`/internal/ProductList/ProductListItems${formatParams(params)}`)
        }
    
    
        

        

        export type GetProductListModelSuccess = paths['/internal/ProductList/GetProductListModel']['get']['responses']['200']['content']['text/plain'];

        export const GetProductListModelUrl = '/internal/ProductList/GetProductListModel';

        
        export function GetProductListModel(): Promise<GetProductListModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetProductListModelSuccess>(`/internal/ProductList/GetProductListModel`)
        }
    
    
        

        

        export type ResetHistorySuccess = paths['/internal/pos/ProductRecommendationAIAssistant/ResetHistory']['post']['responses']['200']['content']['text/plain'];

        export const ResetHistoryUrl = '/internal/pos/ProductRecommendationAIAssistant/ResetHistory';

        
        export function ResetHistory(): Promise<ResetHistorySuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<ResetHistorySuccess>(`/internal/pos/ProductRecommendationAIAssistant/ResetHistory`)
        }
    
    
        

        

        

        export const ProductRecommendationAIAssistantIndexUrl = '/internal/pos/ProductRecommendationAIAssistant/Index';

        
        export function ProductRecommendationAIAssistantIndex(): Promise<unknown> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<unknown>(`/internal/pos/ProductRecommendationAIAssistant/Index`)
        }
    
    
        

        export type GetRecommendationParams = paths['/internal/pos/ProductRecommendationAIAssistant/GetRecommendation']['post']['parameters']['query'];

        export type GetRecommendationSuccess = paths['/internal/pos/ProductRecommendationAIAssistant/GetRecommendation']['post']['responses']['200']['content']['text/plain'];

        export const GetRecommendationUrl = '/internal/pos/ProductRecommendationAIAssistant/GetRecommendation';

        
        export function GetRecommendation(params: GetRecommendationParams): Promise<GetRecommendationSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<GetRecommendationSuccess>(`/internal/pos/ProductRecommendationAIAssistant/GetRecommendation${formatParams(params)}`)
        }
    
    
        

        export type ValidateFieldParams = paths['/internal/ProductReview/ValidateField']['post']['parameters']['query'];

        export type ValidateFieldSuccess = paths['/internal/ProductReview/ValidateField']['post']['responses']['200']['content']['text/plain'];

        export const ValidateFieldUrl = '/internal/ProductReview/ValidateField';

        
        export function ValidateField(params: ValidateFieldParams): Promise<ValidateFieldSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<ValidateFieldSuccess>(`/internal/ProductReview/ValidateField${formatParams(params)}`)
        }
    
    
        

        export type ProductReviewCreateParams = paths['/internal/ProductReview/Create']['post']['parameters']['query'];

        export type ProductReviewCreateSuccess = paths['/internal/ProductReview/Create']['post']['responses']['200']['content']['text/plain'];

        export const ProductReviewCreateUrl = '/internal/ProductReview/Create';

        
        export function ProductReviewCreate(params: ProductReviewCreateParams): Promise<ProductReviewCreateSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<ProductReviewCreateSuccess>(`/internal/ProductReview/Create${formatParams(params)}`)
        }
    
    
        

        export type DeleteReviewParams = paths['/internal/ProductReview/DeleteReview']['post']['parameters']['query'];

        export type DeleteReviewSuccess = paths['/internal/ProductReview/DeleteReview']['post']['responses']['200']['content']['text/plain'];

        export const DeleteReviewUrl = '/internal/ProductReview/DeleteReview';

        
        export function DeleteReview(params: DeleteReviewParams): Promise<DeleteReviewSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<DeleteReviewSuccess>(`/internal/ProductReview/DeleteReview${formatParams(params)}`)
        }
    
    
        

        export type AddReviewLikeParams = paths['/internal/ProductReviewLike/AddReviewLike']['post']['parameters']['query'];

        export type AddReviewLikeSuccess = paths['/internal/ProductReviewLike/AddReviewLike']['post']['responses']['200']['content']['text/plain'];

        export const AddReviewLikeUrl = '/internal/ProductReviewLike/AddReviewLike';

        
        export function AddReviewLike(params: AddReviewLikeParams): Promise<AddReviewLikeSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<AddReviewLikeSuccess>(`/internal/ProductReviewLike/AddReviewLike${formatParams(params)}`)
        }
    
    
        

        export type ProductReviewSummaryIndexParams = paths['/internal/ProductReviewSummary/Index']['get']['parameters']['query'];

        export type ProductReviewSummaryIndexSuccess = paths['/internal/ProductReviewSummary/Index']['get']['responses']['200']['content']['text/plain'];

        export const ProductReviewSummaryIndexUrl = '/internal/ProductReviewSummary/Index';

        
        export function ProductReviewSummaryIndex(params: ProductReviewSummaryIndexParams): Promise<ProductReviewSummaryIndexSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<ProductReviewSummaryIndexSuccess>(`/internal/ProductReviewSummary/Index${formatParams(params)}`)
        }
    
    
        

        export type GetQuantityParams = paths['/internal/Quantity/GetQuantity']['get']['parameters']['query'];

        export type GetQuantitySuccess = paths['/internal/Quantity/GetQuantity']['get']['responses']['200']['content']['text/plain'];

        export const GetQuantityUrl = '/internal/Quantity/GetQuantity';

        
        export function GetQuantity(params: GetQuantityParams): Promise<GetQuantitySuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetQuantitySuccess>(`/internal/Quantity/GetQuantity${formatParams(params)}`)
        }
    
    
        

        export type GetQuestionnaireDataParams = paths['/internal/myprofile/QuestionnaireResult/GetQuestionnaireData']['get']['parameters']['query'];

        export type GetQuestionnaireDataSuccess = paths['/internal/myprofile/QuestionnaireResult/GetQuestionnaireData']['get']['responses']['200']['content']['text/plain'];

        export const GetQuestionnaireDataUrl = '/internal/myprofile/QuestionnaireResult/GetQuestionnaireData';

        
        export function GetQuestionnaireData(params: GetQuestionnaireDataParams): Promise<GetQuestionnaireDataSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetQuestionnaireDataSuccess>(`/internal/myprofile/QuestionnaireResult/GetQuestionnaireData${formatParams(params)}`)
        }
    
    
        

        export type SubmitQuestionnaireDataParams = paths['/internal/myprofile/QuestionnaireResult/SubmitQuestionnaireData']['post']['parameters']['query'];

        export type SubmitQuestionnaireDataSuccess = paths['/internal/myprofile/QuestionnaireResult/SubmitQuestionnaireData']['post']['responses']['200']['content']['text/plain'];

        export const SubmitQuestionnaireDataUrl = '/internal/myprofile/QuestionnaireResult/SubmitQuestionnaireData';

        
        export function SubmitQuestionnaireData(params: SubmitQuestionnaireDataParams): Promise<SubmitQuestionnaireDataSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<SubmitQuestionnaireDataSuccess>(`/internal/myprofile/QuestionnaireResult/SubmitQuestionnaireData${formatParams(params)}`)
        }
    
    
        

        

        export type QuestionnaireResultListViewModelSuccess = paths['/internal/myprofile/QuestionnaireResult/QuestionnaireResultListViewModel']['get']['responses']['200']['content']['text/plain'];

        export const QuestionnaireResultListViewModelUrl = '/internal/myprofile/QuestionnaireResult/QuestionnaireResultListViewModel';

        
        export function QuestionnaireResultListViewModel(): Promise<QuestionnaireResultListViewModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<QuestionnaireResultListViewModelSuccess>(`/internal/myprofile/QuestionnaireResult/QuestionnaireResultListViewModel`)
        }
    
    
        

        export type QuickGetModelParams = paths['/internal/checkout/Quick/GetModel']['get']['parameters']['query'];

        export type QuickGetModelSuccess = paths['/internal/checkout/Quick/GetModel']['get']['responses']['200']['content']['text/plain'];

        export const QuickGetModelUrl = '/internal/checkout/Quick/GetModel';

        
        export function QuickGetModel(params: QuickGetModelParams): Promise<QuickGetModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<QuickGetModelSuccess>(`/internal/checkout/Quick/GetModel${formatParams(params)}`)
        }
    
    
        export type CreateOrderRequestBody = paths['/internal/checkout/Quick/CreateOrder']['post']['requestBody']['content']['multipart/form-data'];

        

        

        export const CreateOrderUrl = '/internal/checkout/Quick/CreateOrder';

        
        export function CreateOrder(requestBody: CreateOrderRequestBody): Promise<unknown> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postFormData<unknown>(`/internal/checkout/Quick/CreateOrder`, requestBody)
        }
    
    
        

        export type RebuyGetParams = paths['/internal/Rebuy/Get']['get']['parameters']['query'];

        export type RebuyGetSuccess = paths['/internal/Rebuy/Get']['get']['responses']['200']['content']['text/plain'];

        export const RebuyGetUrl = '/internal/Rebuy/Get';

        
        export function RebuyGet(params: RebuyGetParams): Promise<RebuyGetSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<RebuyGetSuccess>(`/internal/Rebuy/Get${formatParams(params)}`)
        }
    
    
        

        export type OrderDetailsParams = paths['/internal/Receipt/OrderDetails']['get']['parameters']['query'];

        export type OrderDetailsSuccess = paths['/internal/Receipt/OrderDetails']['get']['responses']['200']['content']['text/plain'];

        export const OrderDetailsUrl = '/internal/Receipt/OrderDetails';

        
        export function OrderDetails(params: OrderDetailsParams): Promise<OrderDetailsSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<OrderDetailsSuccess>(`/internal/Receipt/OrderDetails${formatParams(params)}`)
        }
    
    
        

        export type CreateOrderDetailsPdfParams = paths['/internal/Receipt/CreateOrderDetailsPdf']['get']['parameters']['query'];

        export type CreateOrderDetailsPdfSuccess = paths['/internal/Receipt/CreateOrderDetailsPdf']['get']['responses']['200']['content']['text/plain'];

        export const CreateOrderDetailsPdfUrl = '/internal/Receipt/CreateOrderDetailsPdf';

        
        export function CreateOrderDetailsPdf(params: CreateOrderDetailsPdfParams): Promise<CreateOrderDetailsPdfSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<CreateOrderDetailsPdfSuccess>(`/internal/Receipt/CreateOrderDetailsPdf${formatParams(params)}`)
        }
    
    
        

        export type GetByProductIdParams = paths['/internal/Recommendation/GetByProductId']['get']['parameters']['query'];

        export type GetByProductIdSuccess = paths['/internal/Recommendation/GetByProductId']['get']['responses']['200']['content']['text/plain'];

        export const GetByProductIdUrl = '/internal/Recommendation/GetByProductId';

        
        export function GetByProductId(params: GetByProductIdParams): Promise<GetByProductIdSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetByProductIdSuccess>(`/internal/Recommendation/GetByProductId${formatParams(params)}`)
        }
    
    
        

        

        export type GetByBasketSuccess = paths['/internal/Recommendation/GetByBasket']['get']['responses']['200']['content']['text/plain'];

        export const GetByBasketUrl = '/internal/Recommendation/GetByBasket';

        
        export function GetByBasket(): Promise<GetByBasketSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetByBasketSuccess>(`/internal/Recommendation/GetByBasket`)
        }
    
    
        

        export type GetByProductGroupIdParams = paths['/internal/Recommendation/GetByProductGroupId']['get']['parameters']['query'];

        export type GetByProductGroupIdSuccess = paths['/internal/Recommendation/GetByProductGroupId']['get']['responses']['200']['content']['text/plain'];

        export const GetByProductGroupIdUrl = '/internal/Recommendation/GetByProductGroupId';

        
        export function GetByProductGroupId(params: GetByProductGroupIdParams): Promise<GetByProductGroupIdSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetByProductGroupIdSuccess>(`/internal/Recommendation/GetByProductGroupId${formatParams(params)}`)
        }
    
    
        

        export type GetBySearchResultParams = paths['/internal/Recommendation/GetBySearchResult']['get']['parameters']['query'];

        export type GetBySearchResultSuccess = paths['/internal/Recommendation/GetBySearchResult']['get']['responses']['200']['content']['text/plain'];

        export const GetBySearchResultUrl = '/internal/Recommendation/GetBySearchResult';

        
        export function GetBySearchResult(params: GetBySearchResultParams): Promise<GetBySearchResultSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetBySearchResultSuccess>(`/internal/Recommendation/GetBySearchResult${formatParams(params)}`)
        }
    
    
        

        export type GetByZeroSearchResultParams = paths['/internal/Recommendation/GetByZeroSearchResult']['get']['parameters']['query'];

        export type GetByZeroSearchResultSuccess = paths['/internal/Recommendation/GetByZeroSearchResult']['get']['responses']['200']['content']['text/plain'];

        export const GetByZeroSearchResultUrl = '/internal/Recommendation/GetByZeroSearchResult';

        
        export function GetByZeroSearchResult(params: GetByZeroSearchResultParams): Promise<GetByZeroSearchResultSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetByZeroSearchResultSuccess>(`/internal/Recommendation/GetByZeroSearchResult${formatParams(params)}`)
        }
    
    
        

        export type GetByHomePageParams = paths['/internal/Recommendation/GetByHomePage']['get']['parameters']['query'];

        export type GetByHomePageSuccess = paths['/internal/Recommendation/GetByHomePage']['get']['responses']['200']['content']['text/plain'];

        export const GetByHomePageUrl = '/internal/Recommendation/GetByHomePage';

        
        export function GetByHomePage(params: GetByHomePageParams): Promise<GetByHomePageSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetByHomePageSuccess>(`/internal/Recommendation/GetByHomePage${formatParams(params)}`)
        }
    
    
        

        export type GetByPowerstepParams = paths['/internal/Recommendation/GetByPowerstep']['get']['parameters']['query'];

        export type GetByPowerstepSuccess = paths['/internal/Recommendation/GetByPowerstep']['get']['responses']['200']['content']['text/plain'];

        export const GetByPowerstepUrl = '/internal/Recommendation/GetByPowerstep';

        
        export function GetByPowerstep(params: GetByPowerstepParams): Promise<GetByPowerstepSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetByPowerstepSuccess>(`/internal/Recommendation/GetByPowerstep${formatParams(params)}`)
        }
    
    
        

        export type GetByPowerstepTwoParams = paths['/internal/Recommendation/GetByPowerstepTwo']['get']['parameters']['query'];

        export type GetByPowerstepTwoSuccess = paths['/internal/Recommendation/GetByPowerstepTwo']['get']['responses']['200']['content']['text/plain'];

        export const GetByPowerstepTwoUrl = '/internal/Recommendation/GetByPowerstepTwo';

        
        export function GetByPowerstepTwo(params: GetByPowerstepTwoParams): Promise<GetByPowerstepTwoSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetByPowerstepTwoSuccess>(`/internal/Recommendation/GetByPowerstepTwo${formatParams(params)}`)
        }
    
    
        

        export type GetCustomParams = paths['/internal/Recommendation/GetCustom']['get']['parameters']['query'];

        export type GetCustomSuccess = paths['/internal/Recommendation/GetCustom']['get']['responses']['200']['content']['text/plain'];

        export const GetCustomUrl = '/internal/Recommendation/GetCustom';

        
        export function GetCustom(params: GetCustomParams): Promise<GetCustomSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetCustomSuccess>(`/internal/Recommendation/GetCustom${formatParams(params)}`)
        }
    
    
        

        export type GetForReceiptPageParams = paths['/internal/Recommendation/GetForReceiptPage']['get']['parameters']['query'];

        export type GetForReceiptPageSuccess = paths['/internal/Recommendation/GetForReceiptPage']['get']['responses']['200']['content']['text/plain'];

        export const GetForReceiptPageUrl = '/internal/Recommendation/GetForReceiptPage';

        
        export function GetForReceiptPage(params: GetForReceiptPageParams): Promise<GetForReceiptPageSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetForReceiptPageSuccess>(`/internal/Recommendation/GetForReceiptPage${formatParams(params)}`)
        }
    
    
        

        

        export type RecommendationRequestModelSuccess = paths['/internal/Recommendation/RecommendationRequestModel']['get']['responses']['200']['content']['text/plain'];

        export const RecommendationRequestModelUrl = '/internal/Recommendation/RecommendationRequestModel';

        
        export function RecommendationRequestModel(): Promise<RecommendationRequestModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<RecommendationRequestModelSuccess>(`/internal/Recommendation/RecommendationRequestModel`)
        }
    
    
        

        

        export type ReviewRequestViewModelSuccess = paths['/internal/myprofile/ReviewRequest/ReviewRequestViewModel']['get']['responses']['200']['content']['text/plain'];

        export const ReviewRequestViewModelUrl = '/internal/myprofile/ReviewRequest/ReviewRequestViewModel';

        
        export function ReviewRequestViewModel(): Promise<ReviewRequestViewModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<ReviewRequestViewModelSuccess>(`/internal/myprofile/ReviewRequest/ReviewRequestViewModel`)
        }
    
    
        

        export type QuickSearchParams = paths['/internal/Search/QuickSearch']['get']['parameters']['query'];

        export type QuickSearchSuccess = paths['/internal/Search/QuickSearch']['get']['responses']['200']['content']['text/plain'];

        export const QuickSearchUrl = '/internal/Search/QuickSearch';

        
        export function QuickSearch(params: QuickSearchParams): Promise<QuickSearchSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<QuickSearchSuccess>(`/internal/Search/QuickSearch${formatParams(params)}`)
        }
    
    
        

        

        export type GetSearchViewModelSuccess = paths['/internal/Search/GetSearchViewModel']['get']['responses']['200']['content']['text/plain'];

        export const GetSearchViewModelUrl = '/internal/Search/GetSearchViewModel';

        
        export function GetSearchViewModel(): Promise<GetSearchViewModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetSearchViewModelSuccess>(`/internal/Search/GetSearchViewModel`)
        }
    
    
        

        

        export type GetConfirmDialogueSuccess = paths['/internal/myprofile/Signoff/GetConfirmDialogue']['get']['responses']['200']['content']['text/plain'];

        export const GetConfirmDialogueUrl = '/internal/myprofile/Signoff/GetConfirmDialogue';

        
        export function GetConfirmDialogue(): Promise<GetConfirmDialogueSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetConfirmDialogueSuccess>(`/internal/myprofile/Signoff/GetConfirmDialogue`)
        }
    
    
        

        

        export type DoSignoffSuccess = paths['/internal/myprofile/Signoff/DoSignoff']['post']['responses']['200']['content']['text/plain'];

        export const DoSignoffUrl = '/internal/myprofile/Signoff/DoSignoff';

        
        export function DoSignoff(): Promise<DoSignoffSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<DoSignoffSuccess>(`/internal/myprofile/Signoff/DoSignoff`)
        }
    
    
        

        

        export type ModelForBasketSuccess = paths['/internal/checkout/Signup/ModelForBasket']['get']['responses']['200']['content']['text/plain'];

        export const ModelForBasketUrl = '/internal/checkout/Signup/ModelForBasket';

        
        export function ModelForBasket(): Promise<ModelForBasketSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<ModelForBasketSuccess>(`/internal/checkout/Signup/ModelForBasket`)
        }
    
    
        

        

        export type ModelForCheckoutSuccess = paths['/internal/checkout/Signup/ModelForCheckout']['get']['responses']['200']['content']['text/plain'];

        export const ModelForCheckoutUrl = '/internal/checkout/Signup/ModelForCheckout';

        
        export function ModelForCheckout(): Promise<ModelForCheckoutSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<ModelForCheckoutSuccess>(`/internal/checkout/Signup/ModelForCheckout`)
        }
    
    
        

        

        export type ClubMatasTermsSuccess = paths['/internal/checkout/Signup/ClubMatasTerms']['get']['responses']['200']['content']['text/plain'];

        export const ClubMatasTermsUrl = '/internal/checkout/Signup/ClubMatasTerms';

        
        export function ClubMatasTerms(): Promise<ClubMatasTermsSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<ClubMatasTermsSuccess>(`/internal/checkout/Signup/ClubMatasTerms`)
        }
    
    
        

        export type TogglePlusSignupParams = paths['/internal/checkout/Signup/TogglePlusSignup']['post']['parameters']['query'];

        export type TogglePlusSignupSuccess = paths['/internal/checkout/Signup/TogglePlusSignup']['post']['responses']['200']['content']['text/plain'];

        export const TogglePlusSignupUrl = '/internal/checkout/Signup/TogglePlusSignup';

        
        export function TogglePlusSignup(params: TogglePlusSignupParams): Promise<TogglePlusSignupSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<TogglePlusSignupSuccess>(`/internal/checkout/Signup/TogglePlusSignup${formatParams(params)}`)
        }
    
    
        

        export type ToggleClubSignupParams = paths['/internal/checkout/Signup/ToggleClubSignup']['post']['parameters']['query'];

        

        export const ToggleClubSignupUrl = '/internal/checkout/Signup/ToggleClubSignup';

        
        export function ToggleClubSignup(params: ToggleClubSignupParams): Promise<unknown> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<unknown>(`/internal/checkout/Signup/ToggleClubSignup${formatParams(params)}`)
        }
    
    
        

        export type DisclaimerParams = paths['/internal/SponsoredProduct/Disclaimer']['get']['parameters']['query'];

        export type DisclaimerSuccess = paths['/internal/SponsoredProduct/Disclaimer']['get']['responses']['200']['content']['text/plain'];

        export const DisclaimerUrl = '/internal/SponsoredProduct/Disclaimer';

        
        export function Disclaimer(params: DisclaimerParams): Promise<DisclaimerSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<DisclaimerSuccess>(`/internal/SponsoredProduct/Disclaimer${formatParams(params)}`)
        }
    
    
        

        export type StateUpdateParams = paths['/internal/checkout/State/Update']['post']['parameters']['query'];

        export type StateUpdateSuccess = paths['/internal/checkout/State/Update']['post']['responses']['200']['content']['text/plain'];

        export const StateUpdateUrl = '/internal/checkout/State/Update';

        
        export function StateUpdate(params: StateUpdateParams): Promise<StateUpdateSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<StateUpdateSuccess>(`/internal/checkout/State/Update${formatParams(params)}`)
        }
    
    
        

        export type ValidateFieldForShippingParams = paths['/internal/checkout/State/ValidateFieldForShipping']['post']['parameters']['query'];

        

        export const ValidateFieldForShippingUrl = '/internal/checkout/State/ValidateFieldForShipping';

        
        export function ValidateFieldForShipping(params: ValidateFieldForShippingParams): Promise<unknown> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<unknown>(`/internal/checkout/State/ValidateFieldForShipping${formatParams(params)}`)
        }
    
    
        

        export type ValidateFieldForTypedAddressParams = paths['/internal/checkout/State/ValidateFieldForTypedAddress']['post']['parameters']['query'];

        

        export const ValidateFieldForTypedAddressUrl = '/internal/checkout/State/ValidateFieldForTypedAddress';

        
        export function ValidateFieldForTypedAddress(params: ValidateFieldForTypedAddressParams): Promise<unknown> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<unknown>(`/internal/checkout/State/ValidateFieldForTypedAddress${formatParams(params)}`)
        }
    
    
        

        export type ValidateInvoiceAddressParams = paths['/internal/checkout/State/ValidateInvoiceAddress']['post']['parameters']['query'];

        export type ValidateInvoiceAddressSuccess = paths['/internal/checkout/State/ValidateInvoiceAddress']['post']['responses']['200']['content']['text/plain'];

        export const ValidateInvoiceAddressUrl = '/internal/checkout/State/ValidateInvoiceAddress';

        
        export function ValidateInvoiceAddress(params: ValidateInvoiceAddressParams): Promise<ValidateInvoiceAddressSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<ValidateInvoiceAddressSuccess>(`/internal/checkout/State/ValidateInvoiceAddress${formatParams(params)}`)
        }
    
    
        

        export type GetStaticImageParams = paths['/internal/StaticImage/GetStaticImage']['get']['parameters']['query'];

        export type GetStaticImageSuccess = paths['/internal/StaticImage/GetStaticImage']['get']['responses']['200']['content']['text/plain'];

        export const GetStaticImageUrl = '/internal/StaticImage/GetStaticImage';

        
        export function GetStaticImage(params: GetStaticImageParams): Promise<GetStaticImageSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetStaticImageSuccess>(`/internal/StaticImage/GetStaticImage${formatParams(params)}`)
        }
    
    
        

        export type SearchStoreByAddressParams = paths['/internal/Store/SearchStoreByAddress']['get']['parameters']['query'];

        export type SearchStoreByAddressSuccess = paths['/internal/Store/SearchStoreByAddress']['get']['responses']['200']['content']['text/plain'];

        export const SearchStoreByAddressUrl = '/internal/Store/SearchStoreByAddress';

        
        export function SearchStoreByAddress(params: SearchStoreByAddressParams): Promise<SearchStoreByAddressSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<SearchStoreByAddressSuccess>(`/internal/Store/SearchStoreByAddress${formatParams(params)}`)
        }
    
    
        

        export type SearchStoreByCoordinatesParams = paths['/internal/Store/SearchStoreByCoordinates']['get']['parameters']['query'];

        export type SearchStoreByCoordinatesSuccess = paths['/internal/Store/SearchStoreByCoordinates']['get']['responses']['200']['content']['text/plain'];

        export const SearchStoreByCoordinatesUrl = '/internal/Store/SearchStoreByCoordinates';

        
        export function SearchStoreByCoordinates(params: SearchStoreByCoordinatesParams): Promise<SearchStoreByCoordinatesSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<SearchStoreByCoordinatesSuccess>(`/internal/Store/SearchStoreByCoordinates${formatParams(params)}`)
        }
    
    
        

        export type ValidateParams = paths['/internal/Store/Validate']['post']['parameters']['query'];

        export type ValidateSuccess = paths['/internal/Store/Validate']['post']['responses']['200']['content']['text/plain'];

        export const ValidateUrl = '/internal/Store/Validate';

        
        export function Validate(params: ValidateParams): Promise<ValidateSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<ValidateSuccess>(`/internal/Store/Validate${formatParams(params)}`)
        }
    
    
        

        export type _GetSelectedProductParams = paths['/internal/Store/_GetSelectedProduct']['get']['parameters']['query'];

        export type _GetSelectedProductSuccess = paths['/internal/Store/_GetSelectedProduct']['get']['responses']['200']['content']['text/plain'];

        export const _GetSelectedProductUrl = '/internal/Store/_GetSelectedProduct';

        
        export function _GetSelectedProduct(params: _GetSelectedProductParams): Promise<_GetSelectedProductSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<_GetSelectedProductSuccess>(`/internal/Store/_GetSelectedProduct${formatParams(params)}`)
        }
    
    
        

        export type SetUserPreferredStoreParams = paths['/internal/Store/SetUserPreferredStore']['post']['parameters']['query'];

        export type SetUserPreferredStoreSuccess = paths['/internal/Store/SetUserPreferredStore']['post']['responses']['200']['content']['text/plain'];

        export const SetUserPreferredStoreUrl = '/internal/Store/SetUserPreferredStore';

        
        export function SetUserPreferredStore(params: SetUserPreferredStoreParams): Promise<SetUserPreferredStoreSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<SetUserPreferredStoreSuccess>(`/internal/Store/SetUserPreferredStore${formatParams(params)}`)
        }
    
    
        

        export type GetProductStoreStockModelParams = paths['/internal/Store/GetProductStoreStockModel']['get']['parameters']['query'];

        export type GetProductStoreStockModelSuccess = paths['/internal/Store/GetProductStoreStockModel']['get']['responses']['200']['content']['text/plain'];

        export const GetProductStoreStockModelUrl = '/internal/Store/GetProductStoreStockModel';

        
        export function GetProductStoreStockModel(params: GetProductStoreStockModelParams): Promise<GetProductStoreStockModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetProductStoreStockModelSuccess>(`/internal/Store/GetProductStoreStockModel${formatParams(params)}`)
        }
    
    
        

        

        

        export const UpdateStoreViewModelUrl = '/internal/myprofile/Store/UpdateStoreViewModel';

        
        export function UpdateStoreViewModel(): Promise<unknown> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<unknown>(`/internal/myprofile/Store/UpdateStoreViewModel`)
        }
    
    
        

        

        

        export const StoreGetUrl = '/internal/myprofile/Store/Get';

        
        export function StoreGet(): Promise<unknown> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<unknown>(`/internal/myprofile/Store/Get`)
        }
    
    
        

        export type ChooseParams = paths['/internal/myprofile/Store/Choose']['post']['parameters']['query'];

        export type ChooseSuccess = paths['/internal/myprofile/Store/Choose']['post']['responses']['200']['content']['text/plain'];

        export const ChooseUrl = '/internal/myprofile/Store/Choose';

        
        export function Choose(params: ChooseParams): Promise<ChooseSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<ChooseSuccess>(`/internal/myprofile/Store/Choose${formatParams(params)}`)
        }
    
    
        

        export type StoreSearchParams = paths['/internal/myprofile/Store/Search']['get']['parameters']['query'];

        export type StoreSearchSuccess = paths['/internal/myprofile/Store/Search']['get']['responses']['200']['content']['text/plain'];

        export const StoreSearchUrl = '/internal/myprofile/Store/Search';

        
        export function StoreSearch(params: StoreSearchParams): Promise<StoreSearchSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<StoreSearchSuccess>(`/internal/myprofile/Store/Search${formatParams(params)}`)
        }
    
    
        

        export type ListStoresParams = paths['/internal/booking/Store/ListStores']['get']['parameters']['query'];

        export type ListStoresSuccess = paths['/internal/booking/Store/ListStores']['get']['responses']['200']['content']['text/plain'];

        export const ListStoresUrl = '/internal/booking/Store/ListStores';

        
        export function ListStores(params: ListStoresParams): Promise<ListStoresSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<ListStoresSuccess>(`/internal/booking/Store/ListStores${formatParams(params)}`)
        }
    
    
        

        export type _SubscriptionProductModalParams = paths['/internal/Subscription/_SubscriptionProductModal']['get']['parameters']['query'];

        export type _SubscriptionProductModalSuccess = paths['/internal/Subscription/_SubscriptionProductModal']['get']['responses']['200']['content']['text/plain'];

        export const _SubscriptionProductModalUrl = '/internal/Subscription/_SubscriptionProductModal';

        
        export function _SubscriptionProductModal(params: _SubscriptionProductModalParams): Promise<_SubscriptionProductModalSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<_SubscriptionProductModalSuccess>(`/internal/Subscription/_SubscriptionProductModal${formatParams(params)}`)
        }
    
    
        

        

        export type CreatePendingSubscriptionSuccess = paths['/internal/pos/Subscription/CreatePendingSubscription']['post']['responses']['200']['content']['text/plain'];

        export const CreatePendingSubscriptionUrl = '/internal/pos/Subscription/CreatePendingSubscription';

        
        export function CreatePendingSubscription(): Promise<CreatePendingSubscriptionSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<CreatePendingSubscriptionSuccess>(`/internal/pos/Subscription/CreatePendingSubscription`)
        }
    
    
        export type GetPosSubscriptionInformationRequestBody = paths['/internal/pos/Subscription/GetPosSubscriptionInformation']['post']['requestBody']['content']['application/json'];

        

        export type GetPosSubscriptionInformationSuccess = paths['/internal/pos/Subscription/GetPosSubscriptionInformation']['post']['responses']['200']['content']['text/plain'];

        export const GetPosSubscriptionInformationUrl = '/internal/pos/Subscription/GetPosSubscriptionInformation';

        
        export function GetPosSubscriptionInformation(requestBody: GetPosSubscriptionInformationRequestBody): Promise<GetPosSubscriptionInformationSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postJSON<GetPosSubscriptionInformationSuccess>(`/internal/pos/Subscription/GetPosSubscriptionInformation`, requestBody)
        }
    
    
        

        

        export type OverviewModelSuccess = paths['/internal/myprofile/Subscription/OverviewModel']['get']['responses']['200']['content']['text/plain'];

        export const OverviewModelUrl = '/internal/myprofile/Subscription/OverviewModel';

        
        export function OverviewModel(): Promise<OverviewModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<OverviewModelSuccess>(`/internal/myprofile/Subscription/OverviewModel`)
        }
    
    
        

        

        export type GetAllSuccess = paths['/internal/myprofile/Subscription/GetAll']['get']['responses']['200']['content']['text/plain'];

        export const GetAllUrl = '/internal/myprofile/Subscription/GetAll';

        
        export function GetAll(): Promise<GetAllSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetAllSuccess>(`/internal/myprofile/Subscription/GetAll`)
        }
    
    
        

        

        

        export const SubscriptionDeliveryDeliveryGroupModelUrl = '/internal/myprofile/SubscriptionDelivery/DeliveryGroupModel';

        
        export function SubscriptionDeliveryDeliveryGroupModel(): Promise<unknown> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<unknown>(`/internal/myprofile/SubscriptionDelivery/DeliveryGroupModel`)
        }
    
    
        

        

        

        export const SubscriptionDeliveryGetPrivateAddressModelUrl = '/internal/myprofile/SubscriptionDelivery/GetPrivateAddressModel';

        
        export function SubscriptionDeliveryGetPrivateAddressModel(): Promise<unknown> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<unknown>(`/internal/myprofile/SubscriptionDelivery/GetPrivateAddressModel`)
        }
    
    
        

        export type SubscriptionDeliverySelectAndUpdatePrivateDeliveryAddressParams = paths['/internal/myprofile/SubscriptionDelivery/SelectAndUpdatePrivateDeliveryAddress']['post']['parameters']['query'];

        export type SubscriptionDeliverySelectAndUpdatePrivateDeliveryAddressSuccess = paths['/internal/myprofile/SubscriptionDelivery/SelectAndUpdatePrivateDeliveryAddress']['post']['responses']['200']['content']['text/plain'];

        export const SubscriptionDeliverySelectAndUpdatePrivateDeliveryAddressUrl = '/internal/myprofile/SubscriptionDelivery/SelectAndUpdatePrivateDeliveryAddress';

        
        export function SubscriptionDeliverySelectAndUpdatePrivateDeliveryAddress(params: SubscriptionDeliverySelectAndUpdatePrivateDeliveryAddressParams): Promise<SubscriptionDeliverySelectAndUpdatePrivateDeliveryAddressSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<SubscriptionDeliverySelectAndUpdatePrivateDeliveryAddressSuccess>(`/internal/myprofile/SubscriptionDelivery/SelectAndUpdatePrivateDeliveryAddress${formatParams(params)}`)
        }
    
    
        

        export type SubscriptionDeliverySelectAndUpdateCompanyDeliveryAddressParams = paths['/internal/myprofile/SubscriptionDelivery/SelectAndUpdateCompanyDeliveryAddress']['post']['parameters']['query'];

        export type SubscriptionDeliverySelectAndUpdateCompanyDeliveryAddressSuccess = paths['/internal/myprofile/SubscriptionDelivery/SelectAndUpdateCompanyDeliveryAddress']['post']['responses']['200']['content']['text/plain'];

        export const SubscriptionDeliverySelectAndUpdateCompanyDeliveryAddressUrl = '/internal/myprofile/SubscriptionDelivery/SelectAndUpdateCompanyDeliveryAddress';

        
        export function SubscriptionDeliverySelectAndUpdateCompanyDeliveryAddress(params: SubscriptionDeliverySelectAndUpdateCompanyDeliveryAddressParams): Promise<SubscriptionDeliverySelectAndUpdateCompanyDeliveryAddressSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<SubscriptionDeliverySelectAndUpdateCompanyDeliveryAddressSuccess>(`/internal/myprofile/SubscriptionDelivery/SelectAndUpdateCompanyDeliveryAddress${formatParams(params)}`)
        }
    
    
        

        export type SubscriptionDeliverySelectAndUpdateParcelShopDeliveryParams = paths['/internal/myprofile/SubscriptionDelivery/SelectAndUpdateParcelShopDelivery']['post']['parameters']['query'];

        export type SubscriptionDeliverySelectAndUpdateParcelShopDeliverySuccess = paths['/internal/myprofile/SubscriptionDelivery/SelectAndUpdateParcelShopDelivery']['post']['responses']['200']['content']['text/plain'];

        export const SubscriptionDeliverySelectAndUpdateParcelShopDeliveryUrl = '/internal/myprofile/SubscriptionDelivery/SelectAndUpdateParcelShopDelivery';

        
        export function SubscriptionDeliverySelectAndUpdateParcelShopDelivery(params: SubscriptionDeliverySelectAndUpdateParcelShopDeliveryParams): Promise<SubscriptionDeliverySelectAndUpdateParcelShopDeliverySuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<SubscriptionDeliverySelectAndUpdateParcelShopDeliverySuccess>(`/internal/myprofile/SubscriptionDelivery/SelectAndUpdateParcelShopDelivery${formatParams(params)}`)
        }
    
    
        

        export type SubscriptionDeliverySelectMatasDeliveryParams = paths['/internal/myprofile/SubscriptionDelivery/SelectMatasDelivery']['post']['parameters']['query'];

        export type SubscriptionDeliverySelectMatasDeliverySuccess = paths['/internal/myprofile/SubscriptionDelivery/SelectMatasDelivery']['post']['responses']['200']['content']['text/plain'];

        export const SubscriptionDeliverySelectMatasDeliveryUrl = '/internal/myprofile/SubscriptionDelivery/SelectMatasDelivery';

        
        export function SubscriptionDeliverySelectMatasDelivery(params: SubscriptionDeliverySelectMatasDeliveryParams): Promise<SubscriptionDeliverySelectMatasDeliverySuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<SubscriptionDeliverySelectMatasDeliverySuccess>(`/internal/myprofile/SubscriptionDelivery/SelectMatasDelivery${formatParams(params)}`)
        }
    
    
        

        export type SubscriptionDeliverySelectAndUpdateMatasDeliveryParams = paths['/internal/myprofile/SubscriptionDelivery/SelectAndUpdateMatasDelivery']['post']['parameters']['query'];

        export type SubscriptionDeliverySelectAndUpdateMatasDeliverySuccess = paths['/internal/myprofile/SubscriptionDelivery/SelectAndUpdateMatasDelivery']['post']['responses']['200']['content']['text/plain'];

        export const SubscriptionDeliverySelectAndUpdateMatasDeliveryUrl = '/internal/myprofile/SubscriptionDelivery/SelectAndUpdateMatasDelivery';

        
        export function SubscriptionDeliverySelectAndUpdateMatasDelivery(params: SubscriptionDeliverySelectAndUpdateMatasDeliveryParams): Promise<SubscriptionDeliverySelectAndUpdateMatasDeliverySuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<SubscriptionDeliverySelectAndUpdateMatasDeliverySuccess>(`/internal/myprofile/SubscriptionDelivery/SelectAndUpdateMatasDelivery${formatParams(params)}`)
        }
    
    
        

        export type SubscriptionDeliveryListMatasParcelShopsParams = paths['/internal/myprofile/SubscriptionDelivery/ListMatasParcelShops']['get']['parameters']['query'];

        export type SubscriptionDeliveryListMatasParcelShopsSuccess = paths['/internal/myprofile/SubscriptionDelivery/ListMatasParcelShops']['get']['responses']['200']['content']['text/plain'];

        export const SubscriptionDeliveryListMatasParcelShopsUrl = '/internal/myprofile/SubscriptionDelivery/ListMatasParcelShops';

        
        export function SubscriptionDeliveryListMatasParcelShops(params: SubscriptionDeliveryListMatasParcelShopsParams): Promise<SubscriptionDeliveryListMatasParcelShopsSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<SubscriptionDeliveryListMatasParcelShopsSuccess>(`/internal/myprofile/SubscriptionDelivery/ListMatasParcelShops${formatParams(params)}`)
        }
    
    
        

        export type SubscriptionDeliveryListParcelShopsParams = paths['/internal/myprofile/SubscriptionDelivery/ListParcelShops']['get']['parameters']['query'];

        export type SubscriptionDeliveryListParcelShopsSuccess = paths['/internal/myprofile/SubscriptionDelivery/ListParcelShops']['get']['responses']['200']['content']['text/plain'];

        export const SubscriptionDeliveryListParcelShopsUrl = '/internal/myprofile/SubscriptionDelivery/ListParcelShops';

        
        export function SubscriptionDeliveryListParcelShops(params: SubscriptionDeliveryListParcelShopsParams): Promise<SubscriptionDeliveryListParcelShopsSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<SubscriptionDeliveryListParcelShopsSuccess>(`/internal/myprofile/SubscriptionDelivery/ListParcelShops${formatParams(params)}`)
        }
    
    
        

        

        

        export const SubscriptionDeliveryCompanyDeliveryAddressUrl = '/internal/myprofile/SubscriptionDelivery/CompanyDeliveryAddress';

        
        export function SubscriptionDeliveryCompanyDeliveryAddress(): Promise<unknown> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<unknown>(`/internal/myprofile/SubscriptionDelivery/CompanyDeliveryAddress`)
        }
    
    
        

        export type SubscriptionDeliveryValidateFieldForShippingSwaggerParams = paths['/internal/myprofile/SubscriptionDelivery/ValidateFieldForShippingSwagger']['post']['parameters']['query'];

        export type SubscriptionDeliveryValidateFieldForShippingSwaggerSuccess = paths['/internal/myprofile/SubscriptionDelivery/ValidateFieldForShippingSwagger']['post']['responses']['200']['content']['text/plain'];

        export const SubscriptionDeliveryValidateFieldForShippingSwaggerUrl = '/internal/myprofile/SubscriptionDelivery/ValidateFieldForShippingSwagger';

        
        export function SubscriptionDeliveryValidateFieldForShippingSwagger(params: SubscriptionDeliveryValidateFieldForShippingSwaggerParams): Promise<SubscriptionDeliveryValidateFieldForShippingSwaggerSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<SubscriptionDeliveryValidateFieldForShippingSwaggerSuccess>(`/internal/myprofile/SubscriptionDelivery/ValidateFieldForShippingSwagger${formatParams(params)}`)
        }
    
    
        

        export type GetDetailsParams = paths['/internal/myprofile/SubscriptionDetails/GetDetails']['get']['parameters']['query'];

        export type GetDetailsSuccess = paths['/internal/myprofile/SubscriptionDetails/GetDetails']['get']['responses']['200']['content']['text/plain'];

        export const GetDetailsUrl = '/internal/myprofile/SubscriptionDetails/GetDetails';

        
        export function GetDetails(params: GetDetailsParams): Promise<GetDetailsSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetDetailsSuccess>(`/internal/myprofile/SubscriptionDetails/GetDetails${formatParams(params)}`)
        }
    
    
        

        export type GetMoreParams = paths['/internal/myprofile/SubscriptionDetails/GetMore']['get']['parameters']['query'];

        export type GetMoreSuccess = paths['/internal/myprofile/SubscriptionDetails/GetMore']['get']['responses']['200']['content']['text/plain'];

        export const GetMoreUrl = '/internal/myprofile/SubscriptionDetails/GetMore';

        
        export function GetMore(params: GetMoreParams): Promise<GetMoreSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetMoreSuccess>(`/internal/myprofile/SubscriptionDetails/GetMore${formatParams(params)}`)
        }
    
    
        

        export type GetAdhocOrderParams = paths['/internal/myprofile/SubscriptionDetails/GetAdhocOrder']['get']['parameters']['query'];

        export type GetAdhocOrderSuccess = paths['/internal/myprofile/SubscriptionDetails/GetAdhocOrder']['get']['responses']['200']['content']['text/plain'];

        export const GetAdhocOrderUrl = '/internal/myprofile/SubscriptionDetails/GetAdhocOrder';

        
        export function GetAdhocOrder(params: GetAdhocOrderParams): Promise<GetAdhocOrderSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetAdhocOrderSuccess>(`/internal/myprofile/SubscriptionDetails/GetAdhocOrder${formatParams(params)}`)
        }
    
    
        

        export type UpdateAdhocOrderParams = paths['/internal/myprofile/SubscriptionDetails/UpdateAdhocOrder']['post']['parameters']['query'];

        export type UpdateAdhocOrderSuccess = paths['/internal/myprofile/SubscriptionDetails/UpdateAdhocOrder']['post']['responses']['200']['content']['text/plain'];

        export const UpdateAdhocOrderUrl = '/internal/myprofile/SubscriptionDetails/UpdateAdhocOrder';

        
        export function UpdateAdhocOrder(params: UpdateAdhocOrderParams): Promise<UpdateAdhocOrderSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<UpdateAdhocOrderSuccess>(`/internal/myprofile/SubscriptionDetails/UpdateAdhocOrder${formatParams(params)}`)
        }
    
    
        

        export type GetConfirmUpdateInfoParams = paths['/internal/myprofile/SubscriptionUpdate/GetConfirmUpdateInfo']['get']['parameters']['query'];

        export type GetConfirmUpdateInfoSuccess = paths['/internal/myprofile/SubscriptionUpdate/GetConfirmUpdateInfo']['get']['responses']['200']['content']['text/plain'];

        export const GetConfirmUpdateInfoUrl = '/internal/myprofile/SubscriptionUpdate/GetConfirmUpdateInfo';

        
        export function GetConfirmUpdateInfo(params: GetConfirmUpdateInfoParams): Promise<GetConfirmUpdateInfoSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetConfirmUpdateInfoSuccess>(`/internal/myprofile/SubscriptionUpdate/GetConfirmUpdateInfo${formatParams(params)}`)
        }
    
    
        

        export type GetCancelParams = paths['/internal/myprofile/SubscriptionUpdate/GetCancel']['get']['parameters']['query'];

        export type GetCancelSuccess = paths['/internal/myprofile/SubscriptionUpdate/GetCancel']['get']['responses']['200']['content']['text/plain'];

        export const GetCancelUrl = '/internal/myprofile/SubscriptionUpdate/GetCancel';

        
        export function GetCancel(params: GetCancelParams): Promise<GetCancelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetCancelSuccess>(`/internal/myprofile/SubscriptionUpdate/GetCancel${formatParams(params)}`)
        }
    
    
        

        export type SubscriptionUpdateConfirmCancelParams = paths['/internal/myprofile/SubscriptionUpdate/ConfirmCancel']['get']['parameters']['query'];

        

        export const SubscriptionUpdateConfirmCancelUrl = '/internal/myprofile/SubscriptionUpdate/ConfirmCancel';

        
        export function SubscriptionUpdateConfirmCancel(params: SubscriptionUpdateConfirmCancelParams): Promise<unknown> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<unknown>(`/internal/myprofile/SubscriptionUpdate/ConfirmCancel${formatParams(params)}`)
        }
    
    
        

        export type OnHoldParams = paths['/internal/myprofile/SubscriptionUpdate/OnHold']['get']['parameters']['query'];

        export type OnHoldSuccess = paths['/internal/myprofile/SubscriptionUpdate/OnHold']['get']['responses']['200']['content']['text/plain'];

        export const OnHoldUrl = '/internal/myprofile/SubscriptionUpdate/OnHold';

        
        export function OnHold(params: OnHoldParams): Promise<OnHoldSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<OnHoldSuccess>(`/internal/myprofile/SubscriptionUpdate/OnHold${formatParams(params)}`)
        }
    
    
        

        export type SubscriptionUpdateUpdateParams = paths['/internal/myprofile/SubscriptionUpdate/Update']['get']['parameters']['query'];

        export type SubscriptionUpdateUpdateSuccess = paths['/internal/myprofile/SubscriptionUpdate/Update']['get']['responses']['200']['content']['text/plain'];

        export const SubscriptionUpdateUpdateUrl = '/internal/myprofile/SubscriptionUpdate/Update';

        
        export function SubscriptionUpdateUpdate(params: SubscriptionUpdateUpdateParams): Promise<SubscriptionUpdateUpdateSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<SubscriptionUpdateUpdateSuccess>(`/internal/myprofile/SubscriptionUpdate/Update${formatParams(params)}`)
        }
    
    
        

        export type GetSubscriptionPriceTotalParams = paths['/internal/myprofile/SubscriptionUpdate/GetSubscriptionPriceTotal']['get']['parameters']['query'];

        export type GetSubscriptionPriceTotalSuccess = paths['/internal/myprofile/SubscriptionUpdate/GetSubscriptionPriceTotal']['get']['responses']['200']['content']['text/plain'];

        export const GetSubscriptionPriceTotalUrl = '/internal/myprofile/SubscriptionUpdate/GetSubscriptionPriceTotal';

        
        export function GetSubscriptionPriceTotal(params: GetSubscriptionPriceTotalParams): Promise<GetSubscriptionPriceTotalSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetSubscriptionPriceTotalSuccess>(`/internal/myprofile/SubscriptionUpdate/GetSubscriptionPriceTotal${formatParams(params)}`)
        }
    
    
        

        

        export type GetGroupedBasketSuccess = paths['/internal/checkout/Summary/GetGroupedBasket']['get']['responses']['200']['content']['text/plain'];

        export const GetGroupedBasketUrl = '/internal/checkout/Summary/GetGroupedBasket';

        
        export function GetGroupedBasket(): Promise<GetGroupedBasketSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetGroupedBasketSuccess>(`/internal/checkout/Summary/GetGroupedBasket`)
        }
    
    
        

        export type UpdateHasUserCommentParams = paths['/internal/checkout/Summary/UpdateHasUserComment']['post']['parameters']['query'];

        

        export const UpdateHasUserCommentUrl = '/internal/checkout/Summary/UpdateHasUserComment';

        
        export function UpdateHasUserComment(params: UpdateHasUserCommentParams): Promise<unknown> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<unknown>(`/internal/checkout/Summary/UpdateHasUserComment${formatParams(params)}`)
        }
    
    
        

        export type ActivateGiftCardParams = paths['/internal/checkout/Summary/ActivateGiftCard']['post']['parameters']['query'];

        export type ActivateGiftCardSuccess = paths['/internal/checkout/Summary/ActivateGiftCard']['post']['responses']['200']['content']['text/plain'];

        export const ActivateGiftCardUrl = '/internal/checkout/Summary/ActivateGiftCard';

        
        export function ActivateGiftCard(params: ActivateGiftCardParams): Promise<ActivateGiftCardSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<ActivateGiftCardSuccess>(`/internal/checkout/Summary/ActivateGiftCard${formatParams(params)}`)
        }
    
    
        

        export type RemoveGiftCardLineParams = paths['/internal/checkout/Summary/RemoveGiftCardLine']['post']['parameters']['query'];

        export type RemoveGiftCardLineSuccess = paths['/internal/checkout/Summary/RemoveGiftCardLine']['post']['responses']['200']['content']['text/plain'];

        export const RemoveGiftCardLineUrl = '/internal/checkout/Summary/RemoveGiftCardLine';

        
        export function RemoveGiftCardLine(params: RemoveGiftCardLineParams): Promise<RemoveGiftCardLineSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<RemoveGiftCardLineSuccess>(`/internal/checkout/Summary/RemoveGiftCardLine${formatParams(params)}`)
        }
    
    
        

        

        export type ActivatePaymentByGiftCardSuccess = paths['/internal/checkout/Summary/ActivatePaymentByGiftCard']['post']['responses']['200']['content']['text/plain'];

        export const ActivatePaymentByGiftCardUrl = '/internal/checkout/Summary/ActivatePaymentByGiftCard';

        
        export function ActivatePaymentByGiftCard(): Promise<ActivatePaymentByGiftCardSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<ActivatePaymentByGiftCardSuccess>(`/internal/checkout/Summary/ActivatePaymentByGiftCard`)
        }
    
    
        

        

        export type DeactivatePaymentByGiftCardSuccess = paths['/internal/checkout/Summary/DeactivatePaymentByGiftCard']['post']['responses']['200']['content']['text/plain'];

        export const DeactivatePaymentByGiftCardUrl = '/internal/checkout/Summary/DeactivatePaymentByGiftCard';

        
        export function DeactivatePaymentByGiftCard(): Promise<DeactivatePaymentByGiftCardSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<DeactivatePaymentByGiftCardSuccess>(`/internal/checkout/Summary/DeactivatePaymentByGiftCard`)
        }
    
    
        

        

        export type AddGiftCardLineSuccess = paths['/internal/checkout/Summary/AddGiftCardLine']['post']['responses']['200']['content']['text/plain'];

        export const AddGiftCardLineUrl = '/internal/checkout/Summary/AddGiftCardLine';

        
        export function AddGiftCardLine(): Promise<AddGiftCardLineSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<AddGiftCardLineSuccess>(`/internal/checkout/Summary/AddGiftCardLine`)
        }
    
    
        

        

        export type GetGiftCardInfoSuccess = paths['/internal/checkout/Summary/GetGiftCardInfo']['get']['responses']['200']['content']['text/plain'];

        export const GetGiftCardInfoUrl = '/internal/checkout/Summary/GetGiftCardInfo';

        
        export function GetGiftCardInfo(): Promise<GetGiftCardInfoSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetGiftCardInfoSuccess>(`/internal/checkout/Summary/GetGiftCardInfo`)
        }
    
    
        

        

        export type GetUserCommentInfoSuccess = paths['/internal/checkout/Summary/GetUserCommentInfo']['get']['responses']['200']['content']['text/plain'];

        export const GetUserCommentInfoUrl = '/internal/checkout/Summary/GetUserCommentInfo';

        
        export function GetUserCommentInfo(): Promise<GetUserCommentInfoSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetUserCommentInfoSuccess>(`/internal/checkout/Summary/GetUserCommentInfo`)
        }
    
    
        export type CompleteSurveyRequestBody = paths['/internal/questionnaire/SurveyGizmo/CompleteSurvey']['post']['requestBody']['content']['application/json'];

        

        export type CompleteSurveySuccess = paths['/internal/questionnaire/SurveyGizmo/CompleteSurvey']['post']['responses']['200']['content']['text/plain'];

        export const CompleteSurveyUrl = '/internal/questionnaire/SurveyGizmo/CompleteSurvey';

        
        export function CompleteSurvey(requestBody: CompleteSurveyRequestBody): Promise<CompleteSurveySuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postJSON<CompleteSurveySuccess>(`/internal/questionnaire/SurveyGizmo/CompleteSurvey`, requestBody)
        }
    
    
        

        export type SurveyResponseIndexParams = paths['/internal/surveysystem/SurveyResponse/Index']['get']['parameters']['query'];

        export type SurveyResponseIndexSuccess = paths['/internal/surveysystem/SurveyResponse/Index']['get']['responses']['200']['content']['text/plain'];

        export const SurveyResponseIndexUrl = '/internal/surveysystem/SurveyResponse/Index';

        
        export function SurveyResponseIndex(params: SurveyResponseIndexParams): Promise<SurveyResponseIndexSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<SurveyResponseIndexSuccess>(`/internal/surveysystem/SurveyResponse/Index${formatParams(params)}`)
        }
    
    
        

        export type SurveyResponseCreateParams = paths['/internal/surveysystem/SurveyResponse/Create']['post']['parameters']['query'];

        export type SurveyResponseCreateSuccess = paths['/internal/surveysystem/SurveyResponse/Create']['post']['responses']['200']['content']['text/plain'];

        export const SurveyResponseCreateUrl = '/internal/surveysystem/SurveyResponse/Create';

        
        export function SurveyResponseCreate(params: SurveyResponseCreateParams): Promise<SurveyResponseCreateSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<SurveyResponseCreateSuccess>(`/internal/surveysystem/SurveyResponse/Create${formatParams(params)}`)
        }
    
    
        export type SurveyResponseUpdateRequestBody = paths['/internal/surveysystem/SurveyResponse/Update']['post']['requestBody']['content']['application/json'];

        

        export type SurveyResponseUpdateSuccess = paths['/internal/surveysystem/SurveyResponse/Update']['post']['responses']['200']['content']['text/plain'];

        export const SurveyResponseUpdateUrl = '/internal/surveysystem/SurveyResponse/Update';

        
        export function SurveyResponseUpdate(requestBody: SurveyResponseUpdateRequestBody): Promise<SurveyResponseUpdateSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postJSON<SurveyResponseUpdateSuccess>(`/internal/surveysystem/SurveyResponse/Update`, requestBody)
        }
    
    
        

        

        export type TermIndexSuccess = paths['/internal/Term/Index']['get']['responses']['200']['content']['text/plain'];

        export const TermIndexUrl = '/internal/Term/Index';

        
        export function TermIndex(): Promise<TermIndexSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<TermIndexSuccess>(`/internal/Term/Index`)
        }
    
    
        

        

        export type HideSuccess = paths['/internal/Term/Hide']['post']['responses']['200']['content']['text/plain'];

        export const HideUrl = '/internal/Term/Hide';

        
        export function Hide(): Promise<HideSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<HideSuccess>(`/internal/Term/Hide`)
        }
    
    
        

        export type TopBannerIndexParams = paths['/internal/TopBanner/Index']['get']['parameters']['query'];

        export type TopBannerIndexSuccess = paths['/internal/TopBanner/Index']['get']['responses']['200']['content']['text/plain'];

        export const TopBannerIndexUrl = '/internal/TopBanner/Index';

        
        export function TopBannerIndex(params: TopBannerIndexParams): Promise<TopBannerIndexSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<TopBannerIndexSuccess>(`/internal/TopBanner/Index${formatParams(params)}`)
        }
    
    
        export type TrackAdformClickRequestBody = paths['/internal/Tracking/TrackAdformClick']['post']['requestBody']['content']['application/json'];

        

        

        export const TrackAdformClickUrl = '/internal/Tracking/TrackAdformClick';

        
        export function TrackAdformClick(requestBody: TrackAdformClickRequestBody): Promise<unknown> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postJSON<unknown>(`/internal/Tracking/TrackAdformClick`, requestBody)
        }
    
    
        export type TrackAdformImpressionsRequestBody = paths['/internal/Tracking/TrackAdformImpressions']['post']['requestBody']['content']['application/json'];

        

        

        export const TrackAdformImpressionsUrl = '/internal/Tracking/TrackAdformImpressions';

        
        export function TrackAdformImpressions(requestBody: TrackAdformImpressionsRequestBody): Promise<unknown> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postJSON<unknown>(`/internal/Tracking/TrackAdformImpressions`, requestBody)
        }
    
    
        export type TrackEventRequestBody = paths['/internal/Tracking/TrackEvent']['post']['requestBody']['content']['multipart/form-data'];

        

        

        export const TrackEventUrl = '/internal/Tracking/TrackEvent';

        
        export function TrackEvent(requestBody: TrackEventRequestBody): Promise<unknown> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postFormData<unknown>(`/internal/Tracking/TrackEvent`, requestBody)
        }
    
    
        export type TrackEnhancedEcommercePromotionImpressionEventRequestBody = paths['/internal/Tracking/TrackEnhancedEcommercePromotionImpressionEvent']['post']['requestBody']['content']['application/json'];

        

        

        export const TrackEnhancedEcommercePromotionImpressionEventUrl = '/internal/Tracking/TrackEnhancedEcommercePromotionImpressionEvent';

        
        export function TrackEnhancedEcommercePromotionImpressionEvent(requestBody: TrackEnhancedEcommercePromotionImpressionEventRequestBody): Promise<unknown> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postJSON<unknown>(`/internal/Tracking/TrackEnhancedEcommercePromotionImpressionEvent`, requestBody)
        }
    
    
        export type VisualSearchResultIndexRequestBody = {Image: File};

        

        

        export const VisualSearchResultIndexUrl = '/internal/visualsearch/VisualSearchResult/Index';

        
        export function VisualSearchResultIndex(requestBody: VisualSearchResultIndexRequestBody): Promise<unknown> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postFormData<unknown>(`/internal/visualsearch/VisualSearchResult/Index`, requestBody)
        }
    
    
        

        export type FollowBarCodeParams = paths['/internal/visualsearch/VisualSearchResult/FollowBarCode']['get']['parameters']['query'];

        

        export const FollowBarCodeUrl = '/internal/visualsearch/VisualSearchResult/FollowBarCode';

        
        export function FollowBarCode(params: FollowBarCodeParams): Promise<unknown> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<unknown>(`/internal/visualsearch/VisualSearchResult/FollowBarCode${formatParams(params)}`)
        }
    
    
        

        export type WrappingIndexParams = paths['/internal/Wrapping/Index']['get']['parameters']['query'];

        export type WrappingIndexSuccess = paths['/internal/Wrapping/Index']['get']['responses']['200']['content']['text/plain'];

        export const WrappingIndexUrl = '/internal/Wrapping/Index';

        
        export function WrappingIndex(params: WrappingIndexParams): Promise<WrappingIndexSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<WrappingIndexSuccess>(`/internal/Wrapping/Index${formatParams(params)}`)
        }
    
    
        

        export type DeleteAllParams = paths['/internal/Wrapping/DeleteAll']['post']['parameters']['query'];

        export type DeleteAllSuccess = paths['/internal/Wrapping/DeleteAll']['post']['responses']['200']['content']['text/plain'];

        export const DeleteAllUrl = '/internal/Wrapping/DeleteAll';

        
        export function DeleteAll(params: DeleteAllParams): Promise<DeleteAllSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<DeleteAllSuccess>(`/internal/Wrapping/DeleteAll${formatParams(params)}`)
        }
    
    
        export type _UpdateRequestBody = paths['/internal/Wrapping/_Update']['post']['requestBody']['content']['application/json'];

        

        export type _UpdateSuccess = paths['/internal/Wrapping/_Update']['post']['responses']['200']['content']['text/plain'];

        export const _UpdateUrl = '/internal/Wrapping/_Update';

        
        export function _Update(requestBody: _UpdateRequestBody): Promise<_UpdateSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postJSON<_UpdateSuccess>(`/internal/Wrapping/_Update`, requestBody)
        }
    
    

        export * from './discriminators';
    