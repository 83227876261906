import styled from 'styled-components';
import { Typography } from 'components/generic/typography';
import { ColorClubMatasPlus3 } from 'autogen/design-tokens/tokens';
import { type BasketMessageMatasPlusViewModel } from 'autogen/swagger/all';
import format from 'helpers/translations';
import WithIcon from 'components/hoc/withIcon/WithIcon';
import dynamic from 'helpers/dynamic';
import BasketMessageContainer from '../shared/styles';

const StyledBasketMessageContainer = styled(BasketMessageContainer)`
    background-color: ${ColorClubMatasPlus3};
`;

const Checkmark = dynamic(() => import('icons/Checkmark.svg?react'));

export default function MatasPlusMessage({ model: { text } }: { model: BasketMessageMatasPlusViewModel }) {
    return (
        <StyledBasketMessageContainer>
            <WithIcon icon={<Checkmark />}>
                <Typography.Body as="span" themeTypes="small">
                    {format(text)}
                </Typography.Body>
            </WithIcon>
        </StyledBasketMessageContainer>
    );
}
