import { Price } from 'components/generic/productPrice';
import { Typography } from 'components/generic/typography';
import { type AdditionalSummaryLine as AdditionalSummaryLineModel } from 'autogen/swagger/all';
import Layout from 'components/Layout';

type AdditionalSummaryLine = Record<string, any> & { model: AdditionalSummaryLineModel };

export default function AdditionalSummaryLine({ model, ...restProps }: AdditionalSummaryLine) {
    return (
        <Layout.Flex.Row justifyContent="space-between" alignItems="center" {...restProps}>
            <Typography.Body as="span" themeTypes="mediumSemiBold">
                {model.text}
            </Typography.Body>
            <Price price={model.amount} />
        </Layout.Flex.Row>
    );
}
