import React, { useEffect, useState } from 'react';
import UAEventTracking, { type TrackingAction } from 'modules/tracking/UAEventTracking';
import type { TooltipModelMaybe } from 'autogen/swagger/all';
import TooltipNotificationAtom from 'components/shared/TooltipNotification/shared/recoil/atom';
import { useRecoilValue } from 'recoil';
import MyProfileTooltip from 'components/shared/TooltipNotification/MyProfileTooltip';
import useIsAuthenticated from 'hooks/user/useIsAuthenticated';
import { useTranslation } from 'react-i18next';
import { LogIn, MyProfile as MyProfileTrans } from 'autogen/translation-keys/trans-website-shared';
import { SessionMenuItem, SessionMenuAnchorItem } from 'components/SessionMenu/components/SessionMenuItem';
import type { MyProfileViewModel } from 'autogen/swagger/all';
import useLoginLink from 'hooks/user/useLoginLink';
import Default from 'modules/modal/layouts';
import loadHtmlModalFunc from 'modules/modal/helpers';
import { bindComponents } from 'modules/modalLink';
import Person from 'icons/Person.svg?react';
import { firstCharacterToLower } from 'modules/helpers/strings';
import { GA4Tracking } from 'modules/tracking/GA4';
import { HeaderItemText } from './shared/styles';
import PersonWithNudge from 'icons/PersonWithNudge.svg?react';
import { useDynamicStorage } from 'hooks/storage';

function MyProfileLoginModal({ model: { loginRequest } }: { model: MyProfileViewModel }) {
    const { t } = useTranslation();
    const { loginModal } = useLoginLink();
    const { loginLinkAction } = loginRequest;

    return (
        <SessionMenuItem
            icon={<Person aria-hidden />}
            onClick={async () => {
                const htmlModal = await loadHtmlModalFunc();
                const modal = htmlModal({ layout: { component: <Default /> } });
                modal({ url: loginLinkAction.url, onAfterContent: bindComponents });

                UAEventTracking({
                    eventCategory: 'header',
                    eventAction: firstCharacterToLower(loginModal?.trackingAction) as TrackingAction
                });

                GA4Tracking({
                    eventName: 'open_sso_modal',
                    context: 'header',
                    category: 'login'
                });
            }}
        >
            <HeaderItemText>{t(LogIn)}</HeaderItemText>
        </SessionMenuItem>
    );
}

function useLoginUrlTemplate(loginUrlTemplate: string): string {
    const [loginUrl, setLoginUrl] = useState(loginUrlTemplate);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect((): void => {
        const returnUrl = window.encodeURIComponent(window.location.href.substring(window.location.origin.length));

        setLoginUrl(`${loginUrl}&returnurl=${returnUrl}`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return loginUrl;
}

function MyProfileNotAuthenticated({
    model: { loginRequest },
    tooltip
}: {
    model: MyProfileViewModel;
    tooltip?: TooltipModelMaybe;
}) {
    const { loginLinkAction } = loginRequest;
    const { t } = useTranslation();
    const loginUrlTemplate = useLoginUrlTemplate(loginLinkAction.url ?? '');
    const isOpen = tooltip ? useRecoilValue(TooltipNotificationAtom(tooltip.tooltipType)) : false;
    const isAuthenticated = useIsAuthenticated();
    const [showNudgingStorage, setShowNudgingStorage] = useDynamicStorage(
        'sessionStorage',
        'shouldShowLoginNudgeKey',
        !isAuthenticated
    );

    useEffect(() => {
        if (!isAuthenticated && showNudgingStorage) {
            setShowNudging(true);
        }
    }, [isAuthenticated, showNudgingStorage]);

    const [showNudging, setShowNudging] = useState(false);

    const mutatedLoginLinkAction = { ...loginLinkAction, url: loginUrlTemplate }; // todo niels, what should be happening here?

    return (
        <MyProfileTooltip model={tooltip}>
            <SessionMenuAnchorItem
                icon={showNudging ? <PersonWithNudge /> : <Person />}
                action={mutatedLoginLinkAction}
                onClick={() => {
                    setShowNudgingStorage(false);
                    UAEventTracking({
                        eventCategory: 'header',
                        eventAction: 'goToLogin',
                        eventLabel: isOpen ? 'TooltipActive' : undefined
                    });

                    GA4Tracking({
                        eventName: 'open_sso_modal',
                        context: 'header',
                        category: 'login'
                    });
                }}
            >
                <HeaderItemText>{t(LogIn)}</HeaderItemText>
            </SessionMenuAnchorItem>
        </MyProfileTooltip>
    );
}

function MyProfileAuthenticated({ model: { myProfileLinkAction } }: { model: MyProfileViewModel }) {
    const { t } = useTranslation();

    return (
        <SessionMenuAnchorItem
            icon={<Person />}
            action={myProfileLinkAction}
            onClick={() => {
                UAEventTracking({
                    eventCategory: 'header',
                    eventAction: 'goToMyProfile'
                });

                GA4Tracking({
                    eventName: 'go_to_my_matas',
                    context: 'header',
                    category: 'navigation',
                    eventParams: [{ param_name: 'link_type', param_value: 'internal' }]
                });
            }}
        >
            <HeaderItemText>{t(MyProfileTrans)}</HeaderItemText>
        </SessionMenuAnchorItem>
    );
}

export default function MyProfile({ model, tooltip }: { model: MyProfileViewModel; tooltip?: TooltipModelMaybe }) {
    const {
        loginRequest: { loginLinkAction }
    } = model;
    const isAuthenticated = useIsAuthenticated();
    const { loginModal } = useLoginLink();

    if (!loginLinkAction.url && loginModal) {
        return <MyProfileLoginModal model={model} />;
    }

    if (!isAuthenticated) {
        return <MyProfileNotAuthenticated model={model} tooltip={tooltip} />;
    }

    return <MyProfileAuthenticated model={model} />;
}
